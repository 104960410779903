export interface TableProperty {
    isLazy: boolean;
    isResize: boolean;
    isFilterable: boolean;
    totalRecords: number;
}

export interface Header {
    name: string;
    fieldName: string;
    isFilterable: boolean;
    isSortable: boolean;
}

export class ReportTable {
    tableProperty: TableProperty;
    header: Header[];
    data: any;
}
