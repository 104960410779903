import {Injectable} from '@angular/core';
import {EcommerceAPIService} from '../../../core/http/ecommerce/ecommerce-api.service';
import {Observable} from 'rxjs';
import {
  ActivePlatform,
  CategoryHeader,
  CompanyList,
  ItemToggle,
  OutletDetail,
  StoreConfig,
  StoreEdit,
  StoreListing
} from '../../../core/models';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {AlertService} from '../../../core/http/alert/alert.service';
import {AccountApiService} from '../../../core/http/account/account-api.service';


@Injectable({
  providedIn: 'root'
})
export class EcommerceService {

  constructor(
      private ecommerceService: EcommerceAPIService,
      private accountService: AccountApiService,
      private authenticationService: AuthenticationService,
      private alertService: AlertService,
  ) {
  }

  public getOutletDetails(): Observable<OutletDetail[]> {
    const param = new HttpParams().set('loginId', this.authenticationService.getEmailId());
    return this.alertService.get('micro_service/store/details', param);
  }

  public getCompanyDetails(outletId): Observable<CompanyList[]> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', outletId);
    return this.ecommerceService.get('get/configured-company', param);
  }

  public getShopDetails(): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get(this.getShopDetailsURL(), param, null, true);
  }

  public updateAssignOutlet(outletId: any, selectedOrderId: any, selectedOrderRefId: any): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', outletId)
        .set('orderId', selectedOrderId)
        .set('onlineVendorId', selectedOrderRefId);
    return this.ecommerceService.put('assign-outlet', param, null, null, true);
  }

  public getOESStatus(): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('about-page', param);
  }

  private getShopDetailsURL(): string {
    if (this.authenticationService.getSkewCode() === '535') {
      if (window.location.pathname.includes('ecommerce/sales-order')) {
        return 'get/configured-store';
      } else {
        return 'get/store-list';
      }
    } else {
      return 'get/configured-store';
    }
  }

  public getPlatformDetails(): Observable<StoreListing[]> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', this.authenticationService.getOutlet());
    return this.ecommerceService.get('get/active/plateform', param);
  }

  public getCategoryDetails(outletSelected: string): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', outletSelected)
        .set('pageIndex', '1')
        .set('pageSize', '1000');
    return this.ecommerceService.get('get/category-master', param);
  }

  public getCategory(outletSelected: string): Observable<CategoryHeader> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', outletSelected)
        .set('pageIndex', '0')
        .set('pageSize', '10000');
    return this.ecommerceService.get('report/category', param);
  }

  public getCategoryForOrderEasy(outletSelected: string, filter: string, pageIndex: any, pageSize: any): Observable<CategoryHeader> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', outletSelected)
        .set('pageIndex', pageIndex)
        .set('pageSize', pageSize)
        .set('filter', filter);
    return this.ecommerceService.get('get/category-master', param);
  }

  public getCategoryForBannersOrderEasy(outletSelected: string, filter: string, pageIndex: any, pageSize: any): Observable<CategoryHeader> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', outletSelected)
        .set('pageIndex', pageIndex)
        .set('pageSize', pageSize)
        .set('filter', filter);
    return this.ecommerceService.get('get/category-master', param, null, true);
  }

  public getItemToggle(outletSelected: string, category: string, searchTag: string): Observable<ItemToggle[]> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        // tslint:disable-next-line: max-line-length
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', outletSelected)
        .set('searchTag', searchTag)
        .set('category', category);
    return this.ecommerceService.get('get/item/toggle', param, null, true);
  }

  public getDeliverySlotDetails(selectedOutlet, body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', selectedOutlet);
    return this.ecommerceService.post('get/v1/delivery-days', param, header, body, true);
  }

  public getItem(outletSelected: string, pageIndex: string, pageSize: any, categoryList: any, filter: string): Observable<ItemToggle[]> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', outletSelected)
        .set('pageIndex', pageIndex)
        .set('pageSize', pageSize)
        .set('orFilter', categoryList)
        .set('filter', filter);
    return this.ecommerceService.get('get/item-master', param, null, true);
  }

  public getItemDetail(outletSelected: string, pageIndex: any, pageSize: any, filter: string): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', outletSelected)
        .set('pageIndex', pageIndex)
        .set('pageSize', pageSize)
        .set('orFilter', '')
        .set('filter', filter);
    return this.ecommerceService.get('v2/get/item-master', param, null, false);
  }

  public getStoreDetailById(refId: string): Observable<StoreEdit> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('refId', refId);
    return this.ecommerceService.get('get/store/id/', param, null, true);
  }

  public updateStoreDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('update/store-detail', param, header, body, true);
  }

  public uploadImageUrbanpiper(outletSelected: string, body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('outletId', outletSelected);
    return this.ecommerceService.post('upload-image-aggregatorwise', param, header, body, false);
  }

  // post mapped food tag
  public uploadMappedFoodTag(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('foodTag', param, header, body, true);
  }

  public deleteImageUrbanpiper(outletSelected: string, body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('outletId', outletSelected);
    return this.ecommerceService.post('delete-image-aggregatorwise', param, header, body, false);
  }

  public getStoreConfiguration(): Observable<StoreConfig[]> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('get/store/toggle', param);
  }

  public getActivePlatformDetails(): Observable<ActivePlatform[]> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', '1');
    return this.ecommerceService.get('/urbanpiper/get/active/plateform', param);
  }

  public getAggregators(outletId: string): Observable<ActivePlatform[]> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', outletId);
    return this.ecommerceService.get('/urbanpiper/get/active/plateform', param, null, true);
  }


  public updateStoreConfiguration(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('update/store/toggle', param, header, body, false);
  }

  public saveItemConfig(outletSelected: string, body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('outletId', outletSelected)
        .set('aggStatus', 'true');
    if (this.authenticationService.getSkewCode().includes('739') || this.authenticationService.getSkewCode().includes('740')) {
      return this.ecommerceService.post('/map/item-jt', param, header, body, true);
    } else {
      return this.ecommerceService.post('update/item/toggle', param, header, body, true);
    }
  }

  public saveCatConfig(outletSelected: string, body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('/category-toggle', param, header, body, true);
  }


  public saveItemImage(itemId: string, formData: FormData, type: any): Observable<any> {
    const param = new HttpParams()
        .set('id', itemId)
        .set('type', type);
    return this.ecommerceService.postFormData('upload-image', param, null, formData, true);
  }

  public saveImageAsBase64(itemId: string, formData: FormData, type: any, channel?: string): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('id', itemId)
        .set('type', type)
        .set('channel', channel ? channel : 'All');
    return this.ecommerceService.postFormData('upload-images', param, null, formData, true);
  }

  public replaceImageAsBase64(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/update-image', param, header, body, true);
  }

  public deleteItemImage(body: any, type: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('type', type);
    return this.ecommerceService.post('delete-image', param, header, body, true);
  }


  public saveConfigCategory(outletSelected: string, body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('outletId', outletSelected);
    if (this.authenticationService.getSkewCode().includes('739') || this.authenticationService.getSkewCode().includes('740')) {
      return this.ecommerceService.post('/map/item-jt', param, header, body, false);
    } else {
      return this.ecommerceService.post('category-toggle', param, header, body, false);
    }
  }

  public getOrderStatus(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/order-status', param, header, body, true);
  }

  public getOrders(searchTag: string, status: string, pageIndex: number,
                   pageSize: number, showLoader: Boolean = false, outletId?: any, orderSource?: string, orderExpressSource?: string,
                   startFilterDate?: string, endFilterDate?: string,
                   filterSlotDate?, filterSlotId?): Observable<any> {
    if (startFilterDate === undefined || endFilterDate === undefined) {
      startFilterDate = '';
      endFilterDate = '';
      filterSlotDate = '';
      filterSlotId = '';
    }
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', outletId ? outletId : this.authenticationService.getOutlet())
        .set('searchTag', searchTag)
        .set('status', status)
        .set('source', orderSource ? orderSource : 'both')
        .set('startFilterDate', startFilterDate)
        .set('endFilterDate', endFilterDate)
        .set('deliverySlotDate', filterSlotDate)
        .set('deliverySlotId', filterSlotId)
        .set('deliveryType', orderExpressSource ? orderExpressSource : 'both')
        .set('pageIndex', pageIndex.toString())
        .set('pageSize', pageSize.toString());
    return this.ecommerceService.get('get/order', param, null, showLoader);
  }

  public getAllOrders(searchTag: string, status: string, channel:string, pageIndex: number,
                   pageSize: number, showLoader: Boolean = false, outletId?: any, orderSource?: string, orderExpressSource?: string,
                   startFilterDate?: string, endFilterDate?: string,
                   filterSlotDate?, filterSlotId?, deliveryBoyStatus?): Observable<any> {
    if (startFilterDate === undefined || endFilterDate === undefined) {
      startFilterDate = '';
      endFilterDate = '';
      filterSlotDate = '';
      filterSlotId = '';
    }
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const body = {
      'userId' : '' + this.authenticationService.getServiceUserId(),
      'vendor' : this.authenticationService.getSkewName(),
      'outletId' : outletId ? outletId : this.authenticationService.getOutlet(),
      'searchTag' : searchTag,
      'status': status,
      'channel': channel,
      'source': orderSource ? orderSource : 'both',
      'startFilterDate': startFilterDate,
      'endFilterDate': endFilterDate,
      'slotDate': filterSlotDate,
      'slotIds': filterSlotId && filterSlotId.length ? filterSlotId : '',
      'deliveryType' : orderExpressSource ? orderExpressSource : 'both',
      'pageIndex': pageIndex,
      'pageSize': pageSize,
      'sort': 'onlineReferenceNo desc',
      'deliveryBoyStatus': deliveryBoyStatus
    };
    return this.ecommerceService.post('v2/get/order', null, header, body, showLoader);
  }

  public getUnAssignedOrdersList(searchTag: string, status: string, pageIndex: number,
                                 pageSize: number, showLoader: Boolean = false, outletId?: any,
                                 startFilterDate?: string, endFilterDate?: string): Observable<any> {
    if (startFilterDate === undefined || endFilterDate === undefined) {
      startFilterDate = '';
      endFilterDate = '';
    }
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('searchTag', searchTag)
        .set('status', status)
        .set('startFilterDate', startFilterDate)
        .set('endFilterDate', endFilterDate)
        .set('pageIndex', pageIndex.toString())
        .set('pageSize', pageSize.toString());
    return this.ecommerceService.get('get/orders', param, null, showLoader);
  }

  public getRiderInfo(orderId: string, onlineReferenceNo: string): Observable<any> {
    const param = new HttpParams()
        .set('vendor', this.authenticationService.getSkewName())
        .set('orderId', orderId)
        .set('onlineReferenceNo', onlineReferenceNo)
        .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.ecommerceService.get('urbanpiper/get/rider-status', param, null, true);
  }

  public getOrderDetails(orderId: string, onlineReferenceNo: string, outletId?: any, userId?: any, vendor?: any, storeUserId?: any): Observable<any> {
    const param = new HttpParams()
        .set('userId', userId ? userId : '' + this.authenticationService.getServiceUserId())
        .set('vendor', vendor ? vendor : this.authenticationService.getSkewName())
        .set('outletId', outletId ? outletId : this.authenticationService.getOutlet())
        .set('onlineReferenceNo', onlineReferenceNo)
        .set('orderId', orderId)
      .set('storeUserId', storeUserId ? storeUserId : '');
    return this.ecommerceService.get('v2/get/order/id', param, null, true);
  }

  public getPaymentDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/payment-sales-order', param, header, body, true);
  }

  public executeQuery(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('run-sql', param, header, body, true);
  }

  public getPaymentOrderDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/payment/order/id', param, header, body, true);
  }

  public pushPaymentOrderDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/order/payment-status', param, header, body, true);
  }

  public syncAllPaymentDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/payment-orders-sync', param, header, body, true);
  }

  public getUnAssignedOrderDetails(orderId: string, onlineReferenceNo: string, outletId?: any): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('onlineReferenceNo', onlineReferenceNo)
        .set('orderId', orderId);
    return this.ecommerceService.get('get/unassigned-order/id', param, null, true);
  }

  public updateOrderStatus(orderId: string, onlineReferenceNo: string, toStatus: string, body: any, message: string): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('orderId', orderId)
        .set('onlineReferenceNo', onlineReferenceNo)
        .set('status', toStatus)
        .set('message', message);
    return this.ecommerceService.post('update/order/status', param, header, body, true);
  }

  public updateOrderStatusandPayment(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.post('/v1/update/order/status', param, header, body, true);
  }

  public updateAmazonOrderStatus(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('update/amazon-order-status', param, header, body, true);
  }

  public syncDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('ids', body);
    return this.ecommerceService.get('get/sync-status', param, header, true);
  }

  public pushOrderPOS(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('/push/order/pos', param, headers, false);
  }

  public getOrderStatusPOS(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get(`/get-status-master?verticalId=${this.authenticationService.getVerticalID()}`, param, headers, false);
  }

  public pullSalesOrderStatusFromPOS(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('/do/salesorder-sync', param, headers, false);
  }

  public pullChargeMasterFromPOS(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('/do/charge-sync', param, headers, false);
  }


  public pushSalesOrderStatusOnline(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('/push/salesorder-online', param, headers, false);
  }

  public pushSalesOrderToPOS(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('/push/order/pos', param, headers, false);
  }

  public pullSalesOrderFromOnline(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('/pull/salesorder-online', param, headers, false);
  }

  public pushSingleOrderPOS(onlineReferenceNo: string, onlineVendorSoNo: string): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('onlineReferenceNo', onlineReferenceNo)
        .set('onlineVendorSoNo', onlineVendorSoNo);
    return this.ecommerceService.get('/push/order/pos', param, headers, false);
  }

  public pushUrbanpiperOrderOnline(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('urbanpiper/push/order-status', param, headers, false);
  }

  public pushItemsOnline(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('push/item-online', param, headers, false);
  }

  public pullItemsPOS(outletSelected: string): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    let param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    param = param.append('outletId', outletSelected);
    return this.ecommerceService.get('do/item-sync', param, headers, false);
  }

  // tempFunctions

  public categorySyncService(outletSelected: string): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    let param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    param = param.append('outletId', outletSelected);
    return this.ecommerceService.get('do/category-sync', param, headers, false);
  }

  public pushcategorySyncService(outletSelected: string): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    let param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    param = param.append('outletId', outletSelected);
    return this.ecommerceService.get('push/category-online', param, headers, false);
  }

  public CustomerSyncService(outletSelected: string): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    let param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    param = param.append('outletId', outletSelected);
    return this.ecommerceService.get('do/customer-sync', param, headers, false);
  }

  public resetSyncService(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('reset-sync', param, headers, false);
  }

  public pullAllDataService(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('do/all-data-sync', param, headers, false);
  }

  public pullSalesReturnFromOnline(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('pull/sale-return-online', param, headers, false);
  }

  public pushSalesReturnToPos(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('push/sale-return-to-pos', param, headers, false);
  }

  public pullPendingSoQuantityFromPOS(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('do/pending-so-qty-sync', param, headers, false);
  }

  public pullPaymentTypesFromPos(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('sync/payment-types', param, headers, false);
  }

  public pushUrbanpiperDataOnlineService(outletSelected: string): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    let param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    param = param.append('outletId', outletSelected);
    return this.ecommerceService.get('/urbanpiper/push-items', param, headers, false);
  }

  public pullEanAndBarCodeFromPOS(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams();
    const body = {
      'userId' : this.authenticationService.getServiceUserId(),
      'vendor' : this.authenticationService.getSkewName()
    };
    return this.ecommerceService.post('v1/do/ean-sync', param, headers, body, true);
  }

  public getService(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('do/salesorder-sync', param, headers, false);
  }

  public taxSyncService(outletSelected: string): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    let param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    param = param.append('outletId', outletSelected);
    return this.ecommerceService.get('do/tax-sync', param, headers, false);
  }

  public pushtaxSyncService(outletSelected: string): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    let param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    param = param.append('outletId', outletSelected);
    return this.ecommerceService.get('push/tax-online', param, headers, false);
  }

  // tempFunctions

  public purchaseOrderSync(outletSelected: string): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', outletSelected);
    return this.ecommerceService.get('do/po-sync', param, headers, false);
  }

  public receiptNoteSync(outletSelected: string): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', outletSelected);
    return this.ecommerceService.get('do/sales-sync', param, headers, false);
  }

  public salesSync(outletSelected: string): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', outletSelected);
    return this.ecommerceService.get('do/sales-sync', param, headers, false);
  }

  public pullOrderStatusPOS(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('do/order-sync', param, headers, false);
  }

  public pullModifierGroupMaster(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('do/modifier-group-sync', param, headers, false);
  }

  public pullModifierMaster(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('do/modifier-sync', param, headers, false);
  }

  public getTrans(): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', this.authenticationService.getOutlet())
        .set('filter', 'status=pending');
    return this.ecommerceService.get('get/item-update/list', param);
  }

  public getTransDetail(transId: string): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('transId', transId)
        .set('outletId', this.authenticationService.getOutlet());
    return this.ecommerceService.get('get/item-update/id', param);
  }

  public updateTransStatus(transId: string, status: string): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('transId', transId)
        .set('status', status)
        .set('approvedBy', this.authenticationService.getUserName())
        .set('outletId', this.authenticationService.getOutlet());
    return this.ecommerceService.get('approve/item-update', param);
  }

  public SupplierSyncFromPOS(outletSelected: string): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('pull/supplier-from-pos', param, headers, false);
  }

  public supplierSyncOnline(outletSelected: string): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('push/supplier-online', param, headers, false);
  }

  public getItemReport(outletSelected: string, pageIndex: string, pageSize: any, categoryList: any, filter: string): Observable<ItemToggle[]> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', outletSelected)
        .set('pageIndex', pageIndex)
        .set('pageSize', pageSize)
        .set('orFilter', categoryList)
        .set('filter', filter);
    return this.ecommerceService.get('get/item-master/report', param, null, true);
  }

  public getMasterUpload(outletSelected: any): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', outletSelected);
    return this.ecommerceService.get('get/master-upload-status', param, null, true);
  }

  public getRecommendedItems(outletSelected: string, pageIndex: string, pageSize: any, categoryList: any, filter: string): Observable<ItemToggle[]> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', outletSelected)
        .set('pageIndex', pageIndex)
        .set('pageSize', pageSize)
        .set('orFilter', categoryList)
        .set('filter', filter);
    return this.ecommerceService.get('get/item-master/report', param, null, true);
  }

  public updateRecommendedItems(userId: any, outletId: any, itemId: any, recommendedItem: any, body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('outletId', outletId)
        .set('itemId', itemId)
        .set('isRecommendedItem', recommendedItem);
    return this.ecommerceService.post('/update/recommended-items', param, header, body, true);
  }

  public riderTemperatureUpdate(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('urbanpiper/update/rider-temperature', param, header, body, true);
  }

  public getAmazonDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('get/amazon-order-details', param, header, body, true);
  }

  public saveItemImageWithOutletId(itemId: string, formData: FormData, type: any, outletId: any): Observable<any> {
    const param = new HttpParams()
        .set('id', itemId)
        .set('outletId', outletId)
        .set('type', type);
    return this.ecommerceService.postFormData('upload-image', param, null, formData, true);
  }

  public saveItemImageAsBase64WithOutletId(itemId: string, formData: FormData, type: any, outletId: any): Observable<any> {
    const param = new HttpParams()
        .set('id', itemId)
        .set('outletId', outletId)
        .set('type', type);
    return this.ecommerceService.postFormData('upload-images', param, null, formData, true);
  }

  public getAmazonOrders(pageIndex: number, pageSize: number, showLoader: Boolean = false, outletId: any, filter: any, orFilter: any, sort: any): Observable<any> {
    const param = new HttpParams()
        .set('outletId', outletId ? outletId : this.authenticationService.getOutlet())
        .set('filter', filter)
        .set('orFilter', orFilter)
        .set('sort', sort)
        .set('pageIndex', pageIndex.toString())
        .set('pageSize', pageSize.toString());

    return this.ecommerceService.get('get/sales-orders', param, null, showLoader);
  }

  // Get all masters for Data flush
  getAllMastersForFlush(outletId?) {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', outletId ? outletId : '');
    return this.ecommerceService.post('v1/get-masters', param, header, null, true);
  }

  // Trigger OTP for Data flush
  triggerOTPForFlush(formTriggerData) {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.post('v1/trigger-otp', param, header, formTriggerData, true);
  }

  // Flush data for Data flush
  flushDataMasters(flushData) {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.post('v1/flush-data', param, header, flushData, true);
  }

  // Bulk upload image
  public saveBulkUploadImage(itemId: string, formData: FormData, type: any): Observable<any> {
    const param = new HttpParams()
        .set('id', itemId)
        .set('type', type);
    return this.ecommerceService.postFormBulkUploadImageData('upload-image', param, null, formData, false);
  }

  // Bulk upload image
  public saveBulkUploadAsBase64Image(itemId: string, formData: FormData, type: any): Observable<any> {
    const header = new HttpHeaders({ timeout: `${30000}`});
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('id', itemId)
        .set('type', type);
    return this.ecommerceService.postFormBulkUploadImageData('upload-images', param, header, formData, false);
  }

  // Get trouble shooting details
  getTroubleShootingDetails(formObj) {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.post('v1/data-sync/troubleshoot', param, header, formObj, true);
  }

  // Get amazon details for security
  public getSecurityAmazonDetails(body: any, type): Observable<any> {
    // const header = new HttpHeaders()
    // .set('responseType', 'arraybuffer' as 'json');
    const param = new HttpParams()
        .set('type', type);
    return this.ecommerceService.postPDF('get/amazon-order-file', param, null, body, true);
  }

  // Get retrieve pick up slots
  public getRetriveSlots(data: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('amazon/retrieve-pickup-slots', param, header, data, true);
  }

  // Post retrieve pick up slots
  postPickUpSlots(body) {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('update/amazon/pickup-time-slot', param, header, body, true);
  }

  // Get aggregator list details
  getAggregatorListDetails(formObj) {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('get/aggregator-list', param, header, formObj, true);
  }

  // Get menu publis list details
  getMenuPublishListDetails(formObj) {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post(`get/data/menu-publish`, param, header, formObj, true);
  }

  // Get publish status list
  getPublishStatusList(formObj) {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('get/status-list', param, header, formObj, true);
  }

  // Get item wise menu publish list
  getItemMenuStatusList(formObj, value) {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post(`get/menu-publish/${value}`, param, header, formObj, true);
  }
  // check data flush for urbanpiper
  checkDataFlushForUp(formObj) {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post(`urbanpiper/check-data-flush`, param, header, formObj, true);
  }
  // submit for menu publish
  submitForMenuPublish(formObj) {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post(`menu/publish`, param, header, formObj, true);
  }

  // Get pos dats sync details
  getPOSDataSyncDetails(formObj) {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post(`get/data/pos-data-sync`, param, header, formObj, true);
  }

  // Post pos dats sync details
  postPOSDataSyncDetails(formObj) {
    // const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.get(`do/all-data-sync`, param, null, true);
  }

  // check web report status
  checkWebReportStausDetails(outletId, formObj) {
    const header = new HttpHeaders({ timeout: `${30000}` }).set('Content-Type', 'application/json').set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams()
        .set('outletId', outletId);
    return this.ecommerceService.post(`check/posHealth`, param, header, formObj, true);
  }

  public resetSyncServiceLoader(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams();
    return this.ecommerceService.get('reset-sync', param, headers, true);
  }

  // Update option toggle for urbanpiper
  updateOptionToggle(outletId, formObj) {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('outletId', outletId);
    return this.ecommerceService.post('update/option/toggle', param, header, formObj, true);
  }

  // UnSync Orders Loading
  public unSyncOrderPOS(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('/push/order/pos', param, headers, true);
  }

  // Post pos dats sync details
  getAvailablePosOffers(formObj): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/available-offer', param, header, formObj, true);
  }

  // To get promo code report
  getPromoCodeOfferReport(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/customer-wise-promo', param, header, body, true);
  }

  postSubscriptionDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.accountService.post('v1/create-subscription', param, header, body, true);
  }

  sortOrStatusUpdatePosOffers(formObj): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    return this.ecommerceService.post('v1/sort-offer', param, header, formObj, true);
  }

  postBannerImageForPosOffers(formObj) {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    return this.ecommerceService.post('v1/add/offer-banner', param, header, formObj, true);
  }

  postOfferNotification(formObj) {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    return this.ecommerceService.post('v1/send/notification', param, header, formObj, true);
  }

  getAvailablePromoCodes(formObj): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/promo-code', param, header, formObj, true);
  }
  getInAppNotificationDetails(formObj): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/in-app-notification', param, header, formObj, true);
  }
  postInAppNotificationDetails(formObj): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    return this.ecommerceService.post('v1/in-app-notification', param, header, formObj, true);
  }


  getPromoCodeDetails(formObj): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/promo-code-mapping', param, header, formObj, true);
  }
  postAvailableInAppNotifications(formObj) {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    return this.ecommerceService.post('v1/in-app-notification', param, header, formObj, true);
  }
  postAvailablePromoCodes(formObj) {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    return this.ecommerceService.post('v1/add/promo-code', param, header, formObj, true);
  }
  postAvailableWebhookEvent(formObj) {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    return this.ecommerceService.post('v1/update/socket-event', param, header, formObj, true);
  }
  deleteWebhookEvent(formObj) {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    return this.ecommerceService.post('v1/webhook/delete/seller-event', param, header, formObj, true);
  }


  sortPromocodeOffers(formObj): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    return this.ecommerceService.post('v1/sort-promo-code', param, header, formObj, true);
  }

  // Get recommended package details for amazon
  public getRecommendedPackageDetails(formObj: any, outletId?: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('outletId', outletId ? outletId : this.authenticationService.getOutlet());
    return this.ecommerceService.post('amazon/retrieve-default-packageDetails', param, header, formObj, true);
  }

  // Get Item mapping fro Unicomerce api
  public getItemMapping(outletId): Observable<any> {
    // pageSize=1000000&filter=appliesOnline=1
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', outletId)
        .set('pageSize', '' + 1000000)
        .set('filter', 'appliesOnline=1');
    return this.ecommerceService.get('get/unicommerce-item-export', param, null, true);
  }

  // Update item mapping for unicommerce
  updateItemMapping(formObj) {
    const header = new HttpHeaders({ timeout: `${30000}` }).set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('update/unicommerce-item-mapping', param, header, formObj, true);
  }

  deleteOfferBannerImage(formObj): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.post('v1/delete-banner', param, header, formObj, true);
  }

  pullOfferDetailsFromPOS(): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.post('v1/do/offer-sync', param, header, null, true);
  }

  pullItemImageFromGoAct(): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.post('v1/do/item-image-sync', param, header, null, true);
  }

  calculateDeliveryCharge(totalAmount, selectedconfig, kms?, pincode?): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('orderAmount', totalAmount)
        .set('kms', selectedconfig === 3 ? kms : 0)
        .set('pincode', selectedconfig === 2 ? pincode : '');
    return this.ecommerceService.get('v2/calculate/delivery-charge', param, header, false);
  }

  getTaxMaster(): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.get('/get/tax-master', param, header, false);
  }

  postItemMapping(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/pharmacy/map-item', param, header, body, true);
  }

  public getModifierMaster(outletSelected?: string, pageIndex?: string, pageSize?: any, filter?: string, orFilter?: string, sort?: string): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    const body = {
      'userId' : '' + this.authenticationService.getServiceUserId(),
      'vendor' : this.authenticationService.getSkewName(),
      'outletId' : outletSelected ? outletSelected : '1',
      'filter' : filter,
      'orFilter': orFilter ? orFilter : '',
      'pageIndex': pageIndex,
      'pageSize': pageSize,
    };
    // if (sort) {
    //   body['sort'] = sort;
    // }
    return this.ecommerceService.post('v1/get/modifier-master', param, header, body, true);
  }

  public getModifierMappingMaster(outletSelected?: string, pageIndex?: string, pageSize?: any, filter?: string, orFilter?: string, sort?: string): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    const body = {
      'userId' : '' + this.authenticationService.getServiceUserId(),
      'vendor' : this.authenticationService.getSkewName(),
      'outletId' : outletSelected ? outletSelected : '1',
      'mapModifierGroup' : true,
      'filter' : filter,
      'orFilter': orFilter ? orFilter : '',
      'pageIndex': pageIndex,
      'pageSize': pageSize,
    };
    return this.ecommerceService.post('v1/get/modifier-mapping', param, header, body, true);
  }
  public getAllModifier(outletSelected?: string, pageIndex?: string, pageSize?: any, filter?: string, orFilter?: string): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    const body = {
      'userId' : '' + this.authenticationService.getServiceUserId(),
      'vendor' : this.authenticationService.getSkewName(),
      'outletId' : outletSelected ? outletSelected : '1',
      'filter' : filter,
      'orFilter': orFilter ? orFilter : '',
      'pageIndex': pageIndex,
      'pageSize': pageSize,
    };
    return this.ecommerceService.post('/v1/get/modifiers', param, header, body, true);
  }

  public updateModifier(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('/v1/modifiers', param, header, body, true);
  }

  public getItemMaster(outletSelected: string, pageIndex: string, pageSize: any, categoryList: any, filter: string, storeUserId?: any, catFilter?: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    const body = {
      'userId' : '' + this.authenticationService.getServiceUserId(),
      'vendor' : this.authenticationService.getSkewName(),
      'outletId' : outletSelected ? outletSelected : '1',
      'orFilter' : categoryList ? categoryList : '',
      'filter' : filter,
      'pageIndex': pageIndex,
      'pageSize': pageSize,
      'sort' : 'skuCode asc',
      'catFilter' : catFilter ? catFilter : ''
    };
    if (storeUserId) {
      body['storeId'] = storeUserId;
    }
    return this.ecommerceService.post('v1/get/item-master/report', param, header, body, true);
  }
  public getItemKeywordMaster(outletSelected: string, pageIndex: string, pageSize: any, categoryList: any, filter: string, storeUserId?: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    const body = {
      'userId' : '' + this.authenticationService.getServiceUserId(),
      'vendor' : this.authenticationService.getSkewName(),
      'outletId' : outletSelected ? outletSelected : '1',
      'pageIndex': pageIndex,
      'pageSize': pageSize,
      'sort' : 'skuCode asc',
    };
    return this.ecommerceService.post('v1/get/bulk-item-keyword', param, header, body, true);
  }
  public getCategoryMaster(outletSelected: string, pageIndex: any, pageSize: any, filter: string, orFilter: string, sort: string, storeUserId?: any, isCentralized?: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    const body = {
      'userId' : '' + this.authenticationService.getServiceUserId(),
      'vendor' : this.authenticationService.getSkewName(),
      'outletId' : outletSelected ? outletSelected : '1',
      'orFilter' : orFilter,
      'filter' : filter,
      'pageSize' : pageSize,
      'pageIndex': pageIndex,
      'sort' : sort,
      'isCentralized': isCentralized ? isCentralized : 0
    };
    if (storeUserId) {
      body['storeId']  = storeUserId;
    }
    return this.ecommerceService.post('v2/get/category-master', param, header, body, true);

  }
  getNoImageItemMaster(outletSelected: string, filter: any, orFilter: any, pageIndex: any, pageSize: any, storeUserId?: any): Observable<any> {
    const formObj = {
      'userId': this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
      'outletId': outletSelected ? outletSelected : '1',
      'filter': filter ? filter : '',
      'orFilter': orFilter ? orFilter : '',
      'pageIndex': pageIndex,
      'pageSize': pageSize,
      'sort' : 'skuCode asc',
    };
    if (storeUserId) {
      formObj['storeId'] = storeUserId;
    }
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/no-item-image', param, header, formObj, true);
  }
  getCategoryForItemMaster(selectedId): Observable<any> {
    const body = {
      'userId': this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
      'outletId': selectedId ? selectedId : '1'
    }
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/category-filter', param, header, body, true);
  }

  getModifierGroupList(): Observable<any> {
    const formObj = {
      'userId': this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
      'outletId': this.authenticationService.getSelectedOutletId() ? this.authenticationService.getSelectedOutletId() : '-1',
    };
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/modifier-group-master', param, header, formObj, true);
  }

  checkOrderSync(outletId): Observable<any> {
    const header = new HttpHeaders();
    const param = new HttpParams()
        .set('outletId', outletId);
    return this.ecommerceService.post('urbanpiper/check-order-sync', param, header, null, true);
  }

  pullConversionMasterFromPos() {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    const body = {
      'userId': this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
    };
    return this.ecommerceService.post('do/conversion-sync', param, header, body, true);
  }

  updateCategoryStatus(outletSelected: string, catValueId: any, checked: any, categorySortList: any, displayOnAppCountLimit: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    const body = {
      'userId' : '' + this.authenticationService.getServiceUserId(),
      'vendor' : this.authenticationService.getSkewName(),
      'outletId' : outletSelected ? outletSelected : '1',
      'categoryId': catValueId ,
      'displayOnApp' : checked,
      'displayOnAppCountLimit' : displayOnAppCountLimit,
      'categorySortList' : categorySortList
    };
    return this.ecommerceService.post('v1/update/category-status', param, header, body, true);
  }

  getNoImageCategoryMaster(outletSelected: string, pageIndex: any, pageSize: any, filter: string, orFilter: string, sort: string, storeUserId?: any, isCentralized?: any): Observable<any> {
    const formObj = {
      'userId': this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
      'outletId': outletSelected ? outletSelected : '1',
      'filter': filter,
      'orFilter': orFilter,
      'pageIndex': pageIndex,
      'pageSize': pageSize,
      'sort' : sort,
      'isCentralized': isCentralized ? isCentralized : 0
    };
    if (storeUserId) {
      formObj['storeId'] = storeUserId;
    }
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/no-category-image', param, header, formObj, true);
  }
  updateSortCategory(formObj): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    return this.ecommerceService.post('v1/sort/category', param, header, formObj, true);
  }

  updateCategoryLayout(categoryLayout): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    const body = {
      'userId' : '' + this.authenticationService.getServiceUserId(),
      'vendor' : this.authenticationService.getSkewName(),
      'categoryLayout' : categoryLayout
    };
    return this.ecommerceService.post('v1/add/category-layout', param, header, body, true);
  }

  getAllCustomers(pageIndex: any, pageSize: any, filter: string, orFilter: string, sort: string, isDetail: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    const body = {
      'userId' : '' + this.authenticationService.getServiceUserId(),
      'vendor' : this.authenticationService.getSkewName(),
      'outletId' : 0,
      'orFilter' : orFilter,
      'filter' : filter,
      'isDetail': isDetail,
      'pageSize' : pageSize,
      'pageIndex': pageIndex,
      'sort' : sort,
    };
    return this.ecommerceService.post('get/v1/customer-master/report', param, header, body, true);
  }

  updateCustomerStatus(isRestricted: any, companyId: any, mobile: any, restrictedRemarks: any, restrictedBy: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    const body = {
      'userId' : '' + this.authenticationService.getServiceUserId(),
      'vendor' : this.authenticationService.getSkewName(),
      'outletId' : 0,
      'isRestricted': isRestricted,
      'companyId' : companyId,
      'mobile': mobile,
      'restrictedRemarks': restrictedRemarks,
      'restrictedBy': restrictedBy,
    };
    return this.ecommerceService.post('update/customer-status', param, header, body, true);
  }
  sortImage(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    return this.ecommerceService.post('v1/sort/item-images', param, header, body, true);
  }

  setMinMaxOrderQty(outletSelected: string, skuCode: any, itemName: any, minOrderQty: any, maxOrderQty: any ): Observable<any> {
    const body = {
      'userId': this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
      'outletId': outletSelected ? outletSelected : '1',
      'skuCode': skuCode,
      'itemName': itemName,
      'minOrderQty': minOrderQty,
      'maxOrderQty': maxOrderQty,
    };
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    return this.ecommerceService.post('v1/add/min-max/order-item', param, header, body, true);
  }

  addItemKeyword(outletSelected: string, skuCode: any, itemName: any, itemKeyword: any, removedArray: any ): Observable<any> {
    const body = {
      'userId': this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
      'outletId': outletSelected ? outletSelected : '1',
      'skuCode': skuCode,
      'itemName': itemName,
      'itemKeyword': itemKeyword,
      'unmapItemKeyword': removedArray
    };
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    return this.ecommerceService.post('v1/add/item-keyword', param, header, body, true);
  }

  getAnalyticsReport(): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('customerId', this.authenticationService.getCustomerId())
        .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.ecommerceService.getAnalytics('analytics-report', param, header, false);
  }

  // Madurai Kadai
  getAggregatorDetails(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.accountService.post('v1/get/aggregator-profile', param, header, body, true);
  }


  saveAggregatorDetails(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.accountService.post('v1/add/aggregator-details', param, header, body, true);
  }

  addUpdateSupportDetails(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.accountService.post('v1/add/aggregator-support-details', param, header, body, true);
  }

  getStoreAggregatorDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.accountService.post('/v1/get/store-list', param, header, body, true);
  }
  getChannelDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('/v1/webhook/events', param, header, body, true);
  }


  getAggregatorList(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.accountService.post('/v1/get/aggregator-master', param, header, body, true);
  }


  getMyAggregatorDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.accountService.post('v1/get/my-aggregators', param, header, body, true);
  }


  updateAvailiabilityStatus(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.accountService.post('v1/add/publish-status', param, header, body, true);
  }

  generateClientAndSecretId(customerId): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    const body = {
      'userId' : '' + this.authenticationService.getServiceUserId(),
      'customerId' : customerId,
    };
    return this.accountService.post('v1/client', param, header, body, true);
  }

  getClientDetails(customerId): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    const body = {
      'userId' : '' + this.authenticationService.getServiceUserId(),
      'customerId' : customerId,
    };
    return this.accountService.post('v1/get-client', param, header, body, true);
  }

  bulkItemUpload(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v1/create/bulk-item-master', param, header, body, false);
  }
  bulkKeywordUpload(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v1/map/bulk-item-keyword', param, header, body, false);
  }

  bulkCategoryUpload(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.post('/v1/bulk/create-category', param, header, body, false);
  }

  addSingleItem(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v1/create/item-master', param, header, body, true);
  }

  addNewCategory(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v1/create/category-master', param, header, body, true);
  }

  bulkItemUpdate(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v1/update/bulk-item-master', param, header, body, false);
  }
  bulkCategoryUpdate(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.post('/v1/bulk/update-category', param, header, body, false);
  }

  realTimeItemMaster(outletSelected: string, skuCode: any) {
    const body = {
      'userId': this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
      'outletId': outletSelected ? outletSelected : '1',
      'skuCode': skuCode,
    };
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    return this.ecommerceService.post('get/real-time/item-master', param, header, body, true);
  }

  getOffers(outletSelected: string, mobileNo: any, totalOrderAmount: any, category: any) {
    const body = {
      'userId': this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
      'outletId': outletSelected ? outletSelected : '1',
      'companyId': 1,
      'mobileNo': mobileNo,
      'fromPortal': true,
      'totalOrderAmount': totalOrderAmount,
      'offerProduct': category
    };
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    return this.ecommerceService.post('v1/applicable-offer', param, header, body, false);
  }

  getManualPromoCode(outletSelected: string, mobileNo: any, totalOrderAmount: any, category: any, couponCode: any) {
    const body = {
      'userId': this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
      'outletId': outletSelected ? outletSelected : '1',
      'companyId': 1,
      'mobileNo': mobileNo,
      'totalOrderAmount': totalOrderAmount,
      'offerProduct': category,
      'couponCode': couponCode
    };
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    return this.ecommerceService.post('v2/applicable-promo', param, header, body, false);
  }

  editedOrderDetail(body: any) {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    return this.ecommerceService.post('/v1/edit-orders', param, header, body, false);
  }

  getOrderEditedHistory(outletSelected: string, orderId) {
    const body = {
      'userId': this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
      'outletId': outletSelected ? outletSelected : '1',
      'orderId': orderId
    };
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    return this.ecommerceService.post('/v1/get/order-edit/history/id', param, header, body, false);
  }

  public getCatParentCode(body: any, type: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.post('v2/get/category-master', param, header, body, true);
  }
  getTaxMasterReport(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/tax-master/report', param, header, body, true);
  }

  uploadImageToS3(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/update/master-attachment', param, header, body, true);
  }

  getStoreDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.accountService.post('v1/get/store-details', param, header, body, true);
  }

  getStoreProfileDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/store-setup', param, header, body, true);
  }

  getStoreOutletListDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v2/get/configured-stores', param, header, body, true);
  }

  deleteStoreProfileDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v1/delete/store-setup', param, header, body, true);
  }
  getStoreHistory(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/store-history', param, header, body, true);
  }

  getStoreOutletDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/store-availability', param, header, body, true);
  }

  saveChannelRemarks(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v1/update/store-availability', param, header, body, true);
  }

  sendCancelRequest(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.accountService.post('v1/add/store-request', param, header, body, true);
  }

  sendRequestRemarks(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.accountService.post('v1/add/request-remarks', param, header, body, true);
  }


  addStoreDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.accountService.post('v1/add/store-details', param, header, body, true);
  }

  updateStoreStatus(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.accountService.post('v1/add/update-status', param, header, body, true);
  }
  deleteSocket(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/delete/socket-event-header', param, header, body, true);
  }

  getOrderRatings(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/order-rating', param, header, body, true);
  }

  getPolicies(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.accountService.post('v1/get/privacy-policy', param, header, body, true);
  }

  savePolicies(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.accountService.post('v1/add/privacy-policy', param, header, body, true);
  }

  getConfiguration(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams()
        .set('productType', this.authenticationService.getSkewCode() === '910' ? 'MarketPlace' : 'marketplace_store');
    return this.ecommerceService.get('742/get-config', param, header, true);
  }

  saveConfiguration(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('productType', this.authenticationService.getSkewCode() === '910' ? 'MarketPlace' : 'marketplace_store');
    return this.ecommerceService.post('742/save-config', param, header, body, true);
  }

  getMarketplaceStoreConfiguration(): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams()
        .set('productType', this.authenticationService.getSkewCode() === '910' ? 'MarketPlace' : 'marketplace_store')
        .set('centralisedCategory', '1');
    return this.ecommerceService.get('742/get-config', param, header, true);
  }

  saveStoreConfiguration(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('productType', 'Store');
    return this.ecommerceService.post('742/save-config', param, header, body, true);
  }

  saveRatingRemakrs(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v1/add/approve-rating', param, header, body, true);
  }

  getRatingHistory(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/tracking-history', param, header, body, true);
  }

  getPublishHistory(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.accountService.post('v1/get/publish-history', param, header, body, true);
  }

  uploadAttachment(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v1/attachment-upload', param, header, body, true);
  }

  uploadAudio(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v1/upload-audio', param, header, body, true);
  }

  sendTicketToMarketPlace(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v2/add/support/default', param, header, body, true);
  }

  getTicketReports(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v2/get/support/default', param, header, body, true);
  }

  getSubscriptionDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.accountService.post('v1/get-subscription', param, header, body, true);
  }
  getSubscriptionDetail(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.accountService.post('v1/get-subscription/id', param, header, body, true);
  }


  getCollectionDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.accountService.post('v1/get/collections-history', param, header, body, true);
  }

  saveCollectionDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.accountService.post('v1/add/store-collections', param, header, body, true);
  }

  postStoreProfileDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v1/store-setup', param, header, body, true);
  }
  public uploadImg(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v1/upload-image', param, header, body, true);
  }

  getStdCodes(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/city-std-codes', param, header, body, true);
  }

  getManufacturerDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/manufacturer-master', param, header, body, true);
  }

  postManufacturerDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v1/manufacturer-master', param, header, body, true);
  }

  public getReturnOrder(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get-returns-hdr', param, header, body, true);
  }

  public getReturnOrderItem(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    return this.ecommerceService.post('v1/get-returns-dtl', null, header, body, true);
  }

  public updateReturnStatus(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/update-returns', param, header, body, true);
  }

  public getManufacturerMaster(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    return this.ecommerceService.post('v1/get/manufacturer-master', null, header, body, true);
  }

  public partialCancel(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/partial-order/cancel', param, header, body, true);
  }

  public getCancelReason(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    return this.ecommerceService.post('v1/get/cancel-reason-master', null, header, body, true);
  }

  public getParticularSubscriptionDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.accountService.post('v1/get/mapped-subscription', param, header, body, true);
  }

    updateSubscription(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.accountService.post('v1/map-subscription', param, header, body, true);
  }

  getReferralData(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.post('v1/get/referral-program', param, header, body, true);
  }

  saveReferralData(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.post('v1/referral-program', param, header, body, true);
  }

  itemStockExport(body: any): Observable<any> {
    const header = new HttpHeaders({ timeout: `${300000}` }).set('Accept', 'text/csv');
    const param = new HttpParams();
    return this.ecommerceService.getText('get/items/downloadCSV', param, header, body, true);
  }


  getItemToggleList(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/item-mapping', param, header, body, true);
  }

  updateItemListStatus(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/update/item-online-status', param, header, body, true);
  }

  getItemAvailablityHistory(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/item-status-history', param, header, body, true);
  }

  getOptionAvailablityHistory(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/option-status-history', param, header, body, true);
  }

  getOutletAvailablityList(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/store-availability-list', param, header, body, true);
  }

  getFoodtagList(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/food-tags', param, header, body, true);
  }

  updateTagsList(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/update/food-tags', param, header, body, true);
  }

  updateOptionStatus(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/update/option-online-status', param, header, body, true);
  }

  getStoreAvailablityList(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/store-availability-list', param, header, body, true);
  }
  getSellerEventList(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/webhook/get/seller-events', param, header, body, true);
  }
  getHistoryEventList(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/webhook/event-history', param, header, body, true);
  }

  syncData(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/webhook/sync-event', param, header, body, true);
  }
  updateStoreAvailablityList(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/update/store-availability-list', param, header, body, true);
  }

  getFoodAggregatorSettings(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/fa-settings', param, header, body, true);
  }

  getSyncConfiguration(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/sync-config', param, header, body, true);
  }

  updateFoodAggregatorSettings(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/update/fa-settings', param, header, body, true);
  }

  updateFoodAggregatorConfiguration(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/update/sync-config', param, header, body, true);
  }

  getMenuPublishDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/menu-publish', param, header, body, true);
  }

  updateMenuPublishDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/update/menu-publish', param, header, body, true);
  }

  getMenuPublishItems(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/items/menu-publish', param, header, body, true);
  }

  getMenuPublishCategories(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/category/menu-publish', param, header, body, true);
  }

  getMenuPublishModifierGroups(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/modifier-group/menu-publish', param, header, body, true);
  }

  getMenuPublishModifiers(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/modifier/menu-publish', param, header, body, true);
  }

  getItemStock(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('get/items', param, header, body, true);
  }


  saveStockImport(body: any): Observable<any> {
    const header = new HttpHeaders();
    const param = new HttpParams();
    return this.ecommerceService.postUpCsv('save/all-items', param, header, body, true);
  }

  updateMinStock(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/update/item-min-online-stock', param, header, body, true);
  }

  updateRiderTemperature(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceService.post('v1/update/rider-temperature', param, header, body, true);
  }

  public getCategoryMapping(body: any): Observable <any> {
    const header = new HttpHeaders({timeout : `${50000}`}).set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.ecommerceService.post('v1/category/items', param, header, body, true);
  }

  public updateCategoryMapping(body: any): Observable <any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.post('v1/category/manual-map', param, header, body, true);
  }

  public getUtcToIstConverter(utcDate) {
    const dateUTC = new Date(utcDate).getTime();
    const dateIST = new Date(dateUTC);
    dateIST.setHours(dateIST.getHours() + 5);
    dateIST.setMinutes(dateIST.getMinutes() + 30);
    return dateIST;
  }

  public autoCategoryMapping(body): Observable <any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceService.post('v1/category/auto-map', param, header, body, true);
  }

  acceptPolicy(body) {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    return this.accountService.post('v1/accept/privacy-policy', param, header, body, true);
  }
  checkPolicyApprovedOrNot() {
    const body = {
      'userId' : this.authenticationService.getServiceUserId(),
      'vendor' : this.authenticationService.getSkewName(),
      'outletId' : this.authenticationService.getOutlet(),
    };
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    return this.accountService.post('v1/get/policy-status', new HttpParams(), header, body, false);
  }
  postPickupAddressDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v1/pickup-master', param, header, body, true);
  }

  getPickupAddressDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams();
    return this.ecommerceService.post('v1/get/pickup-master', param, header, body, true);
  }
}
