import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {LoginDetailsService} from '../../services/login-details.service';

@Injectable({
  providedIn: 'root'
})
export class AccountApiService {

  private loginDetails = this.loginDetailService.getLoginDetails();

  constructor(private apiService: ApiService,
              private loginDetailService: LoginDetailsService
  ) {
    this.loginDetailService.themeDetailsList.subscribe(res => {
      if (res) {
        this.loginDetails = res;
      }
    })
  }

  get(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), showLoader?: Boolean): Observable<any> {
    return this.apiService.get(this.loginDetails.accountURL, path, param, header, showLoader)
        .pipe(catchError(this.handleError('Get - Accounts')));
  }

  put(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: Object = {}, showLoader?: Boolean): Observable<any> {
    return this.apiService.put(this.loginDetails.accountURL, path, param, header, body, showLoader)
        .pipe(catchError(this.handleError('Put - Accounts')));
  }
  post(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: Object = {}, showLoader?: Boolean): Observable<any> {
      return this.apiService.post(this.loginDetails.accountURL, path, param, header, body, showLoader)
          .pipe(catchError(this.handleError('Post - Accounts')));

  }


  postFormData(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: Object = {}, showLoader?: Boolean): Observable<any> {
    return this.apiService.postFormData(this.loginDetails.accountURL, path, param, header, body, showLoader)
        .pipe(catchError(this.handleError('Post - Accounts')));
  }

  delete(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), showLoader?: Boolean): Observable<any> {
    return this.apiService.delete(this.loginDetails.accountURL, path, param, header, showLoader)
        .pipe(catchError(this.handleError('Delete - Accounts')));
  }

  getIpAddress(header: HttpHeaders = new HttpHeaders()): Observable<any> {
    return this.apiService.getIp(header)
        .pipe(catchError(this.handleError('GetIP - Accounts')));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }
}
