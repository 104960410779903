import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Observable } from "rxjs/internal/Observable";
import { of } from "rxjs";
import { LoginDetailsService } from "../../services/login-details.service";

@Injectable({
  providedIn: "root",
})
export class SurveyApiService {

  private loginDetails = this.loginDetailService.getLoginDetails();
  private surveyURL = window.location.origin !== 'http://localhost:4200' ? (window.location.origin + '/mypulse/') : 'https://ordereasy-qa.gofrugalretail.com/mypulse/';

  constructor(
    private apiService: ApiService,
    private loginDetailService: LoginDetailsService
  ) {
    this.loginDetailService.themeDetailsList.subscribe(res => {
      if (res) {
        this.loginDetails = res;
      }
    }) ;
  }

  get(
    path: string,
    param: HttpParams = new HttpParams(),
    header: HttpHeaders = new HttpHeaders(),
    showLoader?: Boolean
  ): Observable<any> {
    return this.apiService
      .get(this.surveyURL, path, param, header, showLoader)
      .pipe(catchError(this.handleError("Get - Accounts")));
  }

  put(
    path: string,
    param: HttpParams = new HttpParams(),
    header: HttpHeaders = new HttpHeaders(),
    body: Object = {},
    showLoader?: Boolean
  ): Observable<any> {
    return this.apiService
      .put(this.surveyURL, path, param, header, body, showLoader)
      .pipe(catchError(this.handleError("Put - Accounts")));
  }

  post(
    path: string,
    param: HttpParams = new HttpParams(),
    header: HttpHeaders = new HttpHeaders(),
    body: Object = {},
    showLoader?: Boolean
  ): Observable<any> {
    return this.apiService
      .post(this.surveyURL, path, param, header, body, showLoader)
      .pipe(catchError(this.handleError("Post - Accounts")));
  }

  postFormData(
    path: string,
    param: HttpParams = new HttpParams(),
    header: HttpHeaders = new HttpHeaders(),
    body: Object = {},
    showLoader?: Boolean
  ): Observable<any> {
    return this.apiService
      .postFormData(this.surveyURL, path, param, header, body, showLoader)
      .pipe(catchError(this.handleError("Post - Accounts")));
  }

  delete(
    path: string,
    param: HttpParams = new HttpParams(),
    header: HttpHeaders = new HttpHeaders(),
    showLoader?: Boolean
  ): Observable<any> {
    return this.apiService
      .delete(this.surveyURL, path, param, header, showLoader)
      .pipe(catchError(this.handleError("Delete - Accounts")));
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }
}
