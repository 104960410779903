import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {LoginDetailsService} from '../../../../core/services/login-details.service';

@Component({
  selector: 'app-store-dashboard',
  templateUrl: './store-dashboard.component.html',
  styleUrls: ['./store-dashboard.component.scss']
})
export class StoreDashboardComponent implements OnInit, OnChanges {

  addon: any;
  url: any;
  productId: any;
  referrerProduct = 'ordereasy';
  @Input() userId = this.authService.getServiceUserId();
  @Input() refresh = false;
  @Input() type: any;
  skewCode: any;
  loginDetails = this.loginDetailsService.getLoginDetails();

  constructor(private route: ActivatedRoute,
              private sanitizer: DomSanitizer,
              private authService: AuthenticationService,
              private loginDetailsService: LoginDetailsService,
              private router: Router
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.onSameUrlNavigation = 'reload';
  }

  ngOnInit() {
    console.log('Initialized---');
    this.route.params.subscribe(params => {
      this.addon = (params['a']);
      this.referrerProduct = ((params['p']) && (params['p']).toLowerCase() === 'ecommerce') ? 'ordereasy' : params['p'] ;
      this.skewCode = (params['s']);
    });
    let tempUrl = '';
    if (this.type === 'marketplace_store') {
      this.skewCode = this.type;
      tempUrl = '&searchId=' + this.userId;
      this.referrerProduct = 'ordereasy';
    }
    if (this.type === 'store_marketplace') {
      this.skewCode = this.type;
      tempUrl = '&searchId=' + this.userId;
      this.referrerProduct = 'ordereasy';
    }
    let url = this.loginDetails.srDashboard
        + '&referrerProduct=' + this.referrerProduct
        + '&serviceUserId=' + this.authService.getServiceUserId()
        + '&skewName=' + this.skewCode;
    url = (tempUrl) ? url + tempUrl : url;
    this.url = this.getSafeUrl(url);
    console.log(this.url);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && !!changes.refresh) {
      this.ngOnInit();
    }
  }

  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
