import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {EcommerceService} from '../../../modules/integration/ecommerce/ecommerce.service';
import {NotificationService} from '../../../core/services/notification.service';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {UiCommonService} from '../../service/uiCommon.service';
import {ImageCompressService} from "../../../core/services/image-compress.service";
import { MatDrawer } from '@angular/material/sidenav';
import { MatPaginator } from '@angular/material/paginator';
import {Subscription} from "rxjs";
import {SharedService} from "../../service/shared.service";

@Component({
  selector: 'app-item-availability',
  templateUrl: './item-availability.component.html',
  styleUrls: ['./item-availability.component.scss']
})
export class ItemAvailabilityComponent implements OnInit {


  @Input() type: 'Items' | 'Modifiers' = 'Items';
  itemList = [];
  platforms = [];
  platformList = {
    list: [...this.platforms],
    isOpen: false,
    value: this.platforms[0]
  };
  selectedPlatform: any;
  base64Image: any = '';
  foodTags = [];
  foodTagsList = {
    list: [...this.foodTags],
    isOpen: false,
    value: [],
    search: '',
    searchList: [...this.foodTags]
  };
  outletList = {
    list: [
    ],
    searchList : [
    ],
    search: '',
    isOpen: false,
  };
  @ViewChild(MatDrawer, {static: false}) drawer: MatDrawer;
  toggleType: string;
  selectedItem: any;
  totalRecords = 0;
  pageIndex = 1;
  pageSize = 5;
  historyList: any;
  selectedVariant: any;
  variantModal = false;
  dynamicCurrency = this.authenticationService.getCurrency();
  searchFilter = '';
  categoryFilter = '';
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  orFilterList = [
    'categoryType1',
    'categoryType2',
    'categoryType3',
    'categoryType4',
    'categoryType5',
    'categoryType6',
    'categoryType7',
    'categoryType8',
    'categoryType9',
    'categoryType10',
  ];
  @ViewChild('fileIcon', {static: false}) fileElement: ElementRef;
  private readonly subscription: Subscription;

  constructor(
      private readonly ecommerceService: EcommerceService,
      private readonly notificationService: NotificationService,
      private readonly authenticationService: AuthenticationService,
      private readonly uiCommonService: UiCommonService,
      private readonly imageCompressService: ImageCompressService,
      private readonly sharedService: SharedService
  ) {
    this.subscription = this.sharedService.getOutletId().subscribe(
        event => {
          this.getOutletAvailablityList();
          this.getList();
        });
  }

  ngOnInit() {
    this.getOutletAvailablityList();
    this.getList();
  }

  createFilter() {
    const search = this.searchFilter ? ((this.type === 'Items' ? 'itemName:' : 'optionName:') + this.searchFilter) : '';
    const conversionFilter = this.type === 'Items' ? '' : 'type-Conv';
    const appliesOnlineFilter = 'appliesOnline=1';
    const filter = [search, conversionFilter, appliesOnlineFilter].filter(Boolean).join(',');
    console.log(filter);
    return filter;
  }

  createOrFilter() {
    if (!this.categoryFilter) {
      return '';
    }
    const filterList = this.orFilterList.map(x => {
      return x + ':' + this.categoryFilter;
    });
    return filterList.filter(Boolean).join(',');
  }


  public setInputs(type, searchFilter, categoryFilter) {
    this.type = type;
    this.searchFilter = searchFilter;
    this.categoryFilter = categoryFilter;
    this.pageIndex = 1;
    this.paginator.firstPage();
    this.getList();
  }

  getList() {
    if (this.type === 'Items') {
      this.getItemToggleList();
    } else if (this.type === 'Modifiers') {
      this.getModifiersList();
    }
  }


  getIcon(iconName: number) {
    switch (iconName) {
      case 1: // Veg
        return 'assets/icons/food-aggregator/veg.svg';
      case 2: // Non - Veg
        return 'assets/icons/food-aggregator/nonveg.svg';
      case 3: // Egg
        return 'assets/icons/food-aggregator/eggitarian.svg';
      default:
        return 'assets/icons/food-aggregator/veg.svg';
    }
  }

  onStatuToggle(item: any, event, type) {
    if (type === 'aggregatorStatus') {
      item.aggregatorStatus = event.checked;
      this.updateStatus([item], 'aggregatorStatus');
    } else if (type === 'variant') {
      item.aggretorStatus = event.checked;
    } else if (type === 'aggretorStatus') {
      item.aggretorStatus = event.checked;
      this.updateStatus([item], 'aggretorStatus');
    }
  }

  openSideDrawer(drawer: MatDrawer, item?: any, toggleType?: string) {
    this.drawer = drawer;
    if (item) {
      this.selectedItem = item;
    } else {
      this.selectedItem = {};
    }
    if (toggleType === 'itemHistory') {
      this.getItemStatusHistory();
    } else if (toggleType === 'FoodTag') {
      this.platformList = {
        list: [...this.platforms],
        isOpen: false,
        value: this.platforms[0]
      };
      this.getFoodtagList();
    } else if (toggleType === 'optionHistory') {
      this.getOptionAvailablityHistory();
    } else if (toggleType === 'Image') {
      const platformsList = this.platforms.map((platform, index) => ({...platform, index: index + 1}));
      this.platformList = {
        list: [...platformsList],
        isOpen: false,
        value: platformsList[0]
      };
      this.selectedPlatform = platformsList[0];
      this.base64Image = '';
      console.log(this.selectedItem);
    }
    this.toggleType = toggleType;
    this.drawer.toggle();
  }

  openModel(modalType: string, item?: any) {
    this[modalType] = true;
    if (modalType === 'variantModal') {
      this.selectedItem = {...item};
      this.selectedVariant = [...item.modifiers];
    }
  }

  closeModal(modalType: any) {
    this.selectedVariant = [];
    this.getList();
    this[modalType] = false;
  }

  closeDrawer() {
    this.getList()
    this.drawer.close();
  }

  openOption(config) {
    config.isOpen = !config.isOpen;
  }

  stopProbagation(event: MouseEvent) {
    event.stopPropagation();
  }

  onSearchList(type: any) {
    const result  = this[type].searchList.filter(item => item.tagName.toLowerCase().includes(this[type].search.toLowerCase()));
    this[type].list = result;
  }

  clearSearch() {
    this.foodTagsList.search = '';
    this.onSearchList('foodTagsList');
  }

  getItemToggleList() {
    const filter = this.createFilter();
    const orFilter = this.createOrFilter();
    const body = {
      'userId': this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
      'outletId': this.authenticationService.getSelectedOutletId(),
      'filter': filter,
      'orFilter': orFilter,
      'pageIndex': this.pageIndex,
      'pageSize': this.pageSize,
      'sort': 'itemName asc'
    };
    this.ecommerceService.getItemToggleList(body).subscribe(
        res => {
          if (res) {
            this.totalRecords = res.totalRecords;
            this.itemList = res.items;
          } else {
            this.notificationService.showWarn('Warn', res.message);
          }
        },
        error => {
          this.notificationService.showWarn('Warn', error);
        }
    );
  }

  getModifiersList() {
    const filter = this.createFilter();
    const outletId = this.authenticationService.getSelectedOutletId();
    const sort = 'optionName asc';
    this.ecommerceService.getModifierMappingMaster(outletId, String(this.pageIndex), this.pageSize, filter, '', sort).subscribe(
        response => {
          response.modifiers.sort((a, b) => Number(a.itemId).valueOf() - Number(b.itemId).valueOf());
          this.totalRecords = response.totalRecords;
          this.itemList = response.modifiers;
        },
        err => {
          this.notificationService.showWarn('Warn', err.message);
        }
    );
  }

  pageEvents(event: any) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getList();
  }

  updateStatus(itemList, type) {
    if (type === 'aggregatorStatus') {
      this.updateItemStatus(itemList);
    } else if (type === 'variant') {
      this.updateOptionStatus(itemList);
    } else if (type === 'aggretorStatus') {
      this.updateOptionStatus(itemList);
    }
  }

  itemMapper(list) {
    const resultList = list.reduce((resultArray, d, index) => {
      const found = resultArray.find(a => a.skuCode === d.skuCode);
      if (!found) {
        resultArray.push({
          'skuCode': d.skuCode,
          'itemName': d.itemName,
          'itemAggregatorStatus': d.aggregatorStatus
        });
      }
      return resultArray;
    }, []);
    return resultList;
  }

  optionMapper(list) {
    return list.map(d => (
        {
          'optionId': d.optionId,
          'optionName': d.optionName,
          'aggregatorStatus': d.aggretorStatus,
          'hqId': d.hqId
        }
    ))
  }

  updateItemStatus(itemList) {
    const result = this.itemMapper(itemList);
    const body = {
      'userId': this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
      'outletId': this.authenticationService.getSelectedOutletId(),
      'updatedBy': this.authenticationService.getEmailId(),
      'items': result
    };
    this.ecommerceService.updateItemListStatus(body).subscribe(
        res => {
          if (res && res.status) {
            this.notificationService.showSuccess('Success', res.message);
          } else {
            this.notificationService.showWarn('Warn', res.message);
          }
          this.getItemToggleList();
        }
    );
  }

  updateOptionStatus(optionList) {
    if (this.type === 'Items' && optionList.every(option => !option.aggretorStatus)) {
      this.notificationService.showWarn('Warn', 'Atleast choose one variant at a minimum');
    } else {
      console.log(optionList)
      const result = this.optionMapper(optionList);
      const body = {
        'userId': this.authenticationService.getServiceUserId(),
        'vendor': this.authenticationService.getSkewName(),
        'outletId': this.authenticationService.getSelectedOutletId(),
        'updatedBy': this.authenticationService.getEmailId(),
        'modifierAggregatorStatusLists': result
      };
      this.ecommerceService.updateOptionStatus(body).subscribe(
          res => {
            if (res && res.status) {
              this.notificationService.showSuccess('Success', res.message);
              this.closeModal('variantModal');
            } else {
              this.notificationService.showWarn('Warn', res.message);
            }
            this.getList();
          }
      );
    }
  }

  getItemStatusHistory() {
    const body = {
      'userId': this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
      'filter': 'itemId=' + this.selectedItem.skuCode + ',platform=All',
      'sort': 'changedTime desc'
    };
    this.ecommerceService.getItemAvailablityHistory(body).subscribe(res => {
      if (res) {
        this.historyList = res.itemStatusHistory;
      } else {
        this.notificationService.showWarn('Warn', res.message);
      }
    },
    error => {
      this.notificationService.showWarn('Warn', error);
    });
  }

  getOptionAvailablityHistory() {
    const body = {
      'userId': this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
      'filter': 'optionId=' + this.selectedItem.optionId + ',platform=All',
      'sort': 'changedTime desc'
    };
    this.ecommerceService.getOptionAvailablityHistory(body).subscribe(res => {
          if (res) {
            this.historyList = this.type === 'Items' ?  res.itemStatusHistory : res.modifierHistory;
          } else {
            this.notificationService.showWarn('Warn', res.message);
          }
        },
        error => {
          this.notificationService.showWarn('Warn', error);
        });
  }

  getStatus(action: any) {
    return action.toLowerCase().includes('enabled');
  }

  getOutletAvailablityList() {
    const body = {
      'userId': this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
      'channelType': 'FA'
    };
    this.ecommerceService.getOutletAvailablityList(body).subscribe(res => {
      if (res) {
        this.platforms = res.channelResponse;
      } else {
        this.notificationService.showWarn('Warn', res.message);
      }
    }, error => {
      this.notificationService.showWarn('Warn', error);
    });
  }

  getFoodtagList() {
    const body = {
      'filter': 'aggregatorId=' + this.platformList.value.channelId
    };
    this.ecommerceService.getFoodtagList(body).subscribe(res => {
      if (res) {
        this.foodTags = res.foodTagResponse;
        this.foodTagsList = {
          list: [...this.foodTags],
          isOpen: false,
          value: [],
          search: '',
          searchList: [...this.foodTags]
        };
        if (this.selectedItem.foodTagsList.length) {
          this.setFoodtagList();
        }
      } else {
        this.notificationService.showWarn('Warn', res.message);
      }
    }, error => {
      this.notificationService.showWarn('Warn', error);
    });
  }

  setFoodtagList() {
    this.selectedItem.foodTagsList.forEach(tag => {
      if (this.platformList.value.channelId === tag.aggregatorId) {
        this.updateValue(this.foodTagsList, tag, 'foodTagsList');
      }
    });
  }

  updateValue(list: any, type: any, updateType: string) {
    if (updateType === 'platform') {
      list.value = type;
      this.getFoodtagList();
    } else if (updateType === 'foodTagsList') {
      if (this.foodTagsList.value.includes(x => x.tagName === type.tagName)) {
        // this.
      } else {
        this.foodTagsList.value.push(type);
        const index = this.foodTagsList.searchList.findIndex(x => x.tagName === type.tagName);
        if (index > -1) {
          this.foodTagsList.searchList.splice(index, 1);
          this.foodTagsList.list = [...this.foodTagsList.searchList];
        }
      }
    } else if (updateType === 'image') {
      list.value = type;
      this.selectedPlatform = type;
      this.selectedItem.images = this.selectedItem.images.filter(x => x.imageId);
    }
  }

  removeTag(tag: any) {
    const index = this.foodTagsList.value.findIndex(x => x.tagName === tag.tagName);
    if (index > -1) {
      this.foodTagsList.value.splice(index, 1);
      this.foodTagsList.list.push(tag);
      this.foodTagsList.searchList.push(tag);
    }
  }

  clearAllTags() {
    this.foodTagsList = {
      list: [...this.foodTags],
      isOpen: false,
      value: [],
      search: '',
      searchList: [...this.foodTags]
    };
  }

  resetTags() {
    this.getFoodtagList();
  }

  updateFoodTags() {
    const deletedArray = this.difference(this.selectedItem.foodTagsList, this.foodTagsList.value);
    const body = {
      'userId': this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
      'outletId': this.authenticationService.getSelectedOutletId(),
      'skuCode': this.selectedItem.skuCode,
      'channelId': this.platformList.value.channelId,
      'channelName': this.platformList.value.channel,
      'mappedFoodTag': this.foodTagsList.value.map(x => x.tagName),
      'unMappedFoodTag': deletedArray.map(x => x.tagName)
    };
    this.ecommerceService.updateTagsList(body).subscribe(
        res => {
          if (res && res.status) {
            this.notificationService.showSuccess('Success', res.message);
            this.closeDrawer();
            this.getList();
          } else {
            this.notificationService.showWarn('Warn', res.message);
          }
        },
        error =>  {
          this.notificationService.showWarn('Warn', error);
        }
    );
  }

  difference(originalArray, modifiedArray) {
    return originalArray.filter((arr) => {
      return modifiedArray.findIndex(x => x.tagName === arr.tagName) < 0;
    });
  }

  async onImageLoad(image) {
    return new Promise(resolve => {
      this.uiCommonService.showLoader('blocking');
      const fr = new FileReader;
      if ((Number(image.size) / 1024) > 5000) {
        resolve(true);
      } else {
        fr.onload = () => {
          const img = new Image;
          img.onload = () => {
            if (img.width !== img.height) {
              resolve(true);
            } else {
              resolve(false);
            }

          };
          img.src = fr.result as any;
        };
        fr.readAsDataURL(image);
      }
    });
  }

  async onUploadImage(image: FileList) {
    // if (this.imageEditableFlag === 'false') {
    //   this.notifyService.showWarn('Warning', 'Can\'t ADD image As \'Sync Image from POS\' is enabled. Kindly disable & try ');
    //   return;
    // }
    const fileType = image[0].type;
    if (fileType.match(/image\/*/) === null) {
      this.notificationService.showWarn('Warning', 'Only jpg, jpeg and png files are allowed.');
      return;
    }
    if (fileType === 'image/gif' || fileType === 'image/svg+xml') {
      this.notificationService.showWarn('Warning', 'Only jpg, jpeg and png files are allowed.');
      return;
    }
    if ((image[0].size / 1024 / 1024) > 5) {
      this.notificationService.showWarn('Warning', 'Kindly upload images less than 5 MB');
      return;
    }
    if (await this.onImageLoad(image[0])) {
      this.uiCommonService.hideLoader();
      this.notificationService.showWarn('Warn', 'Kindly Upload Image of dimensions ratio 1:1');
      return;
    }
    this.base64Image = await this.imageCompressService.compressImage(image[0]);
    this.uiCommonService.hideLoader();
    if (this.base64Image) {
      this.selectedItem.images.unshift({imageUrl: this.base64Image, channel: this.selectedPlatform.channel});
    }
    this.fileElement.nativeElement.value = '';
  }

  changeCarousel(type, index) {
    if (type === 'next') {
      this.selectedPlatform = this.platformList.list.filter(x => x.index === index + 1)[0];
    } else {
      this.selectedPlatform = this.platformList.list.filter(x => x.index === index - 1)[0];
    }
    this.selectedItem.images = this.selectedItem.images.filter(x => x.imageId);
    this.platformList.value = this.selectedPlatform;
  }

  saveImage() {
    if (!this.base64Image) {
      this.notificationService.showWarn('Warn', 'Please Upload Image');
    } else {
      const formData = new FormData();
      console.log(this.base64Image);
      formData.append('image', this.base64Image);
      const skuCode = this.selectedItem.skuCode;
      this.ecommerceService.saveImageAsBase64(skuCode, formData, 'item', this.selectedPlatform.channel).subscribe((data: any) => {
        if (data.id) {
          this.notificationService.showSuccess('Success', 'Image Uploaded Successfully');
          this.getItemToggleList();
          this.closeDrawer();
        } else {
          this.notificationService.showWarn('Warning', data.message);
        }
      });
      this.fileElement.nativeElement.value = '';
      this.base64Image = '';
    }
  }

  getSelectedChannelImage() {
    const selectedImage = this.selectedItem.images.filter(x => x.channel === this.selectedPlatform.channel);
    return  selectedImage && selectedImage.length ? selectedImage[0].imageUrl : '';
  }
}
