import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {LoginDetailsService} from '../../services/login-details.service';

@Injectable({
  providedIn: 'root'
})
export class EcommerceAPIService {
  private loginDetails = this.loginDetailService.getLoginDetails();
  private mockApiPaths  = ['get-personalization' , 'v1/get/app-version' , 'v1/get/app-version-history' , 'v1/update/app-version'];

  constructor(private apiService: ApiService,
              private loginDetailService: LoginDetailsService
  ) {
    this.loginDetailService.themeDetailsList.subscribe(res => {
      if (res) {
        this.loginDetails = res;
      }
    }) ;
  }

  get(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), showLoader?: Boolean): Observable<any> {
    // const urls =  'http://demo3623982.mockable.io/ecommerce/' ;
    // const baseUrl =  this.mockApiPaths.includes(path) ? urls : this.loginDetails.ecomURL;
    // console.log('the base url is ' , baseUrl) ;
    const baseUrl =  this.loginDetails.ecomURL;
    return this.apiService.get(baseUrl , path, param, header, showLoader)
    .pipe(catchError(this.handleError('Get - ECommerce')));
  }

// tslint:disable-next-line: max-line-length
  put(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: Object = {}, showLoader?: Boolean): Observable<any> {
    return this.apiService.put(this.loginDetails.ecomURL, path, param, header, body, showLoader)
    .pipe(catchError(this.handleError('Put - ECommerce')));
  }

// tslint:disable-next-line: max-line-length
  post(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: Object = {}, showLoader?: Boolean): Observable<any> {
    // const urls =  'http://demo3623982.mockable.io/ecommerce/' ;
    // const baseUrl =  this.mockApiPaths.includes(path) ? urls : this.loginDetails.ecomURL;
    // if(path === 'v1/webhook/get/seller-events' || path === 'v1/webhook/event-history' ) {
    //   return this.apiService.post('http://demo3623982.mockable.io/' , path, param, header, body, showLoader)
    //       .pipe(catchError(this.handleError('Post - ECommerce')));
    // } else {
      const baseUrl =  this.loginDetails.ecomURL;
      return this.apiService.post(baseUrl , path, param, header, body, showLoader)
          .pipe(catchError(this.handleError('Post - ECommerce')));
    // }
  }

  postFormData(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: Object = {}, showLoader?: Boolean): Observable<any> {
    // const urls =  'http://demo3623982.mockable.io/ecommerce/' ;
    const baseUrl =  this.loginDetails.ecomURL;
    // const baseUrl =  this.mockApiPaths.includes(path) ? urls : this.loginDetails.ecomURL;
    return this.apiService.postFormData(baseUrl, path, param, header, body, showLoader)
      .pipe(catchError(this.handleError('Post - ECommerce')));
  }

// tslint:disable-next-line: max-line-length
  delete(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), showLoader?: Boolean): Observable<any> {
    return this.apiService.delete(this.loginDetails.ecomURL, path, param, header, showLoader)
    .pipe(catchError(this.handleError('Delete - ECommerce')));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }

  postFormBulkUploadImageData(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: Object = {}, showLoader?: Boolean): Observable<any> {
    return this.apiService.postFormBulkUploadImageData(this.loginDetails.ecomURL, path, param, header, body, showLoader)
      .pipe(catchError(this.handleError('Post - ECommerce')));
  }

  // For PDF in amazon
  postPDF(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: Object = {}, showLoader?: Boolean): Observable<any> {
    return this.apiService.postPDF(this.loginDetails.ecomURL, path, param, header, body, showLoader)
    .pipe(catchError(this.handleError('Post - ECommerce')));
  }

  getAnalytics(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), showLoader?: Boolean): Observable<any> {
    return this.apiService.get(this.loginDetails.analyticsURL, path, param, header, showLoader)
        .pipe(catchError(this.handleError('Get - Analytics')));
  }

  getText(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: Object = {}, showLoader?: Boolean): Observable<any> {
    return this.apiService.getText(this.loginDetails.ecomURL, path, param, header, body, showLoader)
        .pipe(catchError(this.handleError('Get - ECommerce')));
  }

  postUpCsv(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: FormData, showLoader?: Boolean): Observable<any> {
    return this.apiService.postUpCsv(this.loginDetails.ecomURL, path, param, header, body, showLoader)
        .pipe(catchError(this.handleError('Post - ECommerce')));
  }
}
