import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {EcommerceService} from '../../../../modules/integration/ecommerce/ecommerce.service';
import {SharedService} from '../../../service/shared.service';
import {NotificationService} from '../../../../core/services/notification.service';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {
    BannerNewsConfigService
} from '../../../../modules/integration/ecommerce/pages/configuration/banner-news-Config/banner-news-config-service';
import * as moment from 'moment';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MatDrawer} from '@angular/material/sidenav';
import {MapsAPILoader} from '@agm/core';

declare var google;

@Component({
    selector: 'app-order-details',
    templateUrl: './order-details.component.html',
    styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {

    @Input() selectedOrderId: string;
    @Input() selectedOrderRefId: string;
    @Input() isTaxSplit = 'false';
    @Input() editOrderConfig: any;
    @Input() selectedOutletID: any;
    @Output() closeDrawer = new EventEmitter<any>();
    @ViewChild('drawer', {static: false}) drawer: MatDrawer;
    showPartialCancel = false;
    selectedTab = 'Order Details';
    getPaymentHistoryDetails = [];
    onlinePaymentInfo: any;
    dynamicCurrency = '';
    blurModal = false;
    orderOutletID: any;
    deliveryDaysList: any = [];
    rescheduleModal = false;
    openRescheduleDropDown = false;
    selectedDeliveryDay: any;
    slotConfigurationList: any = [];
    rescheduleFlag = false;
    orderDetail: any;
    prescriptionList = [];
    availableItems = [];
    riderInfo: any;
    isSyncLoader = false;
    selectedPayment: any;
    rescheduleRemarks: any;
    rescheduleSlot: any = [];
    isOfferApplied = false;
    latitudeNo: number;
    distance = '0';
    longitudeNo: number;
    orderCompletedAt: any;
    getFeedback: any;
    isPharmacy = false;
    prescriptionUrl: any;
    cancelStatuses = [];
    channel: string;
    timer: any;
    riderOrderStatus: string;
    editedHistoryDetails: any;
    clockTimer: any;
    counter = -1;
    pomoValue = 5 * 60;
    counterValue = this.pomoValue;
    configTime = 20;
    delayedValue = '';
    seconds = '00';
    minutes = '20';
    totalAmount = 0;
    grandTotal: any;
    totalIncTax = 0;
    totalQuanitiy = 0;
    selectedModifierList = [];
    deliveryCharge = 0;
    totalTax = {
        'tax': 0
    };
    private modalRef: NgbActiveModal;
    selectSlot;
    expressDetails: any;
    deliverySlots: any;
    expressSlotList = [];
    selectedDate: any;
    isEditOrder = false;
    expressDeliveryCharge: any;
    expressDeliveryChargeAmount = 0;
    successMsg: any;
    isModalClosed = false;
    openCloseSaveConfirmModal = false;
    deliverySlotDetails = [];
    previousSlot = '';
    grandDelivery = 0;
    filterDateList = [];
    standardSlotList = [];
    private map: any;
    zoom: number;
    private geoCoder;
    isLatitudeCopied = false;
    isLongitudeCopied = false;
    rescheduleErrorMsg = '';
    rescheduleSlotErrorMsg = '';
    selectedIndex = 0;
    itemsToBeCanceled = [];
    cancelAllItem = false;
    temperatureList = [98.4, 98.5, 98.6, 98.7, 98.8];
    selectedTemperature = 0;

    constructor(
        private ecomService: EcommerceService,
        private sharedService: SharedService,
        private notifyService: NotificationService,
        public authenticationService: AuthenticationService,
        private bannerNewsService: BannerNewsConfigService,
        private mapsAPILoader: MapsAPILoader
    ) {
    }

    ngOnInit() {
        this.selectedTab = 'Order Details';
        this.selectedIndex = 0;
        this.availableItems = [];
        this.cancelAllItem = false;
        this.itemsToBeCanceled = [];
        this.showPartialCancel = false;
        console.log(this.selectedOrderId, this.selectedOrderRefId);
        this.getOrderDetails(this.selectedOrderId, this.selectedOrderRefId);
        this.dynamicCurrency = this.authenticationService.getCurrency();
        this.mapsAPILoader.load().then(() => {
            this.geoCoder = new google.maps.Geocoder;
        });
    }

    onTabChange(tabName) {
        this.selectedTab = tabName.tab.textLabel;
    }

    syncOrder(element) {
        element.style.pointerEvents = 'none';
        this.ecomService.pushSingleOrderPOS(this.selectedOrderRefId, this.selectedOrderId).subscribe(
            response => {
                if (response.status) {
                    this.notifyService.showSuccess('Success', response.message);
                    this.orderDetail.orderDetail.posPushStatus = true;
                } else {
                    this.notifyService.showWarn('Error', response.message);
                }
                element.style.pointerEvents = 'auto';
            }
        );
    }

    getTotalOrderAmount(orderItems: any) {
        let total = 0;
        if (orderItems && orderItems.length) {
            total = orderItems.reduce((acc, order) => {
                const modifierTotal = order.orderModifiers.reduce((inacc, item) => inacc + (item.modifierPrice * item.modifierQuantity), 0);
                return acc + modifierTotal + order.itemAmount;
            }, 0);
        }
        return total;
    }

    getDeliveryDayDetails() {
        this.blurModal = true;
        this.rescheduleModal = true;
        this.bannerNewsService.getConfigDeliveryDays(this.orderOutletID).subscribe(
            res => {
                if (res) {
                    this.deliveryDaysList = res.deliveryDays;
                    this.deliveryDaysList.forEach(element => {
                        element['disabled'] = !element.status;
                    });
                }
                this.blurModal = false;
            },
            (error) => {
                this.notifyService.showError('Error', error);
                this.blurModal = false;
            },
            () => {
            }
        );
    }

    openCloseModal(value) {
        this[value] = !this[value];
        this.clearOrderRescheduleDetails();
    }

    filterSlotDays(data) {
        this.openRescheduleDropDown = false;
        this.selectedDeliveryDay = data;
        this.slotConfigurationList = data.deliverySlots.filter(element => element.status === true && !(!element.timeRangeStatus || (element.orderCount >= element.maxDeliveryOrders)));
        this.slotConfigurationList = this.slotConfigurationList.map((slot) => {
            return {...slot, 'isChecked': false};
        });
        this.rescheduleSlot = [];
    }

    assignSlotForReschedule(selectedSlot) {
        this.rescheduleSlot = [];
        this.slotConfigurationList = this.slotConfigurationList.map((slot) => {
            return {...slot, isChecked: !!(slot.id === selectedSlot.id)};
        });
        this.rescheduleSlot.push(selectedSlot);
        this.rescheduleSlotErrorMsg = '';
    }

    audioReload() {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            const audioElement = document.getElementById('order-audio-remarks') as HTMLAudioElement;
            if (audioElement) {
                audioElement.load();
            }
        }, 500);
    }

    clearOrderRescheduleDetails() {
        this.selectedDeliveryDay = null;
        this.rescheduleSlot = [];
        this.rescheduleRemarks = null;
        this.slotConfigurationList = [];
        this.rescheduleErrorMsg = '';
        this.rescheduleSlotErrorMsg = '';
    }

    getRiderInfo() {
        // element.disabled = true;
        this.channel = this.orderDetail.orderDetail.channel.toUpperCase();
        this.riderOrderStatus = this.orderDetail.orderDetail.status.toUpperCase();
        //  this.notifyService.showWarn('warning', 'This feature is under development. Sorry for the inconvenience');
        this.ecomService.getRiderInfo(this.selectedOrderId, this.selectedOrderRefId).subscribe(
            res => {
                if (res) {
                    if (res.message !== 'Order Id Not Found') {
                        this.riderInfo = res;
                    }
                }
            },
            (err) => {
            },
            () => {
                // element.disabled = false;
            }
        );
    }

    getOrderDetails(orderSoNo, onlineReferenceNo) {
        this.isOfferApplied = false;
        this.selectedOrderId = orderSoNo;
        this.selectedOrderRefId = onlineReferenceNo;
        this.riderInfo = null;
        this.isSyncLoader = false;
        this.selectedPayment = '';
        this.orderDetail = [];
        this.prescriptionList = [];
        this.availableItems = [];
        console.log(this.selectedOrderId, this.selectedOrderRefId, this.selectedOutletID);
        this.ecomService.getOrderDetails(this.selectedOrderId, this.selectedOrderRefId, this.selectedOutletID).subscribe(res => {
            if (res) {
                this.latitudeNo = Number(res.shippingDetail.latitude);
                this.longitudeNo = Number(res.shippingDetail.longitude);
                this.distance = res.shippingDetail.kmsValue.toFixed(1) + ' Km';
                this.calculateDistance(this.latitudeNo, this.longitudeNo, res.posCredentialsJson.latitude, res.posCredentialsJson.longitude);
                this.orderDetail = res ? res : [];
                this.counterTimer();
                if (this.orderDetail && this.orderDetail.orderDetail.checkoutNotesVoiceUrl) {
                    this.audioReload();
                }
                if (this.orderDetail === 0) {
                    this.notifyService.showWarn('warn', 'No orders details fetched');
                }
                if (this.orderDetail.orderHistory.length > 0) {
                    this.orderCompletedAt = this.orderDetail.orderHistory[(this.orderDetail.orderHistory.length) - 1].updatedAt;
                }
                if (this.orderDetail.feedback) {
                    this.getFeedback = parseInt(this.orderDetail.feedback.ratings);
                } else {
                    this.getFeedback = 0;
                }

                this.availableItems = this.orderDetail.orderItems.filter((item) => !item.isCancelled).map(item => {
                    return {...item, cancellationReason: '', cancelled: false, isOpen: false};
                });

                this.isPharmacy = (this.orderDetail.orderDetail.status.toLowerCase() === 'prescription review in progress');
                this.getDeliverySlotDetails();
                if (this.isPharmacy) {
                    this.prescriptionUrl = this.orderDetail.prescriptionList[0].fileUrl;
                }
                // this.checkWidth();
                const getPayHistory = this.orderDetail.orderPaymentHistory.length > 0 ? this.orderDetail.orderPaymentHistory : [];
                this.getPaymentHistoryDetails = getPayHistory.reverse();
                this.onlinePaymentInfo = this.orderDetail.onlinePaymentInfo.length > 0 ? this.orderDetail.onlinePaymentInfo[0] : null;
                this.orderOutletID = this.orderDetail.orderHistory[0].outletId;
                if (this.authenticationService.getSkewCode() === '535') {
                    this.getRiderInfo();
                }
                this.clearAllValues();
                if (this.editOrderConfig) {
                    this.getOrderEditedHistory();
                }
            }
        });
    }

    getOrderEditedHistory() {
        const orderId = this.orderDetail.orderDetail.orderId;
        this.ecomService.getOrderEditedHistory(this.selectedOutletID, orderId).subscribe(
            response => {
                this.editedHistoryDetails = response;
            },
            (err) => {
                this.notifyService.showError('Server', 'Something went wrong! Try again after sometime.');
            }
        );
    }

    counterTimer() {
        clearInterval(this.clockTimer);
        const currentTime = moment();
        const updateTime = moment(this.orderDetail.orderDetail.createdTime, ['YYYY-MM-DD HH:mm:ss']);
        const diffTime = moment.duration(currentTime.diff(updateTime));
        const diffHrs = parseInt(String(diffTime.asHours()), 10);
        const diffMins = parseInt(String(diffTime.asMinutes()), 10);
        const diffDays = parseInt(String(diffTime.asDays()), 10);
        this.counter = -1;
        this.counterValue = Number(this.configTime) * 60;
        this.delayedValue = '';
        if (diffMins < this.configTime) {
            this.counterValue = (this.configTime - Number(diffMins)) * 60;
            if (this.counter === -1 && this.counterValue > 0) {
                this.clockTimer = setInterval(() => {
                    const rest = --this.counterValue;
                    const min = Math.floor(rest / 60);
                    const sec = Math.floor(rest % 60);
                    this.seconds = sec.toString().padStart(2, '0');
                    this.minutes = min.toString().padStart(2, '0');
                    if (rest === 0) {
                        this.counterTimer();
                        clearInterval(this.clockTimer);
                    }
                }, 1000);
            }
        } else {
            if (diffMins % 60 > 1) {
                this.delayedValue = String(diffHrs) + ' hrs ' + String((diffMins) - (60 * diffHrs)) + ' mins';
            } else {
                this.delayedValue = String(diffMins) + ' mins';
            }
        }
    }

    clearAllValues() {
        this.totalAmount = this.grandTotal = this.totalTax.tax = this.totalQuanitiy = this.deliveryCharge = this.totalIncTax = 0;
        this.selectSlot = null;
        this.prescriptionList.length = 0;
        this.isEditOrder = false;
        this.selectedModifierList = [];
        this.successMsg = false;
        this.isModalClosed = false;
        this.openCloseSaveConfirmModal = false;
        this.previousSlot = '';
        this.deliveryCharge = this.grandDelivery = this.expressDeliveryChargeAmount = 0;
    }

    getDeliverySlotDetails() {
        const body = {
            'userId': this.authenticationService.getServiceUserId(),
            'vendor': this.authenticationService.getSkewName(),
            'outletId': this.selectedOutletID
        };
        this.ecomService.getDeliverySlotDetails(this.selectedOutletID, body).subscribe(
            res => {
                if (res.expressDelivery) {
                    this.expressDetails = res.expressDelivery;
                    this.expressDeliveryCharge = (this.expressDetails && this.expressDetails.status === 'Active') ? this.expressDetails.amount : 0;
                }
                if (res.deliveryDays.length > 0) {
                    this.deliverySlotDetails = res.deliveryDays;
                    this.deliverySlotDetails.forEach(x => {
                        x['isActive'] = false;
                        this.isExpress(x);
                    });
                    this.setActive(this.deliverySlotDetails[0]);
                }
            },
            error => {
                this.notifyService.showWarn('Warn', error.message);
            }
        );
    }

    setActive(deliverySlot: any) {
        this.standardSlotList = [];
        this.expressSlotList = [];
        this.selectedDate = deliverySlot.date;
        this.deliverySlotDetails.forEach(x => {
            x.isActive = (x.date === deliverySlot.date);
        });
        if (deliverySlot.status) {
            this.deliverySlotDetails.forEach(x => {
                if (x.date === deliverySlot.date) {
                    this.standardSlotList = x.deliverySlots.filter(el => {
                        el['date'] = deliverySlot.date;
                        el['listItemHovered'] = false;
                        el['isChecked'] = false;
                        return (!el.isExpress && !el.notshow);
                    });
                    this.expressSlotList = x.deliverySlots.filter(el => {
                        el['date'] = deliverySlot.date;
                        el['listItemHovered'] = false;
                        el['isChecked'] = false;
                        return (el.isExpress);
                    });
                }
            });
        }
    }

    isExpress(date) {
        const today = moment();
        const curTime = moment(today, ['YYYY-MM-DD h:mm A']);
        const isToday = this.dateCalculation(date.date) === 'Today';
        if (date.status && date.deliverySlots.length > 0) {
            if (!this.filterDateList.includes((date.date))) {
                this.filterDateList.push(date.date);
            }
        }
        if (this.expressDetails && this.expressDetails.status === 'Active' && date.status && this.expressDetails.expressDay.includes(date.day)) {
            date.deliverySlots.forEach(day => {
                const slotFrom = moment(date.date + ' ' + day.from, ['YYYY-MM-DD h:mm A']);
                const slotTo = moment(date.date + ' ' + day.to, ['YYYY-MM-DD h:mm A']);
                const diffValue = slotTo.diff(curTime, 'minutes');
                if (day.status && diffValue <= Number(this.expressDetails.period) * 60 && diffValue > 0) {
                    day['isExpress'] = true;
                } else if (day.status && day.timeRangeStatus) {
                } else {
                    day['notshow'] = true;
                }
            });
        } else if (date.status) {
            date.deliverySlots.forEach(day => {
                const slotFrom = moment(date.date + ' ' + day.from, ['YYYY-MM-DD h:mm A']);
                if (day.status && day.timeRangeStatus) {
                } else {
                    day['notshow'] = true;
                }
            });
        }
    }

    dateCalculation(data) {
        const date = new Date();
        const today = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        const date1 = new Date(data);
        const date2 = new Date(today);
        const diffTime = Math.abs(date1.valueOf() - date2.valueOf());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays === 0) {
            return 'Today';
        } else if (diffDays === 1) {
            return 'Tommorow';
        } else {
            return String(data).split('-').reverse().join('-');
        }
    }

    saveOrderReschedule() {
        if (!this.selectedDeliveryDay) {
            this.rescheduleErrorMsg = 'Please select Reschedule Date';
            return;
        }
        if (this.rescheduleSlot.length === 0) {
            this.rescheduleSlotErrorMsg = 'Please choose Delivery Slot';
            return;
        }
        if (!this.rescheduleRemarks) {
            this.notifyService.showWarn('Warning', 'Please enter Remarks');
            return;
        }
        this.blurModal = true;
        this.rescheduleSlot[0]['slotId'] = this.rescheduleSlot[0].id;
        this.rescheduleSlot[0]['fromTime'] = this.rescheduleSlot[0].from;
        this.rescheduleSlot[0]['toTime'] = this.rescheduleSlot[0].to;
        this.rescheduleSlot[0]['date'] = this.selectedDeliveryDay.date;
        this.rescheduleSlot[0]['reason'] = this.rescheduleRemarks;
        const postJSON = {
            'orderId': this.selectedOrderId,
            'onlineReferenceNo': this.selectedOrderRefId,
            'rescheduleDate': this.selectedDeliveryDay.date,
            'rescheduleDay': this.selectedDeliveryDay.day,
            'deliverySlot': Object.assign([], this.rescheduleSlot)
        };
        this.bannerNewsService.orderReschedule(this.orderOutletID, postJSON).subscribe(
            res => {
                if (res.status) {
                    this.notifyService.showSuccess('Success', res.message);
                    this.rescheduleModal = false;
                    this.closeCancel();
                    this.clearOrderRescheduleDetails();
                    this.getOrderDetails(this.selectedOrderId, this.selectedOrderRefId);
                }
                this.blurModal = false;
            },
            (error) => {
                this.notifyService.showError('Error', error);
                this.blurModal = false;
            },
            () => {
            }
        );
    }

    onMapReady(e) {
        this.map = e;
    }

    openViewMap(drawer) {
        this.drawer = drawer;
        this.drawer.open();
    }

    copyTextClipBoard(data, type) {
        this[type] = true;
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = data;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        setTimeout(() => {
            this[type] = false;
        }, 2500);
    }
    openModifiers(i: number) {
        if (this.selectedModifierList.includes(i)) {
            const index = this.selectedModifierList.indexOf(i);
            if (index > -1) {
                this.selectedModifierList.splice(index, 1);
            }
        } else {
            this.selectedModifierList.push(i);
        }
    }

    /**
     * @description For ONDC address
     */
    removeNull(data) {
        const length = data.split(', null').length - 1;
        for (let i = 0; length > i; i++) {
            data = data.replace(', null', '');
        }
        return data;
    }

    /**
     * @description Calculating Non Motorable Distance
     */
    calculateDistance(lat1, lon1, lat2, lon2) {
        const RadiusOfEarth = 6371;
        const dLat = this.degtoRad(lat2 - lat1);
        const dLon = this.degtoRad(lon2 - lon1);
        const x =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(this.degtoRad(lat1)) *
            Math.cos(this.degtoRad(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        const y = 2 * Math.atan2(Math.sqrt(x), Math.sqrt(1 - x));
        const distance = RadiusOfEarth * y;
        this.distance =
            +distance >= 1
                ? `${+distance.toFixed(2)} Km`
                : `${(+distance * 1000).toFixed(2)} Meter`;
    }

    degtoRad(deg) {
        return deg * (Math.PI / 180);
    }

    isEmptyObject(object: any) {
        return Object.values(object).every(value => value === null);
    }

    openPartialCancel() {
        this.showPartialCancel = true;
        this.selectedIndex = 0;
        console.log(this.availableItems);
        this.getCancelStatus();
    }

    closePartialCancel() {
        this.showPartialCancel = false;
        this.getOrderDetails(this.selectedOrderId, this.selectedOrderRefId);
    }

    openOption(config) {
        config.isOpen = !config.isOpen;
        console.log(config);
    }

    stopProbagation(event: MouseEvent) {
        // event.stopPropagation();
    }

    updateValue(item, type) {
        item.cancellationReason = type;
    }

    getCancelStatus() {
        const body = {'vendor': this.authenticationService.getSkewName(), 'cancelFrom': 'portal'};
        this.ecomService.getCancelReason(body).subscribe(res => {
            console.log(res);
            this.cancelStatuses = [];
            if (res && res.length > 0) {
                this.cancelStatuses = res;
                if (this.authenticationService.getSkewCode() === '923') {
                    const index = this.cancelStatuses.findIndex((el) => el.cancelCode === '1111');
                    if (index > -1) {
                        this.cancelStatuses.splice(index, 1);
                    }
                }
            } else {
                this.notifyService.showWarn('Warning', 'Something went wrong while fetching cancelation remarks');
            }
        });
    }

    onCancelAllItem(items, event) {
        this.availableItems.forEach((res) => {
            if (res.isCancellable) {
                res.cancelled = event.checked;
            }
        });
        const cancelItems = items.filter((data) => data.isCancellable);
        this.itemsToBeCanceled = event.checked ? [...cancelItems] : [];
        this.cancelAllItem = (this.availableItems.length === this.itemsToBeCanceled.length);
    }

    cancelSelectedItem(item, event) {
        if (event.checked) {
            this.itemsToBeCanceled.push(item);
        } else {
            if (this.itemsToBeCanceled.length > 0) {
                this.itemsToBeCanceled.forEach((res) => {
                    if (res.rowNo === item.rowNo) {
                        const index = this.itemsToBeCanceled.indexOf(item);
                        if (index > -1) {
                            this.itemsToBeCanceled.splice(index, 1);
                        }
                    }
                });
            }
        }
        this.cancelAllItem = (this.availableItems.length === this.itemsToBeCanceled.length);
    }
    checkCancelReason() {
        return (this.itemsToBeCanceled.filter((data) => !data.cancellationReason)).length;
    }
    saveCanceledOrders() {
        if (!this.itemsToBeCanceled.length) {
            this.notifyService.showWarn('Warn', 'Please Select Reason and choose the Item to be cancelled!');
        } else if (this.checkCancelReason()) {
            this.notifyService.showWarn('Warn', 'Please Select Reason');
        } else {
            const body = {
                userId: this.authenticationService.getServiceUserId(),
                vendor: this.authenticationService.getSkewName(),
                outletId: this.selectedOutletID,
                onlineReferenceNo: this.selectedOrderRefId,
                orderId: this.selectedOrderId,
                totalOrderAmount: this.orderDetail.orderDetail.totalAmount,
                channel: this.orderDetail.orderDetail.channel,
                totalDiscountAmount: this.orderDetail.orderDetail.totalDiscountAmount,
                orderItems: [],
            };
            this.itemsToBeCanceled.forEach((res) => {
                body.orderItems.push({
                    rowNo: res.rowNo,
                    itemId: res.itemId,
                    itemReferenceCode: res.itemReferenceCode,
                    cancelStatus: true,
                    cancelReason: res.cancellationReason.cancelCode,
                });
            });
            this.ecomService.partialCancel(body).subscribe((res) => {
                if (res.status) {
                    this.notifyService.showSuccess('Success', res.message);
                    this.closeCancel();
                } else {
                    this.notifyService.showError('Error', res.message);
                }
            });
            // this.getOrderDetails(this.selectedOrderId, this.selectedOrderRefId);
        }
    }

    closeCancel() {
        // this.setIsCancelClosed(true);
        this.closeDrawer.emit(true);
    }

    updateTemperature(temperature) {
        this.selectedTemperature = temperature;
    }

    saveDeliveryBoyTemperature() {
        if (!this.selectedTemperature) {
            this.notifyService.showWarn('Warn', 'Please Select Valid Temperature');
        } else {
            const body = {
                'userId': this.authenticationService.getServiceUserId(),
                'vendor': this.authenticationService.getSkewName(),
                'outletId': this.authenticationService.getSelectedOutletId(),
                'onlineReferenceNo': this.orderDetail.onlineReferenceNo,
                'orderId': this.orderDetail.orderDetail.orderId,
                'temperature': this.selectedTemperature,
                'phone': this.orderDetail.riderStatus.phone
            };
            this.ecomService.updateRiderTemperature(body).subscribe(
                res => {
                    if (res && res.status) {
                        this.notifyService.showSuccess('Success', res.message);
                    } else {
                        this.notifyService.showWarn('Warn', res.message);
                    }
                },
                error => {
                    this.notifyService.showWarn('Warn', error);
                }
            );
        }
     }
}
