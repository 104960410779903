import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {EcommerceService} from '../../../../modules/integration/ecommerce/ecommerce.service';
import {NotificationService} from '../../../../core/services/notification.service';
import {
  OrdereasyConfigService
} from '../../../../modules/integration/ecommerce/pages/configuration/ordereasy-config/ordereasy-config.service';
import {OwlOptions} from 'ngx-owl-carousel-o/lib/models/owl-options.model';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-store-rating',
  templateUrl: './store-rating.component.html',
  styleUrls: ['./store-rating.component.scss']
})
export class StoreRatingComponent implements OnInit, OnChanges {

  ratings: any;
  @Input() refresh = false;
  @Input() store: any;
  @Input() type: any;
  totalRecords = 0;
  pageSize = 5;
  pageIndex = 1;
  ratingsList = [];

  // Modal
  isStatusModalOpen = false;
  selectedRatings: any;
  remarks: any;
  selectedStatus: any;
  isDetailsModalOpen = false;
  orderDetail: any;
  dynamicCurrency = '';
  shadeBorderLeftColor: string;
  getPaymentHistoryDetails = [];
  onlinePaymentInfo: any;
  remarksHistory = [];
  selectedRatingDetail: any;
  tabLabel: string;
  getFeedback: number;
  sortFilterOpen = false;
  sortFilter = 'createdAt desc';
  filters = [
    {
      name : 'Latest',
      filter : 'createdAt desc'
    },
    {
      name : 'High to Low',
      filter : 'ratings desc'
    },
    {
      name : 'Low to High',
      filter : 'ratings asc'
    }
  ];
  sortFilterName = 'Latest';
  orderCompletedAt: any;
  isTaxSplit: boolean;
  customOptions1: OwlOptions = {
    center: false,
    autoWidth: true,
    margin: 10,
    lazyLoad : true,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ['<i class="material-icons">arrow_back_ios</i>', '<i class="material-icons">arrow_forward_ios</i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 4
      },
      940: {
        items: 5
      }
    },
    nav: true,
  };
  prescriptionUrl: string;
  modalOpen: boolean;
  prescriptionUrlList: any[];

  constructor(
      public authenticationService: AuthenticationService,
      private readonly ecommerceService: EcommerceService,
      private readonly notifyService: NotificationService,
      private readonly ordereasyService: OrdereasyConfigService,
  ) { }

  ngOnInit() {
    this.getOrderRatings();
    this.getDynamicCurrency();
    this.getTaxSplit();
    this.refreshOwlElement();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.refresh) {
      this.ngOnInit();
    }
  }

  getTaxSplit() {
    this.ordereasyService.getAppConfig().subscribe(
        (res) => {
          if (res.length) {
            if (res[0].configuration.length) {
              res[0].configuration.forEach((config) => {
                if (config.configId === 31 && config.value === 'true') {
                  this.isTaxSplit = true;
                }
              });
            }
          }
        }
    );
  }

  getOrderRatings() {
    let filter = '';
    if (this.type === 'market-place') {
      filter = 'marketPlaceId=' + this.authenticationService.getServiceUserId();
    } else if (this.type === 'store') {
      filter = 'isMarketPlace=true';
    } else {
      filter = '';
    }
    const body = {
      'userId': (this.type === 'market-place') ? this.store.shopId : this.authenticationService.getServiceUserId(),
      'vendor': 'OrderEasy',
      'filter': filter,
      'sort' : this.sortFilter,
      'pageSize' : this.pageSize,
      'pageIndex' : this.pageIndex
    };
    this.ecommerceService.getOrderRatings(body).subscribe(
        response => {
          console.log(response);
          this.ratings = response.storeRating;
          this.ratingsList = response.storeRating.orderRatingList;
          this.totalRecords = response.totalRecords;
          this.sortFilterOpen = false;
        },
        (err) => {
          this.notifyService.showWarn('Warn', err);
        }
    );
  }

  pageEvents(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    this.getOrderRatings();
  }

  calcWidth(rating) {
    return ((rating / 5) * 100);
  }

  // Modal Functions
  closeModal(type) {
    this[type] = false;
  }

  stopProbagation(event: MouseEvent) {
    console.log(event);
    event.stopPropagation();
  }

  onImageClick(prescription: any) {
    this.modalOpen = true;
    this.prescriptionUrl = prescription.fileUrl;
  }

  openModal(type, ratings) {
    this[type] = true;
    this.selectedRatings = ratings;
    if (type === 'isDetailsModalOpen') {
      this.getOrderDetails(this.selectedRatings.orderId, this.selectedRatings.onlineReferenceNo);
      this.getRatingHistory();
      this.getParticularRatingDetail();
      this.refreshOwlElement();
    }
  }

  refreshOwlElement() {
    console.log('refreshed');
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 200);
  }

  saveRemakrs() {
    if (!this.selectedStatus) {
      this.notifyService.showWarn('Warn', 'Please choose status');
    } else if (!this.remarks) {
      this.notifyService.showWarn('Warn', 'Please enter remarks');
    } else {
      const body = {
        'userId': (this.type === 'market-place') ? this.store.shopId : this.authenticationService.getServiceUserId(),
        'marketPlaceId': (this.type === 'market-place') ?  this.authenticationService.getServiceUserId() : '',
        'publishStatus': this.selectedStatus,
        'approvedBy': (this.type === 'market-place') ? 'market place' : 'store',
        'publisherName': this.authenticationService.getUserName(),
        'orderId': this.selectedRatings.orderId,
        'publisherRemarks': this.remarks
      };
      this.ecommerceService.saveRatingRemakrs(body).subscribe(
          (res) => {
            if (res && res.status) {
              this.notifyService.showSuccess('Success', res.message);
              this.selectedStatus = '';
              this.remarks = '';
              this.closeModal('isStatusModalOpen');
            } else {
              this.notifyService.showWarn('Warn', res.message);
            }
          },
          error => {
            this.notifyService.showWarn('Warn', error);
          }
      );
    }
  }

  openCloseOption(option: string) {
    this[option] = !this[option];
  }

  closeDropDown() {
    this.sortFilterOpen = false;
  }

  getDynamicCurrency() {
    const getSelectedOutlet = JSON.parse(window.atob(decodeURIComponent(escape(localStorage.getItem('selectedOutlet')))));
    this.dynamicCurrency = getSelectedOutlet ? decodeURIComponent(getSelectedOutlet['currency']) : '';
  }

  getOrderDetails(orderSoNo, onlineReferenceNo) {
    const userId = this.authenticationService.getServiceUserId();
    const storeUserId = (this.type === 'market-place') ? this.store.shopId : '';
    console.log(storeUserId);
    this.ecommerceService.getOrderDetails(orderSoNo, onlineReferenceNo, this.authenticationService.getOutlet(), userId, 'OrderEasy', storeUserId).subscribe(res => {
      this.orderDetail = res ? res : [];
      if (this.orderDetail.orderHistory.length > 0) {
        this.orderCompletedAt = this.orderDetail.orderHistory[(this.orderDetail.orderHistory.length) - 1].thirdPartyTime;
      }
      this.checkWidth();
      const getPayHistory = this.orderDetail.orderPaymentHistory.length > 0 ? this.orderDetail.orderPaymentHistory : [];
      this.getPaymentHistoryDetails = getPayHistory.reverse();
      this.onlinePaymentInfo = this.orderDetail.onlinePaymentInfo.length > 0 ? this.orderDetail.onlinePaymentInfo[0] : null;
      if (this.orderDetail.feedback) {
        this.getFeedback = parseInt(this.orderDetail.feedback.ratings);
      } else {
        this.getFeedback = 0;
      }
    });
  }

  getTotalOrderAmount(orderItems: any) {
    let total = 0;
    if (orderItems && orderItems.length) {
      orderItems.forEach(item => {
        total += item.itemAmount;
      });
    }
    return total;
  }

  checkWidth() {
    console.log(this.prescriptionUrlList);
    this.prescriptionUrlList = this.orderDetail.prescriptionList;
    if (this.prescriptionUrlList.length > 0) {
      this.prescriptionUrlList.forEach(image => {
        const img = new Image;
        img.src = image.fileUrl;
        img.addEventListener('load', () => {
          image['width'] = img.naturalWidth;
        });
      });
      console.log(this.prescriptionUrlList);
    }
  }


  getClassByValue(status, area) {
    let className = '';
    let styleColor = '';
    switch (status.toUpperCase()) {
      case 'PENDING':
        className = 'badge badge-pill badge-warning';
        styleColor = '#ffc107';
        break;
      case 'ACKNOWLEDGED':
        className = 'badge badge-pill badge-primary';
        styleColor = '#007bff';
        break;
      case 'DISPATCHED':
        className = 'badge badge-pill badge-info';
        styleColor = '#ffc107';
        break;
      case 'COMPLETED':
        className = 'badge badge-pill badge-success';
        styleColor = '#ffc107';
        break;
      case 'CANCELLED':
        className = 'badge badge-pill badge-danger';
        styleColor = '#ffc107';
        break;
      default :
        className = 'badge badge-pill badge-info';
        styleColor = '#17a2b8';
        break;
    }
    this.shadeBorderLeftColor = `3px solid ${styleColor}`;
    return className;
  }

  isExist(data) {
    if (data !== undefined && data !== null && data !== '-' && data !== '' && data !== 'null' && data !== '0') {
      return true;
    }
    return false;
  }

  // History
  getRatingHistory() {
    const body = {
      'userId' : (this.type === 'market-place') ? this.store.shopId : this.authenticationService.getServiceUserId(),
      'orderId' : this.selectedRatings.orderId
    };
    this.ecommerceService.getRatingHistory(body).subscribe(
        res => {
          if (res) {
            this.remarksHistory  = res.ratingHistoryList;
          } else {
            this.notifyService.showWarn('Warn', res.message);
          }
        },
        error => {
          this.notifyService.showWarn('Warn', error);
        }
    );
  }

  getParticularRatingDetail() {
    const body = {
      'userId': (this.type === 'market-place') ? this.store.shopId : this.authenticationService.getServiceUserId(),
      'vendor': 'OrderEasy',
      'filter': 'orderNo=' + this.selectedRatings.orderId,
      'pageSize' : this.pageSize,
      'pageIndex' : this.pageIndex
    };
    this.ecommerceService.getOrderRatings(body).subscribe(
        response => {
          console.log(response);
          this.selectedRatingDetail = response;
        },
        (err) => {
          this.notifyService.showWarn('Warn', err);
        }
    );
  }

  onTabChange(event) {
    this.tabLabel = event.tab.textLabel;
    console.log(this.tabLabel, event);
    if (this.tabLabel === 'review-history') {
      this.getRatingHistory();
    } else if (this.tabLabel === 'Prescription Details') {
      this.checkWidth();
      this.refreshOwlElement();
    }
  }

  onFilterChange(filter: any) {
    console.log(filter);
    this.sortFilter = filter.filter;
    this.sortFilterName = (this.filters.filter(x => x.filter === filter.filter))[0].name;
    this.getOrderRatings();
  }
}
