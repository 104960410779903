import {Injectable} from '@angular/core';
import {AlertService} from '../../core/http/alert/alert.service';
import {AutoLogin, OrdereasyConfig} from '../../core/models';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {AccountApiService} from '../../core/http/account/account-api.service';
import {AuthenticationService} from '../../core/services/authentication.service';
import {EcommerceAPIService} from '../../core/http/ecommerce/ecommerce-api.service';
import {NotificationService} from '../../core/services/notification.service';
import {UiCommonService} from '../../shared/service/uiCommon.service';
import {StompService} from '../../core/web-socket/StompService';
import {LiveTrackingStompService} from '../../core/web-socket/LiveTrackingStompService';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient,
    private notify: NotificationService,
    private uiCommonService: UiCommonService,
    private alertService: AlertService,
    private accountApiService: AccountApiService,
    private authenticationService: AuthenticationService,
    private ecommerceAPIService: EcommerceAPIService,
  ) { }


  public login(key: string): Observable<any> {
    const header = new HttpHeaders().set('key', key);
    return this.accountApiService.post('security-check', new HttpParams(), header, null, true);
  }

  public serviceUserLogin(key: string): Observable<any> {
    const header = new HttpHeaders().set('key', key);
    return this.accountApiService.post('sul/security-check', new HttpParams(), header, null, true);
  }

  public triggerOtp(key: string): Observable<any> {
    const header = new HttpHeaders().set('key', key);
    return this.accountApiService.post('security-check', new HttpParams(), header, null, true);
  }

  public restPassword(key: string): Observable<any> {
    const header = new HttpHeaders().set('key', key);
    return this.accountApiService.post('reset-password', new HttpParams(), header, null, true);
  }

  public introduceCaptcha(key: string): Observable<any> {
    const header = new HttpHeaders().set('key', key);
    return this.accountApiService.post('introduce-captcha', new HttpParams(), header, null, true);
  }

  public forgotPass(email: string): Observable<any> {
    const json = { emailid: email };
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    return this.alertService.post('rest/anon/password/forgot', new HttpParams(), header, json);
  }

  public authLoginPost(autoLoginModel: AutoLogin): Observable<any> {
    //  const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('user_id', autoLoginModel.userId.toString())
      .set('identity', autoLoginModel.identity)
      .set('user_name', autoLoginModel.userName)
      .set('password', autoLoginModel.password)
      .set('custId', autoLoginModel.custId);
    return this.alertService.get('micro_service/auth/check/autoLogin', param);
  }

  // Get Role master
  public getRoleMaster(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.accountApiService.post('get-role', param, header, body, true);
  }

  // Get Active add on details
  public getActiveAddOns(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams()
      .set('loginMailId', '' + this.authenticationService.getEmailId());
    return this.accountApiService.post('active-addon', param, header, body, true);
  }

  // Get Dynamic menu based on add on
  public getDynamicMenu(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.accountApiService.post('get-menu', param, header, body, true);
  }

  // Save role master
  public postRoleMaster(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.accountApiService.post('role-master', param, header, body, true);
  }

  // Get Employee master
  public getEmployeeMaster(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', '' + this.authenticationService.getSkewName())
      .set('outletId', '-1');
    return this.accountApiService.post('get/v1/users', param, header, body, true);
  }

  // Save employee master
  public postEmployeeMaster(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.accountApiService.post('user', param, header, body, true);
  }

  // Get list of add on based on email id
  public getAddOnForHomePage(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    return this.accountApiService.post('addon-details', new HttpParams(), header, null, false);
  }

  // Get list of addons to be displayed
  public getAddons(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('Origin', 'ordereasy-qa.gofrugalretail.com')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    return this.accountApiService.post('v1/addon-details', new HttpParams(), header, null, false);
  }
  public getMultiAddons(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('Origin', 'ordereasy-qa.gofrugalretail.com')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
        return this.accountApiService.post('get/v1/user/addon-info', new HttpParams(), header, null, false);
  }

  // Get Dynamic menu based on add on from home page
  public getDynamicMenuFromHomePage(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId());
    body['emailOrPhone'] = this.authenticationService.getEmailId();
    return this.accountApiService.post('get/user-wise/menu', param, header, body, false);
  }

  public getDynamicMenuFromSearch(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId());
    body['emailOrPhone'] = this.authenticationService.getEmailId();
    return this.accountApiService.post('get/user-wise/menu', param, header, body, false);
  }

  //Get AddOn list for EmployeeMaster
  public getAddOnList(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.accountApiService.post('active-addons', param, header, body, true);
  }

  //Get Outlet list for EmployeeMaster
  public getOutletList(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.accountApiService.post('get/v1/outlets', param, header, body, true);
  }

  public manageSubscriptionLicenseSync(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.accountApiService.post('license-sync', param, header, body, true);
  }

  public currentPlanDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', 'OrderEasy');
    return this.accountApiService.post('current-plan', param, header, body, true);
  }

  public trialPlanChange(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.accountApiService.post('update/trial-license', param, header, body, true);
  }

  public getOrderEasyAddons(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.accountApiService.post('get/addons', param, header, body, true);
  }

  public getUserDetail(key: string): Observable<any> {
    const header = new HttpHeaders().set('key', key);
    return this.accountApiService.post('users', new HttpParams(), header, null, true);
  }

  public getIpAddress(): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    return this.accountApiService.getIpAddress(header);
  }

  public setNewPassword(key: string): Observable<any> {
    const header = new HttpHeaders().set('key', key);
    return this.accountApiService.post('save-users', new HttpParams(), header, null, true);
  }

  public getMyProfileDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', 'OrderEasy');
    return this.accountApiService.post('v1/accounts-profile', param, header, body, true);
  }

  public getMyProfileAccountsDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    return this.ecommerceAPIService.post('/v1/profile', param, header, body, true);
  }

  public profilePicUpload(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', 'OrderEasy');
    return this.accountApiService.post('v1/profile-upload', param, header, body, true);
  }

  public getNotification(body): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken());
    return this.accountApiService.post('v1/get-portal-notification', param, header, body, false);
  }

  public updateNotification(body): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken());
    return this.accountApiService.post('v1/update-portal-notification', param, header, body, true);
  }

  public executeQuery(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', 'OrderEasy');
    return this.accountApiService.post('run-sql', param, header, body, true);
  }

  public submitFeedBackDetails(formObj: any): Observable<any> {
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken());
    return this.accountApiService.post('v1/report-iot', null, header, formObj, true );
  }

  public getAnnouncementDetails(body): Observable<any> {
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken());
    return this.accountApiService.post('v1/get-announcement', null, header, body, false );
  }

  public submitAppRequest(body: any): Observable<any> {
    const param = new HttpParams()
        .set('vendor', this.authenticationService.getSkewName())
        .set('userId', this.authenticationService.getServiceUserId() + '');
    const header = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    return this.ecommerceAPIService.postFormData('v2/app-request', param, header, body, true);
  }

  public getAnnouncementRead(body): Observable<any> {
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken());
    return this.accountApiService.post('v1/announcement-read', null, header, body, true );
  }

  public registerNewStore(body): Observable<any> {
    const header = new HttpHeaders()
        .set('Content-Type', 'application/json');
    return this.accountApiService.post('v1/user-registration', null, header, body, true );
  }

  public generateOTP(body): Observable<any> {
    const header = new HttpHeaders()
        .set('Content-Type', 'application/json');
    return this.accountApiService.post('v1/generate-otp', null, header, body, true );
  }

  public addEmployeeMaster(body): Observable<any> {
    const header = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json');
        // .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const params = new HttpParams()
        .set('userId', String(this.authenticationService.getServiceUserId()))
        .set('vendor', this.authenticationService.getSkewName());
    return this.accountApiService.post('v1/add/customer', params, header, body, true );
  }

  public getConfig(userId, authKey): Observable<OrdereasyConfig> {
    const header = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('X-Auth-Token', authKey);
    const param = new HttpParams()
        .set('userId', '' + userId)
        .set('outletId', '' + 0)
        .set('vendor', 'OrderEasy');
    return this.ecommerceAPIService.get('get/configured-store', param, header, true);
  }

  public getTimeZoneList(): Observable<any> {
    const header = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const params = new HttpParams()
        .set('userId', String(this.authenticationService.getServiceUserId()))
        .set('vendor', this.authenticationService.getSkewName());
    const body = {
      'userId': this.authenticationService.getServiceUserId(),
    };
    return this.accountApiService.post('v1/get-zone', params, header, body, true);
  }

  public updateTimeZone(body): Observable<any> {
    const header = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const params = new HttpParams()
        .set('userId', String(this.authenticationService.getServiceUserId()))
        .set('vendor', this.authenticationService.getSkewName());
    return this.accountApiService.post('v1/add-zone', params, header, body, true);
  }
}
