import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {SidemenuComponent} from './components/sidemenu/sidemenu.component';
import {SidemenuItemComponent} from './components/sidemenu-item/sidemenu-item.component';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatChipsModule} from '@angular/material/chips';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar2';
import {ToastModule} from 'primeng/toast';
import {ButtonModule} from 'primeng/button';
import {TableModule} from 'primeng/table';
import {InputTextModule} from 'primeng/inputtext';
import {InplaceModule} from 'primeng/inplace';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ToolbarNotificationComponent} from './components/toolbar-notification/toolbar-notification.component';
import {ToolbarComponent} from './components/toolbar/toolbar.component';
import {SearchBarComponent} from './components/search-bar/search-bar.component';
import {FullscreenComponent} from './components/fullscreen/fullscreen.component';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {UserMenuComponent} from './components/user-menu/user-menu.component';
import {NotificationComponent} from './components/notification/notification.component';
import {ReportTableComponent} from './components/report-table/report-table.component';
import {CarouselComponent} from './components/carousel/carousel.component';
import {CommonLoaderComponent} from './components/common-loader/common-loader.component';
import {ColorPickerComponent} from './components/color-picker/color-picker.component';
import {ConfigInputComponent} from './components/config-input/config-input.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ChatBotComponent} from './components/chat-bot/chat-bot.component';
import {NgxLoadersCssModule} from 'ngx-loaders-css';
import {WhatsNewComponent} from './components/whats-new/whats-new.component';
import {OutletSelectionComponent} from './components/outlet-selection/outlet-selection.component';
import {OrdereasyProSubscriptionInfoComponent} from './ordereasy-pro-subscription-info/ordereasy-pro-subscription-info.component';
import {AppCompanySelectionComponent} from './components/app-company-selection/app-company-selection.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {SideaddonComponent} from './components/sideaddon/sideaddon.component';
import {LiscenceModalComponent} from './components/liscence-modal/liscence-modal.component';
import {AppAngularEditorComponent} from './components/text-angular-editor/app-angular-editor.component';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {AgmCoreModule} from '@agm/core';
import {UiSwitchModule} from 'ngx-ui-switch';
import {StoreProfileComponent} from './components/store-components/store-profile/store-profile.component';
import {StorePublishComponent} from './components/store-components/store-publish/store-publish.component';
import {AggregatorDetailsComponent} from './components/store-components/aggregator-details/aggregator-details.component';
import {MyAggregatorDetailsComponent} from './components/store-components/my-aggregator-details/my-aggregator-details.component';
import {StoreRatingComponent} from './components/store-components/store-rating/store-rating.component';
import {StoreDashboardComponent} from './components/store-components/store-dashboard/store-dashboard.component';
import {LiscenseExpiryComponent} from './components/liscense-expiry/liscense-expiry.component';
import {ConfettiCannonComponent} from './components/confetti-cannon/confetti-cannon.component';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {PhoneInputComponent} from './components/phone-input/phone-input.component';
import {StoreAggregatorCollectionComponent} from './components/store-components/store-aggregator-collection/store-aggregator-collection.component';
import {OrdereasyStoreProfileComponent} from './components/profile/ordereasy-store-profile/ordereasy-store-profile.component';
import {APIIntegrationSetupComponent} from './components/api-integration-setup/api-integration-setup.component';
import {StoreOrderReturnComponent} from './components/store-order-return/store-order-return.component';
import {SafeUrlPipe} from '../core/pipes/safe-url.pipe';
import {SubscriptionDetailsComponent} from './components/store-components/subscription-details/subscription-details.component';
import {OrderDetailsComponent} from './components/order-processing/order-details/order-details.component';
import {ReturnDetailsComponent} from './components/order-processing/return-details/return-details.component';
import {ItemAvailabilityComponent} from './components/item-availability/item-availability.component';
import {CategoryMasterBulkImportComponent} from '../modules/integration/ecommerce/pages/category-master-bulk-import/category-master-bulk-import.component';
import {HotTableModule} from '@handsontable/angular';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatTabsModule} from '@angular/material/tabs';
import {MatCardModule} from '@angular/material/card';
import {MatSliderModule} from '@angular/material/slider';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { PrivacyPolicyModalComponent } from './components/privacy-policy-modal/privacy-policy-modal.component';
import { SurveySalesInvoiceComponent } from './components/survey-sales-invoice/survey-sales-invoice.component';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({

    declarations: [
        SidemenuComponent,
        SidemenuItemComponent,
        ToolbarNotificationComponent,
        ToolbarComponent,
        SearchBarComponent,
        FullscreenComponent,
        SidebarComponent,
        UserMenuComponent,
        NotificationComponent,
        ReportTableComponent,
        CarouselComponent,
        CommonLoaderComponent,
        ColorPickerComponent,
        ConfigInputComponent,
        ChatBotComponent,
        WhatsNewComponent,
        OutletSelectionComponent,
        OrdereasyProSubscriptionInfoComponent,
        AppCompanySelectionComponent,
        SideaddonComponent,
        LiscenceModalComponent,
        AppAngularEditorComponent,
        StoreProfileComponent,
        StorePublishComponent,
        AggregatorDetailsComponent,
        MyAggregatorDetailsComponent,
        StoreRatingComponent,
        StoreDashboardComponent,
        LiscenseExpiryComponent,
        ConfettiCannonComponent,
        PhoneInputComponent,
        StoreAggregatorCollectionComponent,
        ConfettiCannonComponent,
        OrdereasyStoreProfileComponent,
        APIIntegrationSetupComponent,
        StoreOrderReturnComponent,
        SafeUrlPipe,
        SubscriptionDetailsComponent,
        OrderDetailsComponent,
        ReturnDetailsComponent,
        ItemAvailabilityComponent,
        ReturnDetailsComponent,
        CategoryMasterBulkImportComponent,
        PrivacyPolicyModalComponent,
        SurveySalesInvoiceComponent
    ],

    imports: [
        CommonModule,
        MatListModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        MatChipsModule,
        RouterModule,
        PerfectScrollbarModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatSidenavModule,
        MatTabsModule,
        MatCardModule,
        MatSliderModule,
        MatProgressBarModule,
        MatSortModule,
        MatTooltipModule,
        MatTableModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        ToastModule,
        ButtonModule,
        TableModule,
        InputTextModule,
        InplaceModule,
        ProgressSpinnerModule,
        MatCheckboxModule,
        FormsModule,
        NgxLoadersCssModule,
        NgSelectModule,
        AngularEditorModule,
        AgmCoreModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,
        UiSwitchModule,
        CarouselModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        HotTableModule,
    ],


    exports: [
        SidemenuComponent,
        SidemenuItemComponent,
        ToolbarNotificationComponent,
        ToolbarComponent,
        SearchBarComponent,
        FullscreenComponent,
        SidebarComponent,
        UserMenuComponent,
        NotificationComponent,
        ReportTableComponent,
        CarouselComponent,
        CommonLoaderComponent,
        ColorPickerComponent,
        ConfigInputComponent,
        ChatBotComponent,
        OrdereasyProSubscriptionInfoComponent,
        SideaddonComponent,
        LiscenceModalComponent,
        SideaddonComponent,
        AppAngularEditorComponent,
        AggregatorDetailsComponent,
        StoreProfileComponent,
        MyAggregatorDetailsComponent,
        StoreRatingComponent,
        StoreDashboardComponent,
        ConfettiCannonComponent,
        PhoneInputComponent,
        StoreAggregatorCollectionComponent,
        OrdereasyStoreProfileComponent,
        APIIntegrationSetupComponent,
        StoreOrderReturnComponent,
        SubscriptionDetailsComponent,
        OrderDetailsComponent,
        ReturnDetailsComponent,
        ItemAvailabilityComponent,
        ReturnDetailsComponent,
        SafeUrlPipe,
        SurveySalesInvoiceComponent
    ],

    providers: [
        DatePipe,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ]
})
export class SharedModule {
}
