import {Injectable} from '@angular/core';
import {LoginDetailsApiService} from "../http/login-details/login-details-api.service";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoginDetailsService {

  public loginDetails: any;
  private loginUrl: string;
  private themeDetails = new BehaviorSubject(null);
  themeDetailsList = this.themeDetails.asObservable();

  constructor(
      private readonly loginDetailsApiService: LoginDetailsApiService
  ) { }

  private loadLoginDetails() {
    if (sessionStorage.getItem('OE_01') && this.loginUrl) {
      this.loginDetails = Object.assign(JSON.parse(this.decryptUsingAES256(sessionStorage.getItem('OE_01'))));
      this.setThemeDetails(this.loginDetails);
    } else {
      // this.setLoginDetails(window.location.origin);
    }
  }

  encryptUsingAES256(data: string) {
    return window.btoa(data);
  }

  decryptUsingAES256(data: string) {
    return window.atob(data);
  }

  public setLoginDetails(data: any): void {
    this.loginUrl = data;
     this.loginDetailsApiService.getLoginTheme().subscribe(
        res => {
          console.log(res);
          if (res) {
            sessionStorage.setItem('OE_01', this.encryptUsingAES256(JSON.stringify(res)));
            this.setThemeDetails(res);
          }
        },
        error => {
        }
    );
  }
  setThemeDetails(theme: any) {
    this.themeDetails.next(theme);
  }

  public getLoginDetails() {
    this.loadLoginDetails();
    return this.loginDetails;
  }

  public getLoginSkewCode() {
    this.loadLoginDetails();
    return this.loginDetails.skewCode;
  }

  public getLoginSkewName() {
    this.loadLoginDetails();
    return this.loginDetails.skewName;
  }

}
