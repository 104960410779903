import {Injectable} from '@angular/core';
import {ApiService} from '../api.service';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LoginDetailsApiService {

    loginDetails = window.location.origin !== 'http://localhost:4200' ? (window.location.origin + '/accounts/') : 'https://maduraikadai.qa.ordereasy-market.com/accounts/';

    constructor(private apiService: ApiService,
    ) {
    }

    get(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), showLoader?: Boolean): Observable<any> {
        return this.apiService.get(this.loginDetails, path, param, header, showLoader)
            .pipe(catchError(this.handleError('Get - Accounts')));
    }

    put(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: Object = {}, showLoader?: Boolean): Observable<any> {
        return this.apiService.put(this.loginDetails, path, param, header, body, showLoader)
            .pipe(catchError(this.handleError('Put - Accounts')));
    }

    post(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: Object = {}, showLoader?: Boolean): Observable<any> {
        return this.apiService.post(this.loginDetails, path, param, header, body, showLoader)
            .pipe(catchError(this.handleError('Post - Accounts')));
    }

    postFormData(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: Object = {}, showLoader?: Boolean): Observable<any> {
        return this.apiService.postFormData(this.loginDetails, path, param, header, body, showLoader)
            .pipe(catchError(this.handleError('Post - Accounts')));
    }

    delete(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), showLoader?: Boolean): Observable<any> {
        return this.apiService.delete(this.loginDetails, path, param, header, showLoader)
            .pipe(catchError(this.handleError('Delete - Accounts')));
    }

    public getLoginTheme(): Observable<any> {
        const header = new HttpHeaders().set('x-auth-token', environment.production ? 'c0b88484-a90a-4fa5-99a0-e6adfe4c2a8f' : '10b06a5b-838f-484e-a0ba-dc5aa2e038f5');
        return this.post('v1/get-theme', new HttpParams(), header, null, true);
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            return of(result as T);
        };
    }
}
