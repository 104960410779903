import {Injectable} from '@angular/core';
import {default_page, menu_list, skew_menu} from './menu-element';
import {AuthenticationService} from './authentication.service';
import {Router} from '@angular/router';
import {EcommerceService} from '../../modules/integration/ecommerce/ecommerce.service';
import {LoginService} from "../../modules/login/login.service";
import {SharedService} from "../../shared/service/shared.service";

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private sharedService: SharedService
  ) { }

  public setSkewCode(url: string) {
    const skewSet = this.getSkewSets(url);
    if (skewSet.size === 1) {
      this.authService.setSkewCode('' + skewSet.values().next().value);
    } else {
      this.router.navigate(['/HomePage']);
    }
  }

  public getSkewSets(url: string) {
    const skewSet = new Set();
    let uri = url;
    if (url.indexOf('/Integration/') !== -1) {
      uri = url.slice(url.indexOf('/Integration/') + '/Integration/'.length);
    }
    if (url.indexOf('?') !== -1) {
      uri = uri.slice(0, uri.indexOf('?'));
    }
    const menu_id = this.getMenuId(uri, menu_list);
    for (const key in skew_menu) {
      if (skew_menu[key].includes(menu_id)) {
        skewSet.add(key.split(':')[0]);
      }
    }
    return skewSet;
  }

  private getMenuId(url: string, menus): string {
    for (let i = 0; i < menus.length; i++) {
      if (url.includes(menus[i].link)) {
        return menus[i].id;
      } else {
        if (menus[i].sub) {
          const menu_id = this.getMenuId(url, menus[i].sub);
          if (menu_id) {
            return menu_id;
          }
        }
      }
    }
  }

  public checkMenu(skew: string) {
    return this.checkMenuRec(JSON.parse(JSON.stringify(menu_list)), skew);
  }

  private checkMenuRec(menus: any, skew: string): any {
    if (skew_menu[skew]) {
      return menus.filter(
        (menu) => {
          if (skew_menu[skew].includes(menu.id)) {
            if (menu.sub) {
              menu.sub = this.checkMenuRec(menu.sub, skew);
            }
            return !menu.hidden;
          } else {
            return false;
          }
        });
    }
  }

  public getAvaiSkew() {
    return Object.keys(skew_menu);
  }

  public getDefaultPage(skewCode: string) {
    const menuId = default_page[skewCode]['defaultPage'];
    const menuLink = this.getDefaultRec(menu_list, menuId);
    return menuLink;
  }

  public getConfigPage(skewCode: string) {
    const menuId = default_page[skewCode]['configPage'];
    const menuLink = this.getDefaultRec(menu_list, menuId);
    return menuLink;
  }

  // Get menu by passing menu id 
  getMenuLinkPassingId(menuId) {
    const menuLink = this.getDefaultRec(menu_list, menuId);
    return menuLink;
  }

  // Remove particular Menu from Menu List 
  public removeParticularMenu(menu: string) {
    skew_menu['742:1'] = skew_menu['742:1'].filter(
      res => res !== menu
    );
    skew_menu['535:1'] = skew_menu['535:1'].filter(
      res => res !== menu
    );
  }

  private getDefaultRec(menus: any, menuId: string): any {
    for (let i = 0; i < menus.length; i++) {
      if (menus[i].id === menuId) {
        return menus[i].link;
      } else {
        if (menus[i].sub) {
          const menuLink = this.getDefaultRec(menus[i].sub, menuId);
          if (menuLink) {
            return menuLink;
          }
        }
      }
    }
  }

  // Set Dynamic Menu from json 
  async setDynamicMenuFromJSON(data) {
    skew_menu[this.authService.getSkewCode() + ':1'] = data;
  }

  setDynamicMenu(data) {
    menu_list.length = 0;
    for (let i = 0; i < data.length; i++) {
      menu_list.push(data[i]);
    }
  }

  getMenuIsAvail(skewCode, subMenuCode?, menuType?) {
    let isAvail = false;
    this.sharedService.menus.forEach((menu) => {
      if (menu.id === skewCode) {
        if (menuType === 'main') {
          isAvail = menuType.link;
        } else {
          menu.sub.forEach(submenu => {
            if (submenu.id === subMenuCode) {
              isAvail = submenu.link;
            }
          })
        }
      }
    });
    console.log(isAvail);
    return isAvail;
  }

}
