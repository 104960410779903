import { Injectable } from '@angular/core';
import { OutletDetail, McarrotConfig, BannerNewsHeader, NewsHeader, DeliveryMaster } from '../../../../../../core/models';
import { Observable } from 'rxjs';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { AlertService } from '../../../../../../core/http/alert/alert.service';
import { AuthenticationService } from '../../../../../../core/services/authentication.service';
import { EcommerceAPIService } from '../../../../../../core/http/ecommerce/ecommerce-api.service';

@Injectable({
  providedIn: 'root'
})
export class BannerNewsConfigService {

  constructor(
    private alertService: AlertService,
    private authService: AuthenticationService,
    private ecommerceAPIService: EcommerceAPIService,
  ) { }

  public getOutletDetails(): Observable<OutletDetail[]> {
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('outletId', '' + 0)
      .set('orderNo', '' + '0')
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.get('get/configured-store', param, null, true);
  }

  public getOutletDetailsForPOSMenuPublish(data): Observable<OutletDetail[]> {
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('outletId', '' + 0)
      .set('orderNo', '' + '0')
      .set('vendor', this.authService.getSkewName())
      .set('adminCheck', data);
    return this.ecommerceAPIService.get('get/configured-store', param, null, true);
  }

  public getConfig(outletId: string, orderNo: string): Observable<any> {
    const param = new HttpParams()
      .set('serviceUserId', '' + this.authService.getServiceUserId())
      .set('outletId', outletId)
      .set('rootOrderNumber', orderNo);
    return this.alertService.get('loyalty/registration/get/mcarrot-configuration', param);
  }

  public saveConfig(body: McarrotConfig): Observable<any> {
    body.serviceUserId = this.authService.getServiceUserId();
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.alertService.post('loyalty/registration/configure-mcarrots', null, header, body);
  }

  public getConfigurationDetails(): Observable<any> {
    const param = new HttpParams()
      .set('serviceUserId', '' + this.authService.getServiceUserId());
    return this.alertService.get('loyalty/registration/get/mcarrot-configuration-list', param);
  }

  public getLicenseReport(): Observable<any> {
    const param = new HttpParams()
      .set('serviceUserId', '' + this.authService.getServiceUserId())
      .set('vendor', '' + this.authService.getSkewName());
    return this.alertService.get('loyalty/registration/get/license-report', param);
  }

  public getBannerList(outletId?: number, pageIndex?: any, pageSize?: any): Observable<BannerNewsHeader> {
    const param = new HttpParams();
    let body = {};
    body['userId'] = this.authService.getServiceUserId();
    body['vendor'] = this.authService.getSkewName();
    body['outletId'] = outletId;
    body['hyperLink'] = '1';
    body['fromMobile'] = false;
    body['filter'] = '';
    body['orFilter'] = '';
    body['sort'] = 'sortOrder asc';
    body['pageSize'] = pageSize;
    body['pageIndex'] = pageIndex;
    const header = new HttpHeaders()
        .set('Content-Type', 'application/json');
    return this.ecommerceAPIService.post('v1/get-banners', param, header, body,  true);
  }

  public saveBanner(itemId: string, formData: FormData, type: any): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName())
      .set('outletId', itemId)
      .set('type', type)

    return this.ecommerceAPIService.postFormData('upload-image', param, null, formData, true);
  }

  // Save banner for mapping items / categories
  public saveBannerForMappedItems(outletId: string, formData: FormData, status: any, bannerType: any, mappedIds: any, itemId: any, imageUrl: any): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName())
      .set('outletId', outletId)
      .set('imageId', itemId)
      .set('type', 'banner')
      .set('status', status)
      .set('imageUrl', window.btoa(imageUrl))
      .set('bannerType', bannerType)
      .set('mappedIds', mappedIds)

    return this.ecommerceAPIService.postFormData('upload-image', param, null, formData, true);
  }

  // Save banner as Base64 for mapping items / categories
  public saveBannerAsBase64ForMappedItems(outletId: string, formData: FormData, status: any, bannerType: any, mappedIds: any, itemId: any, imageUrl: any, isMappedAllOutlets: string): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName())
      .set('outletId', outletId)
      .set('imageId', itemId)
      .set('type', 'banner')
      .set('status', status)
      .set('imageUrl', window.btoa(imageUrl))
      .set('bannerType', bannerType)
      .set('mappedIds', mappedIds)
      .set('isMappedAllOutlets', isMappedAllOutlets)

    return this.ecommerceAPIService.postFormData('upload-images', param, null, formData, true);
  }

  // Update banner for mapped items / categories
  public updateBannerForMappedItems(outletId: string, formData: FormData, status: any, bannerType: any, mappedIds: any, itemId: any): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName())
      .set('outletId', outletId)
      .set('id', itemId)
      .set('type', 'banner')
      .set('status', status)
      .set('bannerType', bannerType)
      .set('mappedIds', mappedIds)

    return this.ecommerceAPIService.postFormData('update-banner', param, null, formData, true);
  }

  public updateBanner(itemId: string, formData: FormData, status: string, type: any, outletId: any): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName())
      .set('id', itemId)
      .set('status', status)
      .set('type', type)
      .set('outletId', outletId)

    return this.ecommerceAPIService.postFormData('update-banner', param, null, formData, true);
  }

  public getNewsList(outletId?: number): Observable<NewsHeader> {
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('outletId', '' + (outletId ? outletId : 0))
      .set('vendor', this.authService.getSkewName());
    return this.ecommerceAPIService.get('get/news-master', param, null, true);
  }

  public saveItemImage(itemId: string, formData: FormData, type: any, outletId): Observable<any> {

    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('vendor', this.authService.getSkewName())
      .set('id', itemId)
      .set('type', type)
      .set('outletId', outletId)

    return this.ecommerceAPIService.postFormData('upload-image', param, null, formData, true);
  }

  public updateItemDetails(outletId: any, body): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('outletId', '' + (outletId ? outletId : 0))
      .set('vendor', this.authService.getSkewName())
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.ecommerceAPIService.post('update/item-by-skew', param, header, body, true);
  }

  public deleteBannerDetails(outletId: any, body): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('outletId', '' + (outletId ? outletId : 0))
      .set('vendor', this.authService.getSkewName())
      .set('id', body);
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.ecommerceAPIService.post('delete-banner', param, header, null, true);
  }

  public addNewsDetails(outletId: any, body): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('outletId', '' + (outletId ? outletId : 0))
      .set('vendor', this.authService.getSkewName());
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.ecommerceAPIService.post('news-master', param, header, body, true);
  }

  public deleteNewsDetails(outletId: any, body): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('outletId', '' + (outletId ? outletId : 0))
      .set('vendor', this.authService.getSkewName());
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.ecommerceAPIService.post('delete/news-master', param, header, body, true);
  }

  // Get delivery master
  public getDeliveryMaster(outletId?: number, pageSize?: any, pageIndex?: any): Observable<any> {
    let formBody = {
      'userId': this.authService.getServiceUserId(),
      'vendor': this.authService.getSkewName(),
      'outletId': outletId,
      'sort': "fromTime asc"
    }
    if (pageSize && pageIndex) {
      formBody['pageSize'] = pageSize;
      formBody['pageIndex'] = pageIndex;
    }
    const param = new HttpParams()
      .set('outletId', '' + (outletId ? outletId : 0));
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.ecommerceAPIService.post('get/v1/delivery-slot', param, header, formBody, true);
  }

  // Add / Update delivery master slot
  public addUpdateDeliveryMaster(outletId: any, body): Observable<any> {
    const param = new HttpParams()
      .set('outletId', '' + (outletId));
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.ecommerceAPIService.post('v1/delivery-slot', param, header, body, true);
  }

  // Delete delivery master slot
  public deleteDeliveryMaster(outletId: any, body): Observable<any> {
    const param = new HttpParams()
      .set('outletId', '' + (outletId));
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.ecommerceAPIService.post('delete/v1/delivery-slot', param, header, body, true);
  }

  // Get delivery days
  public getDeliveryDays(outletId?: number, selectedDay?: string): Observable<any> {
    let formBody = {
      'userId': this.authService.getServiceUserId(),
      'vendor': this.authService.getSkewName(),
      'outletId': outletId
    }
    if (selectedDay) {
      formBody['day'] = selectedDay
    }
    const param = new HttpParams();
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.ecommerceAPIService.post('get/v1/delivery-slot-mapping', param, header, formBody, true);
  }
  // save express delivery details
  public saveExpressDeliveryDetails(outletId: number, body: any): Observable<any> {
    const param = new HttpParams()
      .set('outletId', '' + (outletId ? outletId : 0));
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.ecommerceAPIService.post('v1/add/express-delivery', param, header, body, true);
  }

  //get express delivery details
  public getExpressDeliveryDetail(outletId: number, body: any): Observable<any> {
    const param = new HttpParams()
      .set('outletId', '' + (outletId ? outletId : 0));
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.ecommerceAPIService.post('v1/get/express-delivery', param, header, body, true);
  }

  //Map Slot For Days
  public mapDeliverySlot(outletId: any, body): Observable<any> {
    body['userId'] = this.authService.getServiceUserId();
    body['vendor'] = this.authService.getSkewName();
    body['outletId'] = outletId;
    const param = new HttpParams()
      .set('outletId', '' + outletId);
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.ecommerceAPIService.post('map/v1/delivery-slot', param, header, body, true);
  }

  //Map Slot For Days
  public configDeliverySlot(outletId: any, body): Observable<any> {
    body['userId'] = this.authService.getServiceUserId();
    body['vendor'] = this.authService.getSkewName();
    body['outletId'] = outletId;
    const param = new HttpParams()
      .set('outletId', '' + outletId);
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.ecommerceAPIService.post('v1/delivery-slot-configuration', param, header, body, true);
  }

  public getConfigDeliveryDays(outletId: any): Observable<any> {
    let body = {};
    body['userId'] = this.authService.getServiceUserId();
    body['vendor'] = this.authService.getSkewName();
    body['outletId'] = outletId;
    const param = new HttpParams()
      .set('userId', '' + this.authService.getServiceUserId())
      .set('outletId', '' + outletId)
      .set('vendor', this.authService.getSkewName());
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.ecommerceAPIService.post('get/v1/delivery-days', param, header, body, true);
  }

  public getConfigDeliverySlot(outletId: any): Observable<any> {
    let body = {};
    body['userId'] = this.authService.getServiceUserId();
    body['vendor'] = this.authService.getSkewName();
    body['outletId'] = outletId;
    const param = new HttpParams()
      .set('outletId', '' + outletId);
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.ecommerceAPIService.post('get/v1/delivery-slot-configuration', param, header, body, true);
  }

  public getCheckoutNotes(outletId: any): Observable<any> {
    let body = {};
    body['userId'] = this.authService.getServiceUserId();
    body['vendor'] = this.authService.getSkewName();
    body['outletId'] = outletId;
    const param = new HttpParams()
      .set('outletId', '' + outletId);
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.ecommerceAPIService.post('get/v1/checkout-notes', param, header, body, true);
  }

  public postCheckoutNotes(outletId: any, checkoutNotes: any): Observable<any> {
    let body = {};
    body['userId'] = this.authService.getServiceUserId();
    body['vendor'] = this.authService.getSkewName();
    body['outletId'] = outletId;
    body['checkoutNotes'] = checkoutNotes;
    const param = new HttpParams()
      .set('outletId', '' + outletId);
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.ecommerceAPIService.post('v1/checkout-notes', param, header, body, true);
  }

  public orderReschedule(outletId: any, body: any): Observable<any> {
    body['userId'] = this.authService.getServiceUserId();
    body['vendor'] = this.authService.getSkewName();
    body['outletId'] = outletId;
    const param = new HttpParams()
      .set('outletId', '' + outletId);
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.ecommerceAPIService.post('v1/order-reschedule', param, header, body, true);
  }

  public savePrinterDetails(body: any): Observable<any> {
    const param = new HttpParams();
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.ecommerceAPIService.post('v1/add/print-config', param, header, body, true);
  }

  public getPrinterDetails(body: any): Observable<any> {
    const param = new HttpParams();
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.ecommerceAPIService.post('v1/get/print-config', param, header, body, true);
  }

  public bannerSorting(outletId: any, bannerSortList: any): Observable<any> {
    const param = new HttpParams();
        let body = {};
         body['userId'] = this.authService.getServiceUserId();
         body['vendor'] = this.authService.getSkewName();
         body['outletId'] = outletId;
         body['sortBannerList'] = bannerSortList;
    const header = new HttpHeaders()
        .set('Content-Type', 'application/json');
    return this.ecommerceAPIService.post('v1/sort-banners', param, header, body, true);
  }
}
