import { Component, Input, OnInit } from '@angular/core';
import { ReleaseNotesDetails } from '../../../core/models';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { NotificationService } from '../../../core/services/notification.service';
import { OrdereasyConfigService } from '../../../modules/integration/ecommerce/pages/configuration/ordereasy-config/ordereasy-config.service';

@Component({
  selector: 'app-whats-new',
  templateUrl: './whats-new.component.html',
  styleUrls: ['./whats-new.component.scss']
})
export class WhatsNewComponent implements OnInit {

  @Input() showAgainBtn: any;

  constructor(
    private authService: AuthenticationService,
    private ordereasyService: OrdereasyConfigService,
    private notifyService: NotificationService
  ) { }

  async ngOnInit() {
    // await this.getReleaseNotes()
  }

  // Get release notes
  async getReleaseNotes() {
    if (this.authService.getSkewCode() === '742') {
      let formObj = {
        "userId": this.authService.getServiceUserId(),
        "vendor": this.authService.getSkewName(),
        "reqFrom": "admin"
      }
      this.ordereasyService.getReleaseNotes('user', formObj).subscribe(
          (res) => {
            if (res) {
              this.ordereasyService.storeReleaseNotesDetails(res, false);
            }
          },
          (err) => {
            console.error(err);
          }
      );
    }
  }

  // Close Release notes pop up 
  closeReleaseNotesPopup() {
    this.ordereasyService.storeReleaseNotesDetails(null, false)
  }

  // Click Know more
  clickKnowMore(data:ReleaseNotesDetails) {
    window.open(data.releaseNotesUrl, '_blank')
  }

  // Click dont show 
  clickDontShow() {
    let formObj = {
      "userId": this.authService.getServiceUserId(),
      "vendor": this.authService.getSkewName()
    }
    this.ordereasyService.blockReleaseNotesList(formObj).subscribe(
      (res) => {
        if (res) {
          this.closeReleaseNotesPopup()
          this.notifyService.showSuccess('Success', res.message)
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

}
