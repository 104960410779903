import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../core/services/authentication.service';
import {Router} from '@angular/router';
import {StompService} from '../../core/web-socket/StompService';
import {LiveTrackingStompService} from '../../core/web-socket/LiveTrackingStompService';
import {CookieService} from 'ngx-cookie-service';
import {DomSanitizer} from '@angular/platform-browser';
import {LogoutService} from './logout.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  url: any;
  flag: any;
  domainUrl: any;

  constructor(
      private authenticationService: AuthenticationService,
      private router: Router,
      private stompService: StompService,
      private liveTrackingStompService: LiveTrackingStompService,
      private cookieService: CookieService,
      private sanitizer: DomSanitizer,
      private logoutService: LogoutService
  ) {
  }

  async ngOnInit() {
    this.logoutService.expireSession();
  }
}
