import {Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {SharedService} from '../../service/shared.service';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {LoginService} from '../../../modules/login/login.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-liscence-modal',
  templateUrl: './liscence-modal.component.html',
  styleUrls: ['./liscence-modal.component.scss']
})
export class LiscenceModalComponent implements OnInit, OnChanges {

  @ViewChild('expiredModal', {static: false}) expiredModalContent: TemplateRef<any>;
  private modalRef: NgbActiveModal;
  private subscription: Subscription;
  @Input() addon: any;
  management: string;
  menuLength = false;

  constructor(
      private modalService: NgbModal,
      private sharedService: SharedService,
      private authService: AuthenticationService,
      private loginService: LoginService,
      private router: Router,
  ) { }

  ngOnInit() {
    // this.subscription = this.sharedService
    //     .getOpenModal()
    //     .subscribe((event) => {
    //       console.log(event);
    //       if (event) {
    //         console.log(event);
    //         this.addon = event;
    //         this.isMenusMapped();
    //         this.openActionModal();
    //         this.sharedService.setOpenModal(false);
    //       }
    //     });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.addon) {
      console.log(this.addon, changes.addon);
      this.isMenusMapped();
      this.openActionModal();
    }
  }

  openActionModal() {
    if (this.modalService.hasOpenModals()) {
      return;
    }
    if (this.addon.productCode === '742') {
      this.management = 'Order Management';
    } else if (this.addon.productCode === '538') {
      this.management = 'Delivery Management';
    } else if (this.addon.productCode === '744') {
      this.management = 'Delivery Boy Tracking';
    }
    const ngbModalOptions: any = {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      backdrop : this.addon.logout ? 'static' : 'true',
      keyboard : false
    };
    this.modalRef = this.modalService.open(this.expiredModalContent, ngbModalOptions);
  }

  closeTrackingModal() {
    console.log(this.addon);
    if (this.addon.logout) {
      this.router.navigate(['/logout']);
    }
    this.modalRef.close();
  }

  redirectUrl() {
    window.open('https://www.gofrugal.com/integration-pos', '_blank');
  }

  isMenusMapped() {
    const formObj = {
      'userId': this.authService.getServiceUserId(),
      'skewCode': this.authService.getSkewCode(),
      'roleId': this.authService.getRoleId()
    };
    this.loginService.getDynamicMenuFromHomePage(formObj).subscribe(
        (config) => {
          this.menuLength = config.length === 0;
        });
  }
}
