import { Injectable } from '@angular/core';
import {NotificationService} from '../../core/services/notification.service';
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable()
export class UiCommonService {
    constructor(private snackBar: MatSnackBar,
                private notifyService: NotificationService,
    ) {
    }

    public loader: 'blocking' | 'nonBlocking';

    public openLoaderWithTimeOut(type: 'blocking' | 'nonBlocking' = 'nonBlocking', message: string) {
        this.loader = type;
        setTimeout(() => {
            this.loader = null;
            this.notifyService.showError('Note', message);
        }, 10000);
    }

    public showLoader(type: 'blocking' | 'nonBlocking' = 'nonBlocking') {
        this.loader = type;
    }

    public hideLoader() {
        this.loader = null;
    }

    public openSnackBar(message: string, action: string, duration: number = 0) {
        setTimeout(() => {
            this.snackBar.open(message, action, {
                duration: duration,
            });
        }, 100);
    }
}
