import { Injectable } from '@angular/core';
import {NgxImageCompressService} from 'ngx-image-compress';

@Injectable({
  providedIn: 'root'
})
export class ImageCompressService {

  constructor(
      private imageCompressService: NgxImageCompressService,
  ) { }

  public async compressImage(image, size?, ratio?) {
    const imageSize = size ? size : -1;
    ratio = ratio ? ratio : 50;
    console.log(image, imageSize, ratio);
    const imageUrl: any = await this.toBase64(image);
    console.log(imageUrl);
    return new Promise(resolve => {
      setTimeout(() => {
        console.log(imageUrl);
        this.imageCompressService.compressFile(imageUrl, imageSize, ratio, 50).then((result) => {
          console.log(result);
          resolve(result);
        }).catch(error => console.error(error));
      }, 200);
    });
  }

  public toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  })

  public dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
  }
}
