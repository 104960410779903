// export const menu_list = [];

export const menu_list = [
    {
        'id': '1',
        'name': 'Dashboard',
        'icon': 'dashboard',
        'link': 'ecommerce/dashboard',
        'open': false,
        'hidden': true,
    },
    {
        'id': '2',
        'name': 'Order',
        'open': false,
        'link': 'ecommerce/orders',
        'icon': 'assignment',
        'hidden': false,
    },
    {
        'id': '7',
        'name': 'Transaction Processing',
        'open': false,
        'link': 'ecommerce/trans-process',
        'icon': 'assignment',
        'hidden': false,
    },
    {
        'id': '3',
        'name': 'Store Listing',
        'open': false,
        'link': 'ecommerce/store-list',
        'icon': 'store',
        'hidden': false,
    },
    {
        'id': '8',
        'name': 'Store Edit',
        'open': false,
        'link': 'ecommerce/store-edit',
        'icon': 'store',
        'hidden': true,
    },
    {
        'id': '4',
        'name': 'Reports',
        'icon': 'table_view',
        'link': false,
        'open': false,
        'hidden': false,
        'sub': [
            {
                'id': '401',
                'name': 'Item Master',
                'icon': 'view_list',
                'link': 'ecommerce/reports?reportId=1004&productId=5&referrerProduct=truealerts',
                'open': false,
                'hidden': false,
            },
            {
                'id': '402',
                'name': 'Sales Order',
                'icon': 'receipt',
                'link': 'tables/featured',
                'open': false,
                'hidden': false,
            }
        ]

    },
    {
        'id': '5',
        'name': 'Configuration',
        'open': false,
        'link': false,
        'icon': 'settings',
        'hidden': false,
        'sub': [
            {
                'id': '501',
                'name': 'Food Aggregators Configuration',
                'open': false,
                'link': 'ecommerce/config/FoodAggregators',
                'icon': 'shop',
                'hidden': true,
            },
            {
                'id': '502',
                'name': 'Store Configuration',
                'open': false,
                'link': 'ecommerce/store-config',
                'icon': 'shopping_cart',
                'hidden': false,
            },
            {
                'id': '503',  // UrbanPiperItemConfiguration
                'name': 'Item Configuration',
                'open': false,
                'link': 'ecommerce/urbanpiper-item-config',
                'icon': 'shopping_basket',
                'hidden': false,
            },
            {
                'id': '504',
                'name': 'EasyRewardz Configuration',
                'open': false,
                'link': 'loyalty/config/EasyRewardz',
                'icon': 'fastfood',
                'hidden': false,
            },
            {
                'id': '505',
                'name': 'JumboTail Configuration',
                'open': false,
                'link': 'ecommerce/config/jumbotail',
                'icon': 'fastfood',
                'hidden': false,
            },
            {
                'id': '506',
                'name': 'MCarrots Configuration',
                'open': false,
                'link': 'wallet/config/Mcarrots',
                'icon': 'build',
                'hidden': false,
            },
            {
                'id': '507',
                'name': 'MPesa Configuration',
                'open': false,
                'link': 'wallet/config/mpesa',
                'icon': 'fastfood',
                'hidden': false,
            },
            {
                'id': '509',
                'name': 'Order Easy Store Setup',
                'open': false,
                'link': 'ecommerce/config/OrderEasy',
                'icon': 'store',
                'hidden': true,
            },
            {
                'id': '511',  // UrbanpiperCategoryConfiguration
                'name': 'Category Configuration',
                'open': false,
                'link': 'ecommerce/urbanpiper-category-config',
                'icon': 'donut_small',
                'hidden': false,
            },
            {
                'id': '513', // WooCommerce Configuration
                'name': 'WooCommerce',
                'open': false,
                'link': 'ecommerce/config/WooCommerce',
                'icon': 'donut_small',
                'hidden': false,
            },
            {
                'id': '613',
                'name': 'Data Sync',
                'open': false,
                'link': 'ecommerce/sync-configuration',
                'icon': 'donut_small',
                'hidden': false,
            },
            {
                'id': '713', //  Scheduler
                'name': 'Scheduler',
                'open': false,
                'link': 'ecommerce/scheduler',
                'icon': 'donut_small',
                'hidden': false,
            },
            {
                'id': '514',
                'name': 'Mcarrot Configuration Report',
                'open': false,
                'link': 'wallet/config/mcarrotConfigReport',
                'icon': 'assignment',
                'hidden': false,
            },
            {
                'id': '515',
                'name': 'License Report',
                'open': false,
                'link': 'wallet/config/licenseDetails',
                'icon': 'list',
                'hidden': false,
            },
            {
                'id': '516',
                'name': 'Order Easy App Configuration',
                'open': false,
                'link': 'ecommerce/config/OrderEasy-app',
                'icon': 'phonelink_setup',
                'hidden': false,
            },
            {
                'id': '517',
                'name': 'Bajaj Integration Configuration',
                'open': false,
                'link': 'ecommerce/config/BajajFinance',
                'icon': 'phonelink_setup',
                'hidden': false,
            },
            {
                'id': '518',
                'name': 'Ewards Integration Configuration',
                'open': false,
                'link': 'wallet/config/Ewards',
                'icon': 'phonelink_setup',
                'hidden': false,
            },
            {
                'id': '519',
                'name': 'Paytm Integration Configuration',
                'open': false,
                'link': 'wallet/config/paytm',
                'icon': 'phonelink_setup',
                'hidden': false,
            },
            {
                'id': '609',
                'name': 'Ezetap Configuration',
                'open': false,
                'link': 'wallet/config/ezetap-report',
                'icon': 'phonelink_setup',
                'hidden': false,
            },
            {
                'id': '699',
                'name': 'Ezetap Configuration',
                'open': false,
                'link': 'wallet/config/ezetap',
                'icon': 'phonelink_setup',
                'hidden': true,
            },
            {
                'id': '577',
                'name': 'PayU Configuration',
                'open': false,
                'link': 'wallet/config/payu',
                'icon': 'phonelink_setup',
                'hidden': true,
            },
            {
                'id': '547',
                'name': 'PayU Configuration',
                'open': false,
                'link': 'wallet/config/payu-report',
                'icon': 'phonelink_setup',
                'hidden': false,
            },
            {
                'id': '520',
                'name': 'Shopify Integration Configuration',
                'open': false,
                'link': 'ecommerce/config/Shopify',
                'icon': 'phonelink_setup',
                'hidden': true,
            },
            {
                'id': '521',
                'name': 'Charm Health Configuration',
                'open': false,
                'link': 'ecommerce/config/CharmHealth',
                'icon': 'phonelink_setup',
                'hidden': false,
            },
            {
                'id': '523',
                'name': 'Store Toggle History',
                'open': false,
                'link': 'ecommerce/store-toggle-dtl',
                'icon': 'assignment',
                'hidden': false,
            },
            {
                'id': '527',
                'name': 'POS Registration',
                'open': false,
                'link': 'ecommerce/config/ordereasyConfigReport',
                'icon': 'store',
                'hidden': false,
            },
            {
                'id': '526',
                'name': 'Shopify',
                'open': false,
                'link': 'ecommerce/config/Shopify-report',
                'icon': 'assignment',
                'hidden': false,
            },
            {
                'id': '528',
                'name': 'Outlet Configuration',
                'open': false,
                'link': 'ecommerce/config/OrderEasySta',
                'icon': 'phonelink_setup',
                'hidden': true,
            },
            {
                'id': '529',
                'name': 'Outlet List',
                'open': false,
                'link': 'ecommerce/config/OrderEasySta-report',
                'icon': 'store',
                'hidden': false,
            },
            {
                'id': '530',
                'name': 'Banner & News',
                'open': false,
                'link': 'ecommerce/config/bannerNewsConfig',
                'icon': 'panorama',
                'hidden': false,
            },
            {
                'id': '531',
                'name': 'App settings',
                'open': false,
                'link': 'ecommerce/config/commonConfig',
                'icon': 'settings_applications',
                'hidden': false,
            },
            {
                'id': '532',
                'name': 'Recommendation',
                'open': false,
                'link': 'ecommerce/config/dynamicRecommendationConfig',
                'icon': 'announcement',
                'hidden': false,
            },
            {
                'id': '534',
                'name': 'Order Easy',
                'open': false,
                'link': 'ecommerce/config/oeConfigReport',
                'icon': 'build',
                'hidden': false,
            },
            {
                'id': '535',
                'name': 'Order Easy Store Setup',
                'open': false,
                'link': 'ecommerce/config/oeConfiguration',
                'icon': 'store',
                'hidden': true,
            },
            {
                'id': '536',
                'name': 'POS Registration',
                'open': false,
                'link': 'ecommerce/config/amazon',
                'icon': 'store',
                'hidden': false,
            },
            {
                'id': '538',     // DeliveryApp - Configuration
                'name': 'Online Payment',
                'icon': 'payment',
                'link': 'ecommerce/config/onlinePaymentConfig',
                'open': false,
                'hidden': false,
            },
            {
                'id': '539',
                'name': 'Food Aggregator',
                'open': false,
                'link': 'ecommerce/config/urbanpiper-report',
                'icon': 'assignment',
                'hidden': false,
            },
            {
                'id': '540',
                'name': 'Alert~Configuration',
                'open': false,
                'link': 'ecommerce/alert-config',
                'icon': 'panorama',
                'hidden': false,
            },
            {
                'id': '541',
                'name': 'Delivery Charges',
                'open': false,
                'link': 'ecommerce/config/deliveryConfiguration',
                'icon': 'directions_bike',
                'hidden': false,
            },
            {
                'id': '542',
                'name': 'Delivery Slot',
                'open': false,
                'link': 'ecommerce/delivery-slot',
                'icon': 'access_time',
                'hidden': false,
            },
            {
                'id': '543',
                'name': 'BillEasy Configuration',
                'open': false,
                'link': 'billeasy/billeasy-configuration',
                'icon': 'donut_small',
                'hidden': false,
            },
            {
                'id': '544',
                'name': 'RazorPay Configuration',
                'open': false,
                'link': 'wallet/config/razorpay',
                'icon': 'donut_small',
                'hidden': false,
            },
            {
                'id': '546',
                'name': 'App Intro',
                'open': false,
                'link': 'ecommerce/splashScreen',
                'icon': 'phonelink_setup',
                'hidden': false,
            }
        ]
    },
    {
        'id': '6',
        'name': 'Data Sync',
        'icon': 'sync',
        'open': false,
        'link': false,
        'hidden': false,
        'sub': [
            {
                'id': '601',
                'name': 'Automatic Sync',
                'icon': 'swap_vert',
                'link': 'ecommerce/auto-sync',
                'open': false,
                'hidden': false,
            },
            {
                'id': '602',
                'name': 'Manual Sync',
                'icon': 'get_app',
                'link': 'ecommerce/manual-sync',
                'open': false,
                'hidden': false,
            },
            {
                'id': '603',
                'name': 'About',
                'icon': 'get_app',
                'link': 'ecommerce/about-details',
                'open': false,
                'hidden': false,
            },
            {
                'id': '604',
                'name': 'Data Flush',
                'icon': 'cached',
                'link': 'ecommerce/data-flush-sync',
                'open': false,
                'hidden': false,
            },
        ]
    },
    {
        'id': '10',
        'name': 'App Users',
        'icon': 'dashboard',
        'link': 'ecommerce/app-user',
        'open': false,
        'hidden': false,
    },
    {
        'id': '702',
        'name': 'Outlet Setup',
        'icon': 'outlet',
        'link': 'ecommerce/live-tracking-map/outlet',
        'open': false,
        'hidden': false,
    },
    {
        'id': '800',
        'name': 'Maps',
        'icon': 'add_location',
        'open': false,
        'link': false,
        'hidden': false,
        'sub': [
            {
                'id': '801',
                'name': 'Live Tracking',
                'icon': 'directions',
                'link': 'ecommerce/live-tracking-map/live',
                'open': false,
                'hidden': false,
            },
            {
                'id': '802',
                'name': 'History Tracking',
                'icon': 'history',
                'link': 'ecommerce/live-tracking-map/history',
                'open': false,
                'hidden': false,
            }
        ]
    },
    {
        'id': '9',
        'name': 'Reports',
        'icon': 'table_view',
        'link': false,
        'open': false,
        'hidden': false,
        'sub': [
            {
                'id': '901',
                'name': 'Distance Report',
                'icon': 'directions_bike',
                'link': 'ecommerce/live-tracking-distReport',
                'open': false,
                'hidden': false,
            },
            {
                'id': '902',
                'name': 'User Activity Report',
                'icon': 'directions_run',
                'link': 'ecommerce/live-tracking-userActReport',
                'open': false,
                'hidden': false,
            },
            {
                'id': '903',
                'name': 'Paytm Transaction Report',
                'icon': 'sort-amount-up',
                'link': 'wallet/config/paytmTransDetails',
                'open': false,
                'hidden': false,
            },
            {
                'id': '524',  // UrbanpiperItemUpload
                'name': 'Item Master',
                'open': false,
                'link': 'ecommerce/urbanpiper-item-master',
                'icon': 'donut_small',
                'hidden': false,
            },
            {
                'id': '525',
                'name': 'Tax Master Report',
                'icon': 'view_list',
                'link': 'ecommerce/tax-master',
                'open': false,
                'hidden': false,
            },
            {
                'id': '522',
                'name': 'Store Toggle Report',
                'open': false,
                'link': 'ecommerce/store-report',
                'icon': 'assignment',
                'hidden': false,
            },
            {
                'id': '614',
                'name': 'Sync Tracker Report',
                'open': false,
                'link': 'ecommerce/syncTracker',
                'icon': 'assignment',
                'hidden': false,
            },
            {
                'id': '615',
                'name': 'Sync Summary Report',
                'open': false,
                'link': 'ecommerce/syncReport',
                'icon': 'store',
                'hidden': false,
            },
            {
                'id': '907',
                'name': 'Modifier Report',
                'open': false,
                'link': 'ecommerce/modifier-master',
                'icon': 'view_list',
                'hidden': false,
            },
            {
                'id': '908',
                'name': 'Modifier Group Report',
                'open': false,
                'link': 'ecommerce/modifiergrp-master',
                'icon': 'view_list',
                'hidden': false,
            },
            {
                'id': '909',
                'name': 'Customer Report',
                'open': false,
                'link': 'ecommerce/customer-master',
                'icon': 'account_box',
                'hidden': false,
            },
            {
                'id': '910',
                'name': 'Item Report ',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1009/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '911',
                'name': 'Category Report ',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1008/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '912',
                'name': 'Sales order Summary',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1006/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '913',
                'name': 'Sales order details',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1007/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '914',
                'name': 'Order Sync Failure',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1005/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '915',
                'name': 'Audit Master',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1004/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '916',
                'name': 'Sync Tracker',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1003/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '917',
                'name': 'Tax Report',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1002/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '918',
                'name': 'Customer Report',
                'icon': 'account_box',
                'link': 'ecommerce/sr-report/1001/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '920',
                'name': 'Item Report ',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1010/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '921',
                'name': 'Category Report ',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1011/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '922',
                'name': 'Sales order Summary',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1012/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '923',
                'name': 'Sales order details',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1013/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '924',
                'name': 'Tax Report',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1014/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '925',
                'name': 'Customer Report',
                'icon': 'account_box',
                'link': 'ecommerce/sr-report/1015/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '926',
                'name': 'Item Report',
                'icon': 'account_box',
                'link': 'ecommerce/sr-report/1019/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '927',
                'name': 'Sales Order Summary',
                'icon': 'account_box',
                'link': 'ecommerce/sr-report/1018/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '928',
                'name': 'Sales Bill Summary',
                'icon': 'account_box',
                'link': 'ecommerce/sr-report/1016/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '929',
                'name': 'Sales Bill Details',
                'icon': 'account_box',
                'link': 'ecommerce/sr-report/1017/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '930',
                'name': 'MPESA Transaction Details',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1001/5/payment',
                'open': false,
                'hidden': false,
            },

            {
                'id': '931',
                'name': 'Store Toggle History',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1022/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '932',
                'name': 'Item Toggle History',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1023/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '933',
                'name': 'PAYTM Transaction Details',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1002/5/payment',
                'open': false,
                'hidden': false,
            },
            {
                'id': '934',
                'name': 'Modifier Master Details',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1024/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '935',
                'name': 'Item Master',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1027/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '936',
                'name': 'Category Master',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1035/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '937',
                'name': 'Customer Master',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1026/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '938',
                'name': 'Tax Master',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1025/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '939',
                'name': 'Shipping Master',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1032/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '940',
                'name': 'Online Payment History',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1033/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '941',
                'name': 'Sales Orders',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1028/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '942',
                'name': 'Ordered Items',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1029/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '943',
                'name': 'Item Wise SO',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1036/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '944',
                'name': 'Customer Wise SO',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1034/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '945',
                'name': 'Synced Failed SO',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1030/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '946',
                'name': 'Item Master',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1037/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '947',
                'name': 'Sales Order Summary',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1038/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '948',
                'name': 'Sales Order Detail',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1039/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '949',
                'name': 'Item Wise SO',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1040/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '950',
                'name': 'Broadcast Notification',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1041/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '951',
                'name': 'Notification History',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1042/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '952',
                'name': 'Menu Publish History',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1044/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '954',
                'name': 'RazorPay Transaction Report',
                'icon': 'sort-amount-up',
                'link': 'wallet/config/razorpay-report',
                'open': false,
                'hidden': false,
            },
            {
                'id': '953',
                'name': 'Price Modification History',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1047/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '955',
                'name': 'Menu Summary History',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1048/5/ecommerce',
                'open': false,
                'hidden': false
            },
            {
                'id': '956',
                'name': 'PayU Transaction Details',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1003/5/payment',
                'open': false,
                'hidden': false,
            }
        ]
    },
    {
        'id': '11',
        'name': 'About',
        'icon': 'info',
        'link': 'ecommerce/live-tracking-info',
        'open': false,
        'hidden': false,
    },
    {
        'id': '12',
        'name': 'Dashboard',
        'icon': './assets/icons/menu-icons/configuration.svg',
        'link': 'ecommerce/sr-dashboard/ordereasy/ecommerce/app_builder',
        'open': false,
        'hidden': false,
    },
    {
        'id': '13',
        'name': 'App Build',
        'icon': './assets/icons/menu-icons/configuration.svg',
        'link': 'ecommerce/app-build/detail',
        'open': false,
        'hidden': true,
    },
    {
        'id': '14',
        'name': 'App Build',
        'icon': './assets/icons/menu-icons/configuration.svg',
        'link': 'ecommerce/app-build/process',
        'open': false,
        'hidden': true,
    },
    {
        'id': '16',     // DeliveryApp - DeliveryAllocation
        'name': 'Delivery Allocation',
        'open': true,
        'link': 'delivery-app/delivery-allocation',
        'icon': 'departure_board',
        'hidden': false,
    },
    {
        'id': '17',     // DeliveryApp - DeliveryStatus
        'name': 'Delivery Status',
        'open': false,
        'link': 'delivery-app/delivery-status',
        'icon': 'drive_eta',
        'hidden': false,
    },
    {
        'id': '18',     // DeliveryApp - AboutUs
        'name': 'About',
        'icon': 'info',
        'link': 'delivery-app/about',
        'open': false,
        'hidden': false,
    },
    {
        'id': '19',
        'name': 'Session Management',
        'icon': 'today',
        'link': 'delivery-app/session-master',
        'open': false,
        'hidden': false,
    },
    {
        'id': '20',     // DeliveryApp - Configuration
        'name': 'Configuration',
        'open': false,
        'link': 'delivery-app/configuration',
        'icon': 'settings',
        'hidden': false,
    },
    {
        'id': '22',     // DeliveryApp - OutletConfiguration
        'name': 'POS Registration',
        'open': false,
        'link': 'delivery-app/outlet-configuration',
        'icon': 'room_preferences',
        'hidden': false,
    },
    {
        'id': '9999',
        'name': 'UnderConstruction',
        'icon': 'sync',
        'open': false,
        'link': 'ecommerce/under-construct',
        'hidden': true,
    },
    {
        'id': '9404',
        'name': 'Masters',
        'icon': './assets/icons/menu-icons/masters.svg',
        'link': false,
        'open': false,
        'hidden': false,
        'sub': [
            {
                'id': '9434',
                'name': 'Item Master',
                'open': false,
                'link': 'ecommerce/oes-item-report',
                'icon': 'assignment',
                'hidden': false,
                'parentId': '9404'
            },
            {
                'id': '9435',
                'name': 'Category Master',
                'open': false,
                'link': 'ecommerce/oes-category-report',
                'icon': 'assignment',
                'hidden': false,
                'parentId': '9404'
            },
            {
                'id': '9436',
                'name': 'Customer Master',
                'open': false,
                'link': 'ecommerce/oes-customer-master',
                'icon': 'account_box',
                'hidden': false,
                'parentId': '9404'
            },
            {
                'id': '9437',
                'name': 'Tax Master',
                'icon': 'view_list',
                'link': 'ecommerce/tax-master',
                'open': false,
                'hidden': false,
                'parentId': '9404'
            },
            {
                'id': '9438',
                'name': 'Master bulk upload',
                'icon': 'view_list',
                'link': 'ecommerce/master-create',
                'open': false,
                'hidden': false,
                'parentId': '9404'
            },
            {
                'id': '9439',
                'name': 'Upload Status Report',
                'icon': 'assignment',
                'link': 'ecommerce/oes-upload-master',
                'open': false,
                'hidden': false,
                'parentId': '9404'
            },
            {
                'id': '9440',
                'name': 'Language Master',
                'icon': 'language',
                'link': 'ecommerce/language-master',
                'open': false,
                'hidden': false,
                'parentId': '9404'
            },
            {
                'id': '905',
                'name': 'Items Configuration',
                'open': false,
                'link': 'ecommerce/item-report',
                'icon': 'shopping_basket',
                'hidden': false,
                'parentId': '9404'
            },
            {
                'id': '906',
                'name': 'Category Configuration',
                'open': false,
                'link': 'ecommerce/category-report',
                'icon': 'donut_small',
            },
            {
                'id': '9441003',
                'name': 'Item Master',
                'icon': 'assignment',
                'link': 'ecommerce/sta-pos/item',
                'open': false,
                'hidden': false,
                'parentId': '9404'
            },
            {
                'id': '9443',
                'name': 'Category Master',
                'icon': 'assignment',
                'link': 'ecommerce/sta-pos/categoryVal',
                'open': false,
                'hidden': false,
                'parentId': '9404'
            },
            {
                'id': '9444',
                'name': 'Tax Master',
                'icon': 'assignment',
                'link': 'ecommerce/sta-pos/tax',
                'open': false,
                'hidden': false,
                'parentId': '9404'
            },
            {
                'id': '9445',
                'name': 'Customer Master',
                'icon': 'account_box',
                'link': 'ecommerce/sta-pos/customer',
                'open': false,
                'hidden': false,
                'parentId': '9404'
            },
            {
                'id': '9446',
                'name': 'Onboarding Wizward',
                'icon': 'assignment',
                'link': 'ecommerce/sta-pos/onboard',
                'open': false,
                'hidden': false,
                'parentId': '9404'
            },
            {
                'id': '9441',
                'name': 'Recommended Master',
                'open': false,
                'link': 'ecommerce/recommended-master',
                'icon': 'shopping_basket',
                'hidden': false,
                'parentId': '9404'
            },
            {
                'id': '9449',
                'name': 'Payment Master',
                'icon': 'payment',
                'link': 'ecommerce/payment-master',
                'open': false,
                'hidden': false,
                'parentId': '9404'
            },
            {
                'id': '9450',
                'name': 'Order Status Master',
                'icon': 'timeline',
                'link': 'ecommerce/order-status-master',
                'open': false,
                'hidden': false,
                'parentId': '9404'
            },
            {
                'id': '9451',
                'name': 'AddOn Master',
                'icon': 'timeline',
                'link': 'ecommerce/addOn-master',
                'open': false,
                'hidden': false,
                'parentId': '9404'
            },
            {
                'id': '9452',
                'name': 'Bulk Image Upload',
                'open': false,
                'link': 'ecommerce/bulk-upload-image',
                'icon': 'photo_library',
                'hidden': false,
                'parentId': '9404'
            },
            {
                'id': '1501',
                'name': 'Delivery Boy',
                'icon': 'directions_bike',
                'link': 'delivery-app/delivery-boy',
                'open': false,
                'hidden': false,
                'parentId': '9404'
            },
            {
                'id': '1502',
                'name': 'Vehicle Master',
                'icon': 'local_shipping',
                'link': 'delivery-app/vehicle-master',
                'open': false,
                'hidden': false,
                'parentId': '9404'
            },
            {
                'id': '1503',
                'name': 'Reason Master',
                'icon': 'feedback',
                'link': 'delivery-app/reason-master',
                'open': false,
                'hidden': false,
                'parentId': '9404'
            },
            {
                'id': '1504',
                'name': 'Slot Master',
                'icon': 'access_time',
                'link': 'delivery-app/slot-master',
                'open': false,
                'hidden': false,
                'parentId': '9404'
            },
            {
                'id': '1506',
                'name': 'Tender Master',
                'icon': 'payment',
                'link': 'delivery-app/tender-master',
                'open': false,
                'hidden': false,
                'parentId': '9404'
            },
            {
                'id': '9453',
                'name': 'WhatsNew Release Notes',
                'icon': 'fiber_new',
                'link': 'ecommerce/whatsNew',
                'open': false,
                'hidden': false,
                'parentId': '9404'
            },
            {
                'id': '9454',
                'name': 'SMS Configuration',
                'icon': 'fiber_new',
                'link': 'ecommerce/smsAdminConfig',
                'open': false,
                'hidden': false,
                'parentId': '9404'
            },
            {
                'id': '9455',
                'name': 'Employee Master',
                'icon': 'person',
                'link': 'ecommerce/employeeMaster',
                'open': false,
                'hidden': false,
                'parentId': '9404'
            },
            {
                'id': '9456',
                'name': 'Role Master',
                'icon': 'accessibility',
                'link': 'ecommerce/roleMaster',
                'open': false,
                'hidden': false,
                'parentId': '9404'
            }
        ]
    },
    {
        'id': '10000',
        'name': 'Transactions',
        'icon': 'list',
        'link': false,
        'open': false,
        'hidden': false,
        'sub': [
            {
                'id': '10001',
                'name': 'Sales Orders',
                'open': false,
                'link': 'ecommerce/oes-order-report',
                'icon': 'shopping_cart',
                'hidden': false,
            },
            {
                'id': '10002',
                'name': 'Sales Orders',
                'open': false,
                'link': 'ecommerce/sales-order',
                'icon': 'shopping_cart',
                'hidden': false,
            },
            {
                'id': '10005',
                'name': 'Assign Order Processing',
                'open': false,
                'link': 'ecommerce/assign-order',
                'icon': 'assignment',
                'hidden': false,
            },
            {
                'id': '904',
                'name': 'Sales Orders',
                'open': false,
                'link': 'ecommerce/order-report',
                'icon': 'assignment',
                'hidden': false,
            },
            {
                'id': '10003',
                'name': 'Order Processing',
                'open': false,
                'link': 'ecommerce/amazon/order/processing',
                'icon': 'shopping_cart',
                'hidden': false,
            },
            {
                'id': '10004',     // DeliveryApp - Delivery OrderPrcessing
                'name': 'Delivery OrderProcessing',
                'open': false,
                'link': 'delivery-app/order-processing',
                'icon': 'departure_board',
                'hidden': false,
            },
            {
                'id': '10006',
                'name': 'Order Process',
                'open': false,
                'link': 'ecommerce/amazon/order/order-process',
                'icon': 'shopping_cart',
                'hidden': false,
            }
        ]
    },
    {
        'id': '20000',
        'name': 'Tools',
        'icon': 'build',
        'link': false,
        'open': false,
        'hidden': false,
        'sub': [
            {
                'id': '20001',
                'name': 'Bulk Upload',
                'icon': 'backup',
                'link': 'ecommerce/sta-pos/bulk',
                'open': false,
                'hidden': false,
            },
            {
                'id': '20002',
                'name': 'Item Mapping',
                'open': false,
                'link': 'ecommerce/itemMapping',
                'icon': 'assignment',
                'hidden': false,
            },
            {
                'id': '20003',
                'name': 'Item Mapping',
                'open': false,
                'link': 'ecommerce/shopify-item-mapping',
                'icon': 'assignment',
                'hidden': false,
            },
            {
                'id': '919',
                'name': 'Item Mapping',
                'open': false,
                'link': 'ecommerce/item-mapping',
                'icon': 'view_list',
                'hidden': false,
            }
        ]
    },
    {
        'id': '24',
        'name': 'About',
        'icon': 'info',
        'link': 'ecommerce/oes-about-screen',
        'open': false,
        'hidden': false,
    },
    {
        'id': '23',
        'name': 'login truepos',
        'icon': 'info',
        'link': 'ecommerce/sta-login',
        'open': false,
        'hidden': true,
    },
    // {
    //     'id': '25',
    //     'name': 'Assure Care',
    //     'open': false,
    //     'link': 'ecommerce/chat-bot',
    //     'icon': 'contact_support',
    //     'hidden': false,
    // },
    {
        'id': '30000',
        'name': 'Notifications',
        'icon': 'notification_important',
        'link': false,
        'open': false,
        'hidden': false,
        'sub': [
            {
                'id': '30001',
                'name': 'Customer',
                'open': false,
                'link': 'ecommerce/config/smsEmailConfig',
                'icon': 'perm_identity',
                'hidden': false,
            },
            {
                'id': '30002',
                'name': 'Admin',
                'open': false,
                'link': 'ecommerce/config/notification-admin',
                'icon': 'manage_accounts',
                'hidden': false,
            },
            {
                'id': '30003',
                'name': 'Broadcast',
                'open': false,
                'link': 'ecommerce/config/notification-broadcast',
                'icon': 'sensors',
                'hidden': false,
            },
            {
                'id': '30004',
                'name': 'Settings',
                'open': false,
                'link': 'ecommerce/config/notification-configuration',
                'icon': 'settings_cell',
                'hidden': false,
            }
        ]
    },
    {
        'id': '2603',
        'name': 'Customers',
        'open': false,
        'link': 'ecommerce/admin/build',
        'icon': './assets/icons/menu-icons/app-customization.svg',
        'hidden': false,
    },
    {
        'id': '2604',
        'name': 'App Request',
        'open': false,
        'link': 'ecommerce/admin/app-request',
        'icon': './assets/icons/menu-icons/mobile-app.svg',
        'hidden': false,
        // 'parentId': '26'
    },
    {
        'id': '2608',
        'name': 'App Publish Details',
        'open': false,
        'link': 'ecommerce/admin/app-publish-details',
        'icon': './assets/icons/menu-icons/mobile-app.svg',
        'hidden': false,
        // 'parentId': '26'
    } ,
    {
        'id': '26075',
        'name': 'Reports',
        'icon': './assets/icons/menu-icons/configuration.svg',
        'open': false,
        'link': false ,
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'sub': [
            {
                'id': '2607501',
                'name': 'App request Summary',
                'open': false,
                'link': 'ecommerce/sr-report/1076/5/ecommerce',
                'icon': 'perm_identity',
                'hidden': false,
                'parentId': '26075'
            },
            {
                'id': '2607502',
                'name': 'Customer App Version',
                'open': false,
                'link': 'ecommerce/sr-report/1075/5/ecommerce',
                'icon': 'assignment',
                'hidden': false,
                'parentId': '26075'
            }
        ]
    },
    {
        'id': '2605',
        'name': 'App Requests',
        'open': false,
        'link': 'ecommerce/admin/app-request',
        'icon': './assets/icons/menu-icons/mobile-app.svg' ,
        'hidden': false
        // 'parentId': '26'
    },
    {
        'id': '26',
        'name': 'Mobile App',
        'open': false,
        'link': false,
        'icon': './assets/icons/menu-icons/mobile-app.svg',
        'hidden': false,
        'sub': [
            {
                'id': '2601',
                'name': 'App Builder',
                'open': false,
                'link': 'ecommerce/order-easy/app-builder',
                'icon': 'app_settings_alt',
                'hidden': false,
                'parentId': '26'
            },
            {
                'id': '2602',
                'name': 'Dynamic Menu',
                'open': false,
                'link': 'ecommerce/order-easy/dynamic-menu',
                'icon': 'menu_open',
                'hidden': false,
                'parentId': '26'
            },
            {
                'id': '2603',
                'name': 'Customers',
                'open': false,
                'link': 'ecommerce/admin/build',
                'icon': 'menu_open',
                'hidden': false,
                // 'parentId': '26'
            },
            {
                'id': '2604',
                'name': 'App Request',
                'open': false,
                'link': 'ecommerce/app-request',
                'icon': 'menu_open',
                'hidden': false,
                'parentId': '26'
            },
            {
                'id': '2605',
                'name': 'App Requests',
                'open': false,
                'link': 'ecommerce/admin/app-request',
                'icon': 'menu_open',
                'hidden': false,
                'parentId': '26'
            },
            {
                'id': '2606',
                'name': 'Notification Configuration',
                'open': false,
                'link': 'ecommerce/admin/app-request',
                'icon': 'menu_open',
                'hidden': false,
                'parentId': '26'
            },
            {
                'id': '2607',
                'name': 'Release Details',
                'open': false,
                'link': 'ecommerce/admin/app-release-details',
                'icon': 'menu_open',
                'hidden': false,
                'parentId': '26'
            },
            {
                'id': '2608',
                'name': 'App Publish Details',
                'open': false,
                'link': 'ecommerce/admin/app-publish-details',
                'icon': 'menu_open',
                'hidden': false,
                'parentId': '26'
            }
        ]
    },
    {
        'id': '27',     // DeliveryApp - ReturnItemManagement
        'name': 'Sales Return Management',
        'open': true,
        'link': 'delivery-app/return-item-management',
        'icon': 'departure_board',
        'hidden': false,
    },
    {
        'id': '28',     // DeliveryApp - DataSync
        'name': 'Data Sync',
        'open': true,
        'link': 'delivery-app/data-sync',
        'icon': 'departure_board',
        'hidden': true,
    },
    {
        'id': '10111',     // dpAdmin
        'name': 'Run Sql',
        'icon': './assets/icons/menu-icons/configuration.svg',
        'link': 'ecommerce/dp-admin',
        'open': false,
        'hidden': false,
    },
    {
        'id': '50',
        'name': 'Dashboard',
        'icon': './assets/icons/menu-icons/dashboard.svg',
        'link': 'delivery-app/sr-dashboard/DELIVERYSERVICE/delivery-app',
        'open': false,
        'hidden': false
    },
    {
        'id': '51',
        'name': 'Aggregator Profile',
        'icon': './assets/icons/menu-icons/aggregator-profile.svg',
        'link': 'store-aggregator/store-profile',
        'open': false,
        'hidden': false
    },
    {
        'id': '52',
        'name': 'Stores',
        'icon': './assets/icons/menu-icons/stores.svg',
        'link': 'store-aggregator/data-sync',
        'open': false,
        'hidden': false
    },
    {
        'id': '53',
        'name': 'App Customization',
        'icon': './assets/icons/menu-icons/app-customization.svg',
        'link': 'store-aggregator/data-sync',
        'open': false,
        'hidden': false
    },
    {
        'id': '54',
        'name': 'Security Settings',
        'icon': './assets/icons/menu-icons/security.svg',
        'link': 'store-aggregator/data-sync',
        'open': false,
        'hidden': false
    },
    {
        'id': '55',
        'name': 'Notifications',
        'icon': './assets/icons/menu-icons/notification.svg',
        'link': 'store-aggregator/data-sync',
        'open': false,
        'hidden': false
    }
];


export const skew_menu = {
    '744:1': ['10', '800', '801', '802', '9', '901', '11'],
    // tslint:disable-next-line:max-line-length
    '535:1': ['1', '2', '3', '5', '501', '502', '503', '551', '6', '8', '602', '401', '613', '615', '524', '9', '904', '525', '906', '907', '908', '910', '911', '914', '916', '931', '932', '952', '934', '10000', '539', '952', '953', '955'],
    '737:1': ['1', '5', '504'],
    '739:1': ['1', '5', '503', '505', '6', '602', '614'],
    '740:1': ['1', '7', '5', '505', '503', '6', '602', '614'],
    '739,740:1': ['1', '7', '5', '505', '503', '6', '601', '602', '614'],
    '730:1': ['1', '5', '507', '9', '930'],
    '741:1': ['1', '5', '506', '514', '515' ],
    // tslint:disable-next-line:max-line-length
    '762:1': ['1', '5', '531', '530', '532', '9', '534', '535', '920', '921', '922', '923', '924', '925', '528', '9404', '10000', '530', '9441', '9443', '9444', '9445', '23', '20000', '20001', '9441003'],
    // tslint:disable-next-line:max-line-length
    '762765:1': ['1', '5', '531', '530', '532', '9', '534', '535', '920', '921', '922', '923', '924', '925', '528', '9404', '10000', '530', '9441', '9443', '9444', '9445', '23', '20000', '20001', '9441003', '1501', '1503', '1505', '537', '10004'],
    '745:1': ['5', '6', '602', '513', '613', '614', '615', '713', '20000', '20002', '9', '924', '926', '927', '928', '929', '923', '915', '9404', '905', '30000', '30002'],
    // tslint:disable-next-line:max-line-length
    '742:1': ['1', '2', '5', '602', '604', '509', '527', '530', '531', '532', '538', '541', '542', '546', '9404', '9', '905', '906', '9448', '9452', '9455', '9456', '9404', '10000', '10002', '935', '936', '937', '938', '939', '940', '941', '942', '943', '944', '945', '26', '2601', '2602', '2604', '30000', '30001', '30002', '30003', '30004', '950', '951', '20'],
    '754:1': ['5', '6', '602', '521', '613', '614', '615'],
    '757:1': ['5', '517'],
    '1:1': ['12' , '2604'  , '2603' , '2608' , '26075', '2607501', '2607502' ],
    '753:1': ['5', '518', '515'],
    '720:1': ['5', '519', '9', '903', '933'],
    '751:1': ['5', '609', '699'],
    '777:1': ['5', '547', '577', '956'],
    '774:1': ['5', '544', '9', '954'],
    '522:1': ['5', '6', '602', '613', '520', '614', '615', '713', '526', '9', '20000', '20003', '924', '926', '927', '923', '10000', '10005', '928'],
    // '742-538:1': ['1', '2', '5', '6', '602', '509', '516', '526', '614', '615', '9', '904', '905', '906', '525', '15', '1501', '1503', '1505', '16', '17', '20', '21'],
    '538:1': ['16', '19', '20', '22', '27', '9404', '1501', '1503', '1506', '28'],
    '765:1': ['9404', '1501', '1503', '1505', '5', '537', '10000', '10004'],
    '764:1': ['10000', '10003', '10006', '5', '602', '20000', '536', '919', '9', '946', '947', '948', '949'],
    '773:1': ['5', '543'],
    '776:1': [],
    '811:1': [],
    '809:1': [],
    '906:1': [],
    '808:1': [],
    '111:1': ['10111'],
    '1000:1': ['50', '51', '52', '53', '54', '55']
};

export const default_page = {
    '742': {
        defaultPage: '10002',
        configPage: '509',
        skewName: 'OrderEasy',
    },
    '744': {
        defaultPage: '10',
        configPage: '10',
        skewName: 'LiveTracking',
    },
    '1': {
        defaultPage: '12',
        configPage: '12',
        skewName: 'Admin'
    },
    '538': {
        defaultPage: '16',
        configPage: '20',
        skewName: 'DeliveryApp'
    },
    '111' : {
        defaultPage: '10111',
        configPage: '10111',
        skewName: 'dpadmin'
    }
};

export const master_url = {
    'item': {
        value: '/masters/index.html#/products/list/',
    },
    'category': {
        value: '/masters/index.html#/categoryhead/list/',
    },
    'categoryVal': {
        value: '/masters/index.html#/productcategories/list/',
    },
    'tax': {
        value: '/masters/index.html#/taxes/list/',
    },
    'customer': {
        value: '/masters/index.html#/customers/list/',
    },
    'onboard': {
        value: '/startupWizard/index.html',
    },
    'bulk': {
        value: '/com.gofrugal.raymedi.wpmigration.WPMigration/WPMigration.html?dbstr=csdb',
    }
};

export const profileFeatures = {
    '742': [
        {
            'id': 1,
            'featureName': 'Pos Registration',
            'routerLink': 'Integration/ecommerce/config/ordereasyConfigReport'
        },
        {
            'id': 2,
            'featureName': 'Data Sync',
            'routerLink': 'Integration/ecommerce/manual-sync'
        },
        {
            'id': 3,
            'featureName': 'Image upload',
            'routerLink': 'Integration/ecommerce/ordereasy/item-master'
        },
        {
            'id': 4,
            'featureName': 'App Upload',
            'routerLink': 'Integration/ecommerce/app-request'
        },
        {
            'id': 5,
            'featureName': 'App personalization',
            'routerLink': 'Integration/ecommerce/order-easy/app-builder'
        },
        {
            'id': 6,
            'featureName': 'Banner and news',
            'routerLink': 'Integration/ecommerce/config/bannerNewsConfig'
        },
        {
            'id': 7,
            'featureName': 'Delivery Config',
            'routerLink': 'Integration/ecommerce/delivery-slot'
        },
        {
            'id': 8,
            'featureName': 'First order',
            'routerLink': 'Integration/ecommerce/ordereasy/sales-order'
        },
        {
            'id': 9,
            'featureName': 'Offer Management',
            'routerLink': 'Integration/ecommerce/ordereasy-offer-management'
        },
        {
            'id': 10,
            'featureName': 'Chatbot Management',
            'routerLink': 'Integration/ecommerce/chat-bot'
        }
    ]
};

export const menuIconList = [
    {
        'id': '15',
        'name': 'Dashboard',
        'icon': 'equalizer',
        'open': false,
        'link': 'ecommerce/sr-dashboard/ordereasy/ecommerce',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': []
    },
    {
        'id': '40',
        'name': 'Transactions',
        'icon': 'list',
        'open': false,
        'link': 'false',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': [
            {
                'id': '4001',
                'parentId': '40',
                'name': 'Sales Orders',
                'link': 'ecommerce/ordereasy/sales-order',
                'icon': 'shopping_cart',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '10008',
                'parentId': '40',
                'name': 'Payment Sales Order',
                'link': 'ecommerce/payment-salesorder',
                'icon': 'account_balance',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            }
        ]
    },
    {
        'id': '29',
        'name': 'POS Integration',
        'icon': 'compare_arrows',
        'open': false,
        'link': 'false',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': [
            {
                'id': '291',
                'parentId': '29',
                'name': 'POS Registration',
                'link': 'ecommerce/config/ordereasyConfigReport',
                'icon': 'store',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '292',
                'parentId': '29',
                'name': 'Order Easy Store Setup',
                'link': 'ecommerce/config/OrderEasy',
                'icon': 'store',
                'open': false,
                'hidden': true,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            }
        ]
    },
    {
        'id': '9404',
        'name': 'Masters',
        'icon': 'storage',
        'open': false,
        'link': 'false',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': [
            {
                'id': '905',
                'parentId': '9404',
                'name': 'Items Configuration',
                'link': 'ecommerce/item-report',
                'icon': 'shopping_basket',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '9452',
                'parentId': '9404',
                'name': 'Bulk Image Upload',
                'link': 'ecommerce/bulk-upload-image',
                'icon': 'photo_library',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '906',
                'parentId': '9404',
                'name': 'Category Configuration',
                'link': 'ecommerce/category-report',
                'icon': 'donut_small',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '919',
                'parentId': '9404',
                'name': 'Items Master',
                'link': 'ecommerce/ordereasy/item-master',
                'icon': 'shopping_basket',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            }
        ]
    },
    {
        'id': '5',
        'name': 'Configuration',
        'icon': 'settings',
        'open': false,
        'link': 'false',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': [
            {
                'id': '531',
                'parentId': '5',
                'name': 'App settings',
                'link': 'ecommerce/config/commonConfig',
                'icon': 'settings_applications',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '538',
                'parentId': '5',
                'name': 'Online Payment',
                'link': 'ecommerce/config/onlinePaymentConfig',
                'icon': 'payment',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            }
        ]
    },
    {
        'id': '28',
        'name': 'Delivery',
        'icon': 'delivery_dining',
        'open': false,
        'link': 'false',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': [
            {
                'id': '281',
                'parentId': '28',
                'name': 'Delivery Charges',
                'link': 'ecommerce/config/deliveryConfiguration',
                'icon': 'directions_bike',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '282',
                'parentId': '28',
                'name': 'Delivery Slot',
                'link': 'ecommerce/delivery-slot',
                'icon': 'access_time',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '283',
                'parentId': '28',
                'name': 'Delivery Zone',
                'link': 'ecommerce/delivery-zone',
                'icon': 'person_pin_circle',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            }
        ]
    },
    {
        'id': '30000',
        'name': 'Notifications',
        'icon': 'notification_important',
        'open': false,
        'link': 'false',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': [
            {
                'id': '30001',
                'parentId': '30000',
                'name': 'Customer',
                'link': 'ecommerce/config/smsEmailConfig',
                'icon': 'perm_identity',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '30002',
                'parentId': '30000',
                'name': 'Admin',
                'link': 'ecommerce/config/notification-admin',
                'icon': 'manage_accounts',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '30003',
                'parentId': '30000',
                'name': 'Broadcast',
                'link': 'ecommerce/config/notification-broadcast',
                'icon': 'sensors',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '30004',
                'parentId': '30000',
                'name': 'Settings',
                'link': 'ecommerce/config/notification-configuration',
                'icon': 'settings_cell',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            }
        ]
    },
    {
        'id': '21',
        'name': 'Offer Management',
        'icon': 'shopping_basket',
        'open': false,
        'link': 'ecommerce/ordereasy-offer-management',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': true,
        'sub': []
    },
    {
        'id': '30',
        'name': 'App Customization',
        'icon': 'app_settings_alt',
        'open': false,
        'link': 'false',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': [
            {
                'id': '304',
                'parentId': '30',
                'name': 'Customize Homepage',
                'link': 'ecommerce/ordereasy-custom-homepage',
                'icon': 'dashboard_customize',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '304',
                'parentId': '30',
                'name': 'Splash Screen',
                'link': 'ecommerce/ordereasy/splash-screen',
                'icon': 'announcement',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '303',
                'parentId': '30',
                'name': 'App Intro',
                'link': 'ecommerce/splashScreen',
                'icon': 'phonelink_setup',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': true,
                'mappingStatus': true
            },
            {
                'id': '302',
                'parentId': '30',
                'name': 'Banner & News',
                'link': 'ecommerce/config/bannerNewsConfig',
                'icon': 'panorama',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '301',
                'parentId': '30',
                'name': 'Recommendations',
                'link': 'ecommerce/config/dynamicRecommendationConfig',
                'icon': 'announcement',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '2602',
                'parentId': '30',
                'name': 'Dynamic Menu',
                'link': 'ecommerce/order-easy/dynamic-menu',
                'icon': 'menu_open',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': true,
                'mappingStatus': true
            },
            {
                'id': '305',
                'parentId': '30',
                'name': 'Customize Homepage',
                'link': 'ecommerce/ordereasy-custom-homepage',
                'icon': 'dashboard_customize',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '305',
                'parentId': '30',
                'name': 'Splash Screen',
                'link': 'ecommerce/ordereasy/splash-screen',
                'icon': 'announcement',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            }
        ]
    },
    {
        'id': '26',
        'name': 'Mobile App',
        'icon': 'settings',
        'open': false,
        'link': 'false',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': [
            {
                'id': '2601',
                'parentId': '26',
                'name': 'App Builder',
                'link': 'ecommerce/order-easy/app-builder',
                'icon': 'app_settings_alt',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '2604',
                'parentId': '26',
                'name': 'App Request',
                'link': 'ecommerce/app-request',
                'icon': 'menu_open',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            }
        ]
    },
    {
        'id': '27',
        'name': 'Security',
        'icon': 'privacy_tip',
        'open': false,
        'link': 'false',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': [
            {
                'id': '271',
                'parentId': '27',
                'name': 'Employee Master',
                'link': 'ecommerce/employeeMaster',
                'icon': 'manage_accounts',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '272',
                'parentId': '27',
                'name': 'Role Master',
                'link': 'ecommerce/roleMaster',
                'icon': 'supervised_user_circle',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            }
        ]
    },
    {
        'id': '9',
        'name': 'Reports',
        'icon': 'table_view',
        'open': false,
        'link': 'false',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': [
            {
                'id': '935',
                'parentId': '9',
                'name': 'Item Master',
                'link': 'ecommerce/sr-report/1027/5/ecommerce',
                'icon': 'view_list',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '936',
                'parentId': '9',
                'name': 'Category Master',
                'link': 'ecommerce/sr-report/1035/5/ecommerce',
                'icon': 'view_list',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '937',
                'parentId': '9',
                'name': 'Customer Master',
                'link': 'ecommerce/sr-report/1026/5/ecommerce',
                'icon': 'view_list',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '938',
                'parentId': '9',
                'name': 'Tax Master',
                'link': 'ecommerce/sr-report/1025/5/ecommerce',
                'icon': 'view_list',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '939',
                'parentId': '9',
                'name': 'Shipping Master',
                'link': 'ecommerce/sr-report/1032/5/ecommerce',
                'icon': 'view_list',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '940',
                'parentId': '9',
                'name': 'Online Payment History',
                'link': 'ecommerce/sr-report/1033/5/ecommerce',
                'icon': 'view_list',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '941',
                'parentId': '9',
                'name': 'Sales Orders',
                'link': 'ecommerce/sr-report/1028/5/ecommerce',
                'icon': 'view_list',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '942',
                'parentId': '9',
                'name': 'Ordered Items',
                'link': 'ecommerce/sr-report/1029/5/ecommerce',
                'icon': 'view_list',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '943',
                'parentId': '9',
                'name': 'Item Wise SO',
                'link': 'ecommerce/sr-report/1036/5/ecommerce',
                'icon': 'view_list',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '944',
                'parentId': '9',
                'name': 'Customer Wise SO',
                'link': 'ecommerce/sr-report/1034/5/ecommerce',
                'icon': 'view_list',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '945',
                'parentId': '9',
                'name': 'Synced Failed SO',
                'link': 'ecommerce/sr-report/1030/5/ecommerce',
                'icon': 'view_list',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '950',
                'parentId': '9',
                'name': 'Broadcast Notification',
                'link': 'ecommerce/sr-report/1041/5/ecommerce',
                'icon': 'view_list',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '951',
                'parentId': '9',
                'name': 'Notification History',
                'link': 'ecommerce/sr-report/1042/5/ecommerce',
                'icon': 'view_list',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '965',
                'parentId': '9',
                'name': 'Payment Failed Orders',
                'link': 'ecommerce/sr-report/1053/5/ecommerce',
                'icon': 'view_list',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '965',
                'parentId': '9',
                'name': 'Sales Order Pending Item Quantity',
                'link': 'ecommerce/sr-report/1059/5/ecommerce',
                'icon': 'view_list',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            }
        ]
    },
    {
        'id': '20',
        'name': 'Chat Bot',
        'icon': 'reddit',
        'open': false,
        'link': 'ecommerce/chat-bot',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': []
    },
    {
        'id': '39',
        'name': 'Manage Subscription',
        'icon': 'payments',
        'open': false,
        'link': 'ecommerce/manage-subscription',
        'hidden': true,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': []
    },
    {
        'id': '901',
        'name': 'Distance Report',
        'icon': 'directions_bike',
        'open': false,
        'link': 'ecommerce/live-tracking-distReport',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': []
    },
    {
        'id': '702',
        'name': 'Outlet Setup',
        'icon': 'outlet',
        'open': false,
        'link': 'ecommerce/live-tracking-map/outlet',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': []
    },
    {
        'id': '801',
        'name': 'Distance Report',
        'icon': 'directions_bike',
        'open': false,
        'link': 'ecommerce/live-tracking-distReport',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': []
    },
    {
        'id': '700',
        'name': 'Live Tracking',
        'icon': 'directions',
        'open': false,
        'link': 'ecommerce/live-tracking-map/live',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': []
    },
    {
        'id': '701',
        'name': 'History Tracking',
        'icon': 'history',
        'open': false,
        'link': 'ecommerce/live-tracking-map/history',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': []
    },
    {
        'id': '47',
        'name': 'Dashboard',
        'icon': 'equalizer',
        'open': false,
        'link': 'delivery-app/sr-dashboard/DELIVERYSERVICE/delivery-app',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': []
    },
    {
        'id': '40',
        'name': 'Transactions',
        'icon': 'list',
        'open': false,
        'link': 'false',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': []
    },
    {
        'id': '44',
        'name': 'POS Registration',
        'icon': 'store',
        'open': false,
        'link': 'delivery-app/outlet-configuration',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': []
    },
    {
        'id': '36',
        'name': 'POS Registration',
        'icon': 'store',
        'open': false,
        'link': 'delivery-app/outlet-configuration',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': []
    },
    {
        'id': '37',
        'name': 'Masters',
        'icon': 'storage',
        'open': false,
        'link': 'false',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': [
            {
                'id': '371',
                'parentId': '37',
                'name': 'Delivery Boy',
                'link': 'delivery-app/delivery-boy',
                'icon': 'directions_bike',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '372',
                'parentId': '37',
                'name': 'Reason Master',
                'link': 'delivery-app/reason-master',
                'icon': 'feedback',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '373',
                'parentId': '37',
                'name': 'Tender Master',
                'link': 'delivery-app/tender-master',
                'icon': 'payment',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            }
        ]
    },
    {
        'id': '45',
        'name': 'Masters',
        'icon': 'storage',
        'open': false,
        'link': 'false',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': [
            {
                'id': '4501',
                'parentId': '45',
                'name': 'Delivery Boy',
                'link': 'delivery-app/delivery-boy',
                'icon': 'directions_bike',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '4501',
                'parentId': '45',
                'name': 'Item Master',
                'link': 'ondc/ondc-report/1056/5/ecommerce',
                'icon': 'view_list',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '4502',
                'parentId': '45',
                'name': 'Reason Master',
                'link': 'delivery-app/reason-master',
                'icon': 'feedback',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '4502',
                'parentId': '45',
                'name': 'Sales Order Summary',
                'link': 'ondc/ondc-report/1057/5/ecommerce',
                'icon': 'view_list',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '4503',
                'parentId': '45',
                'name': 'Sales Order Detail',
                'link': 'ondc/ondc-report/1058/5/ecommerce',
                'icon': 'view_list',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '4503',
                'parentId': '45',
                'name': 'Tender Master',
                'link': 'delivery-app/tender-master',
                'icon': 'payment',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            }
        ]
    },
    {
        'id': '41',
        'name': 'Delivery Allocation',
        'icon': 'local_shipping',
        'open': false,
        'link': 'delivery-app/delivery-allocation',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': []
    },
    {
        'id': '33',
        'name': 'Chat Bot',
        'icon': 'reddit',
        'open': false,
        'link': 'ecommerce/chat-bot',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': []
    },
    {
        'id': '38',
        'name': 'Sales Return Management',
        'icon': 'assignment_return',
        'open': false,
        'link': 'delivery-app/return-item-management',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': []
    },
    {
        'id': '46',
        'name': 'Sales Return Management',
        'icon': 'assignment_return',
        'open': false,
        'link': 'delivery-app/return-item-management',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': []
    },
    {
        'id': '42',
        'name': 'Session Management',
        'icon': 'today',
        'open': false,
        'link': 'delivery-app/session-master',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': [
            {
                'id': '4201',
                'parentId': '42',
                'name': 'Delivery Bills',
                'link': 'ecommerce/sr-report/1002/5/DELIVERYSERVICE',
                'icon': 'view_list',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '4202',
                'parentId': '42',
                'name': 'Deliver Bill Details',
                'link': 'ecommerce/sr-report/1003/5/DELIVERYSERVICE',
                'icon': 'view_list',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '4203',
                'parentId': '42',
                'name': 'Delivery Boy Session',
                'link': 'ecommerce/sr-report/1001/5/DELIVERYSERVICE',
                'icon': 'view_list',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            }
        ]
    },
    {
        'id': '34',
        'name': 'Offer Management',
        'icon': 'shopping_basket',
        'open': false,
        'link': 'ecommerce/ordereasy-offer-management',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': true,
        'sub': []
    },
    {
        'id': '43',
        'name': 'Configuration',
        'icon': 'settings',
        'open': false,
        'link': 'delivery-app/configuration',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': []
    },
    {
        'id': '35',
        'name': 'Configuration',
        'icon': 'settings',
        'open': false,
        'link': 'delivery-app/configuration',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': []
    },
    {
        'id': '27',
        'name': 'Security',
        'icon': 'privacy_tip',
        'open': false,
        'link': 'false',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': [
            {
                'id': '271',
                'parentId': '27',
                'name': 'Employee Master',
                'link': 'ecommerce/employeeMaster',
                'icon': 'manage_accounts',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '272',
                'parentId': '27',
                'name': 'Role Master',
                'link': 'ecommerce/roleMaster',
                'icon': 'supervised_user_circle',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            }
        ]
    },
    {
        'id': '49',
        'name': 'Reports',
        'icon': 'table_view',
        'open': false,
        'link': 'false',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': [
            {
                'id': '4901',
                'parentId': '49',
                'name': 'Delivery Bills',
                'link': 'ecommerce/sr-report/1002/5/DELIVERYSERVICE',
                'icon': 'view_list',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '4902',
                'parentId': '49',
                'name': 'Deliver Bill Details',
                'link': 'ecommerce/sr-report/1003/5/DELIVERYSERVICE',
                'icon': 'view_list',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '4903',
                'parentId': '49',
                'name': 'Delivery Boy Session',
                'link': 'ecommerce/sr-report/1001/5/DELIVERYSERVICE',
                'icon': 'view_list',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            }
        ]
    },
    {
        'id': '48',
        'name': 'Data Sync',
        'icon': 'sync',
        'open': false,
        'link': 'delivery-app/data-sync',
        'hidden': true,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': []
    },
    {
        'id': '538744',
        'name': 'Location Tracking',
        'icon': 'location_on',
        'open': false,
        'link': 'false',
        'hidden': false,
        'status': null,
        'mappingStatus': true,
        'isPro': false,
        'sub': [
            {
                'id': '538700',
                'parentId': '538744',
                'name': 'Live Tracking',
                'link': 'ecommerce/live-tracking-map/live',
                'icon': 'directions',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '538701',
                'parentId': '538744',
                'name': 'History Tracking',
                'link': 'ecommerce/live-tracking-map/history',
                'icon': 'history',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '538702',
                'parentId': '538744',
                'name': 'Outlet Setup',
                'link': 'ecommerce/live-tracking-map/outlet',
                'icon': 'store',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            },
            {
                'id': '538901',
                'parentId': '538744',
                'name': 'Distance Report',
                'link': 'ecommerce/live-tracking-distReport',
                'icon': 'directions_bike',
                'open': false,
                'hidden': false,
                'status': null,
                'isPro': false,
                'mappingStatus': true
            }
        ]
    }
];

export const addonDetails = [
    {
        'displayName': 'OrderEasy',
        'subSkew': [],
        'addOnName': 'OrderEasy',
        'productCode': '742',
        'isNew': true,
        'purchased': true,
        'addonType': 'Ecommerce',
        'isTrending': true,
        'expired': false,
        'logout': true
    },
    {
        'displayName': 'LocationTracking',
        'subSkew': [],
        'addOnName': 'LocationTracking',
        'productCode': '744',
        'isNew': true,
        'purchased': true,
        'addonType': 'Ecommerce',
        'isTrending': true,
        'expired': false,
        'logout': true
    },
    {
        'displayName': 'GoDeliver',
        'subSkew': [],
        'addOnName': 'GoDeliver',
        'productCode': '538',
        'isNew': true,
        'purchased': true,
        'addonType': 'Ecommerce',
        'isTrending': true,
        'expired': false,
        'logout': true
    }
];

export const ordereasyValidSkewCodes = ['742', '538', '744', '910', '917', '921', '920', '1003', '923', '926', '938' , '937' , '936' , '935', '944'];
