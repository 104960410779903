import { Injectable } from '@angular/core';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import { AccountApiService } from '../../core/http/account/account-api.service';
import { AuthenticationService } from '../../core/services/authentication.service';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {StompService} from '../../core/web-socket/StompService';
import {LiveTrackingStompService} from '../../core/web-socket/LiveTrackingStompService';
import {CookieService} from 'ngx-cookie-service';
import {NotificationService} from '../../core/services/notification.service';
import {LoginDetailsService} from "../../core/services/login-details.service";
@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  constructor(
      private accountApiService: AccountApiService,
      private authenticationService: AuthenticationService,
      private router: Router,
      private stompService: StompService,
      private liveTrackingStompService: LiveTrackingStompService,
      private cookieService: CookieService,
      private notifyService: NotificationService,
      private loginDetailsService: LoginDetailsService
  ) {
  }

  expireSession() {
    if (this.cookieService.get('currentUser')) {
      this.logout().subscribe((resp) => {
            if (resp) {
              localStorage.clear();
              sessionStorage.clear();
              this.stompService.disconnect();
              this.liveTrackingStompService.disconnect();
              this.cookieService.delete('X-Auth-Token', '/', window.location.hostname);
              this.cookieService.deleteAll('/', window.location.hostname);
              this.cookieService.deleteAll();
              this.router.navigate(['/login']).then(() => {
                window.location.reload();
              });
            }
          },
          (err) => {
            this.notifyService.showError('Failure', 'logout Failed');
          });
    } else {
      this.cookieService.deleteAll();
      localStorage.clear();
      sessionStorage.clear();
      this.stompService.disconnect();
      this.liveTrackingStompService.disconnect();
      this.cookieService.delete('X-Auth-Token', '/', window.location.hostname);
      this.cookieService.deleteAll('/', window.location.hostname);
      this.cookieService.deleteAll();
      this.router.navigate(['/login']).then(() => {
        window.location.reload();
      });
    }
  }

  logout(): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('isMobile', '1');
    return this.accountApiService.post('v1/logout', null, header, null, true);
  }
}
