import { Component, OnInit } from '@angular/core';
import {LoginDetailsService} from '../../../core/services/login-details.service';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {EcommerceService} from '../../../modules/integration/ecommerce/ecommerce.service';
import {NotificationService} from '../../../core/services/notification.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-privacy-policy-modal',
  templateUrl: './privacy-policy-modal.component.html',
  styleUrls: ['./privacy-policy-modal.component.scss']
})
export class PrivacyPolicyModalComponent implements OnInit {
  privacyPolicy: any;
  marketPlaceId: any;
  displayModal = false;
  constructor(
      private readonly loginDetailsService: LoginDetailsService,
      private authenticationService: AuthenticationService,
      private ecommerceService: EcommerceService,
      private notificationService: NotificationService,
      private router: Router,
  ) { }

  ngOnInit(): void {
    this.ecommerceService.checkPolicyApprovedOrNot().subscribe(response => {
      if (response) {
        this.marketPlaceId = response.marketPlaceId;
        this.getPolicies();
      }
    });
  }

  getPolicies() {
    const body = {
      'userId': '' + this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
      'outletId': this.authenticationService.getOutlet(),
      'filter': 'marketPlaceId=' + this.marketPlaceId,
    };
    this.ecommerceService.getPolicies(body).subscribe(
        res => {
          console.log(res);
          if (res) {
            this.privacyPolicy = res;
            this.displayModal = true;
          } else {
            this.notificationService.showWarn('Warn', res.message);
          }
        },
        error => {
          this.notificationService.showWarn('Warn', error);
        }
    );
  }
  acceptUpdatedPolicy() {
    const body = {
      'userId': this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
      'emailId': this.authenticationService.getEmailId(),
      'privacyPolicyStatus': 'accepted'
    };
    this.ecommerceService.acceptPolicy(body).subscribe(response => {
      if (response.status) {
        this.checkPolicyStatus();
        this.notificationService.showSuccess('Success', response.message);
      } else {
        this.notificationService.showError('Error', response.message);
      }
    });
  }
checkPolicyStatus() {
  this.ecommerceService.checkPolicyApprovedOrNot().subscribe(response => {
    if (response && response.privacyPolicyStatus  !== 'pending') {
      this.displayModal = false;
      this.router.navigate( ['/Integration/ecommerce/sr-dashboard/ordereasy/ecommerce/oe_marketplace']);
    }
  });
}
  cancelUpdatedPolicy() {
    this.displayModal = false;
    this.router.navigate(['/logout']);
  }
  getPrivacyPolicy(policies) {
    if (!policies) {
      return '';
    }
    return policies.replace(/<a/g, '<a target="_blank"');
  }
}
