import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {compileNgModule} from '@angular/compiler';

@Component({
  selector: 'app-config-input',
  templateUrl: './config-input.component.html',
  styleUrls: ['./config-input.component.scss']
})
export class ConfigInputComponent  implements OnChanges {

  @Input() configurations: any;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.configurations) {
      this.configurations.sort((a, b) => b.configId - a.configId);
      this.configurations.map(config => {
        config.isOpen = false;
        if (config.type === 'multiselect dropdown') {
          config = this.multiSelectChanges(config);
        }
      });
    }
  }

  onChkChange(config) {
    config.value = String(config.value !== 'true');
  }

  onChangeDropDown(config, value) {
    config.value = value;
  }

  openOption(config) {
    config.isOpen =  !config.isOpen;
  }

  optionChange(config, type) {
    console.log(config, type);
    config.value =  type;
  }

  multiSelectChanges(config) {
    console.log(config);
    config['valueList'] = (config.value.split(','));
    this.createList(config);
    console.log(config);
    return config;
  }

  createList(config) {
    config['checkedList'] = config.dropDown.reduce((resultArray, d) => {
      const found = resultArray.find(a => a === d);
      if (!found) {
        resultArray.push({
          name : d,
          isChecked : config.valueList.includes(d),
        });
      }
      return resultArray;
    }, []);
  }

  updateListValues(config, value) {
    if (config.valueList.includes(value)) {
      const index = config.valueList.indexOf(value);
      if (index > -1) {
        config.valueList.splice(index, 1);
      }
    } else {
      config.valueList.push(value);
    }
    config.value = config.valueList.join(',');
    console.log(config);
    this.createList(config);
  }

  stopProbagation(event: MouseEvent) {
    event.stopPropagation();
  }

  closeModal(config) {
    config.isOpen = !config.isOpen;
  }
}
