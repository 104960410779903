import * as Stomp from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import {SharedService} from '../../shared/service/shared.service';
import {Injectable} from '@angular/core';
import {EcommerceService} from '../../modules/integration/ecommerce/ecommerce.service';
import {Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {NotificationService} from '../services/notification.service';
import {TextToSpeech} from '../../scripts/text-to-speech.js'
import {DeliveryService} from '../../modules/integration/delivery-web-app/delivery-web-app.service';
import {LoginService} from '../../modules/login/login.service';
import {LoginDetailsService} from '../services/login-details.service';
import {CookieService} from 'ngx-cookie-service';


@Injectable()
export class StompService {

    disabled = true;
    private reconnected = false;
    private stompClient = null;
    private timer: any;
    private audio = new Audio();
    private notificationAudio = new Audio();
    private loginDetails = this.loginDetailService.getLoginDetails();

    constructor(
        private sharedService: SharedService,
        private ecomService: EcommerceService,
        private deliveryService: DeliveryService,
        private notify: NotificationService,
        private authenticationService: AuthenticationService,
        private router: Router,
        private loginService: LoginService,
        private loginDetailService: LoginDetailsService,
        private cookieService: CookieService,
    ) {
    }

    setConnected(connected: boolean) {
        this.disabled = !connected;
    }

    getConnected(): boolean {
        if (this.stompClient) {
            return this.stompClient.connected;
        } else {
            return false;
        }
    }

    playAudio(orderType: string) {
        this.audio.src = `./assets/audio/${orderType}.wav`;
        // this.audio.loop = true;
        this.audio.playbackRate = 5;
        this.audio.load();
        this.audio.play();
    }


    stopAudio() {
        this.audio.pause();
    }

    playNotificationAudio() {
        this.notificationAudio.src = './assets/audio/notification_audio.wav';
        this.notificationAudio.playbackRate = 0;
        this.notificationAudio.load();
        this.notificationAudio.play();
    }

    stopNotificationAudio() {
        this.notificationAudio.pause();
    }

    connect(serviceId) {
        const socket = new SockJS(this.loginDetails.ecomURL + '../notification');
        this.stompClient = Stomp.over(socket);

        const _this = this;
        _this.sendPendingOrderCount();
        _this.sendPendingNotificationsCount();
        this.stompClient.connect({}, function (frame) {
                if (_this.reconnected) {
                    _this.sharedService.setReconnected(true);
                    _this.reconnected = false;
                }
                _this.stompClient.subscribe('/topic/' + serviceId, function (response) {
                    const resp = JSON.parse(response.body);
                    _this.sharedService.setAllStatusCount(resp.count);
                    if (response.body && resp.cancel) {
                        if (resp.cancel.status === 'cancelled') {
                            TextToSpeech('Hi Order number' + resp.cancel.orderId + 'has been cancelled');
                        }
                    }
                    console.log(resp);
                    if (response.body && resp.notification) {
                        _this.playNotificationAudio();
                        _this.getNotificationsList();
                    } else if (response.body && resp.order) {
                        // tslint:disable-next-line:max-line-length
                        if (_this.authenticationService.getSkewCode() === '742' || _this.authenticationService.getSkewCode() === '765'
                            || _this.authenticationService.getSkewCode() === '535' || _this.authenticationService.getSkewCode() === '811'
                            || _this.authenticationService.getSkewCode() === '917' || _this.authenticationService.getSkewCode() === '1003' || _this.authenticationService.getSkewCode() === '923' || _this.authenticationService.getSkewCode() === '926') {
                            if ((_this.authenticationService.getSelectedOutletId()).toString() === (resp.order.outletId).toString()
                                && (_this.authenticationService.getSkewCode() === '742' || _this.authenticationService.getSkewCode() === '917' || _this.authenticationService.getSkewCode() === '1003' || _this.authenticationService.getSkewCode() === '923' || _this.authenticationService.getSkewCode() === '926')) {
                                if (resp.order.orderStatus && resp.order.orderStatus.toLowerCase() === 'prescription review in progress') {
                                    _this.playAudio('prescription-notification'); // prescription order
                                } else if (resp.order.deliveryType && resp.order.deliveryType === 'express') {
                                    _this.playAudio('expressnotification'); // express order
                                } else {
                                    _this.playAudio('notification'); // standard order
                                }
                                const checkStringNumber = isNaN(resp.order.customerName);
                                let stringArray = '';
                                if (!checkStringNumber) {
                                    for (let i = 0; i < resp.order.customerName.length; i++) {
                                        stringArray = stringArray ? stringArray + ' ' + resp.order.customerName[i] : resp.order.customerName[i];
                                    }
                                }
                                TextToSpeech(`Hi You Have Received New  ${(resp.order.orderStatus && resp.order.orderStatus.toLowerCase() === 'prescription review in progress') ? 'Prescription' : ''}  Order From ` + (checkStringNumber ? resp.order.customerName : stringArray));
                                //Hi You Have Received New  ${(resp.order.orderStatus && resp.order.orderStatus.toLowerCase() === 'prescription review in progress') ? 'Prescription' : ''}  Order From ` + (checkStringNumber ? resp.order.customerName : stringArray)
                                // _this.speech.speak({
                                //     // tslint:disable-next-line:max-line-length
                                //     text: `,
                                //     listeners: {
                                //         onboundary: (event) => {
                                //
                                //         }
                                //     }
                                // }).then(() => {
                                //     // this.stopAudio()
                                // }).catch(e => {
                                //
                                // });
                                _this.sharedService.setRecentOrders(resp.order);
                            } else if ( _this.authenticationService.getSkewCode() !== '742') {
                                if (resp.order.deliveryType && resp.order.deliveryType === 'express') {
                                    _this.playAudio('expressnotification');
                                } else {
                                    _this.playAudio('notification');
                                }
                                const checkStringNumber = isNaN(resp.order.customerName);
                                let stringArray = '';
                                if (!checkStringNumber) {
                                    for (let i = 0; i < resp.order.customerName.length; i++) {
                                        stringArray = stringArray ? stringArray + ' ' + resp.order.customerName[i] : resp.order.customerName[i];
                                    }
                                }
                                TextToSpeech('Hi You Have Received New Order From ' + (checkStringNumber ? resp.order.customerName : stringArray));
                                _this.sharedService.setRecentOrders(resp.order);
                            }
                        }
                    } else if (response.body && resp.sync) {
                        _this.sharedService.setUnsyncOrders(resp.sync.unsync);
                    } else if (response.body && resp.login) {
                        if (_this.authenticationService.getAuthToken() !== resp.login) {
                            _this.notify.showCloseMsg('info', 'Session Expired', 'Kindly login', () => {
                                _this.router.navigate(['/logout']);
                            });
                        }
                    } else if (response.body && resp.token) {
                        /*                    if (_this.authenticationService.getAuthToken() !== resp.token) {
                                                _this.notify.showCloseMsg('info', 'Session Expired', 'Kindly login', () => {
                                                    _this.router.navigate(['/logout']);
                                                });
                                            }*/
                    } else if (response.body && resp.update) {
                        _this.sharedService.setRecentOrders(resp);
                    } else if (response.body && resp.deliveryExecutiveAllocation) {
                        // tslint:disable-next-line:max-line-length
                        _this.notify.showSuccess('Success', `Delivery Executive ${resp.deliveryExecutiveAllocation.deliveryBoyDetails.deliveryBoyName} is assigned for Order Id - ${resp.deliveryExecutiveAllocation.onlineReferenceNO}`);
                        TextToSpeech('Hi Delivery Executive' + resp.deliveryExecutiveAllocation.deliveryBoyDetails.deliveryBoyName + 'is assigned for Order Id' + resp.deliveryExecutiveAllocation.onlineReferenceNO);
                    } else if (response.body && resp.cancel) {
                        _this.sharedService.setRecentOrders(resp);
                    }
                });
                _this.getPendingOrders();
            },
            () => {
                _this.reconnected = true;
                clearTimeout(_this.timer);
                _this.timer = setTimeout(() => {
                    _this.connect(serviceId);
                }, 10000);
            });
    }

    getPendingOrders() {
        if (this.authenticationService.getSkewCode() === '538') {
            this.deliveryService.fetchBillHeader('bill-header', 50, '1', 'deliveryStatus=UD,deliveryBoyId=0', '', false).subscribe(data => {
                if (data) {
                }
            });
        } else if (this.authenticationService.getSkewCode() === '744') {
                return;
        } else if (this.authenticationService.getSkewCode() === '923'){
            this.ecomService.getOrders('', 'Acknowledged', 0, 50, false).subscribe(res => {
                if (res && res.data) {
                    this.sharedService.setPendingOrderCount(res.data.length);
                    this.stopAudio();
                }
            });
        } else {
            this.ecomService.getOrders('', 'Pending', 0, 50, false).subscribe(res => {
                if (res && res.data) {
                    this.sharedService.setPendingOrderCount(res.data.length);
                    this.stopAudio();
                }
            });
        }
    }

    sendPendingOrderCount() {
        this.sharedService.setPendingOrderCount(0);
    }

    sendPendingNotificationsCount() {
        this.sharedService.setPendingNotificationCount(0);
    }

    getNotificationsList() {
        if (this.cookieService.get('currentUser')) {
            const body = {
                'userId': this.authenticationService.getServiceUserId(),
                'vendor': this.authenticationService.getSkewName()
            };
            this.loginService.getNotification(body).subscribe(
                (data) => {
                    if (data) {
                        this.sharedService.setPendingNotificationCount(data.unreadNotificationCount);
                    }
                },
                (err) => {
                });
        }
    }

    disconnect() {
        if (this.stompClient != null) {
            this.stompClient.disconnect();
        }
        this.sendPendingOrderCount();
        this.sendPendingNotificationsCount();
    }
}
