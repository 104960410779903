import {Component, Input, OnInit} from '@angular/core';
import {EcommerceService} from '../../../../modules/integration/ecommerce/ecommerce.service';
import {NotificationService} from '../../../../core/services/notification.service';
import {AuthenticationService} from '../../../../core/services/authentication.service';

@Component({
    selector: 'app-subscription-details',
    templateUrl: './subscription-details.component.html',
    styleUrls: ['./subscription-details.component.scss']
})
export class SubscriptionDetailsComponent implements OnInit {
    subscriptionDetails: any;
    @Input() storeDetails: any;
    isModalOpen: any;
    subscriptionList = {
        isOpen: false,
        list: []
    };
    subscriptionValue: any;
    selectedSubscriptionValue: any;

    constructor(
        private readonly ecommerceService: EcommerceService,
        private readonly notificationService: NotificationService,
        public readonly authenticationService: AuthenticationService
    ) {
    }

    ngOnInit() {
        this.getSubscriptionDetails();
    }

    closeOpenModal(type) {
        this[type] = false;
    }

    openModal(type) {
        this[type] = true;
        this.getSubscriptionList();
    }

    stopProbagation(event: MouseEvent) {
        event.stopPropagation();
    }

    openOption(config) {
        console.log(config);
        config.isOpen = !config.isOpen;
    }

    optionChange(type) {
        if (type) {
            this.subscriptionValue = type.name;
            this.selectedSubscriptionValue = type;
            console.log(this.subscriptionValue);
        }
    }

    getSubscriptionDetails() {
        const body = {
            'userId': this.authenticationService.getServiceUserId(),
            'customerId': this.authenticationService.getCustomerId(),
            'source': 'store'
        };
        if (this.authenticationService.getSkewCode() === '910') {
            body['source'] = 'mp';
            body['storeId'] = this.storeDetails.shopId;
            body['storeCustomerId'] = this.storeDetails.customerId;
        }
        this.ecommerceService.getParticularSubscriptionDetails(body).subscribe(
            res => {
                console.log(res);
                if (res && res.status !== true) {
                    this.subscriptionDetails = res;
                    this.subscriptionDetails['featureList'] = res.featureList.filter(x => x.applied);
                    console.log(this.subscriptionDetails);
                } else {
                    this.subscriptionDetails = '';
                }
            }, error => {
                this.notificationService.showWarn('Warn', error);
            }
        );
    }

    getSubscriptionList() {
        const body = {
            'userId': this.authenticationService.getServiceUserId(),
            'vendor': this.authenticationService.getSkewName(),
            'outletId': this.authenticationService.getOutlet()
        } ;
        this.ecommerceService.getSubscriptionDetails(body).subscribe(
            res => {
                if (res) {
                    console.log(res);
                    this.subscriptionList.list = res.subscriptionList.filter(sub => (sub.status === 'active'));

                } else {
                    this.notificationService.showWarn('Warn', res.message);
                }
            },
            err => {
                this.notificationService.showWarn('Warn', err.message);
            }
        );
    }

    updateSubscription() {
        const body = {
            'userId': this.authenticationService.getServiceUserId(),
            'customerId': this.authenticationService.getCustomerId(),
            'skewCode': this.selectedSubscriptionValue.skewCode,
            'storeId': this.storeDetails.shopId,
            'storeCustomerId': this.storeDetails.customerId

        };
        this.ecommerceService.updateSubscription(body).subscribe(
            res => {
                if (res && res.status) {
                    this.notificationService.showSuccess('Success', res.message);
                    this.closeOpenModal('isModalOpen');
                    this.getSubscriptionDetails();
                } else {
                    this.notificationService.showWarn('Warn', res.message);
                }
            },
            error =>  {
                this.notificationService.showWarn('Warn', error);
            }
        );
    }
}
