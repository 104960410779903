import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {EcommerceService} from '../../../../modules/integration/ecommerce/ecommerce.service';
import {SharedService} from '../../../service/shared.service';
import {NotificationService} from '../../../../core/services/notification.service';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {BannerNewsConfigService} from '../../../../modules/integration/ecommerce/pages/configuration/banner-news-Config/banner-news-config-service';
import {MapsAPILoader} from '@agm/core';
import * as moment from 'moment';
import {ReturnOrderItem, UpdateReturn} from '../../../../core/models';
import $ from 'jquery';
import { MatDrawer } from '@angular/material/sidenav';

declare var google;

@Component({
  selector: 'app-return-details',
  templateUrl: './return-details.component.html',
  styleUrls: ['./return-details.component.scss']
})
export class ReturnDetailsComponent implements OnInit {

  @ViewChild('drawer', {static: false}) drawer: MatDrawer;
  selectedTab = 'Basic Information';
  dynamicCurrency = '';
  @Input() returnDetails: any;
  latitudeNo: number;
  distance = '0';
  longitudeNo: number;
  @Output() closeDrawer = new EventEmitter<any>();
  selectedReturnDetails: ReturnOrderItem[];
  orderDetails: any;
  imageViewModal = false;
  activeMinViewImag: number;
  selectedReturnImage = [];
  currentIndex = 1;
  selectedItem: any;
  approveStatus = {
    value: 'Approve Return',
    isOpen: false,
    list: [{name: 'Reject Return', id: 'RETURN_REJECTED'}, {name: 'Liquidate Return', id: 'LIQUIDATED'} ]
  };
// {name: 'Approve Return', id: 'RETURN_APPROVED'},
  endStatus = ['RETURN_REJECTED', 'LIQUIDATED', 'RETURN_DELIVERED'];
  updateReturnDetails = new UpdateReturn();
  selectedIndex = 0;
  private map: any;
  zoom: number;
  private geoCoder;
  isLatitudeCopied = false;
  isLongitudeCopied = false;

  constructor(
      private ecommerceService: EcommerceService,
      private sharedService: SharedService,
      private notifyService: NotificationService,
      public authenticationService: AuthenticationService,
      private bannerNewsService: BannerNewsConfigService,
      private mapsAPILoader: MapsAPILoader,
  ) {
  }

  ngOnInit() {
    this.selectedTab = 'Basic Information';
    this.selectedIndex = 0;
    this.dynamicCurrency = this.authenticationService.getCurrency();
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
    });
    this.getParticularReturnOrder(this.returnDetails);
  }

  onTabChange(tabName) {
    this.selectedTab = tabName.tab.textLabel;
  }

  getParticularReturnOrder(item) {
    this.selectedReturnDetails = [];
    const body = {
      'userId': Number(this.authenticationService.getServiceUserId()),
      'vendor': this.authenticationService.getSkewName(),
      'outletId': Number(this.authenticationService.getSelectedOutletId()),
      'onlineReferenceNo': item.onlineReferenceNo,
      'orderId': item.orderId,
      'returnId': item.returnId
    };
    this.ecommerceService
        .getReturnOrderItem(body)
        .subscribe((response) => {
          this.selectedReturnDetails = response.returnDtl;
          this.orderDetails = response.order;
          this.latitudeNo = Number(this.orderDetails.latitude);
          this.longitudeNo = Number(this.orderDetails.longitude);
          this.selectedReturnDetails.forEach((res) => {
            res.ttlApproval = this.readableIsoParse(
                Object.values(moment.duration(res.ttlApproval))[4]
            );
            res.ttlReverseQc = this.readableIsoParse(
                Object.values(moment.duration(res.ttlReverseQc))[4]
            );
            res.currentStatus = res.approveStatus ? res.approveStatus : '';
            const statusName = this.approveStatus.list.filter(x => x.id === res.currentStatus);
            res.currentStatusName = (res.currentStatus && statusName) ? statusName[0].name : '';
            res.isOpen = false;
            if (res.defectImages !== null && res.defectImages !== '') {
              res.defectImagesSlide = res.defectImages.split(',');
            }
          });
        });
  }

  getClassByValue(status?) {
    let className: string;
    const sts = status ? status.toUpperCase() : '';
    switch (sts) {
      case 'RETURN_REJECTED':
        className = 'status-rejected';
        break;
      case 'LIQUIDATED':
        className = 'status-liquidated';
        break;
      case 'RETURN_DELIVERED':
        className = 'status-approved';
        break;
      default:
        className = 'status-acknowledged';
        break;
    }
    return className;
  }

  readableIsoParse(obj) {
    const entries = Object.entries(obj);
    const ttl = entries
        .filter((res) => res[1] > 0)
        .map((ele) => ele.reverse().toString().replace(',', ' '));
    return ttl.toString();
  }
  onMapReady(e) {
    this.map = e;
  }

  openViewMap(drawer) {
    this.drawer = drawer;
    this.drawer.open();
  }

  copyTextClipBoard(data, type) {
    this[type] = true;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = data;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    setTimeout(() => {
      this[type] = false;
    }, 2500);
  }

  /**
   * @description For ONDC address
   */
  removeNull(data) {
    const length = data.split(', null').length - 1;
    for (let i = 0; length > i; i++) {
      data = data.replace(', null', '');
    }
    return data;
  }

  openOption(config) {
    config.isOpen = !config.isOpen;
    console.log(config);
  }

  stopProbagation(event: MouseEvent) {
    // event.stopPropagation();
  }


  viewReturnImage(data, image, activeSlide) {
    this.selectedItem = data;
    this.imageViewModal = true;
    this.activeMinViewImag = activeSlide;
    this.selectedReturnImage = image;
    this.currentIndex = activeSlide + 1;
  }

  getCurrentIndex() {
    setTimeout(() => {
      const carouselEl = $('#fullViewCarousel');
      const carouselItems = carouselEl.find('.carousel-item');
      console.log(carouselItems, carouselEl);
      console.log(carouselItems.siblings('.active').index() + 1);
      this.currentIndex = carouselItems.siblings('.active').index() + 1;
    }, 1000);
  }

  optionChange(item, config, type) {
    console.log(config, type);
    if (config === 'currentStatus') {
      item['approveStatus'] = type.id;
      item['currentStatusName'] = type.name;
    } else {
      item[config] = type.id;
    }
  }

  replaceApprovedStatus(status) {
    return status.replace('_', ' ');
  }

  showApproveStatus(item) {
    return ((item.currentStatus === 'RETURN_REJECTED' || item.currentStatus === 'LIQUIDATED' || item.currentStatus === 'RETURN_DELIVERED') && (item.currentStatus !== null))
    || (this.returnDetails.isLogistics && (item.currentStatus === 'RETURN_APPROVED' || item.currentStatus === 'RETURN_PICKED'));
  }

  checkItemStatus() {
      const getBoolean = [];
      const removedEndReturnItems = this.selectedReturnDetails.filter(
          (res) => !this.endStatus.includes(res.approveStatus)
      );
      removedEndReturnItems.forEach((res) => {
        if (
            res.approveStatus !== null &&
            res.approveStatus !== '' &&
            res.currentStatus !== res.approveStatus
        ) {
          getBoolean.push(0);
        } else {
          getBoolean.push(1);
        }
      });
      return getBoolean.includes(0);
  }

  updateReturn () {
    console.log(this.checkItemStatus());
    if (this.checkItemStatus()) {
      this.notifyService.showWarn('Warn', 'Please Update All Status');
    } else {
      this.updateReturnDetails = {
        userId : this.authenticationService.getServiceUserId(),
        vendor: this.authenticationService.getSkewName(),
        outletId: Number(this.authenticationService.getOutlet()),
        onlineReferenceNo: this.returnDetails.onlineReferenceNo,
        orderId: this.returnDetails.orderId,
        approveStatus: 'acknowledged',
        returnId: this.returnDetails.returnId,
        reverseQcInstructions: this.returnDetails.reverseQcInstructions ?
            this.returnDetails.reverseQcInstructions : '',
        returnItemUpdateList: this.selectedReturnDetails
      };
      this.ecommerceService.updateReturnStatus(this.updateReturnDetails).subscribe((res) => {
        if (res.status) {
          this.notifyService.showSuccess('Success', res.message);
          this.close();
        } else {
          this.notifyService.showError('Error', res.message);
        }
      },
      error => {
        this.notifyService.showWarn('Warn', error);
      });
      console.log(this.updateReturnDetails);
    }
  }

  close() {
    this.closeDrawer.emit(true);
  }

  getNextStatus(item) {
    switch (item.approveStatus) {
      case 'RETURN_APPROVED':
        return 'Pick Return';
      case 'RETURN_PICKED':
        return 'Deliver Return';
      default:
        return '';
    }
  }

  updateStatus(item: ReturnOrderItem) {
    item['approveStatus'] = item.currentStatus === 'RETURN_APPROVED' ? 'RETURN_PICKED' : 'RETURN_DELIVERED';
    this.updateReturn();
  }
}
