import {Component, OnInit, Input, Output} from '@angular/core';
import { SharedService } from '../../service/shared.service';

@Component({
    selector: 'cdk-sidemenu-item',
    templateUrl: './sidemenu-item.component.html',
    styleUrls: ['./sidemenu-item.component.scss']
})
export class SidemenuItemComponent implements OnInit {

    @Input() menu;
    @Input() iconOnly: boolean;
    @Input() secondaryMenu = false;
    hover  = false;

    constructor(
        private sharedService: SharedService,
    ) { }

    ngOnInit() {
    }


    chechForChildMenu() {
        return !!(this.menu && this.menu.sub);
    }

    toggleOpen() {
        if (this.chechForChildMenu) {
            if (this.iconOnly) {
                return;
            }
            this.menu.open = !this.menu.open;

            if (this.menu.link === false) {
                this.sharedService.setParentMenuId(this.menu.id);
            } else if (this.menu.parentId) {
                this.sharedService.setParentMenuId(this.menu.parentId);
            } else {
                this.sharedService.setParentMenuId(null);
            }
        }
    }

    onHover() {
        this.hover = !this.hover;
        this.sharedService.setIsHover(this.hover);
    }

    isPro() {
        return (this.menu.isPro && !this.iconOnly);
    }

    onScroll(event) {
    }
}
