import {Component, OnInit, Input, HostListener, ElementRef} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../core/services/authentication.service';

@Component({
    selector: 'cdk-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
    isOpen = false;

    @Input() currentUser = null;

    @HostListener('document:click', ['$event', '$event.target'])
    onClick(event: MouseEvent, targetElement: HTMLElement) {
        if (!targetElement) {
            return;
        }

        const clickedInside = this.elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.isOpen = false;
        }
    }


    constructor(
        private elementRef: ElementRef,
        private router: Router,
        private authenticationService: AuthenticationService) {
    }


    ngOnInit() {
        this.currentUser.currentUserName = this.authenticationService.getUserName();
    }

    onManageSubscriptionClick() {
        this.router.navigate(['Integration/ecommerce/manage-subscription']);
        this.isOpen = false;
    }

    logout() {
        localStorage.removeItem('selectedOutletId');
        localStorage.removeItem('selectedOutletName');
        localStorage.removeItem('outletDetails');
        localStorage.removeItem('selectedOutlet');
        localStorage.removeItem('companyCode');
        localStorage.removeItem('companyAvailiable');
        this.router.navigate(['/logout']);
    }
}
