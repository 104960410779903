import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {LoginDetailsService} from '../../services/login-details.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private loginDetails = this.loginDetailService.getLoginDetails();

  constructor(private apiService: ApiService,
              private loginDetailService: LoginDetailsService
  ) {
    this.loginDetailService.themeDetailsList.subscribe(res => {
      if (res) {
        this.loginDetails = res;
      }
    })
  }

  get(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), showLoader?: Boolean): Observable<any> {
    return this.apiService.get(this.loginDetails.alertURL, path, param, header, showLoader)
      .pipe(catchError(this.handleError('Get - Alert')));
  }

// tslint:disable-next-line: max-line-length
  put(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: Object = {}, showLoader?: Boolean): Observable<any> {
    return this.apiService.put(this.loginDetails.alertURL, path, param, header, body, showLoader)
      .pipe(catchError(this.handleError('Put - Alert')));
  }

  // tslint:disable-next-line: max-line-length
  post(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: Object = {}, showLoader?: Boolean): Observable<any> {
    return this.apiService.post(this.loginDetails.alertURL, path, param, header, body, showLoader)
      .pipe(catchError(this.handleError('Post - Alert')));
  }

  // tslint:disable-next-line: max-line-length
  delete(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), showLoader?: Boolean): Observable<any> {
    return this.apiService.delete(this.loginDetails.alertURL, path, param, header, showLoader)
      .pipe(catchError(this.handleError('Delete - Alert')));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }
}
