export class OutletDetail {
    customerId?: string;
    shopName: string;
    shopLocation?: string;
    custStreetName?: string;
    orderNo?: string;
    outletUrl?: string;
    productCode?: string;
    outletId = 1;
    companyDetails?: Array<CompanyList>
    selectedMsg?: string;
    isSelected: boolean;
    baseProduct:string;
    vendor: string;
    corporateCustomerId :string;
}

export class CompanyList {
    id: number;
    name: string;
    mobile: string;
    email: string;
    currencySymbol: string;
    address: string;
    countryName: string;
    state: string;
    city: string;
    pin: string;
    fax: string;
    companyId: string;
    taxId: string;
    web: string;
    cstNo: string;
    drugNo: string;
    addDrugNo: string;
    billPrintMsg: string;
    addBillPrintMsg: string;
    narration: string;
    addNarration: string;
    financialYearStart: string;
    financialYearEnd: string;
    stateCode: number;
    gstNo: string;
    syncTS: number;
    companyCode: string;
    taxType: string;
    verticalName: string;
    verticalId: number;
    cinNo: string;
}

export class SyncInfo {
    userId: any;
    vendor: string;
    taskId: any;
    lastSyncDate: string;
    previousSyncDate: string;
    syncCount: string;
    status: string;
    task: string;
    remarks: string;
    timeTaken: any;
}

export class VerticalDetail {
    addBillPrintMsg: string;
    addDrugNo: string;
    addNarration: string;
    address: string;
    billPrintMsg: string;
    cinNo: string;
    city: string;
    companyCode: string;
    companyId: string;
    countryName: string;
    cstNo: string;
    currencySymbol: string;
    drugNo: string;
    email: string;
    fax: string;
    financialYearEnd: string;
    financialYearStart: string;
    gstNo: string;
    id: any;
    mobile: string;
    name: string;
    narration: string;
    pin: string;
    state: string;
    stateCode: any;
    syncTS: any;
    taxId: string;
    taxType: string;
    verticalId: any;
    verticalName: string;
    web: string;
}

export interface Product {
    name: string;
    value: string;
}

export interface CategoryTypes {
    name: string;
    value: string;
}

export class UrbanpiperConfig {
    userId: number;
    posUrl: string;
    skewCode: number;
    domain: string;
    apiUser: string;
    categoryType: string;
    otp: number;
    vendor: string;
    baseProduct: string;
    apilimit: number;
    reqTimeout: number;
    selectall = false;
    modiferSync = false;
    categorySync = false;
    outletId: number;
    userName: string;
    upkey: string;
    custId: string;
    shopName: string;
    orderNo: string;
}

export class OrdereasyConfig {
    userId: number;
    posUrl: string;
    skewCode: number;
    domain: string;
    apiUser: string;
    categoryType: string;
    otp: number;
    vendor: string;
    baseProduct: string;
    apilimit: number;
    reqTimeout: number;
    outletId: number;
    outletName: string;
    userName: string;
    custId: string;
    status: ['', null];
    shopName: string;
    organization: any;
    latitude: any;
    longitude: any;
    address: any;
    vertical: string;
    currency: string;
    orderNo: string;
    availability: string;
    languageType: string;
    socialMedia = '';
    socialMediaLink = '';
    posLocationId: any;
    locationName: any;
}

export class EasyRwdzCred {
    serviceUserId: string;
    outletId: string;
    orderNo: string;
    outletName: string;
    appId: string;
    devId: string;
    userName: string;
    password: string;
    programCode: string;
    redemptionCode: string;
    apiUser: string;
    channelCode: string;
    accrualCode: string;
    storeCode: string;
    securityToken: string;
    group: string;
    department: string;
    category: string;
    countryCode: string;
}
export class JTConfig {
    userId: number;
    posUrl: string;
    skewCode: string;
    domain: string;
    otp: string;
    custId: string;
    vendor: string;
    baseProduct: string;
    outletId: number;
    autoApproval: string;
    itemSync: string;
    salesSync: string;
    salesOrderSync: string;
    rnSync: string;
    poSync: string;
    shopName: string;
    shopLocation: string;
    orderNo: string;
    supplierMaster: SupplierMaster;
}

export class Organization {
    id: number;
    name: string;
    mobile: string;
    email: string;
    currencySymbol: string;
    address: string;
    countryName: string;
    state: string;
    city: string;
    pin: string;
    fax: string;
    companyId: string;
    taxId: string;
    web: string;
    cstNo: string;
    drugNo: string;
    addDrugNo: string;
    billPrintMsg: string;
    addBillPrintMsg: string;
    narration: string;
    addNarration: string;
    financialYearStart: string;
    financialYearEnd: string;
    stateCode: number;
    gstNo: string;
    syncTS: number;
    companyCode: string;
    taxType: string;
    verticalName: string;
    verticalId?: number;
    cinNo: string;
    status: string;
}

export class SupplierMaster {
    id: number;
    name: string;
    address1: string;
    address2: string;
    address3: string;
    pincode: number;
    telephone: string;
    mobileNumber: string;
    emailId: string;
    gstin?: any;
    contactPerson: string;
}

export class McarrotConfig {
    serviceUserId: number;
    custId: number;
    outletId: number;
    orderNo: string;
    userName: string;
    password: string;
    businessName: string;
    storeName: string;
}

export class EwardsConfig {
    serviceUserId: number;
    customerId: number;
    outletId: number;
    orderNo: string;
    merchantId: string;
    merchantEmail: string;
    customerKey: string;
    provisionBy = 'GOFRUGAL';
    shopname: string;
}

export class MPesaConfig {
    userId: number;
    skewCode: string;
    outletId: number;
    shortCode: string;
    passKey: string;
    outletName: string;
    customerId: number;
    orderNo: string;
    consumerKey: string;
    secretKey: string;
    transactionType = 'CustomerBuyGoodsOnline';
    businessCode: string;
    tillId: string;
    posUrl: string;
    shopName: string;
    phone: string;
    city: string;
    Address: string;
    mailId: string;
    pos: string;
    baseProduct: string;
    status: string;
}

export class PaytmConfig {
    userId: number;
    skewCode: string;
    outletId: number;
    merchantId: string;
    merchantGuid: string;
    aesKey: string;
    customerId: number;
    orderNo: string;
    tillId: string;
    posUrl: string;
    shopName: string;
    phone: string;
    city: string;
    Address: string;
    mailId: string;
    pos: string;
    baseProduct: string;
    status: string;
}

export class EzetapConfig {
    userId: number;
    skewCode: string;
    outletId: number;
    customerId: number;
    orderNo: string;
    posUrl: string;
    shopName: string;
    pos: string;
    baseProduct: string;
    appKey: string;
    userName: string;
    orgCode: string;
    deviceId: string;
    tillId: string;
    phone: string;
    city: string;
    address: string;
    mailId: string;
    status: string;
}

export class PayUConfig {
    userId: number;
    skewCode: string;
    outletId: number;
    customerId: number;
    orderNo: string;
    posUrl: string;
    shopName: string;
    pos: string;
    baseProduct: string;
    userName: string;
    tillId: string;
    phone: string;
    city: string;
    address: string;
    mailId: string;
    status: string;
    appKey: string;
    appSalt: string;
}

export class PaytmedcConfig {
    userId: number;
    skewCode: string;
    outletId: number;
    customerId: number;
    orderNo: string;
    posUrl: string;
    shopName: string;
    pos: string;
    baseProduct: string;
    paytmMid: string;
    paytmTid: string;
    paytmMerchantKey: string;
    tillId: string;
    phone: string;
    city: string;
    address: string;
    mailId: string;
    status: string;
}

export class RazorpayConfig {
    custId: number;
    userId: number;
    outletId: number;
    vendor: string;
    gftTeam: string;
    shopName: string;
    keyId: string;
    keySecret: string;
    paymentPageDescription: string;
    paymentPageColor: string;
    paymentPageImage: string;
    currency: string;
    posUrl: string;
    pos: string;
    orderNo: string;
}

export class WooCommerceConfig {
    userId: number;
    posUrl: string;
    skewCode: number;
    domain: string;
    woocommerceDomain: string;
    apiUser: string;
    otp: number;
    vendor: string;
    baseProduct: string;
    apilimit: number;
    reqTimeout: number;
    selectall = false;
    modiferSync = false;
    categorySync = false;
    outletId: number;
    custId: string;
    shopName = '';
    shopLocation = '';
    shopStreet = '';
    accessKey: string;
    accessCode: string;
    orderNo: string;
    organization: any[];
    configured: boolean;
}

export class CharmHealthConfig {
    userId: number;
    posUrl: string;
    skewCode: number;
    domain: string;
    apiUser: string;
    otp: number;
    vendor: string;
    baseProduct: string;
    reqTimeout: number;
    selectall = false;
    categorySync = false;
    outletId: number;
    custId: string;
    shopName = '';
    shopLocation = '';
    shopStreet = '';
    charmOutletId: string;
    refreshToken: string;
    clientId: string;
    clientSecret: string;
    grantType: string;
    charmUrl: string;
    accessKey: string;
}


export class BajajFinservConfig {
    userId: number;
    posUrl: string;
    skewCode: number;
    domain: string;
    apiUser: string;
    otp: number;
    vendor: string;
    baseProduct: string;
    reqTimeout: number;
    selectall = false;
    categorySync = false;
    outletId: number;
    custId: string;
    shopName = '';
    shopLocation = '';
    shopStreet = '';
    partnerId: string;
}

export class ShopifyConfig {
    userId: number;
    posUrl: string;
    skewCode: number;
    domain: string;
    apiUser: string;
    otp: number;
    vendor: string;
    baseProduct: string;
    reqTimeout: number;
    selectall = false;
    categorySync = false;
    outletId: number;
    custId: string;
    shopName = '';
    shopLocation = '';
    shopStreet = '';
    shopifyDomain: string;
    apiKey: string;
    apiPassword: string;
    locationId: string;
    orderNo: string;
    shopifyOption1: string;
    shopifyOption2: string;
    shopifyOption3: string;
    configured: boolean;
}

export class AmazonConfig {
    userId: number;
    posUrl: string;
    skewCode: number;
    domain: string;
    apiUser: string;
    otp: number;
    vendor: string;
    baseProduct: string;
    reqTimeout: number;
    selectall = false;
    categorySync = false;
    outletId: number;
    custId: string;
    shopName = '';
    shopLocation = '';
    shopStreet = '';
    onlineLocationId: string;
    orderNo: string;
    organization: any;
    verticalId: string;
}

export class AmazonKeys {
    userId: number;
    vendor: string;
    kmsKeyARN: string;
    accessToken: string;
    lwaRefreshToken: string;
    authorizationCode: string;
    isConfigured: boolean
}

export class DataFlushDetails {
    id: number;
    name: string;
    notes: string;
    status: boolean
    isSelected?: boolean
    reason: string
    flushBy: string
    lastFlushDate: string
}

export interface OutletDetail {
    outletName?: string;
    vertical?: string;
    outletId: number;
}

export interface ItemMaster {
    master: string;
    totalCount: number;
    activeCount: number;
    inActiveCount: number;
    outletId: number;
    lastSyncStatus: string;
    maxTimeStamp: number;
}

export interface CategoryMaster {
    master: string;
    totalCount: number;
    activeCount: number;
    inActiveCount: number;
    outletId: number;
    lastSyncStatus: string;
    maxTimeStamp: number;
}

export interface TroubleShootingList {
    posUrl: string;
    posHealthCheck: string;
    outletDetails: OutletDetail[];
    itemMasters: ItemMaster[];
    itemSyncRemarks: string;
    itemLastSyncTime: string;
    categoryMasters: CategoryMaster[];
    categorySyncRemarks: string;
    categoryLastSyncTime: string;
    taxMasters: any[];
    schedulerStatus: string;
    lastSchedulerTime: string;
    nextSchedulerTime: string;
}

export interface AppBuildDetail {
    userId: number;
    custId: number;
    productCode: string;
    vendor: string;
    appId: number;
    appName: string;
    appIconUrl: string;
    theme: string;
    primaryColor: string;
    secondaryColor: string;
    isAndroid: boolean;
    isIos: boolean;
    isOnline: boolean;
    appShortDescription: string;
    appDetailedDescription: string;
    keyAliasName: string;
    keyPassword: string;
    keyStore: string;
    keyStoreFileUrl: string;
    isApproved: boolean;
    appPackageName: string;
    textColor: string;
}

export interface AppInfo {
    appName: string;
    packageName: string;
    playStoreLink: string;
    appStoreLink: string;
    androidBuild: Build;
    iosBuild: Build;
}

export interface Build {
    lastBuildTime: string;
    lastBuildStatus: string;
    lastBuildVersion: string;
}

export interface MenuPublishListData {
    id: number;
    outletName: string;
    outletId: any;
    itemValidationCount: MenuStatus;
    categoryValidationCount: MenuStatus;
    modifierGroupValidationCount: MenuStatus;
    modifierValidationCount: MenuStatus;
    publishStatus: string;
    remarks: string;
    lastPublishTime: object;
    isSelected: boolean
    userId: any;
  }
  
export interface MenuStatus {
    yetToSync: number;
    validData: number;
    invalidData: number;
}

export interface MenuPublishItem {
    itemId: number;
    skuCode: string;
    itemName: string;
    itemSalePrice: number;
    category: string;
    status: string;
    remarks: string;
    itemQuantity: any;
    posTimestamp: any;
}

export interface MenuPublishCategory {
    catValueId: number;
    categoryName: string;
    status: string;
    remarks: string;
    syncTs: any;
}

export interface MenuPublishTax {
    groupId: any;
    groupName: string;
    status: string;
    remarks: string;
    posTimestamp: any;
}

export interface MenuPublishModifier {
    optionId: any;
    optionName: string;
    status: string;
    remarks: string;
    posTimestamp: any;
}

export interface POSSyncList {
    id: number;
    outletId: number;
    outletName: string;
    masterData: MasterList[];
    synced?: any
  }
  
export interface MasterList {
    id: number;
    masterName: string;
    status: string;
    syncRemarks: string;
    lastSyncTime: object;
}

export interface AggregatorList {
    aggregatorName: string
    isSelected: boolean
}

export interface PublishStatusList {
    id: number;
    statusName: string
}
export interface PWAConfig {
    userId: number;
    vendor: string;
    outletId: number;
    filter: string;
    orFilter: string;
    sort: string;
    pageIndex: number;
    pageSize: number;
    domain: string;
    domainType: string;
    custId: string;
}
export class DeliveryAppConfig {
    userId: number;
    posUrl: string;
    skewCode: number;
    domain: string;
    apiUser: string;
    otp: number;
    vendor: string;
    baseProduct: string;
    apilimit: number;
    reqTimeout: number;
    outletId: number;
    outletName: string;
    userName: string;
    custId: string;
    shopName: string;
    organization: any;
    vertical: string;
    orderNo: string;
    availability: string;
    outletCustomerId : string;
    corporateCustomerId :string;
}
export class BillEasyCredentials {
    serviceUserId: number;
    customerId: string;
    outletId: number;
    rootOrderNumber: string;
    storeCode: string;
    clientToken: string;
}

export class UnicommerceConfiguration {
    userId: number;
    posUrl: string;
    vendor: string;
    baseProduct: string;
    outletId: number;
    clientId: string;
    userName: string;
    apiUser: string;
    apiPassword: string;
    status: boolean;
    message: string;
    domain: string;
    unicommerceDomain: string;
    configured: boolean;
    skewCode: number;
    custId: string;
    reqTimeout: number;
    shopName: string;
    shopStreet: string;
    orderNo: string;
    otp: number;
    onlineLocationId: string;
    otherStoresConfigured?: boolean 
}

export class ConfigStore {
    id: number;
    userId: number;
    custId: number;
    posUrl: string;
    pos: string;
    serviceVersion: string;
    vendor: string;
    baseProduct: string;
    modifier: boolean;
    consolstock: boolean;
    categorySync: boolean;
    selectall: boolean;
    apilimit: number;
    reqtimeout: number;
    outletId: number;
    createdAt: number;
    updatedAt: number;
    rnSync: boolean;
    poSync: boolean;
    salesSync: boolean;
    itemSync: boolean;
    itemUpdate: boolean;
    autoApproval: boolean;
    shopName: string;
    shopLocation: string;
    streetName: string;
    orderNo: string;
    latitude: string;
    longitude: string;
    address: string;
    categoryConfig: string;
    vertical?: any;
    verticalId: string;
    currency: string;
    contactPhone: string;
    contactEmail: string;
    languageType: string;
    availability: string;
    onlineLocationId: string;
    isCommonKey: string;
    socialMediaType: string;
    socialMediaLink: string;
}

export class OndcConfig {
    id: number;
    userId: number;
    custId: number;
    posUrl: string;
    pos: string;
    serviceVersion: string;
    vendor: string;
    baseProduct: string;
    modifier: boolean;
    consolstock: boolean;
    categorySync: boolean;
    selectall: boolean;
    apilimit: number;
    reqtimeout: number;
    outletId: number;
    createdAt: number;
    updatedAt: number;
    rnSync: boolean;
    poSync: boolean;
    salesSync: boolean;
    itemSync: boolean;
    itemUpdate: boolean;
    autoApproval: boolean;
    shopName: string;
    shopLocation: string;
    streetName: string;
    orderNo: string;
    latitude: any;
    longitude: any;
    address: string;
    categoryConfig: string;
    vertical?: any;
    verticalId: string;
    currency: string;
    contactPhone: string;
    contactEmail: string;
    languageType: string;
    availability: string;
    onlineLocationId: string;
    isCommonKey: string;
    socialMediaType: string;
    locationId: number;
    locationName?: any;
    socialMediaLink: string;
    otp: number;
    apiUser: string;
    skewCode: string;
    domain: string;
    apiKey: string;
    apiPassword: string;
    shopImageUrl: string;
    stdCode: string;
    fssaiLicenseNo: string;
    doorNo: string;
    buildingName: string;
    shopStreet: string;
    locality: string;
    city: string;
    district: string;
    state: string;
    country: string;
    pinCode: string;
}

export class BlueOcktopusCredentials {
    serviceUserId: number;
    customerId: string;
    outletId: number;
    rootOrderNumber: string;
    securityKey: string;
}

export class BlueOcktopusGetConfigurationDetails {
    serviceUserId: number;
    outletId: number;
    rootOrderNumber: string;
}

export class ReeloSaveConfigurationDetails {
    serviceUserId: number;
    outletId: number;
    customerId: number;
    rootOrderNumber: string;
    merchantId: string;
}

export class ReeloGetConfigurationDetails {
    serviceUserId: number;
    outletId: number;
    rootOrderNumber: string;
}

export class OndcShippingConfig {
    userId: number;
    subscriberId: string;
    subscriberUrl: string;
    privateKey: string;
    publicKey: string;
    encPrivateKey: string;
    encPublicKey: string;
    unique_key_id: string;
    isSelfShip: boolean;
    isKeyGenerated: boolean;
    radius: number;
}

export class OndcSellerAppPayDetails {
    user: any;
    settlementType: string;
    bankAccountNumber: string;
    ifscCode: string;
    vpa: string;
}
