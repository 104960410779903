import {Component, OnInit, Input, HostListener} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {LoginService} from '../../../modules/login/login.service';
import {SharedService} from '../../service/shared.service';
import {UiCommonService} from '../../service/uiCommon.service';
import {SidebarComponent} from '../sidebar/sidebar.component';
import {ToolbarHelpers} from './toolbar.helpers';

@Component({
    selector: 'cdk-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    providers: [SidebarComponent],
})
export class ToolbarComponent implements OnInit {

    @Input() sidenav;
    @Input() sidebar;
    @Input() drawer;
    @Input() matDrawerShow;
    @Input() isShownRippleEffect;
    @Input() matDrawerOpened;

    searchOpen = false;
    toolbarHelpers = ToolbarHelpers;
    profilePicUrl = './assets/profile.jpg';
    private subscription: Subscription;
    myProfileDetails: any;
    isStandAlone = false;
    dynamicRouterLink = 'ecommerce/manual-sync';
    locationTrackingMenu = (this.authService.getSkewCode() === '744');

    @HostListener('document:click')
    clickout() {
        this.console();
    }

    constructor(
        public authService: AuthenticationService,
        private uiCommonService: UiCommonService,
        private sharedService: SharedService,
        private sidebarComponent: SidebarComponent,
        private loginService: LoginService,
    ) {
        if (window.location.pathname !== '/HomePage') {
            this.uiCommonService.hideLoader();
        }
    }

    ngOnInit() {
        console.log(this.authService.getSkewCode(), (this.authService.getSkewCode() === '742' || this.authService.getSkewCode() === '917' || this.authService.getSkewCode() === '923' || this.authService.getSkewCode() === '1003' || this.authService.getSkewCode() === '926'));
        if (this.authService.getSkewCode() === '538' || this.authService.getSkewCode() === '921')  {
            this.dynamicRouterLink = 'delivery-app/data-sync';
        }
        this.isStandAlone = this.authService.isStandalone() || this.authService.isMarketPlace();
        if (this.authService.getSkewCode() !== '944') {
            this.onGetMyProfileDetails();
        }
        // this.drawer.close();
        this.sidenav.open();
        this.onClick(6);
    }

    async onGetMyProfileDetails() {
        const formObj = {
            'userId': '' + this.authService.getServiceUserId(),
            'vendor': this.authService.getSkewName(),
            'emailId': this.authService.getEmailId()
        };
        await this.getMyProfileDetails(formObj);
        await this.getMyProfileAccountsDetails(formObj);
    }

    getMyProfileDetails(body) {
        this.loginService.getMyProfileDetails(body).subscribe(
            (data) => {
                if (data) {
                    this.myProfileDetails = data;
                    if (this.myProfileDetails.profileImage) {
                        this.profilePicUrl = this.myProfileDetails.profileImage;
                    }
                }
            },
            (err) => {
                console.log('error:', err);
            });
    }

    getMyProfileAccountsDetails(body) {
        this.loginService.getMyProfileAccountsDetails(body).subscribe((data) => {
            console.log(data, this.myProfileDetails);
            if (data) {
                this.profilePicUrl = (this.myProfileDetails && this.myProfileDetails.profileImage) ? this.profilePicUrl : data.profileUrl;
                this.toolbarHelpers.currentUser.photoURL = this.profilePicUrl ? this.profilePicUrl : '';
            }
        });
    }

    onShowSideBar() {
        // this.sharedService.setViewProfile(true);
        this.sidebar.toggle();
    }

    onClickAnnouncement() {
        this.sharedService.setAnnouncementAvailable('announcement');
    }

    onClick(number) {
        // this.matDrawerOpened = !this.matDrawerOpened;
        this.sharedService.setMatDrawerOpenStatus(!this.matDrawerOpened);
    }

    console() {
    }
}
