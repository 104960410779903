import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MenuMaster} from '../../../core/models';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {MenuService} from '../../../core/services/menu.service';
import {LoginService} from '../../../modules/login/login.service';
import {SharedService} from '../../service/shared.service';
import {environment} from '../../../../environments/environment';
import {Subscription} from 'rxjs';
import {ordereasyValidSkewCodes} from '../../../core/services/menu-element';

@Component({
  selector: 'cdk-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
})
export class SidemenuComponent implements OnInit, OnChanges {
  @Input() iconOnly = false;
  @Input() sideNavOpened = true;
  @Input() matDrawerOpened = true;
  @Input() sidenav;
  public menus = [];
  menuListArray: Array<MenuMaster> = [];
  filteredMenuList: Array<MenuMaster> = [];
  showSyncMenu = false;
  disableSyncMenuSkews: any = ['773', '774', '757', '737', '519', '753', '721', '741', '532', '729', '923'];
  dynamicRouterLink: any = 'ecommerce/manual-sync';
  selectedParentMenuId: any;
  isCompanyAvailiable = false;
  isShown = [];
  isordereasy = false;
  locationTrackingMenu = false;

  private subscription: Subscription;
  readAllValue: any;
  isShownRippleEffect = false;
  showLT =  false;
  count = 0;
  hideSideMenu = false;

  constructor(
      private authService: AuthenticationService,
      private menuService: MenuService,
      private loginService: LoginService,
      private sharedService: SharedService
  ) {
    this.sharedService.getMenuReload().subscribe(
        (message) => {
          console.log('<====== message ====> ', message);
          if (message) {
            this.sharedService.setMenuReload(false);
            this.ngOnInit();
          }
        }
    );
  }

  async ngOnInit() {
    if (this.authService.getSkewCode() === '742' ||  this.authService.getSkewCode() === '538' || this.authService.getSkewCode() === '744') {
      this.isordereasy = true;
      this.getAnnouncementDetails();
      this.subscription = this.sharedService.getAllReadValue().subscribe(
        res => {
          this.isShownRippleEffect = res != false;
      });
    }
    if (this.authService.getSkewCode() === '744') {
      this.locationTrackingMenu = true;
    }
    this.sharedService.shareSelectedMenuId.subscribe(
      (message) => (this.selectedParentMenuId = message)
    );
    this.sharedService.shareMenuList.subscribe(
      (message) => (this.menus = message)
    );
    if (this.authService.getSkewCode() === '538') {
      this.dynamicRouterLink = 'delivery-app/data-sync';
    } else {
      if (this.menus) {
        for (const element of this.menus) {
          if (element.sub) {
            element.sub = element.sub.filter(
                (res) => res.id !== '503' && res.id !== '502'
            );
          }
        }
      }
    }
    this.getMenuDetails();
    this.hideSideMenu = (this.authService.isMarketPlace() || this.authService.getRoleId() === 20001);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.matDrawerOpened && this.menus.length) {
      this.menus.forEach(menu => {
        menu.open = false;
      });
    }
  }

  getMenuDetails() {
    const formObj = {
      userId: this.authService.getServiceUserId(),
      skewCode: this.authService.getSkewCode(),
      roleId: this.authService.getRoleId(),
    };
    console.log(formObj, this.authService.getSkewCode());
    this.loginService.getDynamicMenuFromHomePage(formObj).subscribe(
        (config) => {
          if (config) {
            console.log(config);
            config.forEach((res) => {
              res['link'] = res['link'] === 'false' ? false : res.link;
              if (this.selectedParentMenuId) {
                if (res.id == this.selectedParentMenuId) {
                  res['open'] = true;
                }
              }
            });
            this.menuListArray = config;
            const formMenu = [];
            this.filteredMenuList = config
                .filter((element) => {
                  this.isCompanyAvailiable =
                      localStorage.getItem('companyAvailiable') === 'true';
                  const removeParentId = environment.production ? '49' : '42';
                  if (this.isCompanyAvailiable && element.id === removeParentId) {
                    element.sub = element.sub.filter((sub) => {
                      return sub.id !== removeParentId + '03';
                    });
                  }
                  this.showLT = this.authService.getAddonDetails('744');
                  const rmlist = environment.production ? ['6', '42'] : ['6', '34'];
                  const removeList = this.isCompanyAvailiable ? rmlist : ['6'];
                  if (!this.showLT) { removeList.push('538744'); }
                  return !element.hidden && !removeList.includes(element.id);
                })
                .map((res) => {
                  if (res.sub.length === 0) {
                    delete res.sub;
                    return Object.assign({}, res);
                  } else {
                    return Object.assign({}, res, {
                      sub: res.sub.filter((ele) => !ele.hidden),
                    });
                  }
                });
            this.menuListArray.forEach((res) => {
              if (res.id === '6') {
                this.showSyncMenu = !this.disableSyncMenuSkews.includes(
                    this.authService.getSkewCode()
                );
                this.menuListArray = this.menuListArray.filter(
                    (element) => element.id != '6'
                );
              }
              if (res.mappingStatus) {
                formMenu.push(res.id);
              }
              if (res.sub && res.sub.length > 0) {
                res.sub.forEach((res1) => {
                  if (res1.mappingStatus) {
                    formMenu.push(res1.id);
                  }
                });
              }
            });
            console.log(ordereasyValidSkewCodes.includes(this.authService.getSkewCode()), this.authService.getSkewCode());
            if (ordereasyValidSkewCodes.includes(this.authService.getSkewCode())) {
              console.log('setting menu');
              this.menuService.setDynamicMenuFromJSON(formMenu);
              this.menus = this.filteredMenuList;
            } else if (this.authService.getSkewCode() === '538') {
              console.log('setting menu');
              this.menuService.setDynamicMenuFromJSON(formMenu);
              this.menus = this.filteredMenuList;
              this.menuListArray.forEach((res) => {
                if (res.id === '41') {
                  this.showSyncMenu = true;
                }
              });
            } else {
              this.menus = this.menuService.checkMenu(
                  this.authService.getSkewCode() +
                  ':' +
                  this.authService.getOutlet()
              );
              this.showSyncMenu = !this.disableSyncMenuSkews.includes(
                  this.authService.getSkewCode()
              );
            }
            console.log(this.authService.getAddonDetails(this.authService.getSkewCode()), this.menus);
            if (this.authService.getAddonDetails(this.authService.getSkewCode()) || this.authService.getRoleId() === 20001) {
              console.log(this.authService.getAddonDetails(this.authService.getSkewCode()));
              this.sharedService.setMenuList(this.menus);
            } else {
              console.log(this.authService.getEmailId());
              this.sharedService.setMenuList([]);
            }
          }
        },
        (err) => {}
    );
  }


  getAnnouncementDetails() {
    const data = {
      userId: this.authService.getServiceUserId(),
      vendor: this.authService.getSkewName(),
      loginId: this.authService.getEmailId(),
    };
    if (this.count === 0) {
      this.count++;
    } else {
      setTimeout(() => {
        this.count = 0;
      }, 500);
      console.log(this.count);
      return;
    }
  }

  onClickAnnouncement() {
    this.sharedService.setAnnouncementAvailable('announcement');
  }

  toggleSideNav() {
    this.sharedService.setSideNavOpenStatus(this.sidenav.opened);
    this.sidenav.toggle();
  }

  onClickChatBot() {
    this.sharedService.setAssureCare(true);
  }

  onClickFeedback() {
    this.sharedService.setFeedbackAvailable('FeedBack');
  }
}
