import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {FormBuilder} from '@angular/forms';
import {OrdereasyConfigService} from '../ordereasy-config/ordereasy-config.service';
import {NotificationService} from '../../../../../../core/services/notification.service';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../../../../core/services/authentication.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-standalone-outlet-config',
    templateUrl: './standalone-outlet-config.component.html',
    styleUrls: ['./standalone-outlet-config.component.scss']
})
export class StandaloneOutletConfigComponent implements OnInit {

    outletList: any;
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    totalRecords: any;
    pageIndex = 1;
    pageSize = 5;

    constructor(
        private _formBuilder: FormBuilder,
        private ordereasyService: OrdereasyConfigService,
        private notifyService: NotificationService,
        private router: Router,
        private authenticationService: AuthenticationService,
    ) {
    }

    ngOnInit() {

        this.getConfig();
    }

    getConfig() {
        this.ordereasyService.getOutlets(0, 0).subscribe(
            response => {
                this.outletList = response;
                this.totalRecords = response.length;
            },
            (error) => {
                this.notifyService.showWarn('Error', 'Something Went Wrong');
            },
            () => {
            });
    }

    pageEvents(event: any) {
        this.pageIndex = event.pageIndex + 1;
        this.pageSize = event.pageSize;
        // this.getAnalyticData();
    }

    alert(outletId: string) {
        this.router.navigate(['/Integration/ecommerce/config/standalone'], {queryParams: {outletId: outletId}});
    }

    onDeleteOutlet(element) {
        (Swal as any).fire({
            text: 'Are you sure, you want to delete outlet? This action cannot be reverted back.!',
            type: 'question',
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonText: 'Yes'
        }).then(
            (result) => {
                if (result.value) {
                    const body = {
                        'userId': '' + this.authenticationService.getServiceUserId(),
                        'outletId': element.outletId,
                        'vendor': this.authenticationService.getSkewName()
                    };
                    this.ordereasyService.deleteConfig(body).subscribe(
                        response => {
                            if (response && response.status) {
                                this.notifyService.showSuccess('Success', response['message']);
                                this.getConfig();
                            } else {
                                this.notifyService.showError('Error', response['message']);
                            }
                        },
                        (err) => {
                            this.notifyService.showWarn('Error', 'Something Went Wrong');
                        });
                }
            });
    }
}
