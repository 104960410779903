
export class StoreListing {
    userId: number;
    vendor: string;
    skewcode: number;
    name: string;
    merchantRefId: string;
    enableOrderingStatus: boolean;
    status: boolean;
    outletId: string;
}

export class AmazonOrderUpdate {
    userId: number;
    vendor: string;
    onlineReferenceNo: number;
    onlineVendorSoNo: string;
    lengthValue: number;
    lengthDimension: string;
    widthValue: number;
    widthDimension: string;
    heightValue: number;
    heightDimension: string;
    weightValue: number;
    weightDimension: string;
}

export class AmazonPrintDetails {
    invoiceFileUrl: string;
    shiplabelFileUrl: string;
}

export class CompanyListing {
    name: string;
    companyCode: string;
}

export class ItemConfig {
    outletName: string;
    outletId: string;
    catValueName: string;
    currency: string;
}

export class CategoryDetails {
    userId: number;
    vendor: string;
    categoryName: string;
    displayName: string;
    status: string;
    outletId: number;
    catValueId: number;
    catValueName: string;
    parentId: number;
    appliesOnline: number;
    createdAt: any;
    updatedAt: any;
    description: string;
    sortOrder: number;
    syncTs: number;
    onlinePushStatus: number;
    syncId: string;
    aggregatorStatus: boolean;
    lastToggledTime: any;
}

export class CategoryHeader {
    data: CategoryDetails[];
    count: number;
    totalRecords: number;
    currentPage: number;
    perPage: number;
    totalPages: number;
}

export class ItemToggle {
    userId: number;
    vendor: string;
    skuCode: string;
    outletId: number;
    itemName: string;
    quantity: number;
    salePrice: number;
    swiggy: boolean;
    zomato: boolean;
    foodPanda: boolean;
    uberEats: boolean;
    status: string;
    appliesOnline: number;
    images: urbanPiperImage[];
    variants: urbanPiperVariantAddOn[]
    addons: urbanPiperVariantAddOn[]
}

export class urbanPiperImage {
    id: string;
    aggregator: string[];
    imageData: string;
}

export class urbanPiperVariantAddOn {
    id: string;
    name: string;
    isSelected: boolean;
    prevIsSelected?: boolean
}

export class urbanPiperImageWithSku {
    skuCode: string;
    images: urbanPiperImage[];
}

export class urbanPiperImageForDelete {
    skuCode: string;
    images: string[];
}
export class AmazonItemPost {
    itemName: string;
    skuCode: string;
    onlineProductId: string;
}


export class ShopifyItemPost {
    itemName: string;
    skuCode: string;
    aliasCode : string;
    onlineProductId: string;
    onlineVarientId: string;
}

export class ModifiedAggregatorStatus {
    userId: number;
    vendor: string;
    skuCode: string;
    outletId: number;
    aggregatorStatus: boolean;
    constructor(userId: number, vendor: string, skuCode: string, outletId: number, aggregatorStatus: boolean) {
        this.userId = userId;
        this.vendor = vendor;
        this.skuCode = skuCode;
        this.outletId = outletId;
        this.aggregatorStatus = aggregatorStatus;
    }
}

export class StoreEdit {
    userId: number;
    vendor: string;
    name: string;
    address: string;
    city: string;
    contactPhone: number;
    notificationPhones: string;
    notificationMail: string;
    refId: string;
    orderingEnabled: boolean;
    status: boolean;
    zipCodes: string;
    latitude: string;
    longitude: string;
    minDeliveryTime: number;
    minOrderValue: number;
    minPickupTime: number;
    outletId: number;
    posId: number;
    swiggy: boolean;
    zomato: boolean;
    foodPanda: boolean;
    uberEats: boolean;
    urbanpiper: boolean;
    swiggyId: string;
    swiggyPlatformStoreId: any;
    zomatoId: string;
    zomatoPlatformStoreId: any
    foodPandaId: string;
    uberEatsId: string;
    urbanpiperId: string;
    urbanpiperPlatformStoreId: any;
    dunzo: string;
    dunzoId: string;
    dunzoPlatformStoreId: any;
    swiggystore: string;
    swiggystoreId: string;
    swiggystorePlatformStoreId: any;
    zomatomarket: string;
    zomatomarketId: string;
    zomatomarketPlatformStoreId: any;
    dotpe: string;
    dotpeId: string;
    dotpePlatformStoreId: any;
    amazonfood: string;
    amazonfoodId: string;
    amazonfoodPlatformStoreId: any;
    jahez: string;
    jahezId: string;
    jahezPlatformStoreId: any;
    talabat: string;
    talabatId: string;
    talabatPlatformStoreId: any;
    deliveroo: string;
    deliverooId: string;
    deliverooPlatformStoreId: any
    magicpin: string;
    magicpinId: string;
    magicpinPlatformStoreId: any;
}

export class StoreConfig {
    userId: number;
    vendor: string;
    name: string;
    refId: number;
    swiggy: boolean;
    zomato: boolean;
    uberEats: boolean;
    urbanpiper: boolean;
    foodpanda: boolean;
    dunzo: string;
    swiggystore: string;
    zomatomarket: string;
    dotpe: string;
    amazonfood: string;
    jahez: string;
    talabat: string;
    deliveroo: string;
}

export class ActivePlatform {
    name: string;
    enabled: boolean;
}

export class OrderStatusMaster {
    statusId?: number;
    verticalId?: number;
    status: string;
    statusSortOrder?: number;
    statusValue?: string;
    createdAt?: string;
    updatedAt?: string;
}

export class DeliveryTimeSchedule {
    id: number;
    minTime: number;
    maxTime: number;
    selectedTime?: any
    timeRange?: any
}

export class DeliveryScheduleList {
    id: number;
    day: string;
    isAllowed: boolean;
    times?: Array<DeliveryTimeSchedule>
}

export class BannerNewsHeader {
    totalRecords: number;
    perPage: number;
    count: number;
    totalPages: number;
    currentPage: number;
    bannerImages: BannerImage[];
}

export class BannerImage {
    imageId: number;
    userId: number;
    vendor: string;
    imagUrl: string;
    bucketName: string;
    objectName: string;
    updatedAt: string;
    outletId: number;
    status: string;
    bannerType: string;
}

export class NewsMaster {
    id: number;
    news: string;
    status: string;
    createdAt: string;
    updatedAt: string;
    userId: number;
    vendor: string;
    outletId: number;
}

export class NewsHeader {
    totalRecords: number;
    perPage: number;
    count: number;
    totalPages: number;
    currentPage: number;
    newsMaster: NewsMaster[];
}

export class LanguageDetails {
    id: number;
    longName: string;
    shortName: string;
    isSelected: boolean;
    status: any
    addNewLanguage: boolean
}

export class GetAboutContent {
    id: number
    userId: number
    vendor: string
    content: string
}

export class RecommendedDetails {
    id: number;
    userId?: number
    vendor?: string
    recommendedName: string;
    createdAt?: string;
    updatedAt?: string;
    status: any
    addNewRecommended?: boolean
    description?: string
    sortOrder?: number
}

export class PaymentDetails {
    id: number;
    imageUrl: string;
    paymentName: string;
    displayName: string;
    paymentType: string;
    redirectUrl?: string;
    clientId?: string;
    clientSecret?: string;
    status: string;
    isSelected: boolean;
}

export class OnlinePaymentDetails {
    id: number;
    userId: number;
    vendor: string;
    outletId: number;
    paymentMasterId: number;
    keyId: any;
    keySecret: any;
    oAuthCode: string
}

export class OrderStatusDetails {
    id: number;
    vendor: string;
    orderType: string;
    vertical: string;
    imageUrl: string;
    statusNameInPortal: string;
    statusNameInApp: string;
    statusNameInAction?: string;
    statusDesc: string;
    isCancellableByMerchant: boolean;
    isCancellableByCustomer: boolean;
    updatedAt: string;
    isSelected: boolean;
    totalCount: number
}

export class AddOnList {
    id: number;
    addOnName: string;
    addOnImage: string;
    hasVertical: boolean;
    hasSalesScreen: boolean
}

export class DeliveryChargeDetails {
    id: number;
    deliveryConfigName: string;
    deliveryConfigList: DeliveryChargeList[]
}

export class DeliveryChargeList {
    id: number;
    deliveryDesc: string;
    minOrderValue: number;
    maxOrderValue: number;
    deliveryCharge: number;
    taxPercentage: number;
    taxType: string;
    status: any;
    taxTypeVal?: boolean
}

export class AlertConfig {
    emailId: string;
    orderNo: string;
    passcode: string;
    smsCount: string;
    validity: string;
    userId: number;

    constructor() {
    }
}

// Modal for delviery master
export class DeliveryMaster {
    userId: number;
    vendor: string;
    outletId: number;
    filter: string;
    orFilter: string;
    sort: string;
    pageIndex: number;
    pageSize: number;
    id: number;
    day?: any;
    from: string;
    to: string;
    maxDeliveryOrders: number;
    status: boolean;
    createdAt: string;
    updatedAt: string;
    name: string;
    orderCount: number;
    isSelected?: boolean;
    fromTimeMinutes: number;
}

// Modal for delivery days
export class DeliveryDays {
    maxDaysToDeliver: number;
    timeZone: string;
    deliveryDays: DeliveryDay[];
}

export interface DeliveryDay {
    date?: any;
    day: string;
    id: number;
    status: boolean;
    createdAt?: any;
    updatedAt?: any;
    deliverySlots: DeliverySlot[];
}

export interface DeliverySlot {
    userId: number;
    vendor: string;
    outletId: number;
    filter: string;
    orFilter: string;
    sort: string;
    pageIndex: number;
    pageSize: number;
    id: number;
    day: string;
    from: string;
    to: string;
    maxDeliveryOrders: number;
    status: boolean;
    createdAt: string;
    updatedAt: string;
    name?: any;
    orderCount: number;
    isSelected?: boolean;
    mappingStatus: boolean;
}

export class ReleaseNotesDetails {
    releaseNotes: string;
    releaseDate: string;
    releaseVersion: string;
    rpos6: string;
    rpos7: string;
    de: string;
    hq: string;
    truepos: string;
    servquick: string;
    goact: string;
    releaseNotesUrl: string;
    createdAt: string;
    updatedAt: string;
    vendor: string;
    showReleaseNotes: boolean;
    status: boolean;
    baseProduct: string;
    baseProductMinVersion: string;
}

export interface Content {
    id: number;
    content: string;
    isDefault: number;
    status: boolean;
    event: string;
    title: string;
    type: string;
    description: string;
    createdAt: string;
    updatedAt: string;
    dynamicFieldMaster: any[];
    notification: string;
    isSelected?: any;
    defaultContent: string;
}

export interface ContentMaster {
    contents: Content[];
}
export class AppRequest {
    userId: string;
    vendor: string;
    requestedBy: string;
    purpose: string;
    platform: string;
    phoneNumber: string;
}

export class UpdateAppRequest {
    userId: string;
    vendor: string;
    id: number;
    apkUrl: string;
    status: string;
    gofrugalComments: string;
}

export class AppRequestList {
    userId: string;
    vendor: string;
    requestedBy: string;
    purpose: string;
    platform: string;
    status: string;
    requestId: number;
    customerId: string;
    gofrugalComment: string;
    appRequestType: string;
    apkUrl: string;
    createdAt: string;
}

export class TimeSlotDetails {
    userId: number;
    vendor: string;
    onlineRefNo: number;
    onlineVendorSoNo: string;
    packageId: number;
    selectedTimeSlot?: any;
    selectedTimeSlotLabel?: any;
    timeslots: TimeSlotList[];
}

export class TimeSlotList {
    timeSlotId: string;
    timeSlot: string;
    startDateTime: string;
    endDateTime: string;
    formTimeSlot?: any
    selectedTimeSlot: any
}

export class AppIntro {
    id?: number;
    image?: any;
    imageUrl: string;
    title: string;
    description: string;
    sortId: any
}

export class AppIntroDetails {
    AppIntro: AppIntro[];
    totalRecords: number;
    perPage: number;
    count: number;
    totalPages: number;
    currentPage: number;
}

export class PosOfferDetails {
    count: number;
    currentPage: number;
    offerList: OfferList[];
    perPage: number;
    totalPages: number;
    totalRecords: number;
}

export class OfferList {
    applyOnline: boolean;
    bannerImage: string;
    bannerImageHeight: string;
    bannerImageWidth: string;
    companyId: string;
    conditions: any;
    familyId: number;
    id: number;
    itemOffers: any;
    offerConditions: any;
    offerFamily: string;
    offerId: number;
    offerName: string;
    period: any;
    rowNum: number;
    sortOrder: number;
}

export class PromoCodeDetails {
    count: number;
    currentPage: number;
    offerList: OfferList[];
    perPage: number;
    totalPages: number;
    totalRecords: number;
    outletId: number;
    promoCode: PromoCodeList[];
    userId: number;
    vendor: string;
}

export class PromoCodeList {
    bannerImageHeight: string;
    bannerImageWidth: string;
    categoryPromoCodeList: any;
    categoryResponses: any;
    couponCategory: string;
    couponCode: string;
    couponName: string;
    couponType: string;
    description: string;
    discountLimit: string;
    discountValue: string;
    endDate: string;
    id: number;
    itemMasters: any;
    itemPromoCodeList: any;
    noOfCustomer: number;
    noOfTime: number;
    outletId: number;
    rowNum: number;
    sortOrder: number;
    startDate: string;
    status: boolean;
    userId: number;
}
export class ChatBotConfig {
    botType: string;
    isActive: boolean
}

export class AndroidApiKeys {
    platform: string;
    appKey: string;
    accessKey: string;
    bundleId: string;
}

export class IOSKeys {
    platform: string;
    appKey: string;
    accessKey: string;
    bundleId: string;
}

export class PwaKeys {
    platform: string;
    script: string;
}

export class ChatBotSalesIQ {
    userId: number;
    vendor: string;
    outletId: number;
    androidApiKey: AndroidApiKeys;
    iosApiKey: IOSKeys;
    pwaApiKey: PwaKeys;
    isAndroid: boolean;
    isIos: boolean;
    isPwa: boolean;
}

export class ChatRemarks {
    id: number;
    remarksDesc: string;
    updatedAt: any
}

export class ChatDefaultDetails {
    id: number;
    name: string;
    date: string;
    status: string;
    type: string;
    description: string;
    mobileNo: any;
    remarks: ChatRemarks[];
    storeName: string;
    audio: any;
    attachmentUrls: any[];
    audioFile: any;
}

export class ChatDefaultList {
    totalRecords: number;
    perPage: number;
    count: number;
    totalPages: number;
    currentPage: number;
    data: ChatDefaultDetails[];
}
export interface RecommendedPackage {
    rowNo: number;
    weight: number;
    weightDimension: string;
    length: number;
    lengthDimension: string;
    height: number;
    widthDimension: string;
    width: number;
    heightDimension: string;
    listDimension?: string
}

export interface RecommendedPackageList {
    onlineReferenceNo: number;
    onlineVendorSoNo: string;
    itemNames: string[];
    totalQuantity: number;
    selectedRecPackage?: any
    selectedRecValue?: any
    recommendedPackages: RecommendedPackage[];
}

export class DynamicMenu {
    count: number;
    currentPage: number;
    menu: Menu[];
    perPage: number;
    totalPages: number;
    totalRecords: number;
}

export class Menu {
    createdAt: string;
    masterMenuId: 0
    menuIcon: string;
    menuId: number;
    menuName: string;
    menuType: string;
    pageHeading: string;
    pageType: string;
    sortId: number;
    status: boolean;
    updatedAt: string;
    value: string;
}
export class MappedFoodTag {
    userId: number;
    vendor: string;
    skuCode: string;
    outletId: number;
    foodTags: FoodTag[];
}

export class FoodTag {
    name: string;
    tags: Tag[];
}

export class Tag {
    tagName: string;
    isActive: boolean;
}

export class AddItem {
    itemName: string;
    itemQuantity: number;
    itemMrp: string;
    itemSalePrice: any;
    itemTaxPercentage: any;
    taxInclusiveFlag: any;
    description: any;
    status: any;
    categoryType1: any;
    categoryType2: any;
    categoryType3: any;
    productType: any;
    itemEanCode: any;
    taxId: any;
    aliasCode: any;
    ibaru: any;
    imageUrl: any;
    skuCode: any;
    image: any;
}

export class NewCategory {
    categoryName: string;
    catValueName: string;
    status: string;
    parentId: number;
    lovSequence: number;
    catValueId: number;
    image: string;
}

export class ReturnOrder {
    userId: number;
    outletId: number;
    orderId: string;
    returnId: number;
    onlineReferenceNo: number;
    orderPk: number;
    totalQuantity: number;
    totalAmount: number;
    posPushStatus: boolean;
    onlinePushStatus: boolean;
    posTimestamp?: any;
    retryCount: number;
    isFailed: boolean;
    approveStatus: string;
    createdAt: string;
    updatedAt: string;
    reverseQcInstructions: string;
    customerName: any;
    mobileNo: any;
}

export class ReturnOrderItem {
    userId: number;
    orderId: string;
    returnId: number;
    rowNo: number;
    itemId: string;
    itemReferenceCode: string;
    itemName: string;
    itemQuantity: number;
    itemAmount: number;
    ttlApproval: string;
    ttlReverseQc: string;
    defectImages: string;
    approveStatus: string;
    reverseQcStatus: string;
    currentStatus: string;
    currentStatusName: string;
    defectImagesSlide: string[];
    isLogisticsReturn: boolean;
    isOpen: boolean;
}

export class UpdateReturn {
    userId: number;
    vendor: string;
    outletId: number;
    onlineReferenceNo: number;
    orderId: string;
    approveStatus: string;
    returnId: number;
    reverseQcInstructions: string;
    returnItemUpdateList: any;
  }

export class urbanpiperImageAllOutlet{
    imageData: String ;
    aggregator: String;
    outletIds: Number[];
}
