import {Injectable} from '@angular/core';
import {User} from '../models';
import {NotificationService} from './notification.service';
import {Router} from '@angular/router';
import {default_page} from './menu-element';
import * as CryptoJS from 'crypto-js';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    private currentUser: User;
    private orderEasyLicenseDetails: any;
    private addonList = [];
    private multiAddonList=[] ;
    private addons = [];
    private sideNavOpened: any;
    private outletDetails: any;
    private apiIntegrationAvailable = false;

    constructor(
        private notify: NotificationService,
        private router: Router,
        private cookieService: CookieService
    ) {
    }

    private loadUser(): void {
        if (this.cookieService.get('currentUser')) {
            this.currentUser = Object.assign(new User(), JSON.parse(this.decryptUsingAES256(this.cookieService.get('currentUser'))));
        } else {
            this.router.navigate(['/logout']);
        }
    }

    encryptUsingAES256(data: string) {
        return CryptoJS.AES.encrypt(data, this.cookieService.get('X-Auth-Token')).toString();
    }

    decryptUsingAES256(data: string) {
        return CryptoJS.AES.decrypt(data, this.cookieService.get('X-Auth-Token')).toString(CryptoJS.enc.Utf8);
    }

    encryptUsingMD5(data: string) {
        return CryptoJS.MD5(data).toString();
    }

    public isLoggedIn(): boolean {
        return !!this.cookieService.get('currentUser');
    }

    public loginUser(user: User): void {
        this.currentUser = user;
        this.cookieService.set('currentUser', this.encryptUsingAES256(JSON.stringify(user)), 7, '/', null, true, 'Strict');
    }

    public getLoginUser() {
        this.loadUser();
        return this.currentUser;
    }

    public getAuthToken(): string {
        this.loadUser();
        return this.currentUser.authKey;
    }

    public getServiceUserId(): number {
        this.loadUser();
        return this.currentUser ? this.currentUser.serviceUserId : null;
        // return 4242;
    }

    public getEmailId(): string {
        this.loadUser();
        const emailId: string = this.currentUser.email;
        return emailId.trim();
    }

    public getRoleId(): number {
        this.loadUser();
        return this.currentUser.roleId;
    }

    //Get CustomerID
    public getCustomerId(): string {
        this.loadUser();
        return this.currentUser.custId;
    }

    //Set CustomerID
    public setCustomerId(customerID: string): void {
        this.loadUser();
        this.currentUser.custId = customerID;
        this.loginUser(this.currentUser);
    }

    // Get VerticalID
    public getVerticalID(): string {
        this.loadUser();
        return this.currentUser.verticalId;
    }

    // Get Base Product
    public getBaseProduct(): string {
        this.loadUser();
        return this.currentUser.baseProduct ? this.currentUser.baseProduct : '';
    }

    public getUserName(): string {
        this.loadUser();
        return this.currentUser.userName;
    }

    public getOutlet(): string {
        this.loadUser();
        return this.currentUser.outletId;
    }

    public setSkewCode(productCode: string): void {
        this.loadUser();
        this.currentUser.skewCode = productCode;
        this.loginUser(this.currentUser);
    }

    public getSkewCode(): string {
        this.loadUser();
        return this.currentUser.skewCode;
    }

    public getDomainUrl(): string {
        this.loadUser();
        return this.currentUser.domainUrl;
    }

    public getDomainPassword(): string {
        this.loadUser();
        return this.currentUser.domainPassword;
    }

    public getIsConfigured(): number {
        this.loadUser();
        return this.currentUser.isConfigured;
    }

    public setIsConfigured(isConfigured: number): void {
        this.loadUser();
        this.currentUser.isConfigured = isConfigured;
        this.loginUser(this.currentUser);
    }

    public setSkewName(productName: string): void {
        this.loadUser();
        this.currentUser.skewName = productName;
        this.loginUser(this.currentUser);
    }

    public getSkewName(): string {
        this.loadUser();
        // if (!this.currentUser.skewName) {
        //   this.setSkewName(this.getDefSkewName(this.getSkewCode()));
        // }
        return this.currentUser.skewName;
    }

    public getAuthEmpId(): number {
        this.loadUser();
        return this.currentUser.authEmpId;
    }

    public getAuthId(): number {
        this.loadUser();
        return this.currentUser.authId;
    }

    public getVerticalName(): string {
        this.loadUser();
        return this.currentUser.vertical;
    }

    public setVerticalName(verticalName: string) {
        this.loadUser();
        this.currentUser.vertical = verticalName;
        this.loginUser(this.currentUser);
    }

    public logoutUser(): void {
        this.currentUser = null;
        this.cookieService.deleteAll();
        sessionStorage.removeItem('multiAddonList');
        sessionStorage.removeItem('currentUser');
        sessionStorage.removeItem('ordereasylicense');
    }

    // Kept seperate here cuz of circular dependency
    private getDefSkewName(skewCode: string) {
        return default_page[skewCode]['skewName'];
    }

    public saveOrderEasyLicenseDetails(licenseDetails: any): void {
        this.orderEasyLicenseDetails = licenseDetails;
        sessionStorage.setItem('ordereasylicense', this.encryptUsingAES256(JSON.stringify(licenseDetails)));
    }

    public saveApiIntegrationAvailiable(data) {
        this.apiIntegrationAvailable = data;
    }

    private loadOrderEasyLicenseDetails(): void {
        if (sessionStorage.getItem('ordereasylicense')) {
            this.orderEasyLicenseDetails = Object.assign([], JSON.parse(this.decryptUsingAES256(sessionStorage.getItem('ordereasylicense'))));
        } else {
            this.notify.showWarn('Warning', 'Session Expired, Kindly login again');
            this.router.navigate(['/logout']);
        }
    }

    public checkOrderEasyAddonIsActive(skewCode: string) {
        this.loadOrderEasyLicenseDetails();
        let status = this.orderEasyLicenseDetails.filter(res => res.skewCode == skewCode)[0];
        status = status ? status.status : false;
        if (status) {
            return status.toLowerCase() === 'active';
        } else {
            return false;
        }
    }


    public getSelectedOutletId() {
        if (localStorage.getItem('selectedOutletId')) {
            return localStorage.getItem('selectedOutletId');
        }
    }

    public getOutletLicenseCount() {
        if (sessionStorage.getItem('ordereasylicense')) {
            this.orderEasyLicenseDetails = Object.assign([], JSON.parse(this.decryptUsingAES256(sessionStorage.getItem('ordereasylicense'))));
        } else {
            this.router.navigate(['/logout']);
        }
        return this.orderEasyLicenseDetails.filter(res => res.skewCode == '01.0AO')[0].licenseCount;
    }

    public getSelectedOutletName() {
        if (localStorage.getItem('selectedOutletName')) {
            return localStorage.getItem('selectedOutletName');
        }
        return '';
    }
    public setMultiAddonDetails(multiAddonList) {
        this.multiAddonList = multiAddonList.map(x => x.productCode) ;
        sessionStorage.setItem('AddonList', multiAddonList.map(x => x.productCode));
        return this.multiAddonList ;
    }
    public getMultiAddonDetails(skewCode) {
        const array = sessionStorage.getItem('AddonList') ;
        if (array && array.length) {
            return array.includes(skewCode);
        }
    }


    public getCompanyCode() {
        if (localStorage.getItem('companyCode')) {
            return localStorage.getItem('companyCode') ? JSON.parse(localStorage.getItem('companyCode')).companyCode : '';
        }
        return '';
    }

    public setAddonDetails(addonlist) {
        this.addonList = addonlist.ecommerce;
        this.setSkewName((this.currentUser && this.currentUser.skewName) ? this.getSkewName() : this.addonList[0].addOnName);
        this.setSkewCode((this.currentUser && this.currentUser.skewCode) ? this.getSkewCode() : this.addonList[0].productCode);
    }

    public getAddonDetails(skewCode) {
        let isActive = false;
        this.addonList.forEach(x => {
            if (x.productCode === skewCode) {
                isActive = x.purchased && !x.expired;
            }
        });
        return isActive;
    }

    public getAddonsList() {
        return this.addonList;
    }

    public setAddonList(addonsList) {
        this.addonList = addonsList;
    }

    public setSideNavOpen(sideNavOpened) {
        this.sideNavOpened = sideNavOpened;
    }

    public getSideNavOpen() {
        return this.sideNavOpened;
    }

    public convertToFriendlyFormat(n, d) {
        let x = ('' + n).length;
        const p = Math.pow;
        d = p(10, d);
        x -= x % 3;
        return Math.round(n * d / p(10, x)) / d + ' kMGTPE'[x / 3];
    }

    public isStandalone() {
        return (this.getSkewCode() === '917' || this.getSkewCode() === '1003' || this.getSkewCode() === '918' || this.getSkewCode() === '920' || this.getSkewCode() === '923');
    }

    public isMarketPlace() {
        return (this.getSkewCode() === '910' || this.getSkewCode() === '1003');
    }

    public isAdmin() {
        return (this.getSkewCode() === '1' || this.getSkewCode() === '111');
    }

    private getOutletDetails() {
        if (localStorage.getItem('outletDetails')) {
            const outletDetail = JSON.parse(window.atob(localStorage.getItem('outletDetails')));
            this.outletDetails = outletDetail ? outletDetail[0] : {};
        }
    }

    public getCurrency() {
        this.getOutletDetails();
        if (this.outletDetails && this.outletDetails.currency) {
            return decodeURIComponent(this.outletDetails['currency']);
        }
        return '';
    }

    public getApiIntegrationAvailable() {
        return this.apiIntegrationAvailable ? this.apiIntegrationAvailable : false;
    }

    public isStoreProfileAvail() {
        return !(this.getSkewCode() === '744' || this.getSkewCode() === '538' || this.getSkewCode() === '920' || this.isAdmin() || this.getSkewCode() === '937' || this.getSkewCode() === '921');
    }

}
