import {Component, ElementRef, HostListener, OnInit} from '@angular/core';
import {LoginService} from '../../../modules/login/login.service';
import {NotificationService} from '../../../core/services/notification.service';
import {SharedService} from '../../service/shared.service';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {DeliveryService} from '../../../modules/integration/delivery-web-app/delivery-web-app.service';

@Component({
    selector: 'app-app-company-selection',
    templateUrl: './app-company-selection.component.html',
    styleUrls: ['./app-company-selection.component.scss']
})
export class AppCompanySelectionComponent implements OnInit {
    hideOutletTab: any;
    showHideOutletList: any;
    companyList = [];
    defaultCompanyCode = {
        name : 'All Company',
        companyCode : ''
    };
    selectedCompanyCode = this.defaultCompanyCode;

    @HostListener('document:click', ['$event', '$event.target'])
    onClick(event: MouseEvent, targetElement: HTMLElement) {
        if (this.authService.getSkewCode() === '538') {
            // if (window.location.pathname.includes('delivery-allocation')) {
            //     this.hideOutletTab = true;
            // } else {
            //
            // }
            this.hideOutletTab = (window.location.pathname.includes('delivery-allocation')) || (window.location.pathname.includes('delivery-boy')) || (window.location.pathname.includes('return-item-management'));
        } else {
            this.hideOutletTab = false;
        }
        if (!targetElement) {
            return;
        }
        const clickedInside = this.elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.showHideOutletList = false;
        }
    }

    constructor(
        private appService: DeliveryService,
        private notifyService: NotificationService,
        private elementRef: ElementRef,
        private sharedService: SharedService,
        private authService: AuthenticationService
    ) {
    }

    ngOnInit() {
        if (this.authService.getSkewCode() === '538') {
            // if (window.location.pathname.includes('delivery-allocation')) {
            //     this.hideOutletTab = true;
            // }
            // this.hideOutletTab = false;
            // tslint:disable-next-line:max-line-length
            this.hideOutletTab = (window.location.pathname.includes('delivery-allocation')) || (window.location.pathname.includes('delivery-boy')) || (window.location.pathname.includes('return-item-management'));
        } else {
            this.hideOutletTab = false;
        }
        this.getOutletConfiguration();
        if (localStorage.getItem('companyCode')) {
            this.selectedCompanyCode = JSON.parse(localStorage.getItem('companyCode')).name;
        }
    }

    getOutletConfiguration() {
        this.appService.getOutletConfig('', '').subscribe(
            data => {
                if (data) {
                    if (data.configuredStores.length === 0) {
                        this.hideOutletTab = false;
                    } else {
                        this.companyList = data.configuredStores[0].organization;
                        this.companyList.unshift(this.defaultCompanyCode);
                    }
                }
            },
            (err) => {
            }
        );
    }

    async onCompanyFilterChange(data, boolVal) {
        if (boolVal) {
            localStorage.removeItem('companyCode');
        }
        if (!localStorage.getItem('companyCode')) {
            this.selectedCompanyCode = data;
            localStorage.setItem('companyCode', JSON.stringify(data));
        }
        this.sharedService.setCompanyCodeChange(data.companyCode);
    }

}
