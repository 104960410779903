import {Component, HostListener, OnInit} from '@angular/core';
import * as moment from 'moment';
import {ReturnOrder, ReturnOrderItem, UpdateReturn} from '../../../core/models';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {NotificationService} from '../../../core/services/notification.service';
import {EcommerceService} from '../../../modules/integration/ecommerce/ecommerce.service';

@Component({
  selector: 'app-store-order-return',
  templateUrl: './store-order-return.component.html',
  styleUrls: ['./store-order-return.component.scss'],
})
export class StoreOrderReturnComponent implements OnInit {
  returnedStatus = [
    {
      id: 1,
      statusName: 'all',
    },
    {
      id: 2,
      statusName: 'pending',
    },
    {
      id: 3,
      statusName: 'acknowledged',
    },
  ];

  clickedSelectedStatus = '';
  selectedReturnDetails: ReturnOrderItem[];
  returnedOrders: ReturnOrder[];
  selectedReturn: ReturnOrder;
  updateReturn = new UpdateReturn();
  pageIndex = 0;
  totalPages: number;
  returnInstruction: string;
  endStatus = ['RETURN_REJECTED', 'LIQUIDATED', 'RETURN_DELIVERED'];
  hideUpdateBtn = true;
  viewReturnDetails = false;
  imageViewModal = false;
  selectedReturnImage = [];
  activeMinViewImag: number;
  orderDetails: any;
  statusUpdated = 'status-updated';

  constructor(
    public ecommerceService: EcommerceService,
    public authenticationService: AuthenticationService,
    public notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.listSearchedStatus(this.returnedStatus[0].statusName);
  }

  listSearchedStatus(data) {
    this.returnedOrders = [];
    this.clickedSelectedStatus = data;
    this.pageIndex = 0;
    this.getReturnOrder('');
  }

  getReturnOrder(result) {
    this.viewReturnDetails = false;
    const body = {
      'userId': this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
      'outletId': this.authenticationService.getSelectedOutletId(),
      'status': this.clickedSelectedStatus === 'all' ? '' : this.clickedSelectedStatus,
      'pageIndex': this.pageIndex.toString(),
      'pageSize': 20,
    };

    this.ecommerceService.getReturnOrder(body).subscribe((returns) => {
      if (returns) {
        this.returnedOrders =
          result !== 'status-updated' ? this.returnedOrders : [];
        this.returnedOrders = this.returnedOrders.concat(returns.data);
        console.log(this.returnedOrders)
        this.totalPages = returns.totalPages;
        this.clickedSelectedOrder(this.returnedOrders[0]);
        if (returns.data.length === 0) {
          this.notificationService.showWarn(
            'warn',
            'No orders for the search criteria'
            );
          }
        }else{
          this.notificationService.showError(
            'Error',
            'Something went wrong kindly reach gofrugal support'
          );
        }
      });
  }

  clickedSelectedOrder(item) {
    this.selectedReturn = item;
    this.selectedReturnDetails = [];
    const body = {
      'userId': Number(this.authenticationService.getServiceUserId()),
      'vendor': this.authenticationService.getSkewName(),
      'outletId': Number(this.authenticationService.getSelectedOutletId()),
      'onlineReferenceNo': this.selectedReturn.onlineReferenceNo,
      'orderId': this.selectedReturn.orderId,
      'returnId': this.selectedReturn.returnId
      }

    this.ecommerceService
      .getReturnOrderItem(body)
      .subscribe((response) => {
        this.hideUpdateBtn = true;
        this.selectedReturnDetails = response.returnDtl;
        this.orderDetails = response.order;
        this.selectedReturnDetails.forEach((res) => {
          res.ttlApproval = this.readableIsoParse(
            Object.values(moment.duration(res.ttlApproval))[4]
          );
          res.ttlReverseQc = this.readableIsoParse(
            Object.values(moment.duration(res.ttlReverseQc))[4]
          );
          res.currentStatus = res.approveStatus ? res.approveStatus : '';
          if (res.defectImages !== null && res.defectImages !== '') {
            res.defectImagesSlide = res.defectImages.split(',');
          }
        });
      });
  }
  readableIsoParse(obj) {
    const entries = Object.entries(obj);
    const ttl = entries
      .filter((res) => res[1] > 0)
      .map((ele) => ele.reverse().toString().replace(',', ' '));
    return ttl.toString();
  }

  checkItemStatus() {
    setTimeout(() => {
      const getBoolean = [];
      const removedEndReturnItems = this.selectedReturnDetails.filter(
        (res) => !this.endStatus.includes(res.currentStatus)
      );
      removedEndReturnItems.forEach((res) => {
        if (
          res.approveStatus !== null &&
          res.approveStatus !== '' &&
          res.currentStatus !== res.approveStatus
        ) {
          getBoolean.push(0);
        } else {
          getBoolean.push(1);
        }
      });
      this.hideUpdateBtn = getBoolean.includes(1);
    }, 0);
  }

  updateReturnStatus(selectedReturn) {
    this.updateReturn.userId = this.authenticationService.getServiceUserId();
    this.updateReturn.vendor = this.authenticationService.getSkewName();
    this.updateReturn.outletId = Number(this.authenticationService.getOutlet());
    this.updateReturn.onlineReferenceNo= selectedReturn.onlineReferenceNo;
    this.updateReturn.orderId = selectedReturn.orderId;
    this.updateReturn.approveStatus = 'acknowledged';
    this.updateReturn.returnId = selectedReturn.returnId;
    this.updateReturn.reverseQcInstructions =
      this.selectedReturn.reverseQcInstructions;
    this.updateReturn.returnItemUpdateList = [];
    this.selectedReturnDetails.forEach(res => {
      this.updateReturn.returnItemUpdateList.push({
        'rowNo': res.rowNo,
        'itemId': res.itemId,
        'itemReferenceCode': res.itemReferenceCode,
        'approveStatus': res.approveStatus
      });
    });
    this.hideUpdateBtn = true;
    this.ecommerceService
      .updateReturnStatus(this.updateReturn)
      .subscribe((res) => {
        if (res.status) {
          this.notificationService.showSuccess('Success', res.message);
          this.hideUpdateBtn = true;
          this.getReturnOrder(this.statusUpdated);
        } else {
          this.notificationService.showError('Error', res.message);
        }
      });
  }

  // Set class name for background
  setStatusColor(data) {
    if (data) {
      if (data.toLowerCase() === 'pending') {
        return 'pendingReturn';
      } else if (data.toLowerCase() === 'acknowledged') {
        return 'acknowledgedReturn';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  setStatusBulletColor(data) {
    if (data) {
      if (data.toLowerCase() === 'pending') {
        return 'pendingReturnBullet';
      } else if (data.toLowerCase() === 'acknowledged') {
        return 'acknowledgedReturnBullet';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  setStatusIconStyle(data) {
    if (data) {
      if (data.toLowerCase() === 'pending') {
        return 'pendingStatusIcon';
      } else if (data.toLowerCase() === 'acknowledged') {
        return 'acknowledgedStatusIcon';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  // Get class name
  setSelectedReturn(data) {
    if (
      `${data.orderId}-${data.returnId}` ===
      `${this.selectedReturn.orderId}-${this.selectedReturn.returnId}`
    ) {
      if (data.approveStatus.toLowerCase() === 'pending') {
        return 'selectedListPendingActive';
      } else if (data.approveStatus.toLowerCase() === 'acknowledged') {
        return 'selectedListAcceptedActive';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  getStatusMatIcon(data) {
    let matIcon;
    switch (data) {
      case 'pending':
        matIcon = 'pending_actions';
        break;
      case 'acknowledged':
        matIcon = 'published_with_changes';
        break;
      default:
        matIcon = 'description';
    }
    return matIcon;
  }

  @HostListener('scroll', [])
  onScroll(event): void {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight
    ) {
      if (this.pageIndex < this.totalPages - 1) {
        this.pageIndex++;
        this.getReturnOrder('');
      }
    }
  }

  replaceApprovedStatus(status) {
    return status.replace('_', ' ');
  }

  viewReturnDetailsFull() {
    this.viewReturnDetails = !this.viewReturnDetails;
  }

  viewReturnImage(data, activeSlide) {
    this.imageViewModal = true;
    this.activeMinViewImag = activeSlide;
    this.selectedReturnImage = data;
  }

  returnEndStatus(status) {
    let endReturnState;
    switch (status) {
      case 'RETURN_REJECTED':
        endReturnState = 'return-end__return-rejected';
        break;
      case 'LIQUIDATED':
        endReturnState = 'return-end__return-liquidated';
        break;
      case 'RETURN_DELIVERED':
        endReturnState = 'return-end__return-delivered';
        break;
    }
    return endReturnState;
  }
}
