import { Injectable } from '@angular/core';
import { EcommerceAPIService } from '../../../../../../core/http/ecommerce/ecommerce-api.service';
import { AlertService } from '../../../../../../core/http/alert/alert.service';
import { Observable, of } from 'rxjs';
import { ItemToggle, OrdereasyConfig, OutletDetail, Product, ReleaseNotesDetails } from '../../../../../../core/models/integration/ecommerce';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigData } from '../data';
import { AuthenticationService } from '../../../../../../core/services/authentication.service';
import { AccountApiService } from '../../../../../../core/http/account/account-api.service';
import { ConnectionService } from 'ng-connection-service';
import { NotificationService } from '../../../../../../core/services/notification.service';


@Injectable({
  providedIn: 'root'
})
export class OrdereasyConfigService {
  configData = ConfigData;
  private isConnected: any = true;
  storeReleaseNotes: ReleaseNotesDetails
  showAgainBtn: boolean = false; // show again button for release notes
  callAgain = true; // show again button for release notes

  constructor(
    private ecommerceAPIService: EcommerceAPIService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private accountApiService: AccountApiService,
    private notifyService: NotificationService,
    private connectionService: ConnectionService
  ) {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.notifyService.showSuccess('Success', 'You are ONLINE');
      } else {
        this.notifyService.showWarn('Warning', 'Please check your internet connection');
      }
    });
  }

  public getOutletDetails(): Observable<OutletDetail[]> {
    let headers = new HttpHeaders().set('X-Auth-Token', this.authenticationService.getAuthToken()).set('Content-Type', 'application/json').set('key', window.btoa(this.authenticationService.getEmailId()));
    if (this.authenticationService.getSkewCode() === '538' || this.authenticationService.getSkewCode() === '744' || this.authenticationService.getSkewCode() === '944') {
      headers = headers.append('skipHq', '2');
    }
    const param = new HttpParams();
    const body = {
      'userId' : this.authenticationService.getServiceUserId(),
      'vendor' : this.authenticationService.getSkewName()
    };
    return this.accountApiService.post('list-store', param, headers, body, true);
  }

  public getProduct(): Observable<Product[]> {
    return of(this.configData.urbanpiper.product);
  }

  public getDeliverAppProduct(): Observable<Product[]> {
    return of(this.configData.deliveryapp.product);
  }

  public getStatus(verticalId): Observable<any> {
    const param = new HttpParams().set('verticalId', verticalId)
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.get('get-status-master', param);
  }

  public testURL(url: string, product: string): Observable<any> {
    const param = new HttpParams().set('url', window.btoa(url)).set('product', product)
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.get('store/test/domain-url', param);
  }

  public genOTP(url: string, domain: string): Observable<any> {
    const param = new HttpParams()
      .set('url', window.btoa(url))
      .set('skewCode', '' + this.authenticationService.getSkewCode())
      .set('domain', domain);
    return this.ecommerceAPIService.get('store/regen/otp', param);
  }

  public saveConfig(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('userId', String(this.authenticationService.getServiceUserId()))
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', body.outletId);
    return this.ecommerceAPIService.post('configure', param, header, body, true);
  }

  public deleteConfig(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('outletId', this.authenticationService.getOutlet());
    return this.ecommerceAPIService.post('v1/delete-outlet', param, header, body, true);
  }

  public getConfig(outletId?: number, orderNo?: any): Observable<OrdereasyConfig> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('outletId', '' + (outletId ? outletId : 0))
      // .set('orderNo', '' + (orderNo ? orderNo : '0'))
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.get('get/configured-store', param, null, true);
  }

  public getAppConfig(): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('from', 'portal');
    return this.ecommerceAPIService.get('742/get-config', param);
  }

  public saveAppConfig(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('userId', String(this.authenticationService.getServiceUserId()))
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.post('742/save-config', param, header, body, true);
  }

  public getAboutConfig(): Observable<any> {
    const param = new HttpParams();
    return this.ecommerceAPIService.get('get-about', param);
  }

  public saveAboutContent(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.post('add-about', param, header, body, true);
  }

  public getLanguageMaster(): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.get('get-languages-master', param);
  }

  public addLanguageMaster(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    body['userId'] = this.authenticationService.getServiceUserId();
    return this.ecommerceAPIService.post('add-language-master', param, header, body, true);
  }

  // Get multi language based on userID
  public getLanguageBasedUser(): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.get('get-languages', param);
  }

  // Add language master
  public updateMultiLanguageBasedOnUser(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.post('add-language', param, header, body, true);
  }

  //Get shipping configuration
  public getShippingConfig(): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    const body = {};
    body['userId'] = '' + this.authenticationService.getServiceUserId();
    body['vendor'] = this.authenticationService.getSkewName();
    body['sort'] = 'id desc';
    return this.ecommerceAPIService.post('v1/get/delivery-type', param, header, body, true);
  }

  //add or update shipping configuration
  public saveShippingConfig(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/add/delivery-type', param, header, body, true);
  }
  // Get Recommended master
  public getRecommendedList(): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.get('get-recommended-master', param, null, true);
  }

  // Get Recommended master for mapping items
  public getRecommendedListForMappingItem(): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('filter', 'status=Active');
    return this.ecommerceAPIService.get('get-recommended-master', param, null, true);
  }

  // Add Recommended master
  public addRecommendedMaster(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.post('add-recommended-master', param, header, body, true);
  }

  // Add Recommended master
  public addMappedRecommendedItems(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('outletId', body.outletId)
      .set('id', body.id)
      .set('itemId', body.itemId);
    return this.ecommerceAPIService.post('add-recommended-mapping', param, header, body, true);
  }

  public getMappedRecommendedList(outletId, isMobile): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('fromMobile', isMobile)
      .set('outletId', outletId);
    return this.ecommerceAPIService.get('get-mapping-items', param, null, true);
  }

  // Sort Recommended master
  public sortRecommendedMaster(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.post('sort-recommended-master', param, header, body, true);
  }

  public saveBanner(id: any, formData: FormData, type: any): Observable<any> {
    const param = new HttpParams()
      .set('id', id)
      .set('type', type);
    return this.ecommerceAPIService.postFormData('upload-images', param, null, formData, true);
  }

  public getPaymentMaster(): Observable<any> {
    // const param = new HttpParams()
    //   .set('userId', '' + this.authenticationService.getServiceUserId())
    //   .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.get('get-payment-master');
  }

  public addPaymentMaster(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    // const param = new HttpParams()
    //   .set('userId', '' + this.authenticationService.getServiceUserId())
    //   .set('vendor', this.authenticationService.getSkewName());
    body['userId'] = this.authenticationService.getServiceUserId();
    return this.ecommerceAPIService.post('add-payment-master', null, header, body, true);
  }

  // Get Payment list based on userID
  public getPaymentListBasedUser(isMobile): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('fromMobile', isMobile);
    return this.ecommerceAPIService.get('get-payment-config', param);
  }

  // Update payment configuration
  public updatePaymentBasedOnUser(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.post('add-payment-config', param, header, body, true);
  }

  public getItemReport(outletSelected: string, pageIndex: string, pageSize: any, categoryList: any, filter: string): Observable<ItemToggle[]> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', outletSelected)
        .set('pageIndex', pageIndex)
        .set('pageSize', pageSize)
        .set('orFilter', categoryList)
        .set('filter', filter);
    return this.ecommerceAPIService.get('get/item-master/report', param, null, true);
  }

  // Get Online Payment keys based on userID
  public getOnlinePaymentKeys(showLoader): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.get('get-online-payment-keys', param, null, showLoader);
  }

  // Get Payment list for online payment based on userID
  public getPaymentListForOnlineBasedUser(isMobile, paymentType?): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('fromMobile', isMobile)
        .set('paymentType', paymentType);
    return this.ecommerceAPIService.get('get-payment-config', param);
  }

  public addOnlinePaymentKeys(body: any, forAllOutlet): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', '' + 0)
        .set('forAllOutlet', forAllOutlet);
    return this.ecommerceAPIService.post('add-online-payment-keys', param, header, body, true);
  }

  // Get Add On List in Add On Screen
  public getAddOnListInAddOnScreen(): Observable<any> {
    return this.ecommerceAPIService.get('get/addons', null, null, true);
  }

  // Get Add On List in Order Status Screen
  public getAddOnListInOrderStatusScreen(): Observable<any> {
    const param = new HttpParams()
      .set('hasSalesScreen', '' + true);
    return this.ecommerceAPIService.get('get/addons', param, null, true);
  }

  // Get Add On List in Order Processing Screen
  public getAddOnListInOrderProcessingScreen(): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('hasSalesScreen', '' + true);
    return this.ecommerceAPIService.get('get/addons', param, null, true);
  }

  // Get Order status for order processing screen 
  public getOrderStatusforOrderProcessingScreen(outletId?, vendor?): Observable<any> {
    const getVendor = this.authenticationService.getSkewCode() === '923' ? 'ONDC' : this.authenticationService.getSkewName();
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', getVendor)
        .set('outletId', outletId);
    return this.ecommerceAPIService.get('get/orderStatus', param, null, true);
  }

  // Get Order status master 
  public getOrderStatusMaster(verticalId?, vendor?): Observable<any> {
    const param = new HttpParams()
        .set('vertical', verticalId)
        .set('vendor', vendor);
    return this.ecommerceAPIService.get('get/orderStatus', param, null, true);
  }

  // Update Order status master
  public updateOrderStatusMaster(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    // const param = new HttpParams()
    //   .set('userId', '' + this.authenticationService.getServiceUserId())
    //   .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.put('update/orderStatus', null, header, body, true);
  }

  // Update AddOn master
  public updateAddOnMaster(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    // const param = new HttpParams()
    //   .set('userId', '' + this.authenticationService.getServiceUserId())
    //   .set('vendor', this.authenticationService.getSkewName());
    body['userId'] = this.authenticationService.getServiceUserId();
    return this.ecommerceAPIService.put('update/addons', null, header, body, true);
  }

  //Get Zone Name list
  public getZoneNameList(pageIndex?: any, pageSize?: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    const body = {};
    body['userId'] = '' + this.authenticationService.getServiceUserId();
    body['vendor'] = this.authenticationService.getSkewName();
    if (pageIndex && pageSize) {
      body['pageIndex'] = pageIndex;
      body['pageSize'] = pageSize;
    }
    return this.ecommerceAPIService.post('v1/get/delivery-zone', param, header, body, true);
  }

  // Add Update Zone Name
  public addUpdateZone (body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/add/delivery-zone', param, header, body, true);
  }

  //Add or Update Zone
  public addUpdatePincodeList (body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/add/pincode-master', param, header, body, true);
  }
  // Get Pincode List
  public getPincodeList(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/get/pincode-master', param, header, body, true);
  }

  // Edit Update Pincode
  public editPincode (body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/update/pincode', param, header, body, true);
  }

  //Delete zone
  public deletePincode(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/delete/delivery-zone-mapping', param, header, body, true);
  }

  //Delete pincode
  public deleteZone(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/delete/delivery-zone', param, header, body, true);
  }

  //Get outlet mapping
  public getOuletMappingList(pageIndex?: any, pageSize?: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    const body = {};
    body['userId'] = '' + this.authenticationService.getServiceUserId();
    body['vendor'] = this.authenticationService.getSkewName();
    if (pageIndex && pageSize) {
      body['pageIndex'] = pageIndex;
      body['pageSize'] = pageSize;
    }
    return this.ecommerceAPIService.post('v1/get/delivery-zone-outlet-mapping', param, header, body, true);
  }

  //Get Outlet mapping zone
  public getOuletMappingZone(): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    const body = {};
    body['userId'] = '' + this.authenticationService.getServiceUserId();
    body['vendor'] = this.authenticationService.getSkewName();
    return this.ecommerceAPIService.post('v1/get-zone', param, header, body, true);
  }

  //Add Outlet Mapping
  public addOutletMapping(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/add/delivery-zone-outlet-mapping', param, header, body, true);
  }

  // Get Delivery Configuration List
  public getDeliveryConfigurationList(pageIndex?: any, pageSize?: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    const body = {};
    body['userId'] = '' + this.authenticationService.getServiceUserId();
    body['vendor'] = this.authenticationService.getSkewName();
    body['sort'] = 'id desc';
    if (pageIndex && pageSize) {
      body['pageIndex'] = pageIndex;
      body['pageSize'] = pageSize;
    }
    return this.ecommerceAPIService.post('v2/delivery-charge', param, header, body, true);
  }

  // Add / Update delivery configuration list
  public addUpdateDeliveryConfigList(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.post('v1/delivery-charge', param, header, body, true);
  }
  //Get Delivery Zone List
  public getDeliveryZoneList(pageIndex?: any, pageSize?: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    const body = {};
    body['userId'] = '' + this.authenticationService.getServiceUserId();
    body['vendor'] = this.authenticationService.getSkewName();
    body['sort'] = 'id desc';
    if (pageIndex && pageSize) {
      body['pageIndex'] = pageIndex;
      body['pageSize'] = pageSize;
    }
    return this.ecommerceAPIService.post('v1/get/delivery-charge-zone', param, header, body, true);
  }
  //Get Delivery charge based on kms
  public getZoneChargebasedKms(pageIndex?: any, pageSize?: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    const body = {};
    body['userId'] = '' + this.authenticationService.getServiceUserId();
    body['vendor'] = this.authenticationService.getSkewName();
    body['sort'] = 'id desc';
    if (pageIndex && pageSize) {
      body['pageIndex'] = pageIndex;
      body['pageSize'] = pageSize;
    }
    return this.ecommerceAPIService.post('v1/get/delivery-charge-kms', param, header, body, true);
  }
  //Add Delivery chagre for Zone
  public addUpdateDeliveryZoneCharge(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/add/delivery-charge-zone', param, header, body, true);
  }
  //Add or Update Delivery Charge for kms
  public addUpdateDeliveryKmsCharge(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/add/delivery-charge-kms', param, header, body, true);
  }
  //Add/update Delivery Zone List
  public addUpdateDeliveryZoneList(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/add/delivery-charge-zone', param, header, body, true);
  }
  // Dynamic Menu
  public saveMenuConfig(body: any): Observable<any> {
    if (!this.isConnected) {
      this.notifyService.showWarn('Warning', 'Please check your internet connection');
      return;
    }
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/menu', param, header, body, true);
  }

  public getMenuConfig(pageSize, pageIndex): Observable<any> {
    if (!this.isConnected) {
      this.notifyService.showWarn('Warning', 'Please check your internet connection');
      return;
    }
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    const body = {};
    body['userId'] = '' + this.authenticationService.getServiceUserId();
    body['vendor'] = this.authenticationService.getSkewName();
    return this.ecommerceAPIService.post('v2/get-menu', param, header, body, true);
  }

  public getMenuListWithFilter(): Observable<any> {
    if (!this.isConnected) {
      this.notifyService.showWarn('Warning', 'Please check your internet connection');
      return;
    }
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    const body = {
      userId: '' + this.authenticationService.getServiceUserId(),
      vendor: this.authenticationService.getSkewName(),
      fromMobile: true
    };
    return this.ecommerceAPIService.post('v2/get-menu', param, header, body, true);
  }

  public saveMenuIcon(itemId: string, formData: FormData, type: any): Observable<any> {
    if (!this.isConnected) {
      this.notifyService.showWarn('Warning', 'Please check your internet connection');
      return;
    }
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName())
        .set('id', itemId)
        .set('type', type);
    return this.ecommerceAPIService.postFormData('upload-images', param, null, formData, true);
  }

  // Get all release notes based on vendor 
  public getReleaseNotes(reqFrom, body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('reqFrom', reqFrom);
    return this.ecommerceAPIService.post('v1/get/release-notes', param, header, body, true);
  }

  // Add / Update Whatsnew list 
  public addUpdateWhatsNewList(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/release-notes', param, header, body, true);
  }

  // Block release notes list 
  public blockReleaseNotesList(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/block/release-notes', param, header, body, true);
  }

  // Store release notes details
  public storeReleaseNotesDetails(data, isShowBtn) {
    this.storeReleaseNotes = data;
    this.showAgainBtn = isShowBtn;
    this.callAgain = false;
  }

  // Get content master for admin 
  public getContentMaster(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/get/content-masters', param, header, body, true);
  }

  // Add / Update content master for admin 
  public addUpdateContentMaster(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    return this.ecommerceAPIService.post('v1/content-masters', null, header, body, true);
  }

  // Add / Update dynamic field master for admin 
  public addUpdateDynamicFieldMaster(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    return this.ecommerceAPIService.post('v1/dynamic-feilds', null, header, body, true);
  }

  // Get User content from user 
  public getUserContentFromUser(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/user-content', param, header, body, true);
  }

  // Add / Update content master from Customer 
  public addUpdateContentMasterFromCustomer(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/content-masters', param, header, body, true);
  }

  // Save user onfiguration on SMS / WNS / Mobile notification
  public saveUserConfigurationForNotification(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/user-content', param, header, body, true);
  }

  public saveBroadcastConfiguration(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/user-content', param, header, body, true);
  }

  public sendBroadcast(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/send-broadcast', param, header, body, true);
  }

  public saveAdminConfig(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/admin-config', param, header, body, true);
  }

  public getAdminConfig(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/get/admin-config', param, header, body, true);
  }

  public saveEmployeeProfile(id: any, formData: FormData, type: any): Observable<any> {
    const param = new HttpParams()
      .set('id', id)
      .set('type', type);
    return this.ecommerceAPIService.postFormData('upload-image', param, null, formData, true);
  }

  public saveEmailConfig(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/email-config', param, header, body, true);
  }

  public getEmailConfig(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/get/email-config', param, header, body, true);
  }

  public sendTestEmail(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/test-email', param, header, body, true);
  }

  public getSelectedCustomerList(body) {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post(`v1/email-autocomplete`, param, header, body, true);
  }

  public checkPortStatus(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/port-checkking', param, header, body, true);
  }

  // Get splash screen information
  public getSplashScreenInfo(outletId: any, body): Observable<any> {
    const param = new HttpParams()
      .set('sort', 'sortId asc');
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.ecommerceAPIService.post('v1/get/app-intro', param, header, body, true);
  }

  // Add / Update splash screen information
  public addUpdateSplashScreenInfo(body): Observable<any> {
    const param = new HttpParams();
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.ecommerceAPIService.post('v1/app-intro', param, header, body, true);
  }

  // Get Unsync orders 
  public getUnSyncOrders(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', body.vendor);
    return this.ecommerceAPIService.get('get/unsync-alert', param, header, true);
  }

  // Delete splash screen information
  public deleteSplashScreenInfo(body): Observable<any> {
    const param = new HttpParams();
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.ecommerceAPIService.post('v1/delete/app-intro', param, header, body, true);
  }

  public addPayPalPaymentDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/add/payment-details', param, header, body, true);
  }

  // Get chatbot configuration 
  public getChatBotConfiguration(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/get/support-type', param, header, body, true);
  }

  // Update chatbot configuration 
  public updateChatConfiguration(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/add/support-type', param, header, body, true);
  }

  // Get sales iq configuration 
  public getSalesIQConfiguration(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/get/support/sales-iq', param, header, body, true);
  }

  // Update sales iq configuration 
  public updateSalesIQConfiguration(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/add/support/sales-iq', param, header, body, true);
  }

  // Get chatbot default list 
  public getChatBotDefaultList(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v2/get/support/default', param, header, body, true);
  }

  // Add / Close chatbot default list 
  public updateChatBotDefaultList(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/update/support/default', param, header, body, true);
  }

  public postHomepageCustomize(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/add/home-page-customization', param, header, body, true);
  }

  public getHomepageCustomize(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams();
    return this.ecommerceAPIService.post('v1/get/home-page-customization', param, header, body, true);
  }

  public getSplashScreenDetails(body): Observable<any> {
    const param = new HttpParams();
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken());
    return this.ecommerceAPIService.post('v1/get/splash', param, header, body, true);
  }

  public postSplashScreenDetails(body): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken());
    return this.ecommerceAPIService.post('v1/add/splash', param, header, body, true);
  }

  public saveOutlet(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('outletId', body.outletId);
    return this.ecommerceAPIService.post('v1/save-outlet', param, header, body, true);
  }
  public getOutlets(outletId?: number, orderNo?: any): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('outletId', '' + (outletId ? outletId : 0))
        // .set('orderNo', '' + (orderNo ? orderNo : '0'))
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.get('get/configured-store', param, null, true);
  }
}
