import {Component, HostListener, OnInit} from '@angular/core';
import * as screenfull from 'screenfull';

@Component({
  selector: 'cdk-fullscreen',
  templateUrl: './fullscreen.component.html',
  styleUrls: ['./fullscreen.component.scss']
})
export class FullscreenComponent implements OnInit {
    isFullscreen: boolean = false;
    constructor() { }

    ngOnInit() {
        document.addEventListener('fullscreenchange', exitHandler);
        document.addEventListener('webkitfullscreenchange', exitHandler);
        document.addEventListener('mozfullscreenchange', exitHandler);
        document.addEventListener('MSFullscreenChange', exitHandler);
        const _this = this;
        function exitHandler() {
            _this.isFullscreen = !(!(document as any).fullscreenElement && !(document as any).webkitIsFullScreen);
        }

        window.onresize = function (event) {
            const maxHeight = window.screen.height,
                maxWidth = window.screen.width,
                curHeight = window.innerHeight,
                curWidth = window.innerWidth;
            _this.isFullscreen = (maxHeight === curHeight || maxWidth === curWidth);
        };
    }

    toggleFullscreen() {
        if (screenfull.enabled) {
            screenfull.toggle();
            this.isFullscreen = !this.isFullscreen;
        }
    }
}
