import {AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UiCommonService} from './shared/service/uiCommon.service';
import {Title} from '@angular/platform-browser';
import {LoginDetailsService} from './core/services/login-details.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit, AfterViewChecked {

  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  loginDetails: any;

  constructor(public uiCommonServ: UiCommonService,
                    private loginDetailsService: LoginDetailsService,
                    private titleService: Title,
                    private cdRef: ChangeDetectorRef) {
  }

  async ngOnInit() {
    console.log('--is logged in');
    this.loginDetailsService.setLoginDetails(window.location.host);
    this.loginDetailsService.themeDetailsList.subscribe(res => {
      if (res) {
        this.loginDetails = res;
        this.titleService.setTitle(this.loginDetailsService.getLoginSkewCode() === '910' ? 'Market Place' : this.loginDetailsService.getLoginSkewName());
      }
    })
    this.titleService.setTitle(this.loginDetailsService.getLoginSkewCode() === '910' ? 'Market Place' : this.loginDetailsService.getLoginSkewName());
  }

  ngAfterViewChecked() {
    if (document.getElementById('insertImage-')) {
      document.getElementById('insertImage-').style.display = 'none';
      document.getElementById('insertVideo-').style.display = 'none';
      document.getElementById('toggleEditorMode-').style.display = 'none';
      // document.getElementById('fontSelector-').style.display = 'none';
      document.getElementById('heading-').style.display = 'none';
    }
    this.cdRef.detectChanges();
    this.favIcon.href = './assets/fav-icons/' + this.loginDetailsService.getLoginSkewCode() + '.ico';
  }

  getRouteAnimation(outlet) {
    return outlet.activatedRouteData.animation;
  }
}
