import {ChangeDetectorRef, Component, Inject, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {EcommerceService} from '../../../../modules/integration/ecommerce/ecommerce.service';
import {NotificationService} from '../../../../core/services/notification.service';
import {AudioRecordingService} from '../../../../core/services/audio-recording.service';
import {ImageCompressService} from '../../../../core/services/image-compress.service';
import {MatDrawer} from '@angular/material/sidenav';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {LoginDetailsService} from '../../../../core/services/login-details.service';

@Component({
  selector: 'app-my-aggregator-details',
  templateUrl: './my-aggregator-details.component.html',
  styleUrls: ['./my-aggregator-details.component.scss']
})
export class MyAggregatorDetailsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() refresh = false;
  tabLabel = 'all-stores';
  drawerTabLabel = 'storeDetails';
  storeList: any;
  totalRecords: any;
  paginationEvent: any;
  index: any;
  openModal: any;
  drawer: MatDrawer;
  storeDetails: any;
  pageSize = 5;
  pageIndex = 1;
  private widgetCode = '2eb84f919229eb3e1983c7ec225074a24baacb2f38d3864bc9c453c398b9e1a949c787a5bb3d048d7a9e2041f4d14a4e';
  selectedStore: any;
  requestRemarks: any;
  aggregatorModal = false;
  selectedAggergator: any;
  publishList = [];
  ticketModal = false;
  ticketType = {
    value : 'Feedback',
    isOpen : false,
    list : ['Feedback', 'Information', 'Complaint']
  };
  ticketName = '';
  ticketMessage = '';
  isRecording = false ;

  // Recording
  isAudioRecording = false;
  audioRecordedTime: any;
  audioBlob: any;
  audioBlobUrl: any;
  audioName: any;

  // Images
  ticketImages = [];
  isOpenRemarksTicket = false;
  selectedTicket: any;
  ticketList = [];
  termConditionModal = false;
  privacyPolicy: any;
  constructor(private authenticationService: AuthenticationService,
              private ecommerceService: EcommerceService,
              private notifyService: NotificationService,
              private render: Renderer2,
              @Inject(DOCUMENT) private _document: Document,
              private ref: ChangeDetectorRef,
              private audioRecordingService: AudioRecordingService,
              private imageCompressService: ImageCompressService,
              private readonly loginDetailsService: LoginDetailsService,
  ) {
    this.audioRecordingService.recordingFailed().subscribe(() => {
      this.isAudioRecording = false;
      this.ref.detectChanges();
    });

    this.audioRecordingService.getRecordedTime().subscribe((time) => {
      this.audioRecordedTime = time;
      this.ref.detectChanges();
    });

    this.audioRecordingService.getRecordedBlob().subscribe(async (data) => {
      this.audioBlob = data.blob;
      this.audioName = data.title;
      const base64Image: any = await this.imageCompressService.toBase64(data.blob);
      console.log(base64Image);
      this.uploadAudio(base64Image);
      this.ref.detectChanges();
    });
  }

  ngOnInit() {
    this.getMyAggregatorDetails();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.refresh) {
      this.ngOnInit();
    }
  }

  ngOnDestroy(): void {
    this.abortAudioRecording();
  }

  // onTabChange(event) {
  //   this.tabLabel = event.tab.textLabel;
  //   console.log(this.tabLabel, event);
  //   this.getMyAggregatorDetails();
  // }


  getMyAggregatorDetails() {
    const body = {
      'filter': 'shopId=' + this.authenticationService.getServiceUserId(),
      'pageIndex': this.pageIndex,
      'pageSize': this.pageSize
    };
    this.ecommerceService.getMyAggregatorDetails(body).subscribe(
        res => {
          console.log(res);
          this.storeList = res.masterList;
          this.totalRecords = res.totalRecords;
        },
        err => {
          this.notifyService.showWarn('Warn', err.message);
        }
    );
  }

  calcWidth(ratings) {
    return ((ratings / 5) * 100);
  }

  pageEvents(event) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    this.getMyAggregatorDetails();
  }

  openRemarksModal(store: any, index, event) {
    console.log(store.storeRemarks, index);
    this.index = index;
    this.openModal = true;
    event.stopPropagation();
  }

  closeModal(event) {
    this.openModal = false;
    event.stopPropagation();
  }

  openStoreDetails(store: any, drawer) {
    console.log(store);
    this.storeDetails = store;
    this.drawer = drawer;
    this.drawer.toggle();
  }

  onDrawerTabChange(event: MatTabChangeEvent) {
    this.drawerTabLabel = event.tab.textLabel;
    console.log(this.tabLabel, event);
  }

  onTabChange(event) {
    this.tabLabel = event.tab.textLabel;
    console.log(this.tabLabel, event);
    if (this.tabLabel === 'store-details') {
      this.getProfileDetails(this.selectedAggergator);
    } else if (this.tabLabel === 'publish') {
      this.getPublishHistory();
    } else if (this.tabLabel === 'all-tickets') {
      this.getTicketReports();
    } else {
      console.log(this.tabLabel);
    }
  }

  closeDrawer() {
    this.drawer.toggle();
  }

  calcNoRatings(ratings: any) {
    return this.authenticationService.convertToFriendlyFormat(ratings, 4);
  }


  openSalesIq(event) {
    event.stopPropagation();
    const userData = {
      name: this.authenticationService.getUserName(),
      email: this.authenticationService.getEmailId()
    };
    const salesIqScript = document.getElementById('zsiqchat');  // to check if script already injected
    if (!salesIqScript) {
      const script = this.render.createElement('script');
      script.type = 'text/javascript';
      script.id = 'zsiqchat';
      script.innerHTML =  `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: '2eb84f919229eb3e1983c7ec225074a24baacb2f38d3864bc9c453c398b9e1a949c787a5bb3d048d7a9e2041f4d14a4e', values:{},ready:function(){
                    $zoho.salesiq.visitor.name("${this.authenticationService.getUserName()}");
                    $zoho.salesiq.visitor.email("${this.authenticationService.getEmailId()}");
                    $zoho.salesiq.floatbutton.visible("hide");
                    // the onload function is triggered after the iframe is loaded.
                    $zoho.salesiq.onload=function()
                    {
                        console.log('ZOHO integrated');
                    }
                    if(!document.getElementById('zsiq_float')){
                        $zoho.salesiq.floatwindow.visible("1");
                    }else{
                        $zoho.salesiq.floatwindow.visible("hide");
                    }
                }};
                var d=document;s=d.createElement("script");
                s.type="text/javascript";
                s.id="zsiqscript";
                s.defer=true;
                s.src='https://salesiq.zoho.com/widget';
                t=d.getElementsByTagName("script")[0];
                t.parentNode.insertBefore(s,t);`;
      this.render.appendChild(this._document.body, script);


      // script to invoke reset fun salesIq
      const clearSalesIq = this.render.createElement('script');
      clearSalesIq.type = 'text/javascript';
      clearSalesIq.id = 'clearSalesIq';
      clearSalesIq.innerHTML = `function resetSalesIq(userData) {
                    $zoho.salesiq.reset();
                 }`;
      this.render.appendChild(this._document.body, clearSalesIq);

      // script to init visitor api salesIq
      this.createFormDataEl(userData);
    } else {
      this.openChat(window, userData);
    }
  }

  createFormDataEl(userData) {
    const setFormInfoSalesIq = this.render.createElement('script');
    setFormInfoSalesIq.type = 'text/javascript';
    setFormInfoSalesIq.id = 'formInfoSalesIq';
    setFormInfoSalesIq.innerHTML = `function setSalesIqFormData(userData) {
                    $zoho.salesiq.visitor.name("${userData.name}");
                    if(userData.name === '') document.getElementById('visname')? document.getElementById('visname').value = '' : null
                    $zoho.salesiq.visitor.email("${userData.email}");
                    if(userData.name === '') document.getElementById('visemail')? document.getElementById('visemail').value = '' : null
                    $zoho.salesiq.visitor.contactnumber("8072776713");
                 }`;
    this.render.appendChild(this._document.body, setFormInfoSalesIq);
  }


  openChat(window, userData) {
    const visitor = document.getElementById('formInfoSalesIq');

    // remove old visitor data if present
    if (visitor) {
      visitor.parentNode.removeChild(visitor);
    }
    // create new visitor data
    this.createFormDataEl(userData);

    // set new visitor data
    // window.setSalesIqFormData(userData);

    // open salesIq window
    const link = document.getElementById('zsiq_float');
    link ? link.click() : this.notifyService.showWarn('Warn', 'Kindly Contact Gofrgugal');
  }

  updateAvailiabilityStatus(store: any, event: any) {
    console.log(store, event);
    const body = {
      'userId': this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
      'outletId': this.authenticationService.getOutlet(),
      'aggregatorUserId': store.userId,
      'publishStatus': event.checked ? 'Active' : 'InActive',
      'publishBy': 'store'
    };
    this.ecommerceService.updateAvailiabilityStatus(body).subscribe(
        (response) => {
          if (response && response.status) {
            this.notifyService.showSuccess('Success', response.message);
            this.getMyAggregatorDetails();
          } else {
            this.notifyService.showWarn('Warn', response.message);
            this.getMyAggregatorDetails();
          }
        },
        (err) => {
          this.notifyService.showWarn('Warn', err);
        }
    );
  }

  openAggregatorDetailModal(store: any) {
    this.selectedAggergator = store;
    this.aggregatorModal = !this.aggregatorModal;
    this.getProfileDetails(store);
  }


  getProfileDetails(store) {
    const body = {
      'userId': this.authenticationService.getServiceUserId(),
      'vendor': 'MarketPlace',
      'custId': store.customerId,
      'emailId': store.emailId,
      'aggregatorUserId' : store.userId
    };
    this.ecommerceService.getAggregatorDetails(body).subscribe(
        res => {
          console.log(res);
          this.selectedStore = res;
          this.selectedStore['userId'] = store.userId;
          this.selectedStore['custId'] = store.customerId;
          // this.aggregatorModal = !this.aggregatorModal;
        },
        error => {
          this.notifyService.showWarn('Warn', error);
        }
    );
  }

  openTicketModal(storeDetails: any) {
    this.ticketModal = true;
    this.selectedStore = storeDetails;
    this.clearTicketModal();
    console.log('ticketModal');
  }

  closeOpenModal(modal: any) {
    this[modal] = false;
    if (modal === 'ticketModal') {
      this.stopAudioRecording();
      this.clearAudioRecordedData();
    }
  }

  clearTicketModal() {
    this.ticketName = this.ticketMessage = this.audioBlobUrl = '';
    this.ticketImages = [];
  }

  sendRequestRemarks() {
    const body = {
      'userId': this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
      'outletId': Number(this.authenticationService.getOutlet()),
      'aggregatorUserId': this.selectedStore.userId,
      'requestRemarks': this.requestRemarks,
    };
    this.ecommerceService.sendRequestRemarks(body).subscribe(
        response => {
          if (response && response.status) {
            this.notifyService.showSuccess('Success', response.message);
            this.closeOpenModal('aggregatorModal');
          } else {
            this.notifyService.showWarn('Warn', response.message);
          }
        }
    );
  }

  getPublishHistory() {
    const body = {
      'userId': this.authenticationService.getServiceUserId(),
      'marketPlaceId': this.selectedStore.userId,
      'vendor': this.authenticationService.getSkewName(),
      'outletId':  Number(this.authenticationService.getOutlet())
    };
    this.ecommerceService.getPublishHistory(body).subscribe(
        response => {
          if (response) {
            console.log(response);
            this.publishList = response;
          } else {
            this.notifyService.showWarn('Warn', response.message);
          }
        },
        error => {
          this.notifyService.showWarn('Warn', error);
        }
    );
  }

  optionChange(config, type) {
    console.log(config, type);
    config.value =  type;
  }

  openOption(config) {
    config.isOpen =  !config.isOpen;
  }

  // Recording
  startStopRecord() {
    if (this.isAudioRecording) {
      this.stopAudioRecording();
    } else {
      this.startAudioRecording();
    }
  }

  startAudioRecording() {
    if (!this.isAudioRecording) {
      this.isAudioRecording = true;
      this.audioBlobUrl = null;
      console.log('---start');
      this.audioRecordingService.startRecording();
    }
  }

  abortAudioRecording() {
    if (this.isAudioRecording) {
      this.isAudioRecording = false;
      this.audioRecordingService.abortRecording();
    }
  }

  stopAudioRecording() {
    if (this.isAudioRecording) {
      this.audioRecordingService.stopRecording();
      this.audioRecordedTime = '';
      console.log('---stop');
      this.isAudioRecording = false;
    }
  }

  clearAudioRecordedData() {
    this.audioBlobUrl = null;
    this.isAudioRecording = false;
  }

  async onImagesUpload(files: FileList) {
    console.log(files);
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const base64Image: any = await this.imageCompressService.compressImage(files[i]);
        this.uploadImage(base64Image);
      }
    }
    console.log(this.ticketImages);
  }

  uploadImage(base64Image) {
    const body = {
      'userId': this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
      'attachmentFile': base64Image
    };
    this.ecommerceService.uploadAttachment(body).subscribe(res => {
          if (res) {
            console.log(res);
            this.ticketImages.push(res.attachmentUrl);
          } else {
            this.notifyService.showWarn('Warn', res.message);
          }
        },
        error => {
          this.notifyService.showWarn('Warn', error);
        });
  }

  deleteImage(tickets: any, index: any) {
    console.log(tickets);
    console.log(index);
    if (index > -1) {
      this.ticketImages.splice(index, 1);
    }
  }

  uploadAudio(base64Image: any) {
    const body = {
      'userId': this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
      'outlet': this.authenticationService.getOutlet(),
      'audio': base64Image
    };
    this.ecommerceService.uploadAudio(body).subscribe(
        res => {
          console.log(res);
          if (res) {
            this.audioBlobUrl = res.refId;
          } else {
            this.notifyService.showWarn('Warn', res.message);
          }
        },
        error => {
          this.notifyService.showWarn('Warn', error);
        }
    );
  }

  sendTicketToMarketPlace() {
    if ( !this.ticketName) {
      this.notifyService.showWarn('Warn', 'Please Enter Name');
      return;
    } else if (!this.ticketMessage && !(this.ticketImages && this.ticketImages.length) && !this.audioBlobUrl) {
      this.notifyService.showWarn('Warn', 'Please Leave a Message');
      return;
    } else {
      const body = {
        'userId': this.selectedStore.userId,
        'vendor': this.authenticationService.getSkewName(),
        'type': this.ticketType.value,
        'name': this.ticketName,
        'description': this.ticketMessage ? this.ticketMessage : '',
        'status': 'open',
        'storeUserId': Number(this.authenticationService.getServiceUserId()),
        'audioFile': this.audioBlobUrl ? this.audioBlobUrl : '',
        'attachmentUrls': this.ticketImages && this.ticketImages.length ? this.ticketImages : [],
        'source': 'portal',
        'mobileNo': '',
      };
      console.log(body);
      this.ecommerceService.sendTicketToMarketPlace(body).subscribe(
          res => {
            if ( res && res.status) {
              this.clearTicketModal();
              this.notifyService.showSuccess('Success', res.message);
            } else {
              this.notifyService.showWarn('Warn', res.message);
            }
            this.closeOpenModal('ticketModal');
          },
          error => {
            this.notifyService.showWarn('Warn', error);
          }
      );
    }
  }

  getTicketReports() {
    const body = {
      'userId': this.selectedStore.userId,
      'vendor': this.authenticationService.getSkewName(),
      'filter': 'source=portal,storeUserId=' + this.authenticationService.getServiceUserId()
    };
    this.ecommerceService.getTicketReports(body).subscribe(
        (res) => {
          if (res) {
            console.log(res);
            this.ticketList = res.data;
          } else {
            this.notifyService.showWarn('Warn', res.message);
          }
        },
        error => {
          this.notifyService.showWarn('Warn', error);
        });
  }

  openRemarkTicket(id: any) {
    console.log(id, this.isOpenRemarksTicket, this.selectedTicket);
    if (this.selectedTicket && this.selectedTicket === id) {
      this.selectedTicket = id;
      this.isOpenRemarksTicket = !this.isOpenRemarksTicket;
    } else {
      this.selectedTicket = id;
      this.isOpenRemarksTicket = true;
    }
    console.log(id, this.isOpenRemarksTicket, this.selectedTicket);
    console.log(this.isOpenRemarksTicket &&  id === this.selectedTicket);
  }

  openPolicyModal(event, store?) {
    this.getPolicies(store);
    event.stopPropagation();
  }

  closePolicyModal() {
    this.termConditionModal = false;
  }
  getPolicies(store) {
    const body = {
      'userId': '' + this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
      'outletId': this.authenticationService.getOutlet(),
      'filter': 'marketPlaceId=' + store.userId,
    };
    this.ecommerceService.getPolicies(body).subscribe(
        res => {
          if (res) {
            this.privacyPolicy = res;
            this.termConditionModal = true;
          } else {
            this.notifyService.showWarn('Warn', res.message);
          }
        },
        error => {
          this.notifyService.showWarn('Warn', error);
        }
    );
  }
  getPrivacyPolicy(policies) {
    if (!policies) {
      return '';
    }
    return policies.replace(/<a/g, '<a target="_blank"');
  }
}
