import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationService} from '../../core/services/authentication.service';
import {NotificationService} from '../../core/services/notification.service';
import {LoginService} from '../../modules/login/login.service';
import {LoginDetailsService} from '../../core/services/login-details.service';

@Component({
  selector: 'app-ordereasy-pro-subscription-info',
  templateUrl: './ordereasy-pro-subscription-info.component.html',
  styleUrls: ['./ordereasy-pro-subscription-info.component.scss']
})
export class OrdereasyProSubscriptionInfoComponent implements OnInit {

  @Input() skewName: string;
  description: string;
  currentPlanDetails: any;
  loginDetails = this.loginDetailsService.getLoginDetails();

  constructor(
    private notifyService: NotificationService,
    private authenticationService: AuthenticationService,
    private loginService: LoginService,
    private loginDetailsService: LoginDetailsService,
  ) { }

  ngOnInit() {
    this.onGetCurrentPlanDetails();
    switch (this.skewName) {
      case 'Platform Configurationion iOS':
        this.description = 'Purchase the add-on to enable your iOS mobile user to shop with you'
        break;
      case 'Multi Language Configuration':
        this.description = 'Purchase the add-on to let customers shop conveniently in their regional language'
        break;
      case 'Admin E-MAIL':
        this.description = 'Purchase the add-on to receive instant order updates over email'
        break;
      case 'Broadcast E-MAIL':
        this.description = 'Purchase the add-on to let customers shop frequently by sending bulk promotional mails'
        break;
      case 'Customer E-MAIL':
        this.description = 'Purchase the add-on to delight customers by sending instant order updates over email'
        break;
      case 'Settings E-MAIL':
        this.description = 'Purchase the add-on to unlock the benefits of email marketing and updates'
        break;
      case 'App Intro':
        this.description = 'Purchase the add-on to entic the users by customizing the app inro screens with attractive and brand specific messages'
        break;
      case 'Dynamic Menu':
        this.description = 'Purchase the add-on to add custom menus in your app for easy navigation, better convenience and more engagement'
        break;
      case '' +
      'Splash Screen':
        this.description = 'Purchase the add-on to unlock the benefits of using splash screen'
        break;
      case 'Social Media':
        this.description = 'Purchase the add-on to unlock the benefits of using social media'
        break;
      case 'Offer Management':
        this.description = 'Purchase the add-on to unlock the benefits of offer management'
        break;
      default:
        break;
    }

  }

  onGetCurrentPlanDetails() {
    let formObj = {
      "integrationAccountId": '' + this.authenticationService.getServiceUserId(),
      "customerId": this.authenticationService.getCustomerId(),
      "productCode": this.authenticationService.getSkewCode()
    }
    this.loginService.currentPlanDetails(formObj).subscribe(
      (data) => {
        if (data && data.plan) {
          this.currentPlanDetails = data;
        } else {
          this.notifyService.showWarn('Warning', data.message);
        }
      },
      (err) => {
        this.notifyService.showError('Error', err);
      });
  }

  onRedirectToStore() {
    let idendity = '';
    if (this.currentPlanDetails.orderNo && this.currentPlanDetails.skewCode) {
      idendity = this.currentPlanDetails.orderNo + '742' + (this.currentPlanDetails.skewCode).replace('.', '');
    }
    let url = this.loginDetails.storePurchase
      + 'idendity=' + idendity
      + '&eidendity=' + this.authenticationService.encryptUsingMD5(idendity)
      + '&pfamily_code=742-01.0';
    window.open(url, '_blank');
  }

}
