import {Component} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {animate, group, state, style, transition, trigger} from '@angular/animations';
import {SharedService} from '../../service/shared.service';

@Component({
  selector: 'app-chat-bot',
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.scss'],
  animations: [
    trigger('rotatedUp', [
      state('down', style({ height: '*', opacity: 0 })),
      transition('up => down', [
        style({ height: '*', opacity: 1 }),

        group([
          animate(500, style({ height: 0 })),
          animate('500ms ease-in-out', style({ 'opacity': '0' }))
        ])

      ]),
      transition('down => up', [
        style({ height: '0', opacity: 0 }),

        group([
          animate(500, style({ height: '*' })),
          animate('500ms ease-in-out', style({ 'opacity': '1' }))
        ])

      ])
    ]),
  ]
})
export class ChatBotComponent {

  botStatus = '';
   showChatBot =  false;
   showMinimize = false;
   chatBotSlider = true;
   chatBotDivShow = false;
   chatBotKey =  null;
   chatBotURL: any;

  constructor(private sharedService: SharedService, private sanitizer: DomSanitizer) {
    this.sharedService.getAssureCare()
        .subscribe(value => {
            if (value) {
              this.initiateChatBot();
              this.sharedService.setAssureCare(false);
        }});
      this.sharedService.getLiveChatClose()
          .subscribe(value => {
              if (value === false) {
                  this.closeChatBot();
                  this.sharedService.setLiveChatClose(true);
              }});
  }

  getSafeUrl() {
    return 'https://delightchat.gofrugal.com/#/custhome?product_data=';
  }


   initiateChatBot() {
       this.chatBotDivShow = true;
       this.chatBotURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.getChatBotUrl() + '&source_from=gofrugalinstock');
    setTimeout(() => {
      this.getChatBot();
    });
  }

   openChatBotNewTab() {
    window.open('https://delightchat.gofrugal.com/#/custhome?product_data=');
    this.minimize();
  }

   closeChatBot() {
    this.chatBotDivShow = false;
    this.botStatus = 'CLOSE';
    this.showChatBot = false;
    this.showMinimize = false;
    this.chatBotSlider = true;
  }

   minimize() {
    this.botStatus = 'MINIMIZE';
    setTimeout(() => {
      this.chatBotSlider = true;
    }, 500);
    setTimeout(() => {
      this.showMinimize = true;
    }, 400);
  }

   maximizeChatBot() {
    this.showMinimize = false;
    this.chatBotSlider = false;
    this.botStatus = 'OPEN';
  }

   viewChatBot(status: boolean) {
    setTimeout(() => {
      this.showChatBot = status;
    }, 490);
  }

   getChatBot() {
    this.showMinimize = false;
    this.chatBotSlider = false;
    this.botStatus = 'OPEN';
    this.viewChatBot(true);
  }

  getBotStatus(): boolean {
    if (this.botStatus === 'OPEN') {
      return false;
    } else if (this.botStatus === 'MINIMIZE') {
      setTimeout(() => {
        return true;
      }, 500);
    }
  }

   getChatBotUrl(): string {
    return this.getSafeUrl();
  }

}
