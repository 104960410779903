import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import intlTelInput from 'intl-tel-input';
import parsePhoneNumber, {isValidPhoneNumber} from 'libphonenumber-js';
import {NotificationService} from '../../../core/services/notification.service';
declare var intlTelInputUtils;

@Component({
    selector: 'app-phone-input',
    templateUrl: './phone-input.component.html',
    styleUrls: ['./phone-input.component.scss']
})
export class PhoneInputComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    @ViewChild('telInput', {static: false}) telInput;
    @Input() phoneNumber = '';
    @Input() initialCountryCode = '';
    @Input() cssClass = 'form-control';
    @Output() phoneNumberChange = new EventEmitter<object>();
    @Output() countryCodeChange = new EventEmitter<string>();
    iti;
    isInvalid = false;
    selectedCountryCode;
    selectedCountry;

    constructor(
        private readonly notifyService: NotificationService,
    ) { /* TODO document why this constructor is empty */ }
    ngOnInit() { /* TODO document why this method 'ngOnInit' is empty */
    }

    ngAfterViewInit() {
        this.iti = intlTelInput(this.telInput.nativeElement, {
            utilsScript: 'assets/scripts/utils.js',
            initialCountry: this.initialCountryCode,
            nationalMode: true,
            formatOnDisplay: true
        });
        this.selectedCountryCode = this.iti.getSelectedCountryData().dialCode;
        this.selectedCountry = this.iti.getSelectedCountryData();
        this.iti.getSelectedCountryData('in');
        console.log(this.phoneNumber);
        this.onPhoneNumberChange();
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    ngOnDestroy() {
        this.iti.destroy();
    }

    onFocus = () => {
        if (this.phoneNumber === undefined || this.phoneNumber === '') {
            const getCode = this.iti.getSelectedCountryData().dialCode;
            this.phoneNumber = '+' + getCode;
            console.log(this.phoneNumber);
        }
    }

    onBlur = () => {
        this.isInvalid = false;
        this.validatePhoneNumber();
    }

    onInputKeyPress = (event: KeyboardEvent) => {
        const allowedChars = /[0-9\+\-\ ]/;
        const allowedCtrlChars = /[axcv]/; // Allows copy-pasting
        const allowedOtherKeys = [
            'ArrowLeft',
            'ArrowUp',
            'ArrowRight',
            'ArrowDown',
            'Home',
            'End',
            'Insert',
            'Delete',
            'Backspace',
        ];

        if (
            !allowedChars.test(event.key) &&
            !(event.ctrlKey && allowedCtrlChars.test(event.key)) &&
            !allowedOtherKeys.includes(event.key)
        ) {
            event.preventDefault();
        }
    }

    formatIntlTelInput() {
        if (typeof intlTelInputUtils !== 'undefined') { // utils are lazy loaded, so must check
            const currentText = this.iti.getNumber(intlTelInputUtils.numberFormat.E164);
            if (typeof currentText === 'string') { // sometimes the currentText is an object :)
                this.iti.setNumber(currentText); // will autoformat because of formatOnDisplay=true
            }
        }
    }

    onPhoneNumberChange = () => {
        this.selectedCountryCode = this.iti.getSelectedCountryData().dialCode;
        this.selectedCountry = this.iti.getSelectedCountryData();
        console.log(this.selectedCountryCode, this.selectedCountry);
        this.formatIntlTelInput();
        const phoneNumber = parsePhoneNumber(this.phoneNumber);
        const isValid = isValidPhoneNumber(this.phoneNumber);
        console.log(phoneNumber);
        if (phoneNumber) {
            this.phoneNumberChange.emit({
                phoneNumber : phoneNumber.nationalNumber,
                countryCode : phoneNumber.countryCallingCode,
                isValid: isValid
            });
        }
    }

    validatePhoneNumber () {
        const isValid = isValidPhoneNumber(this.phoneNumber);
        this.isInvalid = !isValid;
        // if (this.isInvalid) {
        //     this.notifyService.showWarn('Warn', 'Kindly Enter Correct Mobile Number');
        // }
    }
}
