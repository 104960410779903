import { Injectable } from '@angular/core';
import {AuthenticationService} from "./authentication.service";

@Injectable({
  providedIn: 'root'
})
export class StoreProfileDetailsService {

  constructor(
      private authService: AuthenticationService,
  ) { }

  skew = this.authService.getSkewCode();

  storeProfileFieldList = {
    //pos
    742: [
      {fieldName: 'outletName', mandatory: true},
      {fieldName: 'outletId', mandatory: true},
      {fieldName: 'vertical', mandatory: true},
      {fieldName: 'baseProduct', mandatory: true},
      {fieldName: 'languageType', mandatory: true},
      {fieldName: 'posUrl', mandatory: true},
      {fieldName: 'buildingName', mandatory: false},
      {fieldName: 'streetName', mandatory: true},
      {fieldName: 'locality', mandatory: false},
      {fieldName: 'city', mandatory: true},
      {fieldName: 'district', mandatory: true},
      {fieldName: 'state', mandatory: true},
      {fieldName: 'country', mandatory: true},
      {fieldName: 'pinCode', mandatory: true},
      {fieldName: 'latitude', mandatory: true},
      {fieldName: 'longitude', mandatory: true},
      {fieldName: 'currency', mandatory: true},
      {fieldName: 'socialMedia', mandatory: false},
      {fieldName: 'shopImageUrl', mandatory: false},
      {fieldName: 'shopName', mandatory: true},
    ],
    //OrderEasy Standalone
    917: [
      {fieldName: 'vertical', mandatory: true},
      {fieldName: 'languageType', mandatory: true},
      {fieldName: 'buildingName', mandatory: false},
      {fieldName: 'streetName', mandatory: true},
      {fieldName: 'locality', mandatory: false},
      {fieldName: 'city', mandatory: true},
      {fieldName: 'district', mandatory: true},
      {fieldName: 'state', mandatory: true},
      {fieldName: 'country', mandatory: true},
      {fieldName: 'pinCode', mandatory: true},
      {fieldName: 'latitude', mandatory: true},
      {fieldName: 'longitude', mandatory: true},
      {fieldName: 'currency', mandatory: true},
      {fieldName: 'socialMedia', mandatory: false},
      {fieldName: 'shopImageUrl', mandatory: false},
      {fieldName: 'shopName', mandatory: true},
    ],
    //MarketPlace
    910: [
      {fieldName: 'shopName', mandatory: true},
      {fieldName: 'vertical', mandatory: true},
      {fieldName: 'description', mandatory: false},
      {fieldName: 'shopImageUrl', mandatory: false},
      {fieldName: 'panNumber', mandatory: true},
      {fieldName: 'panAttachments', mandatory: false},
      {fieldName: 'fssaiNumber', mandatory: true},
      {fieldName: 'fssaiAttachments', mandatory: false},
      {fieldName: 'gstNumber', mandatory: true},
      {fieldName: 'gstAttachments', mandatory: false},
      {fieldName: 'accountName', mandatory: true},
      {fieldName: 'ifscCode', mandatory: true},
      {fieldName: 'accountNo', mandatory: true},
      {fieldName: 'bankName', mandatory: true},
      {fieldName: 'chequeNo', mandatory: true},
      {fieldName: 'chequeAttachments', mandatory: false},
      {fieldName: 'languageType', mandatory: true},
      {fieldName: 'buildingName', mandatory: false},
      {fieldName: 'streetName', mandatory: true},
      {fieldName: 'locality', mandatory: false},
      {fieldName: 'city', mandatory: true},
      {fieldName: 'district', mandatory: true},
      {fieldName: 'state', mandatory: true},
      {fieldName: 'country', mandatory: true},
      {fieldName: 'pinCode', mandatory: true},
      {fieldName: 'stdCode', mandatory: true},
      {fieldName: 'latitude', mandatory: true},
      {fieldName: 'longitude', mandatory: true},
      {fieldName: 'contactName', mandatory: true},
      {fieldName: 'contactPhone', mandatory: true},
      {fieldName: 'contactEmail', mandatory: true},
      {fieldName: 'contactMobile', mandatory: false},
      {fieldName: 'availabilityDays', mandatory: true},
      {fieldName: 'storeTiming', mandatory: true},
      {fieldName: 'addClosingDays', mandatory: false},
      {fieldName: 'deliveryDays', mandatory: true},
      {fieldName: 'orderFulfilment', mandatory: true},
      {fieldName: 'radius', mandatory: true},
      {fieldName: 'currency', mandatory: true},
      {fieldName: 'longDescription', mandatory: true},
      {fieldName: 'isPanServiceable', mandatory: true},
      {fieldName: 'logisticsDeliveryType', mandatory: true},
      {fieldName: 'standardDeliveryType', mandatory: true},
    ],
    1003: [
      {fieldName: 'shopName', mandatory: true},
      {fieldName: 'vertical', mandatory: true},
      {fieldName: 'description', mandatory: false},
      {fieldName: 'shopImageUrl', mandatory: false},
      {fieldName: 'panNumber', mandatory: true},
      {fieldName: 'panAttachments', mandatory: false},
      {fieldName: 'fssaiNumber', mandatory: true},
      {fieldName: 'fssaiAttachments', mandatory: false},
      {fieldName: 'gstNumber', mandatory: true},
      {fieldName: 'gstAttachments', mandatory: false},
      {fieldName: 'accountName', mandatory: true},
      {fieldName: 'ifscCode', mandatory: true},
      {fieldName: 'accountNo', mandatory: true},
      {fieldName: 'bankName', mandatory: true},
      {fieldName: 'chequeNo', mandatory: true},
      {fieldName: 'chequeAttachments', mandatory: false},
      {fieldName: 'languageType', mandatory: true},
      {fieldName: 'buildingName', mandatory: false},
      {fieldName: 'streetName', mandatory: true},
      {fieldName: 'locality', mandatory: false},
      {fieldName: 'city', mandatory: true},
      {fieldName: 'district', mandatory: true},
      {fieldName: 'state', mandatory: true},
      {fieldName: 'country', mandatory: true},
      {fieldName: 'pinCode', mandatory: true},
      {fieldName: 'stdCode', mandatory: true},
      {fieldName: 'latitude', mandatory: true},
      {fieldName: 'longitude', mandatory: true},
      {fieldName: 'contactName', mandatory: true},
      {fieldName: 'contactPhone', mandatory: true},
      {fieldName: 'contactEmail', mandatory: true},
      {fieldName: 'contactMobile', mandatory: false},
      {fieldName: 'availabilityDays', mandatory: true},
      {fieldName: 'storeTiming', mandatory: true},
      {fieldName: 'addClosingDays', mandatory: false},
      {fieldName: 'deliveryDays', mandatory: true},
      {fieldName: 'orderFulfilment', mandatory: true},
      {fieldName: 'radius', mandatory: true},
      {fieldName: 'currency', mandatory: true},
      {fieldName: 'longDescription', mandatory: true},
      {fieldName: 'isPanServiceable', mandatory: true},
      {fieldName: 'logisticsDeliveryType', mandatory: true},
      {fieldName: 'standardDeliveryType', mandatory: true},
    ],
    //Food Aggregator
    926: [
      {fieldName: 'outletName', mandatory: true},
      {fieldName: 'vertical', mandatory: true},
      {fieldName: 'outletId', mandatory: true},
      {fieldName: 'baseProduct', mandatory: true},
      {fieldName: 'posUrl', mandatory: true},
      {fieldName: 'buildingName', mandatory: false},
      {fieldName: 'streetName', mandatory: true},
      {fieldName: 'locality', mandatory: false},
      {fieldName: 'city', mandatory: true},
      {fieldName: 'district', mandatory: true},
      {fieldName: 'state', mandatory: true},
      {fieldName: 'country', mandatory: true},
      {fieldName: 'pinCode', mandatory: true},
      {fieldName: 'stdCode', mandatory: true},
      {fieldName: 'latitude', mandatory: true},
      {fieldName: 'longitude', mandatory: true},
      {fieldName: 'currency', mandatory: true},
      {fieldName: 'shopImageUrl', mandatory: false},
      {fieldName: 'longDescription', mandatory: false},
      {fieldName: 'shopName', mandatory: true},
    ],
    //ONDC
    923: [
      {fieldName: 'shopName', mandatory: true},
      {fieldName: 'vertical', mandatory: true},
      {fieldName: 'description', mandatory: false},
      {fieldName: 'shopImageUrl', mandatory: true},
      {fieldName: 'panNumber', mandatory: true},
      {fieldName: 'panAttachments', mandatory: false},
      {fieldName: 'fssaiNumber', mandatory: true},
      {fieldName: 'fssaiAttachments', mandatory: false},
      {fieldName: 'gstNumber', mandatory: true},
      {fieldName: 'gstAttachments', mandatory: false},
      {fieldName: 'accountName', mandatory: true},
      {fieldName: 'ifscCode', mandatory: true},
      {fieldName: 'accountNo', mandatory: true},
      {fieldName: 'bankName', mandatory: true},
      {fieldName: 'chequeNo', mandatory: true},
      {fieldName: 'chequeAttachments', mandatory: false},
      {fieldName: 'buildingName', mandatory: true},
      {fieldName: 'streetName', mandatory: true},
      {fieldName: 'locality', mandatory: true},
      {fieldName: 'city', mandatory: true},
      {fieldName: 'district', mandatory: true},
      {fieldName: 'state', mandatory: true},
      {fieldName: 'country', mandatory: true},
      {fieldName: 'pinCode', mandatory: true},
      {fieldName: 'stdCode', mandatory: true},
      {fieldName: 'latitude', mandatory: true},
      {fieldName: 'longitude', mandatory: true},
      {fieldName: 'contactName', mandatory: true},
      {fieldName: 'contactPhone', mandatory: true},
      {fieldName: 'contactEmail', mandatory: true},
      {fieldName: 'contactMobile', mandatory: false},
      {fieldName: 'availabilityDays', mandatory: true},
      {fieldName: 'storeTiming', mandatory: true},
      {fieldName: 'addClosingDays', mandatory: false},
      {fieldName: 'deliveryDays', mandatory: true},
      {fieldName: 'orderFulfilment', mandatory: true},
      {fieldName: 'radius', mandatory: true},
      {fieldName: 'longDescription', mandatory: true},
      {fieldName: 'isPanServiceable', mandatory: true},
      {fieldName: 'logisticsDeliveryType', mandatory: true},
      {fieldName: 'standardDeliveryType', mandatory: true},
    ]
  };
  storeProfileGroupList = {
    742: [{groupName: 'shopDetails'},
      {groupName: 'posDetails'},
      {groupName: 'addressDetails'},
      {groupName: 'additionalDetails'},
      {groupName: 'socialMedia'},
    ],
    //OrderEasy Standalone
    917: [{groupName: 'shopDetails'},
      {groupName: 'addressDetails'},
      {groupName: 'additionalDetails'},
      {groupName: 'socialMedia'},
    ],
    910:  [{groupName: 'shopDetails'},
      {groupName: 'bankDetails'},
      {groupName: 'addressDetails'},
      {groupName: 'customerCareDetails'},
      {groupName: 'workingHours'},
      {groupName: 'shippingDetails'},
      {groupName: 'additionalDetails'},
    ],
    1003:  [{groupName: 'shopDetails'},
      {groupName: 'bankDetails'},
      {groupName: 'addressDetails'},
      {groupName: 'customerCareDetails'},
      {groupName: 'workingHours'},
      {groupName: 'shippingDetails'},
      {groupName: 'additionalDetails'},
    ],
    926: [{groupName: 'shopDetails'},
      {groupName: 'posDetails'},
      {groupName: 'addressDetails'},
      {groupName: 'additionalDetails'},
    ],
    923: [{groupName: 'shopDetails'},
      {groupName: 'bankDetails'},
      {groupName: 'addressDetails'},
      {groupName: 'customerCareDetails'},
      {groupName: 'workingHours'},
      {groupName: 'shippingDetails'},
      {groupName: 'additionalDetails'},
    ]
  };

  showField(fieldName) {
    const result = this.setSkewCode().filter(field => {
      return (field.fieldName === fieldName);
    });
    return !!(result && result.length);
  }

  public showGroupName(groupName) {
    const result = this.setGroupName().filter(field => {
      return (field.groupName === groupName);
    });
    return !!(result && result.length);
  }

  public mandatoryField(fieldName) {
    const result = this.setSkewCode().filter(field => {
      return (field.fieldName === fieldName);
    });
    return !!(result.length && result[0].mandatory);
  }

  public setSkewCode() {
    let skewList = [];
    switch (true) {
      case this.skew === '917' && this.authService.getMultiAddonDetails('923') :
        return skewList = [...this.storeProfileFieldList[923], {fieldName: 'socialMedia', mandatory: false}];
      case this.skew === '917' && this.authService.getMultiAddonDetails('926') :
        return skewList = [...this.storeProfileFieldList[926], {fieldName: 'socialMedia', mandatory: false}];
      case this.skew === '742' && this.authService.getMultiAddonDetails('926') :
        return skewList = [...this.storeProfileFieldList[926], {fieldName: 'socialMedia', mandatory: false}];
      case this.skew === '936':
        return skewList = this.storeProfileFieldList[923];
      case this.skew === '742':
        return skewList = this.storeProfileFieldList[742];
      case this.skew === '917':
        return skewList = this.storeProfileFieldList[917];
      case this.skew === '910':
        return skewList = this.storeProfileFieldList[910];
      case this.skew === '1003':
        return skewList = this.storeProfileFieldList[1003];
      case this.skew === '926':
        return skewList = this.storeProfileFieldList[926];
      case this.skew === '923':
        return skewList = this.storeProfileFieldList[923];
      default:
        return skewList = this.storeProfileFieldList[917];
    }
  }
  public setGroupName() {
    let skewList = [];
    switch (true) {
      case this.skew === '917' && this.authService.getMultiAddonDetails('923') :
        return skewList = [...this.storeProfileGroupList[923], {groupName: 'socialMedia'}];
      case this.skew === '917' && this.authService.getMultiAddonDetails('926') :
        return skewList = [...this.storeProfileGroupList[926], {groupName: 'socialMedia'}];
      case this.skew === '742' && this.authService.getMultiAddonDetails('926') :
        return skewList = [...this.storeProfileGroupList[926], {groupName: 'socialMedia'}];
      case this.skew === '936':
        return skewList = [...this.storeProfileGroupList[923]];
      case this.skew === '742':
        return skewList = this.storeProfileGroupList[742];
      case this.skew === '917':
        return skewList = this.storeProfileGroupList[917];
      case this.skew === '910':
        return skewList = this.storeProfileGroupList[910];
      case this.skew === '1003':
        return skewList = this.storeProfileGroupList[1003];
      case this.skew === '926':
        return skewList = this.storeProfileGroupList[926];
      case this.skew === '923':
        return skewList = this.storeProfileGroupList[923];
      default:
        return skewList = this.storeProfileGroupList[917];
    }
  }
}
