import {ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {EcommerceService} from '../../../modules/integration/ecommerce/ecommerce.service';
import {NotificationService} from '../../../core/services/notification.service';
import {SharedService} from '../../service/shared.service';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {UiCommonService} from '../../service/uiCommon.service';
import {DOCUMENT} from '@angular/common';
import {Subscription} from 'rxjs';
import {
    StandaloneOutletConfigComponent
} from '../../../modules/integration/ecommerce/pages/configuration/standalone-outlet-config/standalone-outlet-config.component';
import {MatDrawer} from "@angular/material/sidenav";
import {MatTableDataSource} from "@angular/material/table";

@Component({
    selector: 'app-api-integration-setup',
    templateUrl: './api-integration-setup.component.html',
    styleUrls: ['./api-integration-setup.component.scss']
})
export class APIIntegrationSetupComponent implements OnInit {
    private subscription: Subscription;
    selectedOutletID = this.authService.getSelectedOutletId();
    visibility_off = true;
    visibility_on = false;
    drawer: MatDrawer;
    clientIdList: MatTableDataSource<any>;
    @ViewChild(StandaloneOutletConfigComponent, {static: false}) private standaloneOutletConfigComponent: StandaloneOutletConfigComponent;

    constructor(
        private ecomService: EcommerceService,
        private notifyService: NotificationService,
        private sharedService: SharedService,
        private authService: AuthenticationService,
        private changeDetectorRef: ChangeDetectorRef,
        private uiCommonService: UiCommonService,
        @Inject(DOCUMENT) private document: any
    ) {
        this.subscription = this.sharedService.getOutletId().subscribe(
            event => {
                this.selectedOutletID = this.authService.getSelectedOutletId();
            });
    }

    ngOnInit() {
        console.log('inside api') ;
        this.getClientDetails();
    }

    openSideBar(drawer) {
        this.drawer = drawer;
        this.drawer.open();
    }

    closeSideBar() {
        this.drawer.close();
    }

    generateClientAndSecretId() {
        this.ecomService.generateClientAndSecretId(this.authService.getCustomerId()).subscribe(
            response => {
                if (response.status) {
                    this.getClientDetails();
                    this.notifyService.showSuccess('Success', response.message);
                }
            },
            (error) => {
                this.notifyService.showWarn('Error', error);
            },
            () => {
            }
        );
    }

    getClientDetails() {
        this.ecomService.getClientDetails(this.authService.getCustomerId()).subscribe(
            response => {
                this.clientIdList = response;
                console.log(response);
            },
            (error) => {
                this.notifyService.showWarn('Error', error);
            },
            () => {
            }
        );
    }

    visiableOnFn() {
        this.visibility_on = false;
        this.visibility_off = true;
    }

    visiableOffFn() {
        this.visibility_on = true;
        this.visibility_off = false;
    }

    // Copy text clipboard
    copyTextClipBoard(data) {
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = data;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.notifyService.showSuccess('Success', `Copied Successfully`);
    }
}
