import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CommonModule} from '@angular/common';
import {LogoutComponent} from './modules/logout/logout.component';
import { SurveySalesInvoiceComponent } from './shared/components/survey-sales-invoice/survey-sales-invoice.component';

const routes: Routes = [
  { path: 'login', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule) },
  { path: 'Integration', loadChildren: () => import('./modules/integration/integration.module').then(m => m.IntegrationModule) },
  { path: 'logout', component: LogoutComponent },
  { path: 'zs/:hash', component: SurveySalesInvoiceComponent },
  { path: '**', redirectTo: 'login' },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
