import {Component, ElementRef, HostListener, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {OutletDetail} from '../../../core/models';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {NotificationService} from '../../../core/services/notification.service';
import {DeliveryService} from '../../../modules/integration/delivery-web-app/delivery-web-app.service';
import {LoginService} from '../../../modules/login/login.service';
import {SharedService} from '../../service/shared.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-outlet-selection',
  templateUrl: './outlet-selection.component.html',
  styleUrls: ['./outlet-selection.component.scss']
})
export class OutletSelectionComponent implements OnInit {

  firstFormGroup: FormGroup;
  outletDetails: OutletDetail[];
  selectedOutletID: any;
  selectedOutletName = '';
  showHideOutletList = false;
  hideOutletTab = false;
  tabLabel = 'Store Availability';
  private subscription: Subscription;
  @HostListener('document:click', ['$event', '$event.target'])
  onClick(event: MouseEvent, targetElement: HTMLElement) {
    this.hideOutletDropDown();
    if (!targetElement) {
      return;
    }

    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.showHideOutletList = false;
    }
  }

  constructor(
    private _formBuilder: FormBuilder,
    private loginService: LoginService,
    private notifyService: NotificationService,
    private elementRef: ElementRef,
    private sharedService: SharedService,
    private authService: AuthenticationService,
    private deliveryappService: DeliveryService,
  ) { }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      outlet: [],
    });
    this.subscription = this.sharedService.getFoodAggTabLabel().subscribe(value => {
      this.tabLabel = value;
      this.hideOutletDropDown();
    });
   this.hideOutletDropDown();
      this.getOutletList();

  }

  async getOutletList() {
    const formObj = {
      'integrationAccountId': this.authService.getServiceUserId(),
      'roleId': this.authService.getRoleId(),
      'skewCode': this.authService.getSkewCode(),
      'vendor': this.authService.getSkewName(),
      'authId': this.authService.getAuthId(),
      'authEmpId': this.authService.getAuthEmpId(),
    };

    this.loginService.getOutletList(formObj).subscribe(
      outlets => {
        if (this.authService.getRoleId() === 1) {
          this.outletDetails = outlets;
        } else {
          this.outletDetails = outlets.filter(element => element.mappingStatus);
        }
        for (let i = 0; i < this.outletDetails.length; i++) {
          this.outletDetails[i].outletId = this.outletDetails[i].outletId !== undefined ? this.outletDetails[i].outletId : 1;
        }
        if (this.outletDetails.length > 0) {
          this.getSelectedOutlet(this.outletDetails[0], false);
        }
      },
      (error) => {
        this.notifyService.showError('Error', error);
      },
      () => {
      }
    );
  }

  async getSelectedOutlet(data, boolVal) {
    if (boolVal) {
      localStorage.removeItem('selectedOutletId');
      localStorage.removeItem('selectedOutletName');
    }
    if (localStorage.getItem('selectedOutletId')) {
      this.selectedOutletID = localStorage.getItem('selectedOutletId');
    }
    if (localStorage.getItem('selectedOutletName')) {
      this.selectedOutletName = localStorage.getItem('selectedOutletName');
    } else {
      this.selectedOutletID = data.outletId;
      if (this.authService.getSkewCode() === '538' || this.authService.getSkewCode() === '918' || this.authService.getSkewCode() === '920') {
        this.selectedOutletID = data.custId;
      }
      this.selectedOutletName = data.shopName;
      localStorage.setItem('outletDetails', window.btoa(unescape(encodeURIComponent(JSON.stringify(this.outletDetails)))));
      localStorage.setItem('selectedOutletId', this.selectedOutletID);
      localStorage.setItem('selectedOutletName', this.selectedOutletName);
      localStorage.setItem('selectedOutlet', window.btoa(unescape(encodeURIComponent(JSON.stringify(data)))));
    }
    this.showHideOutletList = false;
    this.sharedService.setOutletIdVal(this.selectedOutletID)
    if (this.authService.getSkewCode() === "538") {
      this.deliveryappService.setBaseProduct(data?.baseProduct);
    }
  }

  // Show / Hide outlet name
  showHideShoName() {
    this.showHideOutletList = !this.showHideOutletList;
  }
hideOutletDropDown() {
  if (window.location.pathname.includes('config/ordereasy-store-profile') || window.location.pathname.includes('ecommerce/market-place')) {
    if(window.location.pathname.includes('config/ordereasy-store-profile') && (this.authService.getMultiAddonDetails('935') ||  this.authService.getMultiAddonDetails('936')) ){
      this.hideOutletTab = false ;
    } else {
      this.hideOutletTab = true;
    }
  } else if (this.authService.getSkewCode() === '742') {
    if (window.location.pathname.includes('order-easy/app-builder') || window.location.pathname.includes('order-easy/dynamic-menu') || window.location.pathname.includes('app-request')
        || window.location.pathname.includes('config/notification-admin') || window.location.pathname.includes('config/smsEmailConfig') || window.location.pathname.includes('config/notification-broadcast') || window.location.pathname.includes('config/notification-configuration')
        || window.location.pathname.includes('config/deliveryConfiguration') || window.location.pathname.includes('bulk-upload-image') || window.location.pathname.includes('sr-report') || window.location.pathname.includes('config/commonConfig')
        || window.location.pathname.includes('config/ordereasyConfigReport') || window.location.pathname.includes('config/OrderEasy') || window.location.pathname.includes('ecommerce/employeeMaster') || window.location.pathname.includes('ecommerce/roleMaster')
        || window.location.pathname.includes('ecommerce/manual-sync') || window.location.pathname.includes('ecommerce/splashScreen') || window.location.pathname.includes('ecommerce/chat-bot') || window.location.pathname.includes('ecommerce/sr-dashboard/ordereasy/ecommerce') || window.location.pathname.includes('ecommerce/manage-subscription')) {
      this.hideOutletTab = true;
    } else {if (window.location.pathname.includes('config/ordereasy-store-profile') || window.location.pathname.includes('ecommerce/market-place')) {
      this.hideOutletTab = true;
    }
      this.hideOutletTab = false;
    }
  } else if (this.authService.getSkewCode() === '811') {
    this.hideOutletTab = false;
  } else if (this.authService.getSkewCode() === '535' && window.location.pathname.includes('ecommerce/sales-order')) {
    this.hideOutletTab = false;
  } else if (this.authService.getSkewCode() === '745' && window.location.pathname.includes('ecommerce/item-report')) {
    this.hideOutletTab = false;
  } else if (this.authService.getSkewCode() === '538') {
    if (window.location.pathname.includes('delivery-app/outlet-configuration') || window.location.pathname.includes('delivery-app/sr-dashboard/DELIVERYSERVICE/delivery-app') || window.location.pathname.includes('ecommerce/employeeMaster') || window.location.pathname.includes('ecommerce/roleMaster')
        || window.location.pathname.includes('ecommerce/sr-report/1002/5/DELIVERYSERVICE') || window.location.pathname.includes('ecommerce/sr-report/1003/5/DELIVERYSERVICE') || window.location.pathname.includes('ecommerce/sr-report/1001/5/DELIVERYSERVICE')
        || window.location.pathname.includes('delivery-app/data-sync') || window.location.pathname.includes('delivery-app/configuration')) {
      this.hideOutletTab = true;
    } else {
      this.hideOutletTab = false;
    }
  } else if (this.authService.getSkewCode() === '926') {
    this.hideOutletTab = false;
  } else if ( this.authService.getMultiAddonDetails('935') ||  this.authService.getMultiAddonDetails('936') ) {
    this.hideOutletTab = false;
  }
  else {
    this.hideOutletTab = true;
  }
  if ((this.authService.getSkewCode() === '926' || this.authService.getSkewCode() === '742') && this.tabLabel === 'Menu Publish') {
    this.hideOutletTab = true;
  }
}
}
