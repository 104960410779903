import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {EcommerceService} from '../../../../modules/integration/ecommerce/ecommerce.service';
import {NotificationService} from '../../../../core/services/notification.service';
import {MatDrawer} from "@angular/material/sidenav";
import {DateAdapter} from "@angular/material/core";

@Component({
  selector: 'app-store-aggregator-collection',
  templateUrl: './store-aggregator-collection.component.html',
  styleUrls: ['./store-aggregator-collection.component.scss']
})
export class StoreAggregatorCollectionComponent implements OnInit {


  @Input() storeDetails: any;
  drawer: MatDrawer;
  pageIndex = 1;
  pageSize = 5;
  taxTypes = [{name : 'Amount', value: 'amount'}, {name : 'Percentage', value: 'percentage'}];
  storeList: any[];
  totalRecords: any;
  selectedStore: any;
  tabEvent: any;
  nextCollectionDate = new Date();
  collectionDetails: FormGroup;
  lastNextCollectionDate: any;
  skippedDays: number;

  constructor(
      public readonly authenticationService: AuthenticationService,
      private readonly ecommerceService: EcommerceService,
      private readonly notificationService: NotificationService,
      private readonly dateAdapter: DateAdapter<Date>,
      private readonly formBuilder: FormBuilder,
  ) {
    this.dateAdapter.setLocale('en-GB');
    this.collectionDetails = this.formBuilder.group({
      collectedAmount: ['', Validators.required],
      nextCollectionDate: [new Date(), Validators.required],
      paymentMode: ['', Validators.required],
      paymentId: ['', Validators.required],
      remarks: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.getCollectionDetails();
  }

  openDrawer(drawer: MatDrawer) {
    this.drawer = drawer;
    this.drawer.toggle();
  }

  closeDrawer() {
    this.collectionDetails.reset();
    this.drawer.close();
  }

  getCollectionDetails() {
    const body = {
      'userId': this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
      'outletId': this.authenticationService.getOutlet(),
      'filter': `storeUserId=${this.authenticationService.getSkewCode() === '910' ? this.storeDetails.shopId : this.authenticationService.getServiceUserId()}`,
      'sort': `nextCollectionDate desc`,
      'pageIndex': this.pageIndex,
      'pageSize': this.pageSize,
      'isMarketPlace': this.authenticationService.getSkewCode() === '910',
    };
    this.ecommerceService.getCollectionDetails(body).subscribe(
        res => {
          if (res) {
            this.storeList = res.collectionMasterList;
            this.lastNextCollectionDate = res.lastNextCollectionDate;
            console.log(res);
            this.totalRecords = res.totalRecords;
            this.skippedDays = res.days;
          } else {
            this.notificationService.showWarn('Warn', res.message);
          }
        },
        err => {
          this.notificationService.showWarn('Warn', err.message);
        }
    );
  }

  getStatus(status: any) {
    status = status.toLowerCase();
    if (status === 'pending') {
      return 'waiting for approval';
    } else if (status === 'rejected') {
      return 'rejected';
    } else {
      return status;
    }
  }

  pageEvents(event) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    this.getCollectionDetails();
  }

  saveCollectionDetails() {
    console.log(this.collectionDetails.value, this.nextCollectionDate);
    const collectionDate = moment(this.nextCollectionDate).utc().format('YYYY-MM-DD HH:MM');
    console.log(collectionDate, this.storeDetails);
    if (!this.collectionDetails.valid) {
      this.notificationService.showWarn('Warn', 'Please Fill All Fields');
    } else {
    const body = {
      'userId': this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
      'outletId': this.authenticationService.getOutlet(),
      'storeUserId': this.storeDetails.shopId,
      'shopName': this.storeDetails.shopName,
      ...this.collectionDetails.value
    };
    body ['nextCollectionDate'] = moment(this.collectionDetails.get('nextCollectionDate').value).format('YYYY-MM-DD HH:MM:ss');
    this.ecommerceService.saveCollectionDetails(body).subscribe(
        res => {
          if (res && res.status) {
            this.notificationService.showSuccess('Success', res.message);
            this.closeDrawer();
            this.getCollectionDetails();
          } else {
            this.notificationService.showWarn('Warn', res.message);
          }
        },
        error => {
          this.notificationService.showWarn('Warn', error);
        }
    );
    }
  }
}

