import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class SharedService {
  private recentOrders: Subject<any> = new Subject<any>();
  private pendingOrderCount: Subject<number> = new Subject<number>();
  private unsyncOrders: Subject<number> = new Subject<number>();
  private reConnected: Subject<boolean> = new Subject<boolean>();
  public liveCoordinates: Subject<any> = new Subject<any>();
  public getAllStatusCount: Subject<any> = new Subject<any>();
  private assureCare: Subject<boolean> = new Subject<boolean>();
  setOutletId: Subject<any> = new Subject<any>();
  private profileImageUrl: Subject<any> = new Subject<any>();
  private pendingNotificationCount: Subject<number> = new Subject<number>();
  private notificationOrderNo: Subject<number> = new Subject<number>();
  private selectedMenuId = new BehaviorSubject(null);
  shareSelectedMenuId = this.selectedMenuId.asObservable();
  private menuList = new BehaviorSubject(null);
  shareMenuList = this.menuList.asObservable();
  private companyCode: Subject<any> = new Subject<any>();
  announcementAvailabe: Subject<any> = new Subject<any>();
  feedbackAvailabe: Subject<any> = new Subject<any>();
  allRead: Subject<any> = new Subject<any>();
  private matDrawerOpened: Subject<any> = new Subject<any>();
  private sideNavOpened: Subject<any> = new Subject<any>();
  private hover: Subject<any> = new Subject<any>();
  private openModal: Subject<any> = new Subject<any>();
  private addonList: Subject<any> = new Subject<any>();
  private notificationClose: Subject<any> = new Subject<any>();
  private liveChatClose: Subject<any> = new Subject<any>();
  private reloadMenu: Subject<boolean> = new Subject<boolean>();
  private openModalValue: any;
  confetti: Subject<any> = new Subject<any>();
  public menus: any;
  tabLabel: Subject<any> = new Subject<any>();
  constructor() {}

  setAllStatusCount(count: any) {
    this.getAllStatusCount.next(count);
  }

  getStatusCount(): Observable<any> {
    return this.getAllStatusCount;
  }

  setAssureCare(assureCare: any) {
    this.assureCare.next(assureCare);
  }

  getAssureCare(): Observable<boolean> {
    return this.assureCare;
  }

  setRecentOrders(orders: any) {
    this.recentOrders.next(JSON.stringify(orders));
  }

  getRecentOrders(): Observable<any> {
    return this.recentOrders;
  }

  setPendingOrderCount(count: number) {
    this.pendingOrderCount.next(count);
  }

  getPendingOrderCount(): Observable<number> {
    return this.pendingOrderCount;
  }

  setUnsyncOrders(count: number) {
    this.unsyncOrders.next(count);
  }

  getUnsyncOrders(): Observable<number> {
    return this.unsyncOrders;
  }

  setReconnected(isconnected: boolean) {
    this.reConnected.next(isconnected);
  }

  getReconnected(): Observable<boolean> {
    return this.reConnected;
  }

  setLiveCoordinates(coordinates: Object) {
    this.liveCoordinates.next(coordinates);
  }

  setOutletIdVal(data) {
    this.setOutletId.next(data);
    // this.setOutletId = data
  }

  getOutletId(): Observable<any> {
    return this.setOutletId;
  }

  setProfilePicUrl(url: string) {
    this.profileImageUrl.next(url);
  }

  setPendingNotificationCount(count: number) {
    this.pendingNotificationCount.next(count);
  }

  getPendingNotificationCount(): Observable<number> {
    return this.pendingNotificationCount;
  }

  setNotificationOrderNo(orderNo: any) {
    this.notificationOrderNo.next(orderNo);
  }

  getNotificationOrderNo(): Observable<number> {
    return this.notificationOrderNo;
  }

  setCompanyCodeChange(company: any) {
    this.companyCode.next(company);
  }

  getCompanyCodeChange(): Observable<any> {
    return this.companyCode;
  }

  setParentMenuId(menuId: string) {
    this.selectedMenuId.next(menuId);
  }

  setMenuList(menus: any) {
    this.menus = menus;
    this.menuList.next(menus);
  }

  getAnnouncementAvailable(): Observable<any> {
    return this.announcementAvailabe;
  }

  setAnnouncementAvailable(isAnnouncementAvailable: any) {
    this.announcementAvailabe.next(isAnnouncementAvailable);
  }

  getFeedbackAvailable(): Observable<any> {
    return this.feedbackAvailabe;
  }

  setFeedbackAvailable(isFeedbackAvailable: any) {
    this.feedbackAvailabe.next(isFeedbackAvailable);
  }

  setAllReadValue(allRead: any) {
    this.allRead.next(allRead);
  }

  getAllReadValue(): Observable<any> {
    return this.allRead;
  }

  setMatDrawerOpenStatus(matDrawerOpened: any) {
    this.matDrawerOpened.next(matDrawerOpened);
  }

  getMatDrawerOpenStatus(): Observable<any> {
    return this.matDrawerOpened;
  }

  setSideNavOpenStatus(sideNavOpened: any) {
    this.sideNavOpened.next(sideNavOpened);
  }

  getSideNavOpenStatus(): Observable<any> {
    return this.sideNavOpened;
  }

  setIsHover(hover: any) {
    this.hover.next(hover);
  }

  getIsHover(): Observable<any> {
    return this.hover;
  }

  setOpenModal(openModal) {
    console.log(openModal);
    this.openModal.next(openModal);
    this.openModalValue = openModal;
  }

  getOpenModal(): Observable<any> {
    return this.openModal;
  }

  getOpenModalValue() {
    return this.openModalValue;
  }

  setAddonList(addonList) {
    this.addonList.next(addonList);
  }

  getAddonList(): Observable<any> {
    return this.addonList;
  }

  setToolBarNotificationClose(notificationClose) {
    this.notificationClose.next(notificationClose);
  }

  getToolBarNotificationClose(): Observable<any> {
    return this.notificationClose;
  }

  setLiveChatClose(liveChatClose) {
    this.liveChatClose.next(liveChatClose);
  }

  getLiveChatClose(): Observable<any> {
    return this.liveChatClose;
  }

  setConfetti(confetti) {
    this.confetti.next(confetti);
  }

  getConfetti (): Observable<any> {
    return this.confetti;
  }

  setMenuReload(menuReload) {
    this.reloadMenu.next(menuReload);
  }

  getMenuReload (): Observable<any> {
    return this.reloadMenu;
  }

  setFoodAggTabLabel(name) {
    this.tabLabel.next(name);
  }

  getFoodAggTabLabel() {
    return this.tabLabel;
  }
}
