import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {EcommerceService} from '../../../../modules/integration/ecommerce/ecommerce.service';
import {NotificationService} from '../../../../core/services/notification.service';
import {MatDrawer} from "@angular/material/sidenav";
import {MatTabChangeEvent} from "@angular/material/tabs";

@Component({
    selector: 'app-aggregator-details',
    templateUrl: './aggregator-details.component.html',
    styleUrls: ['./aggregator-details.component.scss']
})
export class AggregatorDetailsComponent implements OnInit, OnChanges {


    @Input() refresh = false;
    tabLabel = 'all-stores';
    drawerTabLabel = 'storeDetails';
    storeList: any;
    totalRecords: any;
    paginationEvent: any;
    index: any;
    openModal: any;
    drawer: MatDrawer;
    storeDetails: any;
    pageSize = 5;
    pageIndex = 1;
    validStatus = ['approved', 'rejected'];
    aggregatorModal = false;
    selectedStore: any;
    requestRemarks: any;
    aggregatorDetailModal = false;
    selectedAggergator: any;
    publishList = [];

    constructor(
        private authenticationService: AuthenticationService,
        private ecommerceService: EcommerceService,
        private notifyService: NotificationService,
    ) {
    }

    ngOnInit() {
        this.getStoreAggregatorDetails();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.refresh) {
            this.ngOnInit();
        }
    }

    onTabChange(event) {
        this.tabLabel = event.tab.textLabel;
        console.log(this.tabLabel, event);
        if (this.tabLabel === 'store-details') {
            this.getProfileDetails(this.selectedAggergator);
        } else if (this.tabLabel === 'publish') {
            this.getPublishHistory();
        } else {
            console.log(this.tabLabel);
        }
    }

    getStoreAggregatorDetails(status?) {
        const body = {
            'userId': this.authenticationService.getServiceUserId(),
            'vendor': this.authenticationService.getSkewName(),
            'status': status ? status : '',
            'pageIndex': this.pageIndex,
            'pageSize': this.pageSize
        };
        this.ecommerceService.getAggregatorList(body).subscribe(
            res => {
                console.log(res);
                this.storeList = res.masterList;
                this.totalRecords = res.totalRecords;
            },
            err => {
                this.notifyService.showWarn('Warn', err.message);
            }
        );
    }

    calcWidth(ratings) {
        return ((ratings / 5) * 100);
    }

    pageEvents(event) {
        this.pageSize = event.pageSize;
        this.pageIndex = event.pageIndex + 1;
        this.getStoreAggregatorDetails();
    }

    openRemarksModal(store: any, index, event) {
        console.log(store.storeRemarks, index);
        this.index = index;
        this.openModal = true;
        event.stopPropagation();
    }

    closeModal(event) {
        this.openModal = false;
        event.stopPropagation();
    }

    openStoreDetails(store: any, drawer) {
        console.log(store);
        this.storeDetails = store;
        this.drawer = drawer;
        this.drawer.toggle();
    }

    onDrawerTabChange(event: MatTabChangeEvent) {
        this.drawerTabLabel = event.tab.textLabel;
        console.log(this.tabLabel, event);
    }

    closeDrawer() {
        this.drawer.toggle();
    }

    calcNoRatings(ratings: any) {
        return this.authenticationService.convertToFriendlyFormat(ratings, 4);
    }

    sendCancelRequest(store: any) {
        const body = {
            'userId': this.authenticationService.getServiceUserId(),
            'aggregatorId': store.aggregatorId,
            'vendor': this.authenticationService.getSkewName(),
            'outletId': this.authenticationService.getOutlet(),
            'customerId': this.authenticationService.getCustomerId(),
            'aggregatorName': store.aggregatorName,
            'aggregatoruserId': store.userId,
        };
        this.ecommerceService.sendCancelRequest(body).subscribe(
            response => {
                if (response && response.status) {
                    this.notifyService.showSuccess('Success', response.message);
                    this.getStoreAggregatorDetails();
                }
            },
            err => {
                this.notifyService.showWarn('Warn', err);
            }
        );
    }

    openRequestModal(store) {
        this.aggregatorModal = !this.aggregatorModal;
        this.requestRemarks = '';
    }


    getProfileDetails(store) {
        const body = {
            'userId': this.authenticationService.getServiceUserId(),
            'vendor': 'MarketPlace',
            'custId': store.customerId,
            'emailId': store.emailId,
            'aggregatorUserId' : store.userId
        };
        this.ecommerceService.getAggregatorDetails(body).subscribe(
            res => {
                console.log(res);
                this.selectedStore = res;
                this.selectedStore['userId'] = store.userId;
                this.selectedStore['custId'] = store.customerId;
                this.aggregatorDetailModal = !this.aggregatorModal;
            },
            error => {
                this.notifyService.showWarn('Warn', error);
            }
        );
    }

    closeAggregatorDetailModal() {
        this.aggregatorModal = false;
        this.aggregatorDetailModal = false;
    }

    sendRequestRemarks(event) {
        event.stopPropagation();
        const body = {
            'userId': this.authenticationService.getServiceUserId(),
            'vendor': this.authenticationService.getSkewName(),
            'outletId': Number(this.authenticationService.getOutlet()),
            'aggregatorUserId': this.selectedStore.userId,
            'requestRemarks': this.requestRemarks,
        };
        this.ecommerceService.sendRequestRemarks(body).subscribe(
            response => {
                if (response && response.status) {
                    this.notifyService.showSuccess('Success', response.message);
                    this.closeAggregatorDetailModal();
                } else {
                    this.notifyService.showWarn('Warn', response.message);
                }
            }
        );
    }

    openAggregatorDetailModal(store: any) {
        this.selectedAggergator = store;
        this.getProfileDetails(this.selectedAggergator);
    }

    getPublishHistory() {
        const body = {
            'userId': this.authenticationService.getServiceUserId(),
            'marketPlaceId': this.selectedStore.userId,
            'vendor': this.authenticationService.getSkewName(),
            'outletId':  Number(this.authenticationService.getOutlet())
        };
        this.ecommerceService.getPublishHistory(body).subscribe(
            response => {
                if (response) {
                    console.log(response);
                    this.publishList = response;
                } else {
                    this.notifyService.showWarn('Warn', response.message);
                }
            },
            error => {
                this.notifyService.showWarn('Warn', error);
            }
        );
    }
}
