import { Component, OnInit } from '@angular/core';
import { SurveyService } from '../../../core/services/survey.service';
import { ActivatedRoute, Route } from '@angular/router';

@Component({
  selector: 'app-survey-sales-invoice',
  templateUrl: './survey-sales-invoice.component.html',
  styleUrls: ['./survey-sales-invoice.component.scss']
})
export class SurveySalesInvoiceComponent implements OnInit {
  shopDetails: any;
  customerDetails: any;
  billDetails: any;
  productList = [];
  totalAmount = 0.00;
  subTotal = 0.00;
  gstSplitUP: any;
  showInvoice = false;
  surveyLink = '';
  errMsg = '';
  hash = '';


  constructor(public readonly surveyService: SurveyService,
    private readonly route: ActivatedRoute) { }

  async ngOnInit() {
    this.route.params.subscribe(params => {
      this.hash = (params['hash']);
      console.log(this.hash);
  });
    this.showInvoice = false;
    this.errMsg = '';
    this.surveyService.openInvoice(this.hash).subscribe(
      res => {
        if (res && res.status) {
          this.initializeData(res);
          this.calculateSubTotal();
          this.showInvoice = true;
        } else {
          this.showInvoice = false;
          this.errMsg = (res && res.message) ? res.message : 'Please try again later.';
        }
      },
      error => {
        this.showInvoice = false;
        this.errMsg = 'Please try again later.';
        console.error('Error loading invoice data', error);
      }
    );
  }

  initializeData(res) {
    const shopDetails = res;
    const cusDetails = res.sales[0].customers[0];
    const billDetails = res.sales[0];
    const productList = res.sales[0].products;
    this.surveyLink = res.surveyLink;

    this.shopDetails = {
      shopName: shopDetails.shopName,
      address: shopDetails.address,
      contactNumber: shopDetails.contactNumber,
      shopImageUrl: shopDetails.shopImageUrl,
      gstNumber: shopDetails.gstNumber
    };

    if(cusDetails) {
      this.customerDetails = {
        name: cusDetails.name,
        mobile: cusDetails.mobile
      };
    }

    if(billDetails) {
      this.billDetails = {
        billNo: billDetails.billNo,
        totalTaxAmount: billDetails.totalTaxAmount,
        roundOffAmount: billDetails.roundOffAmount,
        totalBillDiscountAmount: billDetails.totalBillDiscountAmount,
        totalItemDiscountAmount: billDetails.totalItemDiscountAmount,
        totalAmount: billDetails.totalAmount,
        createdAt: billDetails.createdAt
      };
    }

    this.productList = [...productList];
    this.gstSplitUP = this.groupGSTDetails(productList);
  }

  formattedAmount(amount, value) {
    return Number(amount).toFixed(value);
  }

  calculateTotalAmount(product) {
    return product.isInclusiveTax === 'Y'
      ? +(this.formattedAmount((product.price - product.taxAmount), 1)) * product.quantity
      : +(this.formattedAmount(product.price, 2)) * product.quantity;
  }

  calculateSubTotal() {
    this.subTotal = this.productList.reduce((sum, product) => {
      return sum + this.calculateTotalAmount(product);
    }, 0);
  }

  groupGSTDetails(productList) {
    if (productList) {
      const helper = {};
      return productList.reduce((r, o) => {
        const key = o.sgstPercentage;
        if (!helper[key]) {
          helper[key] = { ...o };
          r.push(helper[key]);
        } else {
          helper[key].sgstAmount += o.sgstAmount;
          helper[key].cgstAmount += o.cgstAmount;
          helper[key].igstAmount += o.igstAmount;
          helper[key].taxAmount += o.taxAmount;
        }
        return r;
      }, []);
    }
    return [];
  }

  openUrl(url: string): void {
    window.open(url, '_blank');
  }
}
