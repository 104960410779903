import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MapsAPILoader} from '@agm/core';
import {EcommerceService} from '../../../../modules/integration/ecommerce/ecommerce.service';
import {NotificationService} from '../../../../core/services/notification.service';
import {SharedService} from '../../../service/shared.service';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {LoginService} from '../../../../modules/login/login.service';
import {UiCommonService} from '../../../service/uiCommon.service';
import {ImageCompressService} from "../../../../core/services/image-compress.service";

declare var google;

@Component({
  selector: 'app-store-profile',
  templateUrl: './store-profile.component.html',
  styleUrls: ['./store-profile.component.scss']
})

export class StoreProfileComponent implements OnInit, OnChanges {
  storeDetails: any;
  showLocationError = false;
  private geoCoder;
  latitude: number;
  longitude: number;
  zoom: number;
  address: any;
  storeAddress: any;
  private map: any;
  isModalOpen = false;
  @Input() refresh = false;
  @Input() shopId = '';
  @Input() type = '';
  forTime = '00:00';
  toTime = '00:00';
  fromTimeFormat = 'am';
  toTimeFormat = 'am';
  timeFormat = ['am', 'pm'];
  isfromFormatOpen = false;
  istoFormatOpen = false;
  is24Format =  false;
  verticals = [{ id: 3, name: 'PHARMACY' }, { id: 1, name: 'SUPER-MARKET' }, { id: 2, name: 'RESTAURANT' }];
  deliveryTypes = [{name: 'Delivery Partner' }, { name: 'Own Delivery' }];
  paymentMethod = [{name: 'Pay on Delivery' }, { name: 'Pay Online' }];
  posType = [{name: 'Gofrugal' }, { name: 'Other' }];
  optionVertical = false;
  optionDelivery = false;
  optionPayment = false;
  optionCategory = false;
  selectedVertical: any;
  selectedDelivery: any;
  selectedPayment: any;
  selectedPos: any;
  days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  // variable
  storeName: any;
  storeCountry: any;
  storePincode: any;
  gstNo: any;
  panNo: any;
  ownerContact1: any;
  ownerContact2: any;
  // managerContact1: any;
  // managerContact2: any;
  // deliveryContact1: any;
  // deliveryContact2: any;
  supportContact1: any;
  supportContact2: any;
  selectedDays = [];
  imageUrl: string;
  selectedStatus: any;
  storeStatusRemarks: any;
  minRadius: any;
  selectedCategory: any;
  categories = [
      {id: 1, name: 'Atta Rice & Dal', checked: false
},
    {id: 2, name: 'Masala & Oil Products', checked: false
    },
    {id: 3, name: 'Snacks & Packaged Food', checked: false
    },
    {id: 4, name: 'Beverages', checked: false
    },
    {id: 5, name: 'Beauty & Personal Care', checked: false
    },
    {id: 6, name: 'Baby care & wellness', checked: false
    },
    {id: 7, name: 'Diary products & Eggs', checked: false
    },
    {id: 8, name: 'Fruits & Vegetables', checked: false
    },
    {id: 9, name: 'Health Care & Fitness', checked: false
    },
   ];

  // editor Config
  editorConfig = {
    editable: true,
    spellcheck: false,
    height: '8em',
    minHeight: '0',
    maxHeight: 'auto',
    // width: '',
    minWidth: '100%',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'About your profile (max 200 character)',
    defaultParagraphSeparator: 'yes',
    defaultFontName: '',
    defaultFontSize: '4',
    sanitize: true,
    toolbarPosition: 'top',
    uploadUrl: 'no',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'strikeThrough',
        'subscript',
        'superscript',
        'indent',
        'outdent',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };

  // Modal Options
  isDaysModalOpen = false;
  storeAvailiblity = '';

  // Store Profile
  storeProfileDetails = {
    shopName : '',
    emailId : '',
    contactNumber1: '',
    contactNumber2: '',
    panNo: '',
    gstNo: '',
    latitude: 0,
    longitude: 0,
    shopAddress: '',
    shopImage: '',
    description: '',
    availabilityDays: []
  };

  // Availiability
  applyForAll = false;
  selectedDay = {
    shopId : 0,
    outletId : 0,
    companyId : '1',
    day : '',
    shopOpensAt : '00:00',
    shopClosesAt : '00:00',
    shopClosesAt12hrs : '00:00 PM',
    storeAvailability : '',
    shopOpensAt12hrs : '00:00 AM'
  };
  prevSelectedDay: any;
  modalName: any;
  onlineStatus = false;



  constructor(
      private ecommerceService: EcommerceService,
      private notifyService: NotificationService,
      private sharedService: SharedService,
      private authService: AuthenticationService,
      private changeDetectorRef: ChangeDetectorRef,
      private imageCompressService: ImageCompressService,
      private loginService: LoginService,
      private uiCommonService: UiCommonService,
      private mapsAPILoader: MapsAPILoader
  ) { }

  ngOnInit() {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      if (this.storeProfileDetails.shopAddress) {
        console.log(this.storeProfileDetails);
        this.getLatLong(this.storeProfileDetails.shopAddress);
      } else {
        this.setCurrentLocation();
      }
    });
    this.getStoreDetails();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && !!changes.refresh) {
      this.ngOnInit();
    }
    if (changes && !!changes.shopId) {
      console.log(changes);
      this.ngOnInit();
    }
    if (this.type === 'marketplace') {
      this.editorConfig.editable = false;
    }
  }

  async onImageLoad(image) {
    return new Promise( resolve => {
      this.uiCommonService.showLoader('blocking');
      const fr = new FileReader;
      if ((Number(image.size) / 1024) > 5000) {
        resolve(true);
      } else {
        fr.onload = () => {
          const img = new Image;
          img.onload = () => {
            if (img.width < 100 || img.height < 100) {
              resolve(true);
            } else {
              resolve(false);
            }

          };
          img.src = fr.result as any;
        };
        fr.readAsDataURL(image);
      }
    });
  }

  async onUploadImage(image: FileList, event, type: string) {
    // if (this.imageEditableFlag === 'false') {
    //   this.notifyService.showWarn('Warning', 'Can\'t ADD image As \'Sync Image from POS\' is enabled. Please disable & try ');
    //   return;
    // }
    const fileType = image[0].type;
    console.log(fileType);
    if (fileType.match(/image\/*/) === null) {
      this.notifyService.showWarn('Warning', 'Only jpg, jpeg and png files are allowed.');
      return;
    }
    if (!(fileType === 'image/jpg' || fileType === 'image/jpeg' || fileType === 'image/png')) {
      this.notifyService.showWarn('Warning', 'Only jpg, jpeg and png files are allowed.');
      return;
    }
    if ((image[0].size / 1024 / 1024) > 5) {
      this.notifyService.showWarn('Warning', 'Kindly upload images less than 5 MB');
      return;
    }
    if (await this.onImageLoad(image[0])) {
      this.uiCommonService.hideLoader();
      this.notifyService.showWarn('Warn', 'Please Upload Image of dimensions minimum 100 X 100');
      return;
    }
    const base64Image: any = await this.imageCompressService.compressImage(image[0]);
    this.uiCommonService.hideLoader();
    await this.onProfileImageUpload(base64Image);
  }

  async onProfileImageUpload(base64Image) {
    const postJson = {
      'userId': '' + this.authService.getServiceUserId(),
      'vendor': this.authService.getSkewName(),
      'emailId': this.authService.getEmailId(),
      'profileImage': base64Image,
    };

    this.loginService.profilePicUpload(postJson).subscribe(
        (data) => {
          console.log(data);
          if (data && data.status) {
            this.storeProfileDetails.shopImage = data.profileImageUrl;
          }
        },
        (err) => {
          this.notifyService.showError('Error', err);
        });
  }

  getStoreDetails() {
    const body = {
      'userId' : this.authService.getServiceUserId(),
      'vendor' : this.authService.getSkewName(),
      'outletId' : this.authService.getSelectedOutletId()
    };
    if (this.shopId) {
      body['userId'] = Number(this.shopId);
      body['aggregatorUserId'] = Number(this.authService.getServiceUserId());
      body['vendor'] = 'OrderEasy';
    }
    this.ecommerceService.getStoreDetails(body).subscribe(
        res => {
          console.log(res);
          if (res && res.length) {
            console.log(res);
            this.storeProfileDetails = res[0];
            this.onlineStatus = (res[0].availability && (res[0].availability).toLowerCase() === 'active');
            console.log(this.onlineStatus);
            this.storeProfileDetails.availabilityDays =  this.storeProfileDetails.availabilityDays.reduce((resultArray, d) => {
              const found = resultArray.find(a => a === d);
              if (!found) {
                resultArray.push({
                  companyId : '1',
                  day : d.day ? d.day : '',
                  shopOpensAt : d.shopOpensAt ? d.shopOpensAt : '00:00',
                  shopClosesAt : d.shopClosesAt ? d.shopClosesAt :  '00:00',
                  shopClosesAt12hrs : d.shopClosesAt12hrs ? d.shopClosesAt12hrs : '00:00 PM',
                  storeAvailability : d.storeAvailability ? d.storeAvailability : '',
                  shopOpensAt12hrs : d.shopOpensAt12hrs ? d.shopOpensAt12hrs : '00:00 AM'
                });
              }
              return resultArray;
            }, []);
            this.storeDetails = res[0];
            if (this.type === 'marketplace') {
              console.log(this.storeProfileDetails);
              this.getLatLong(this.storeProfileDetails.shopAddress);
            }
            if (!res[0].shopAddress) {
              console.log(res[0]);
              this.setCurrentLocation();
            } else {
              this.getLatLong(this.storeProfileDetails.shopAddress);
            }
          }
        },
        error => {
          this.notifyService.showWarn('Warn', error.message);
        }
    );
  }

  markerDragEnd($event: MouseEvent) {
    console.log($event);
    // @ts-ignore
    this.storeProfileDetails.latitude = $event.coords.lat;
    // @ts-ignore
    this.storeProfileDetails.longitude = $event.coords.lng;
    this.getAddress(this.storeProfileDetails.latitude, this.storeProfileDetails.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results, status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.storeProfileDetails.shopAddress = results[0].formatted_address;
        }
      }
    });
  }

  getLatLong(address) {
    console.log(address);
    if (!address) {
      return;
    }
    this.geoCoder.geocode({'address': address}, (results, status) => {
      console.log(results, status);
      if (status === 'OK') {
        if (results[0]) {
          this.storeProfileDetails.shopAddress = results[0].formatted_address;
          this.zoom = 8;
          this.storeProfileDetails.latitude = results[0].geometry.location.lat();
          this.storeProfileDetails.longitude = results[0].geometry.location.lng();
          console.log(this.storeProfileDetails.latitude, this.storeProfileDetails.longitude);
        }
      }
    });
  }

  onMapReady(e) {
    this.map = e;
  }

  private setCurrentLocation() {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
            this.storeProfileDetails.latitude = position.coords.latitude;
            this.storeProfileDetails.longitude = position.coords.longitude;
            this.zoom = 8;
            this.getAddress(this.storeProfileDetails.latitude, this.storeProfileDetails.longitude);
          },
          error => {
            switch (error.code) {
              case 1:
                console.log('Permission Denied');
                this.showLocationError = true;
                break;
              case 2:
                console.log('Position Unavailable');
                break;
              case 3:
                console.log('Timeout');
                break;
            }
          });
    }
  }

  closeModal(type) {
    this[type] = false;
    if (type === 'isDaysModalOpen') {
      console.log(this.selectedDay, this.prevSelectedDay);
      this.selectedDay.shopClosesAt = this.prevSelectedDay.shopClosesAt;
      this.selectedDay.shopOpensAt = this.prevSelectedDay.shopOpensAt;
      this.selectedDay.storeAvailability = this.prevSelectedDay.storeAvailability;
      console.log(this.selectedDay, this.storeProfileDetails.availabilityDays);
    }
  }

  stopProbagation(event: MouseEvent) {
    event.stopPropagation();
  }

  validateStoreProfile() {
    if (!this.storeProfileDetails.shopName) {
      return 'Kindly Enter Shop Name';
    } else if (!this.storeProfileDetails.emailId) {
      return 'Kindly Enter Shop Email Id';
    } else if (!this.storeProfileDetails.contactNumber1) {
      return 'Kindly Enter Contact Number';
    } else if (!this.storeProfileDetails.panNo) {
      return 'Kindly Enter Pan No';
    } else if (!this.storeProfileDetails.gstNo) {
      return 'Kindly Enter Gst No';
    } else if (!this.storeProfileDetails.shopAddress) {
      return 'Kindly Enter Address';
    } else if (!this.storeProfileDetails.description || this.storeProfileDetails.description === '<br>') {
      return 'Kindly Enter Description';
    } else {
      return '';
    }
  }

  saveDetails() {
    console.log(this.validateStoreProfile());
    if (this.validateStoreProfile()) {
      this.notifyService.showWarn('Warn', this.validateStoreProfile());
    } else {
      const body = {...this.storeProfileDetails,
        'shopId': this.authService.getServiceUserId(),
        'vendor': this.authService.getSkewName(),
        'outletId': 1,
      };
      this.ecommerceService.addStoreDetails(body).subscribe(
          res => {
            if (res && res.status) {
              this.notifyService.showSuccess('Success', res.message);
              this.getStoreDetails();
            } else {
              this.notifyService.showWarn('warn', res.message);
            }
          }
      );
      this.closeModal('isModalOpen');
    }
  }

  openModal(type, value?) {
    this[type] = true;
    if (value) {
      console.log(value);
      this.modalName = value;
      this.selectedDay = value;
      this.prevSelectedDay = {...value};
    }
    this.selectedStatus = '';
    this.storeStatusRemarks = '';
  }

  openFormat(type) {
    this[type] = !this[type];
  }

  updateFormatType(variable: string, value: string) {
    this[variable] = value;
  }

  updateDay(day) {
    if (!this.selectedDay.storeAvailability || this.selectedDay.storeAvailability == '0') {
      if (this.selectedDay.shopOpensAt === '00:00' || this.selectedDay.shopClosesAt === '00:00') {
        this.notifyService.showWarn('Warn', 'Kindly Enter the time value');
        return;
      } else if (Number(this.selectedDay.shopOpensAt.split(':')[0]) > Number(this.selectedDay.shopClosesAt.split(':')[0]) ) {
        this.notifyService.showWarn('Warn', 'Closing time should be after opening time');
        return;
      }
    }
    if (!(!!this.selectedDay.storeAvailability && this.selectedDay.storeAvailability !== '0')) {
      this.selectedDay.storeAvailability = '';
      const openValue = this.selectedDay.shopOpensAt.split(':');
      const closeValue = this.selectedDay.shopClosesAt.split(':');
      openValue[0] = Number(openValue[0]) > 12 ? (Number(openValue[0]) - 12) + ''  : openValue[0];
      closeValue[0] = Number(closeValue[0]) > 12 ? (Number(closeValue[0]) - 12) + ''  : closeValue[0];
      console.log(openValue, closeValue);
      this.selectedDay.shopOpensAt12hrs = openValue.join(':') + (Number(this.selectedDay.shopOpensAt.split(':')[0]) > 12 ? ' PM' : ' AM');
      this.selectedDay.shopClosesAt12hrs = closeValue.join(':') + (Number(this.selectedDay.shopClosesAt.split(':')[0]) > 12 ? ' PM' : ' AM');
    }
    console.log(day);
    if (this.applyForAll) {
      this.applyForAllDays();
      this.applyForAll = false;
    }
    this.isDaysModalOpen = false;
  }

  applyForAllDays() {
    this.storeProfileDetails.availabilityDays.forEach(day => {
      day['shopClosesAt'] = this.selectedDay.shopClosesAt;
      day['shopOpensAt'] = this.selectedDay.shopOpensAt;
      day['shopOpensAt12hrs'] = this.selectedDay.shopOpensAt12hrs;
      day['shopClosesAt12hrs'] = this.selectedDay.shopClosesAt12hrs;
      day['storeAvailability'] = this.selectedDay.storeAvailability;
    });
    console.log(this.storeProfileDetails.availabilityDays);
  }

  openCloseOption(option: string) {
    this[option] = !this[option];
  }

  optionChange(event, variable , value, option) {
    console.log(variable, value);
    this[variable] =  value;
    // this[option] = !this[option];
    // event.stopPropagation();
  }

  addDays(day: string) {
    if (this.selectedDays.includes(day)) {
      const index = this.selectedDays.indexOf(day);
      if (index > -1) { // only splice array when item is found
        this.selectedDays.splice(index, 1); // 2nd parameter means remove one item only
      }
    } else {
      this.selectedDays.push (day);
    }
  }

  isSelected(day: string) {
    return this.selectedDays.includes(day);
  }

  onClickCancel() {
    this.getStoreDetails();
  }

  updateStatus() {
    if (this.modalName === 'registration') {
      this.updateRegistrationStatus();
    } else {
      this.updateAvailiabilityStatus();
    }
  }

  updateRegistrationStatus() {
    if (!this.storeStatusRemarks) {
      this.notifyService.showWarn('Warn', 'Kindly Enter the remarks');
    } else {
      const body = {
        'userId': this.shopId,
        'aggregatoruserId': this.authService.getServiceUserId(),
        'status': this.selectedStatus,
        'availability': this.storeDetails.availability,
        'remarks': this.storeStatusRemarks,
        'outletId': this.authService.getSelectedOutletId(),
        'vendor': 'OrderEasy'
      };
      this.ecommerceService.updateStoreStatus(body).subscribe(
          res => {
            if (res && res.status) {
              this.notifyService.showSuccess('Success', res.message);
              this.getStoreDetails();
              this.closeModal('isModalOpen');
            } else {
              this.notifyService.showWarn('Warn', res.message);
            }
          }
      );
    }
  }

  updateAvailiabilityStatus() {
    const body = {
      'userId': this.shopId,
      'vendor': this.authService.getSkewName(),
      'outletId': this.authService.getSelectedOutletId(),
      'aggregatorUserId': this.authService.getServiceUserId(),
      'publishStatus': (this.onlineStatus) ? 'Active' : 'InActive',
      'publishBy': 'market-place',
      'publishRemarks' : this.storeStatusRemarks
    };
    this.ecommerceService.updateAvailiabilityStatus(body).subscribe(
        (response) => {
          if (response && response.status) {
            this.notifyService.showSuccess('Success', response.message);
            this.getStoreDetails();
            this.closeModal('isModalOpen');
          } else {
            this.notifyService.showWarn('Warn', response.message);
          }
        },
        (err) => {
          this.notifyService.showWarn('Warn', err);
        }
    );
  }

  updateCheckedValue(id: number) {
    console.log(id);
    this.categories.forEach(cat => {
      if (cat.id === id) {
        cat.checked = !cat.checked;
        if (cat.checked) {
          this.selectedCategory = [this.selectedCategory, cat.name].filter(Boolean).join(',');
          console.log(this.selectedCategory);
        } else {
          let categoryArray = this.selectedCategory.split(',');
          categoryArray = categoryArray.filter(x => !(x === cat.name));
          this.selectedCategory = categoryArray.join(',');
        }
      }
    });
  }

  changeTimeFormat() {
    this.forTime = this.is24Format ? this.storeDetails.shopOpensAt : this.storeDetails.shopOpensAt12hrs.split(' ')[0];
    this.toTime = this.is24Format ? this.storeDetails.shopClosesAt : this.storeDetails.shopClosesAt12hrs.split(' ')[0];
  }

  onChange(type) {
    if (type === this.selectedDay.storeAvailability) {
      this.selectedDay.storeAvailability = '';
    } else {
      this.selectedDay.storeAvailability = type;
    }
  }

  updateDescription(event: any, group: any, destination) {
    this[group][destination] = event;
  }
}
