import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReportTable } from './report-table.model';
import { EventEmitter as evnt } from 'events';

@Component({
  selector: 'app-report-table',
  templateUrl: './report-table.component.html',
  styleUrls: ['./report-table.component.scss']
})
export class ReportTableComponent implements OnInit {

  constructor() { }

  @Input() tableData: ReportTable;
  @Output() lazyLoad = new EventEmitter<any>();
  @Input() loading: boolean;
  ngOnInit() {
  }

  public loadLazy($event) {
    this.lazyLoad.emit($event);
  }
}
