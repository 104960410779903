export const ConfigData = {
    urbanpiper: {
        product: [
            {
                name: 'ServQuick',
                value: 'servquick'
            },
            {
                name: 'RPOS6',
                value: 'RPOS6'
            },
            {
                name: 'RPOS7',
                value: 'RPOS7'
            },
            {
                name: 'TruePOS',
                value: 'TruePOS'
            },
            {
                name: 'HQ',
                value: 'HQ'
            },
            {
                name: 'DE',
                value: 'DE'
            }

        ],
        categoryTypes: [
            {
                name: 'CategoryType1',
                value: 'category_type1'
            },
            {
                name: 'CategoryType2',
                value: 'category_type2'
            },
            {
                name: 'CategoryType3',
                value: 'category_type3'
            },
            {
                name: 'CategoryType4',
                value: 'category_type4'
            },
            {
                name: 'CategoryType5',
                value: 'category_type5'
            },
            {
                name: 'CategoryType6',
                value: 'category_type6'
            },
            {
                name: 'CategoryType7',
                value: 'category_type7'
            },
            {
                name: 'CategoryType8',
                value: 'category_type8'
            },
            {
                name: 'CategoryType9',
                value: 'category_type9'
            },
            {
                name: 'CategoryType10',
                value: 'category_type10'
            }
        ]
    },
    deliveryapp: {
        product: [
            {
                name: 'RPOS6',
                value: 'RPOS6'
            },
            {
                name: 'RPOS7',
                value: 'RPOS7'
            }
        ]
    }
}
