import {Component, OnInit, Input} from '@angular/core';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {MenuService} from '../../../core/services/menu.service';
import {LoginService} from '../../../modules/login/login.service';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';

@Component({
    selector: 'cdk-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
    public bigMenu;
    @Input() open;
    searchValue: any;
    menus: any;
    searchInput = new Subject<string>();
    searchedMenus: any[];

    constructor(private authService: AuthenticationService,
                private menuService: MenuService,
                private loginService: LoginService,
                private router: Router) {
    }

    ngOnInit() {
        this.searchInput.pipe(
            debounceTime(400),
            distinctUntilChanged())
            .subscribe(value => {
                this.getDynamicMenuFromSearch();
            });
    }

    getDynamicMenuFromSearch () {
        const formObj = {
            userId: this.authService.getServiceUserId(),
            skewCode: this.authService.getSkewCode(),
            roleId: this.authService.getRoleId(),
            searchTag: this.searchValue,
        };
        this.loginService.getDynamicMenuFromSearch(formObj).subscribe(
            (config) => {
                this.menus = config;
                const newArray = [];
                config.forEach( x => {
                    if (x.sub.length === 0) {
                        newArray.push(x);
                    } else {
                        newArray.push(...x.sub);
                    }
                });
                this.searchedMenus = newArray.filter(x => {
                    return (x.name.toLowerCase()).includes(this.searchValue.toLowerCase());
                });
            });
    }

    clearSearchValue() {
        this.searchValue = '';
        this.searchedMenus = [];
    }

    link(link: any) {
        this.clearSearchValue();
    }
}
