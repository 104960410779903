import {Component, OnInit} from '@angular/core';
import {SharedService} from '../../service/shared.service';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {LoginService} from '../../../modules/login/login.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-liscense-expiry',
  templateUrl: './liscense-expiry.component.html',
  styleUrls: ['./liscense-expiry.component.scss']
})
export class LiscenseExpiryComponent implements OnInit {

  menuLength = false;
  private subscription: Subscription;
  management: string;
  addon: any;

  constructor(
      private sharedService: SharedService,
      private authService: AuthenticationService,
      private loginService: LoginService,
  ) {
  }

  ngOnInit() {
    console.log('initialized');
    this.addon = this.sharedService.getOpenModalValue();
    this.isMenusMapped();
    this.subscription = this.sharedService
        .getOpenModal()
        .subscribe((event) => {
          console.log(event);
          if (event) {
            console.log(event);
            this.addon = event;
            if (this.addon.productCode === '742' || this.addon.productCode === '923') {
              this.management = 'Order Management';
            } else if (this.addon.productCode === '538') {
              this.management = 'Delivery Management';
            } else if (this.addon.productCode === '744') {
              this.management = 'Delivery Boy Tracking';
            } else if (this.addon.productCode === '910') {
              this.management = 'Order Management';
              this.menuLength = true;
            }
            this.sharedService.setOpenModal(false);
          }
        });
  }

  redirectUrl() {
    if (this.addon.productCode === '910') {
      return;
    }
    window.open('https://www.gofrugal.com/integration-pos', '_blank');
  }

  isMenusMapped() {
    const formObj = {
      'userId': this.authService.getServiceUserId(),
      'skewCode': this.authService.getSkewCode(),
      'roleId': this.authService.getRoleId()
    };
    this.loginService.getDynamicMenuFromHomePage(formObj).subscribe(
        (config) => {
          this.menuLength = config.length === 0;
        });
  }

  getDisplayName(skewCode) {
    switch (skewCode) {
      case '742' :
        return 'OrderEasy';
      case '538' :
        return 'GoDeliver';
      case '744' :
        return 'GoTracker';
      case '910' :
        return 'MarketPlace';
      case '917' :
        return 'OrderEasy';
      case '921' :
        return 'GoDeliver';
      case '920' :
        return 'GoDeliver';
      case '1003' :
        return 'OrderEasy';
      case '923' :
        return 'ONDC';
      case '944' :
        return 'Survey';
      default :
        return 'OrderEasy';
    }
  }

  getProductCode(skewCode) {
    const skewCodeOE = ['917', '910', '1003'];
    const skewCodeGD = ['921', '920'];
    const skewCodeSurvey = ['944'];
    return (skewCodeOE.includes(skewCode)) ?
        '742' : (skewCodeGD.includes(skewCode)) ?
            '538' : (skewCodeSurvey.includes(skewCode)) ? '944' : skewCode;
  }

  getAddonName() {
   return this.authService.isMarketPlace() ? 'MarketPlace' : this.addon.displayName;
  }

}
