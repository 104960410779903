import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-angular-editor',
    templateUrl: './app-angular-editor.component.html',
    styleUrls: ['./app-angular-editor.component.scss']
})
export class AppAngularEditorComponent implements OnInit, OnChanges {
    @Input() description: any;
    @Input() placeHolder;
    @Input() condition;
    @Input() editorConfig: any;
    @Output() updateDescription = new EventEmitter<any>();
    @Input() lastUpdate: any;
    @Input() maxLength = Infinity;
    @Input() id: any;
    timer: any;
    percentColors = [
        { pct: 0.0, color: { r: 0x00, g: 0x00, b: 0x00 } },
        { pct: 0.5, color: { r: 0xff, g: 0x9f, b: 0x00 } },
        { pct: 1.0, color: { r: 0xff, g: 0x00, b: 0x00 } } ];
    protected readonly Infinity = Infinity;
    buttons = [
        {
            'id': '#bold-',
            'icon': './assets/icons/bold.svg',
            'set': 'g1'
        },
        {
            'id': '#italic-',
            'icon': './assets/icons/italic.svg',
            'set': 'g1'
        },
        {
            'id': '#underline-',
            'icon': './assets/icons/underline.svg',
            'set': 'g1'
        },
        {
            'id': '#justifyLeft-',
            'icon': './assets/icons/alignLeft.svg',
            'set': 'g2'
        },
        {
            'id': '#justifyCenter-',
            'icon': './assets/icons/alignJustify.svg',
            'set': 'g2'
        },
        {
            'id': '#justifyRight-',
            'icon': './assets/icons/alignRight.svg',
            'set': 'g2'
        },
        {
            'id': '#insertUnorderedList-',
            'icon': './assets/icons/bullets.svg',
            'set': 'g3'
        },
        {
            'id': '#insertOrderedList-',
            'icon': './assets/icons/numberings.svg',
            'set': 'g3'
        },
        {
            'id': '#link-',
            'icon': './assets/icons/link.svg',
            'set': 'g4'
        },
        {
            'id': '#unlink-',
            'icon': './assets/icons/link-break.svg',
            'set': 'g4'
        }
    ];

    constructor() {
    }

    ngOnInit() {
        this.changeIcon();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.changeIcon();
        this.updateLastUpdate();
    }

    private changeIcon() {
        this.timer = setTimeout(() => {
            let selector = '';
            // Bold
            selector = '[id=bold-' + this.id + ']';
            const boldIcon = Array.from(document.querySelectorAll(selector));
            this.changeSeperateIcon(boldIcon, './assets/icons/bold.svg');

            // Italic
            selector = '[id=italic-' + this.id + ']';
            const italicIcon = Array.from(document.querySelectorAll(selector));
            this.changeSeperateIcon(italicIcon, './assets/icons/italic.svg');

            // UnderLine
            selector = '[id=underline-' + this.id + ']';
            const underLineIcon = Array.from(document.querySelectorAll(selector));
            this.changeSeperateIcon(underLineIcon, './assets/icons/underline.svg');

            // LeftAlign
            selector = '[id=justifyLeft-' + this.id + ']';
            const leftIcon = Array.from(document.querySelectorAll(selector));
            this.changeSeperateIcon(leftIcon, './assets/icons/alignLeft.svg');

            // Justify
            selector = '[id=justifyCenter-' + this.id + ']';
            const justifyIcon = Array.from(document.querySelectorAll(selector));
            this.changeSeperateIcon(justifyIcon, './assets/icons/alignJustify.svg');

            // RightAlign
            selector = '[id=justifyRight-' + this.id + ']';
            const rightIcon = Array.from(document.querySelectorAll(selector));
            this.changeSeperateIcon(rightIcon, './assets/icons/alignRight.svg');

            // Bullets
            selector = '[id=insertUnorderedList-' + this.id + ']';
            const bulletIcons = Array.from(document.querySelectorAll(selector));
            this.changeSeperateIcon(bulletIcons, './assets/icons/bullets.svg');

            // Numbering
            selector = '[id=insertOrderedList-' + this.id + ']';
            const numberIcon = Array.from(document.querySelectorAll(selector));
            this.changeSeperateIcon(numberIcon, './assets/icons/numberings.svg');

            // Link
            selector = '[id=link-' + this.id + ']';
            const linkIcon = Array.from(document.querySelectorAll(selector));
            this.changeSeperateIcon(linkIcon, './assets/icons/link.svg');

            // Un-Link
            selector = '[id=unlink-' + this.id + ']';
            const unLinkIcon = Array.from(document.querySelectorAll(selector));
            this.changeSeperateIcon(unLinkIcon, './assets/icons/link-break.svg');

        }, 100);
    }

    changeSeperateIcon(htmlList, path) {
        htmlList.forEach(icon => {
            icon.removeChild(icon.firstChild);
            const newIcon = document.createElement('img');
            newIcon.src = path;
            icon.appendChild(newIcon);
        });
    }

    emitChanges() {
        this.updateDescription.emit(this.description);
    }

    updateLastUpdate() {
        const lastupdated = document.querySelectorAll('.angular-editor-wrapper');
        // <div class="last-update">last updated : 12/12/12</div>
    }

    textEditorcharLength() {
        const div = document.createElement('div');
        div.innerHTML = this.description;
        const text = div.textContent || div.innerText || '';
        return text.length;
    }

    getColor() {
        const percentage = this.textEditorcharLength() / this.maxLength * 100;
        return this.getColorForPercentage(percentage);
    }

    getColorForPercentage(pct) {
        pct = pct / 100;
        for (var i = 1; i < this.percentColors.length - 1; i++) {
            if (pct < this.percentColors[i].pct) {
                break;
            }
        }
        const lower = this.percentColors[i - 1];
        const upper = this.percentColors[i];
        const range = upper.pct - lower.pct;
        const rangePct = (pct - lower.pct) / range;
        const pctLower = 1 - rangePct;
        const pctUpper = rangePct;
        const color = {
            r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
            g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
            b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
        };
        return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
    }
}
