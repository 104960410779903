import {Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {MenuService} from '../../../core/services/menu.service';
import {LoginService} from '../../../modules/login/login.service';
import {SharedService} from '../../service/shared.service';
import {Router} from '@angular/router';
import {NotificationService} from '../../../core/services/notification.service';
import {UiCommonService} from '../../service/uiCommon.service';
import {DeliveryService} from '../../../modules/integration/delivery-web-app/delivery-web-app.service';
import {StompService} from '../../../core/web-socket/StompService';
import {LiveTrackingStompService} from '../../../core/web-socket/LiveTrackingStompService';
import {ordereasyValidSkewCodes} from '../../../core/services/menu-element';

@Component({
  selector: 'app-sideaddon',
  templateUrl: './sideaddon.component.html',
  styleUrls: ['./sideaddon.component.scss']
})
export class SideaddonComponent implements OnInit, OnChanges {

  @Input() sidenav;
  @Input() addons;
  currentPlanDetails: any;
  menuListArray = [];
  @ViewChild('expiredModal', {static: false}) expiredModalContent: TemplateRef<any>;
  addon: any;
  addonsList = [];
  constructor(
      private authService: AuthenticationService,
      private menuService: MenuService,
      private loginService: LoginService,
      private sharedService: SharedService,
      private router: Router,
      private notify: NotificationService,
      private uiCommonService: UiCommonService,
      private appService: DeliveryService,
      private stompService: StompService,
      private liveTrackingStomService: LiveTrackingStompService,
  ) {
  }

  async ngOnInit() {
    this.onGetCurrentPlanDetails();
    this.sidenav.close() ;
  }

  toggleSideNav() {
    this.sidenav.toggle();
    this.authService.setSideNavOpen(this.sidenav.opened);
    console.log(this.sidenav.opened);
    this.sharedService.setSideNavOpenStatus(this.sidenav.opened);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.addons) {
      this.authService.setAddonList(this.addons);
    }
    this.addonsList = this.addons ? this.addons : this.authService.getAddonsList();
    console.log(changes);
  }

  onClickFeedback() {
    this.sharedService.setFeedbackAvailable('FeedBack');
  }

  public async onClick(addon: any) {
    localStorage.clear();
    if (addon.productCode !== '742') {
      console.log('working');
      this.stompService.disconnect();
    }
    if (addon.productCode !== '744') {
      console.log('working');
      this.liveTrackingStomService.disconnect();
    }
    if (addon.subSkew.length) {
      this.authService.setSkewCode(addon.subSkew.join(','));
    } else {
      this.authService.setSkewCode(addon.productCode);
    }
    if (addon.purchased) {
      this.authService.setSkewName(addon.addOnName);
      this.addon = addon;
      console.log(this.menuService.getAvaiSkew().includes(addon.productCode + ':' + this.authService.getOutlet()), this.menuService.getAvaiSkew());
      // if (this.menuService.getAvaiSkew().includes(addon.productCode + ':' + this.authService.getOutlet())) {
        if (ordereasyValidSkewCodes.includes(this.authService.getSkewCode()) && this.authService.getSkewCode() !== '538') {
          if (addon.expired) {
            this.addon = addon;
            this.openActionModal();
          } else {
            console.log('called');
            await this.setMenuDynamically(addon.productCode);
          }
        } else if (this.authService.getSkewCode() === '538') {
          if (addon.expired) {
            this.addon = addon;
            this.openActionModal();
          } else {
            console.log('called');
            this.appService.getOutletConfig('', '').subscribe(
                data => {
                  if (data && data.configuredStores[0].organization.length !== 0) {
                    localStorage.setItem('companyAvailiable', String(true));
                  } else {
                    localStorage.setItem('companyAvailiable', String(false));
                  }
                });
            await this.setMenuDynamically(addon.productCode);
          }
        } else {
          this.menuService.removeParticularMenu('2');
          await this.router.navigate(['/Integration/' + this.menuService.getDefaultPage(this.authService.getSkewCode())]);
        }
    // } else {
    //     console.log('---------------------redirected 108-----------------------');
    //   await this.router.navigate(['/Integration/ecommerce/under-construct']);
    // }
    } else {
      this.addon = addon;
      this.openActionModal();
    }
  }

  onGetCurrentPlanDetails() {
    const formObj = {
      'integrationAccountId': '' + this.authService.getServiceUserId(),
      'customerId': this.authService.getCustomerId(),
      'productCode': '742'
    };
    this.loginService.currentPlanDetails(formObj).subscribe(
        (data) => {
          if (data && data.plan) {
            this.currentPlanDetails = data;
          }
        },
        (err) => {
          this.notify.showError('Error', err);
        });
  }


  async setMenuDynamically(skewCode) {
    console.log('menu called');
    const formObj = {
      'userId': this.authService.getServiceUserId(),
      'skewCode': skewCode,
      'roleId': this.authService.getRoleId()
    };
    this.loginService.getDynamicMenuFromHomePage(formObj).subscribe(
        (config) => {
          if (config) {
            config.forEach(res => { res['link'] = res['link'] === 'false' ? false : res.link; });
            this.menuListArray = config;
            if (this.menuListArray.length > 0) {
              const formMenu = [];
              this.menuListArray.forEach(res => {
                if (res.mappingStatus) {
                  formMenu.push(res.id);
                }
                if (res.sub && res.sub.length > 0) {
                  res.sub.forEach(res1 => {
                    if (res1.mappingStatus) {
                      formMenu.push(res1.id);
                    }
                  });
                }
              });
              this.menuService.setDynamicMenu(config);
              console.log('setting menu');
              this.menuService.setDynamicMenuFromJSON(formMenu);
              for (let index = 0; index < formMenu.length; index++) {
                const element = formMenu[index];
                if (this.menuService.getMenuLinkPassingId(element) !== false) {
                  this.router.navigate(['/Integration/' + this.menuService.getMenuLinkPassingId(element)]);
                  break;
                }
              }
            } else {
              this.uiCommonService.hideLoader();
              console.log(this.addon);
              this.openActionModal();
              // this.notify.showWarn('Warning', 'Menu is not mapped . Please contact admin to map menu');
            }
          }
        },
        (err) => {
          this.notify.showWarn('Warn', err.message);
        }
    );
  }

  setActiveAddon(addon: any) {
    this.addons.forEach( el => {
      el['active'] = (el.productCode === addon);
    });
    this.authService.setAddonList(this.addons)
  }

  openActionModal() {
    this.router.navigate(['Integration/ecommerce/liscense']);
    this.sharedService.setOpenModal(this.addon);
  }

  onClickChatBot() {
      this.sharedService.setAssureCare(true);
  }

  getDisplayName(skewCode) {
    switch (skewCode) {
      case '742' :
        return 'OrderEasy';
      case '538' :
        return 'GoDeliver';
      case '744' :
        return 'GoTracker';
      case '910' :
        return 'MarketPlace';
      case '917' :
        return 'OrderEasy';
      case '921' :
        return 'GoDeliver';
        case '920' :
        return 'GoDeliver';
      case '1003' :
        return 'OrderEasy';
      case '923' :
        return 'ONDC';
      case '936' :
        return 'ONDC';
      case '926' :
        return 'FA';
      case '944' :
        return 'Survey';
      default :
        return 'OrderEasy';
    }
  }

  getProductCode(skewCode) {
    const skewCodeOE = ['917', '910', '1003', '935'];
    const skewCodeGD = ['921', '920'];
    const skewCodeONDC = ['936'];
    const skewCodeSurvey = ['944'];
    return (skewCodeOE.includes(skewCode)) ?
        '742' : (skewCodeGD.includes(skewCode)) ?
        '538' : (skewCodeONDC.includes(skewCode)) ? 
        '923' : (skewCodeSurvey.includes(skewCode)) ? 
        '944' : skewCode;
  }
}
