import {Injectable} from '@angular/core';
import {NotificationService} from '../services/notification.service';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AuthenticationService} from '../services/authentication.service';
import {Router} from '@angular/router';
import {LoginDetailsService} from '../services/login-details.service';
import {CookieService} from 'ngx-cookie-service';

@Injectable()
export class EcommerceInterceptor implements HttpInterceptor {

    private loginDetails = this.loginDetailsService.loginDetails;
    private inValidPaths = 'v1/get-theme';

    constructor(
        private notify: NotificationService,
        private authenticationService: AuthenticationService,
        private router: Router,
        private loginDetailsService: LoginDetailsService,
        private cookieService: CookieService
    ) {
        this.loginDetailsService.themeDetailsList.subscribe(res => {
            if (res) {
                this.loginDetails = res;
            }
        }) ;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!(request.url.includes(this.inValidPaths)) && this.loginDetails && (request.url.includes(this.loginDetails.ecomURL) ||
            request.url.includes(this.loginDetails.paymentURL) || request.url.includes(this.loginDetails.analyticsURL))) {
            let token: string;
            const isconfigureStore = (request.url.includes('get/configured-store') && (sessionStorage.getItem('X-Auth')));
            if (request.url.includes(this.loginDetails.analyticsURL)) {
                token = '5dfc4760-40f8-4a43-9b96-9673061fe111';
            }
            if (request.url.includes(this.loginDetails.ecomURL)  && !isconfigureStore) {
               token  = this.authenticationService.getAuthToken();
            }
            if (request.url.includes(this.loginDetails.paymentURL)) {
                token = 'b7b6c548-45f3-433e-bd0c-bc3cce131167';
            }
            if (request.url.includes(this.loginDetails.ecomURL) && isconfigureStore) {
                token = sessionStorage.getItem('X-Auth');
                sessionStorage.removeItem('X-Auth');
            }
            if (token) {
                request = request.clone({headers: request.headers.set('X-Auth-Token', token)});
            } else {
                this.authenticationService.logoutUser();
            }

            if (!request.headers.has('Content-Type') && !request.url.includes('/upload-image') && !request.url.includes('/save-personalization') && !request.url.includes('/save/all-items')) {
                request = request.clone({headers: request.headers.set('Content-Type', 'application/json')});
            }

            request = request.clone({headers: request.headers.set('Accept', 'application/json')});
        }
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // console.log('event--->>>', event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 403) {
                    localStorage.clear();
                    sessionStorage.clear();
                    this.cookieService.deleteAll();
                    this.router.navigate(['/logout']);
                } else if (error.status === 406) {
                    if (error.error.message && error.error.message !== '') {
                        this.notify.showWarn('Error', error.error.message);
                    }
                } else if (error.status === 500) {
                    this.notify.showError('Sorry', 'Unexpected Error, Kindly contact Support.!');
                } else if (error.status === 502) {
                    this.notify.showError('Sorry', 'Unable to Reach Server, Kindly contact Support.!');
                } else if (error.status === 422) {
                    if (error.error.message && error.error.message !== '') {
                        this.notify.showWarn('Error', error.error.message);
                    }
                } else if (error.status === 503) {
                    this.notify.showError('Sorry', 'Unable to Reach Server, Kindly contact Support.!');
                } else if (error.status === 400) {
                    this.notify.showError('Sorry', 'Unexpected Error, Kindly contact Support.!');
                } else if (error.status === 0) {
                    this.notify.showError('Sorry', 'Unable to Reach Server, Kindly contact Support.!');
                } else if (!error.status) {
                    this.notify.showError('Sorry', 'Unable to Reach Server, Kindly contact Support.!');
                } else {
                    this.router.navigate(['/logout']);
                }
                return throwError(error);
            }));
    }
}
