import * as Stomp from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import { SharedService } from '../../shared/service/shared.service';
import { UiCommonService } from '../../shared/service/uiCommon.service';
import { Injectable } from '@angular/core';
import {LoginDetailsService} from '../services/login-details.service';


@Injectable()
export class LiveTrackingStompService {

    disabled = true;
    private reconnected = false;
    private stompClient = null;
    private timer: any;
    private loginDetails = this.loginDetailService.getLoginDetails();

    constructor(
        private sharedService: SharedService,
        private uiCommonService: UiCommonService,
        private loginDetailService: LoginDetailsService) { }

    setConnected(connected: boolean) {
        this.disabled = !connected;
    }

    getConnected(): boolean {
        if (this.stompClient) {
            return this.stompClient.connected;
        } else {
            return false;
        }
    }

    connect(serviceId: string) {
        const socket = new SockJS(this.loginDetails.locationURL + 'location-tracking-websocket');
        this.stompClient = Stomp.over(socket);
        // this.uiCommonService.openLoaderWithTimeOut('blocking', 'No Location Data From Sales Man.!');
        const _this = this;
        this.stompClient.connect({}, function () {
            if (_this.reconnected) {
                _this.uiCommonService.hideLoader();
                _this.sharedService.setReconnected(true);
                _this.reconnected = false;
            }
            _this.stompClient.subscribe('/topic/' + serviceId, function (response: { body: string; }) {
                _this.uiCommonService.hideLoader();
                const resp = JSON.parse(response.body);
                _this.sharedService.setLiveCoordinates(resp);
            });
        },
            () => {
                _this.reconnected = true;
                clearTimeout(_this.timer);
                _this.timer = setTimeout(() => {
                    _this.connect(serviceId);
                }, 10000);
            });
    }

    disconnect() {
        if (this.stompClient != null) {
            this.stompClient.disconnect();
        }
    }
}
