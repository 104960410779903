import {Injectable} from '@angular/core';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ConnectionService} from 'ng-connection-service';
import {ApiService} from '../../../core/http/api.service';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {NotificationService} from '../../../core/services/notification.service';
import {LoginDetailsService} from '../../../core/services/login-details.service';

@Injectable({
    providedIn: 'root'
})
export class DeliveryService {

    private isConnected: any = true;
    private loginDetails = this.loginDetailsService.getLoginDetails();
    private baseproduct: string = "";
    // private authToken = this.authenticationService.getAuthToken();

    constructor(
        private apiService: ApiService,
        private notifyService: NotificationService,
        private authenticationService: AuthenticationService,
        private loginDetailsService: LoginDetailsService,
        private connectionService: ConnectionService) {
        this.loginDetailsService.themeDetailsList.subscribe(res => {
            if (res) {
                this.loginDetails = res;
            }
        });
        this.connectionService.monitor().subscribe(isConnected => {
            this.isConnected = isConnected;
            if (this.isConnected) {
                this.notifyService.showSuccess('Success', 'You are ONLINE');
            } else {
                this.notifyService.showWarn('Warning', 'Please check your internet connection');
            }
        });
    }

    private getHeader(): HttpHeaders {
        const httpHeaders = new HttpHeaders();
        return httpHeaders.set('Content-Type', 'application/json').set('X-Auth-Token', this.authenticationService.getAuthToken());
    }

    get(path: string, pageSize: any, pageIndex: any, filterPattern: any, orFilter: any, sortBy: string, showLoader?: boolean, companyCode?: any, outletCustomerId?: any): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        if (this.authenticationService.getSkewCode() !== '538') {
            return;
        }
        const param = new HttpParams()
            .set('integrationAccountId', '' + this.authenticationService.getServiceUserId())
            .set('outletCustomerId', '' + outletCustomerId)
            .set('pageSize', '' + pageSize)
            .set('pageIndex', '' + pageIndex)
            .set('filter', filterPattern ? filterPattern : '')
            .set('orFilter', orFilter)
            .set('companyCode', ((companyCode) ? companyCode : (this.authenticationService.getCompanyCode() ? this.authenticationService.getCompanyCode() : '')))
            // this.authenticationService.getCompanyCode()) ? ((path === 'bill-details' && companyCode) ? companyCode : this.authenticationService.getCompanyCode()) : ''
            // )
            .set('sort', sortBy);
        return this.apiService.get(this.loginDetails.deliveryURL, 'get/' + path, param, this.getHeader(), showLoader)
            .pipe(catchError(this.handleError()));
    }

    fetchBillHeader(path: string, pageSize: any, pageIndex: any, filterPattern: any, orFilter: any, showLoader?: boolean, outletCustomerId?: any): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        const param = new HttpParams()
            .set('integrationAccountId', '' + this.authenticationService.getServiceUserId())
            .set('outletCustomerId', '' + outletCustomerId)
            .set('pageSize', '' + pageSize)
            .set('pageIndex', '' + pageIndex)
            .set('filter', filterPattern)
            .set('orFilter', orFilter)
            .set('companyCode', this.authenticationService.isMarketPlace() ? '1' : this.authenticationService.getCompanyCode() ? this.authenticationService.getCompanyCode() : '')
            .set('sort', 'id desc');
        return this.apiService.get(this.loginDetails.deliveryURL, 'get/' + path, param, this.getHeader(), showLoader)
            .pipe(catchError(this.handleError()));
    }

    fetchBillList(path: string, pageSize: any, pageIndex: any, searchTag: any, deliveryboyIds: any, fromDate: any, toDate: any, billStatus: any, showLoader?: boolean, outletCustomerId?: any): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        const param = new HttpParams()
            .set('integrationAccountId', '' + this.authenticationService.getServiceUserId())
            .set('outletCustomerId', '' + outletCustomerId)
            .set('pageSize', '' + pageSize)
            .set('pageIndex', '' + pageIndex)
            .set('searchParam', searchTag ? searchTag : '')
            .set('deliveryboyId', deliveryboyIds ? deliveryboyIds : '0')
            .set('fromDate', fromDate ? fromDate : '')
            .set('toDate', toDate ? toDate : '')
            .set('deliveryStatus', billStatus ? billStatus : '')
            .set('companyCode', (this.authenticationService.getCompanyCode()) ? this.authenticationService.getCompanyCode() : '')
            // .set('filter', filterPattern)
            // .set('orFilter', orFilter)
            .set('sort', 'id desc');
        return this.apiService.get(this.loginDetails.deliveryURL, 'get/' + path, param, this.getHeader(), showLoader)
            .pipe(catchError(this.handleError()));
    }

    getSession(path: string, outletCustomerId, pageSize: any, pageIndex: any, filterPattern: any, orFilter: any, source,showLoader?: boolean): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        const param = new HttpParams()
            .set('integrationAccountId', '' + this.authenticationService.getServiceUserId())
            .set('outletCustomerId', '' + outletCustomerId)
            .set('pageSize', '' + pageSize)
            .set('pageIndex', '' + pageIndex)
            .set('filter', filterPattern)
            .set('orFilter', orFilter)
            .set('sort', 'sessionId desc')
            .set('source',source);
        return this.apiService.get(this.loginDetails.deliveryURL, 'get/' + path, param, this.getHeader(), showLoader)
            .pipe(catchError(this.handleError()));
    }

    getConfig(path: string, outletCustomerId, configId?: any, showLoader?: boolean): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        const param = new HttpParams()
            .set('integrationAccountId', '' + this.authenticationService.getServiceUserId())
            .set('outletCustomerId', '' + outletCustomerId)
            .set('configId', '' + configId);
        return this.apiService.get(this.loginDetails.deliveryURL, path, param, this.getHeader(), showLoader)
            .pipe(catchError(this.handleError()));
    }

    getOutletConfig(pageSize?: any, pageIndex?: any): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        const param = new HttpParams()
            .set('integrationAccountId', '' + this.authenticationService.getServiceUserId())
            .set('outletCustomerId', '' + this.authenticationService.getCustomerId())
            .set('pageSize', '' + pageSize)
            .set('pageIndex', '' + pageIndex);
        return this.apiService.get(this.loginDetails.deliveryURL, 'get/configured-store', param, this.getHeader(), true)
            .pipe(catchError(this.handleError()));
    }

    getOutletPosConfig(outletCustomerId: any, pageSize?: any, pageIndex?: any): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        const param = new HttpParams()
            .set('integrationAccountId', '' + this.authenticationService.getServiceUserId())
            .set('outletCustomerId', '' + outletCustomerId)
            .set('pageSize', '' + pageSize)
            .set('pageIndex', '' + pageIndex);
        return this.apiService.get(this.loginDetails.deliveryURL, 'get/configured-store', param, this.getHeader(), true)
            .pipe(catchError(this.handleError()));
    }

    getPinCode(pincode: string): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        const header = new HttpHeaders()
            .set('Content-Type', 'application/json');
        // .set('web_user', '9999');
        const param = new HttpParams()
            .set('purpose', 'pincode_dtl')
            .set('pincode', '' + pincode);
        return this.apiService.get(this.loginDetails.samAddressDetailURL, 'get_address_details.php', param, header, false)
            .pipe(catchError(this.handleError()));
    }


    post(path: string, body: Object, showLoader?: boolean): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        body['integrationAccountId'] = this.authenticationService.getServiceUserId();
        // body['outletCustomerId'] = this.authenticationService.getCustomerId();
        return this.apiService.post(this.loginDetails.deliveryURL, path, null, this.getHeader(), body, showLoader)
            .pipe(catchError(this.handleError()));
    }

    postConfig(path: string, body: Object, showLoader?: boolean, outletCustomerId?: any): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        const param = new HttpParams()
            .set('integrationAccountId', '' + this.authenticationService.getServiceUserId())
            .set('outletCustomerId', '' + outletCustomerId);
        return this.apiService.post(this.loginDetails.deliveryURL, path, param, this.getHeader(), body, showLoader)
            .pipe(catchError(this.handleError()));
    }

    postImage(body: Object = {}, showLoader?: boolean): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        const param = new HttpParams()
            .set('outletCustomerId', this.authenticationService.getCustomerId());
        return this.apiService.postFormData(this.loginDetails.deliveryURL, 'upload-file', param, null, body, showLoader)
            .pipe(catchError(this.handleError('Post - ECommerce')));
    }

    put(path: string, body: Object = {}, showLoader?: boolean, outletCustomerId?: any): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        body['integrationAccountId'] = '' + this.authenticationService.getServiceUserId();
        body['outletCustomerId'] = '' + outletCustomerId;
        return this.apiService.put(this.loginDetails.deliveryURL, 'update/' + path, null, this.getHeader(), body, showLoader)
            .pipe(catchError(this.handleError('Put - ECommerce')));
    }

    delete(path: string, body: Object, showLoader?: boolean): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        body['integrationAccountId'] = '' + this.authenticationService.getServiceUserId();
        body['outletCustomerId'] = this.authenticationService.getCustomerId();
        return this.apiService.post(this.loginDetails.deliveryURL, 'delete/' + path, null, this.getHeader(), body, showLoader)
            .pipe(catchError(this.handleError()));
    }

    public saveOutletConfig(body: any): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        body['integrationAccountId'] = '' + this.authenticationService.getServiceUserId();
        // body['outletCustomerId'] = this.authenticationService.getCustomerId();
        return this.apiService.post(this.loginDetails.deliveryURL, 'store-configure', null, this.getHeader(), body, true);
    }

    public syncDetails(body: any, outletCustomerId): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        const param = new HttpParams()
            .set('integrationAccountId', '' + this.authenticationService.getServiceUserId())
            .set('ids', body)
            .set('outletCustomerId', outletCustomerId);
        return this.apiService.post(this.loginDetails.deliveryURL, 'sync-status', param, this.getHeader(), null, true);
    }

    // Get trouble shooting details
    getTroubleShootingDetails(): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        const param = new HttpParams()
            .set('integrationAccountId', '' + this.authenticationService.getServiceUserId());
        return this.apiService.post(this.loginDetails.deliveryURL, 'data-sync/troubleshoot', param, this.getHeader(), null, true);
    }

    public resetSyncService(outletCustomerId): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        const param = new HttpParams()
            .set('integrationAccountId', '' + this.authenticationService.getServiceUserId())
            .set('outletCustomerId', '' + outletCustomerId);
        return this.apiService.post(this.loginDetails.deliveryURL, 'reset-sync', param, this.getHeader(), null, true);
    }

    public pushSalesReturnToPos(outletCustomerId): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        const param = new HttpParams()
            .set('integrationAccountId', '' + this.authenticationService.getServiceUserId())
            .set('outletCustomerId', '' + outletCustomerId);
        return this.apiService.post(this.loginDetails.deliveryURL, 'sale-return-to-pos', param, this.getHeader(), null, true);
    }

    public pushSalesBillToPos(outletCustomerId): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        const param = new HttpParams()
            .set('integrationAccountId', '' + this.authenticationService.getServiceUserId())
            .set('outletCustomerId', '' + outletCustomerId);
        return this.apiService.post(this.loginDetails.deliveryURL, 'push/sale-bill-to-pos', param, this.getHeader(), null, true);
    }

    public pushPODToPos(outletCustomerId): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        const param = new HttpParams()
            .set('integrationAccountId', '' + this.authenticationService.getServiceUserId())
            .set('outletCustomerId', '' + outletCustomerId);
        return this.apiService.post(this.loginDetails.deliveryURL, 'push/pod-to-pos', param, this.getHeader(), null, true);
    }

    public pushTradeToPos(outletCustomerId): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        const param = new HttpParams()
            .set('integrationAccountId', '' + this.authenticationService.getServiceUserId())
            .set('outletCustomerId', '' + outletCustomerId);
        return this.apiService.post(this.loginDetails.deliveryURL, 'push/trade-status-to-pos', param, this.getHeader(), null, true);
    }

    public getAlertConfig(): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        const param = new HttpParams()
            .set('integrationAccountId', '' + this.authenticationService.getServiceUserId());
        return this.apiService.get(this.loginDetails.deliveryURL, 'get/sms-configuration', param, this.getHeader(), true)
            .pipe(catchError(this.handleError()));
    }

    public doAlertConfig(alertMailId: any): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        const param = new HttpParams()
            .set('integrationAccountId', '' + this.authenticationService.getServiceUserId())
            .set('emailId', alertMailId);
        return this.apiService.post(this.loginDetails.deliveryURL, 'do/sms-configuration', param, this.getHeader(), null, true);
    }

    public doAlertDeConfig(): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        const param = new HttpParams()
            .set('integrationAccountId', '' + this.authenticationService.getServiceUserId());
        return this.apiService.post(this.loginDetails.deliveryURL, 'delete/sms-configuration', param, this.getHeader(), null, true);
    }

    public getSchedulerConfig(): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        const param = new HttpParams()
            .set('userId', '' + this.authenticationService.getServiceUserId());
        return this.apiService.post(this.loginDetails.deliveryURL, 'get-scheduler-details', param, this.getHeader(), null, true);
    }

    public saveScheduleConfig(task: any, cronString: any, schedule: any): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        const param = new HttpParams()
            .set('integrationAccountId', String(this.authenticationService.getServiceUserId()))
            .set('task', task)
            .set('cron_string', cronString)
            .set('scheduler', schedule);
        return this.apiService.post(this.loginDetails.deliveryURL, 'schedule', param, this.getHeader(), null, true);
    }

    public unScheduleConfig(task: any, schedule: any): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        const param = new HttpParams()
            .set('userId', String(this.authenticationService.getServiceUserId()))
            .set('task', task)
            .set('scheduler', schedule);
        return this.apiService.post(this.loginDetails.deliveryURL, 'unschedule', param, this.getHeader(), null, true);
    }

    public testURL(url: string, product: string): Observable<any> {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        const headers = new HttpHeaders({ timeout: `${300000}` })
            .set('X-Auth-Token', this.authenticationService.getAuthToken());
        const param = new HttpParams().set('url', window.btoa(url)).set('product', product)
            .set('userId', '' + this.authenticationService.getServiceUserId())
            .set('vendor', this.authenticationService.getSkewName());
        return this.apiService.get(this.loginDetails.deliveryURL, 'test/domain-url', param, headers, true);
    }

    private handleError<T>(result?: T) {
        return (error: any): Observable<T> => {
            return of(result);
        };
    }

    getMobilePodDetails(body) {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        return this.apiService.post(this.loginDetails.deliveryURL, 'get-pod', null, this.getHeader(), body, true);
    }

    getPodUpdateStatus(body) {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        return this.apiService.post(this.loginDetails.deliveryURL, 'update/trade-status', null, this.getHeader(), body, true);
    }

    getPodUpload(body) {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        return this.apiService.post(this.loginDetails.deliveryURL, 'upload-pod', null, this.getHeader(), body, true);
    }

    getPodImage(body) {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        return this.apiService.post(this.loginDetails.deliveryURL, 'upload-image', null, this.getHeader(), body, true);
    }

    saveDeliverBoy(body) {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        return this.apiService.post(this.loginDetails.deliveryURL, 'create/delivery-boy-master', null, this.getHeader(), body, true);
    }

    saveTenderMaster(body) {
        if (!this.isConnected) {
            this.notifyService.showWarn('Warning', 'Please check your internet connection');
            return;
        }
        return this.apiService.post(this.loginDetails.deliveryURL, 'create/tender-master', null, this.getHeader(), body, true);
    }

    getBaseProduct = () : string => {
        return this.baseproduct;
    }

    setBaseProduct =(baseproduct: string) : string => {
        this.baseproduct = baseproduct;
        return this.baseproduct;
    }
}
