import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {MenuMaster} from '../../../core/models';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {NotificationService} from '../../../core/services/notification.service';
import {LoginService} from '../../../modules/login/login.service';
import Swal from 'sweetalert2';
import {UiCommonService} from '../../service/uiCommon.service';
import {MenuService} from '../../../core/services/menu.service';
import {SharedService} from '../../service/shared.service';
import {profileFeatures} from '../../../core/services/menu-element';
import {Subscription} from 'rxjs';
import {LoginDetailsService} from '../../../core/services/login-details.service';
import {ImageCompressService} from '../../../core/services/image-compress.service';

@Component({
    selector: 'cdk-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private notifyService: NotificationService,
        private loginService: LoginService,
        private uiCommonService: UiCommonService,
        private menuService: MenuService,
        private sharedService: SharedService,
        private loginDetailsService: LoginDetailsService,
        private imageCompressService: ImageCompressService
    ) { }

    inCompleteProfile: any;
    showPendingProfile = false;
    currentPlanDetails: any;
    inCompleteProfileArray = [[], []];
    addonsList: any;
    menuListArray: Array<MenuMaster> = [];
    copyOption = 'copy';
    addOnListView = 'My Add-ons';
    allAddonList = [
        { productCode: '742', displayName: 'OrderEasy' }, { productCode: '538', displayName: 'GoDeliver' }];
    myProfileDetails : any;
    profilePicUrl: any = './assets/profile.jpg';
    profilePicImage: any;
    profileUrlList = profileFeatures[this.authenticationService.getSkewCode()];
    inCompleteProfileCount = 0;
    private subscription: Subscription;
    functionCallAlreadyDone = false;
    myAddons = [];
    allAddons = [];
    loginDetails = this.loginDetailsService.getLoginDetails();
    selectedZone: any;
    zoneList: any;

    ngOnInit() {
        if (this.authenticationService.getSkewCode() !== '944') {
            this.onGetMyProfileDetails();
        }
        this.getAddOnList();
        this.getTimeZoneList();
    }

    onShowPendingProfile() { this.showPendingProfile = !this.showPendingProfile; }

    onManageSubscriptionClick() {
        this.router.navigate(['Integration/ecommerce/manage-subscription']);
    }

    onGetMyProfileDetails() {
        const formObj = {
            'userId': '' + this.authenticationService.getServiceUserId(),
            'vendor': this.authenticationService.getSkewName(),
            'emailId': this.authenticationService.getEmailId()
        };
        this.loginService.getMyProfileDetails(formObj).subscribe(
            (data) => {
                if (data) {
                    this.myProfileDetails = data;
                    this.currentPlanDetails = data.currentPlan;
                    if (this.myProfileDetails.profileImage) {
                        this.profilePicUrl = this.myProfileDetails.profileImage;
                    }
                }
            },
            (err) => {
                this.notifyService.showError('Error', err);
            });
        this.loginService.getMyProfileAccountsDetails(formObj).subscribe((data) => {
            if (data) {
                this.inCompleteProfile = data.featureStrengthList;
                this.profilePicUrl =  (this.myProfileDetails && this.myProfileDetails.profileImage) ? this.profilePicUrl : data.profileUrl;
                this.sharedService.setProfilePicUrl(this.profilePicUrl);
                this.inCompleteProfileCount = (data.totalFeatureCount - data.completedFeatureCount);
                this.myProfileDetails.totalFeatureCount = data.totalFeatureCount;
                this.myProfileDetails.completedFeatureCount = data.completedFeatureCount;
                this.onArraySplit();
            }
            },
            (error) => {this.notifyService.showWarn('Error', error); }
            );
    }

    onArraySplit() {
        this.inCompleteProfileArray = [[], []];
        const splitCount = Math.ceil(this.inCompleteProfile.length / 2);
        for (let line = 0; line < 2; line++) {
            for (let i = 0; i < splitCount; i++) {
                const value = this.inCompleteProfile[i + line * splitCount];
                if (!value) { continue; }
                this.inCompleteProfileArray[line].push(value);
            }
        }
    }

    onRedirectToStore() {
        let idendity = '';
        if (this.currentPlanDetails.orderNo && this.currentPlanDetails.skewCode) {
            idendity = this.currentPlanDetails.orderNo + '742' + (this.currentPlanDetails.skewCode).replace('.', '');
        }
        const url = this.loginDetails.storePurchase
            + 'idendity=' + idendity
            + '&eidendity=' + this.authenticationService.encryptUsingMD5(idendity)
            + '&pfamily_code=742-01.0';
        window.open(url, '_blank');
    }

    getAddOnList() {
        if (this.authenticationService.isAdmin()) {
            return;
        }
        const formObj = { 'loginMailId': this.authenticationService.getEmailId() };
        this.loginService.getAddons(formObj).subscribe(
            res => {
                if (res && res.status) {
                    this.addonsList = res;
                    this.myAddons = res.ecommerce;
                    this.allAddons = res.ecommerce;
                }
            },
            (err) => {
            });
    }

    async onAddonClick(addOnDetails) {
        await this.onClick(addOnDetails);
    }

    async onClick(addon: any) {
        console.log(addon);
        this.uiCommonService.showLoader('blocking');
        if (addon.purchased) {
            if (addon.subSkew.length) {
                this.authenticationService.setSkewCode(addon.subSkew.join(','));
            } else {
                this.authenticationService.setSkewCode(addon.productCode);
            }
            this.authenticationService.setSkewName(addon.addOnName);
            if (this.menuService.getAvaiSkew().includes(addon.productCode + ':' + this.authenticationService.getOutlet()) && addon.productCode === '744') {
                this.router.navigate(['/Integration/ecommerce/live-tracking-map/live']);
            } else if (this.menuService.getAvaiSkew().includes(addon.productCode + ':' + this.authenticationService.getOutlet())) {
                if (this.authenticationService.getSkewCode() === '535') {
                    localStorage.setItem('hideUnSyncOrders', 'false');
                    await this.setMenuDynamically(addon.productCode);
                } else if (this.authenticationService.getSkewCode() === '762') {
                    this.router.navigate(['/Integration/' + this.menuService.getDefaultPage(this.authenticationService.getSkewCode())]);
                } else if (this.authenticationService.getSkewCode() === '764') {
                    await this.setMenuDynamically(addon.productCode);
                    // await this.checkIsConfigured()
                } else if (this.authenticationService.getSkewCode() === '745') {
                    await this.setMenuDynamically(addon.productCode);
                } else if (this.authenticationService.getSkewCode() === '742') {
                    if (addon.expired) {
                        (Swal as any).fire({
                            title: '',
                            text: 'Your OrderEasy license is expired, click below to purchase',
                            showConfirmButton: true,
                            showCloseButton: true,
                            confirmButtonText: 'Purchase'
                        }).then(
                            (result) => {
                                if (result.value) {
                                    this.onRedirectToStore();
                                }
                            });
                        this.uiCommonService.hideLoader();
                    } else {
                        await this.setMenuDynamically(addon.productCode);
                    }
                } else if (this.authenticationService.getSkewCode() === '522') {
                    await this.setMenuDynamically(addon.productCode);
                } else if (this.authenticationService.getSkewCode() === '538') {
                    await this.setMenuDynamically(addon.productCode);
                } else if (this.authenticationService.getSkewCode() === '776') {
                    await this.setMenuDynamically(addon.productCode);
                } else if (this.authenticationService.getSkewCode() === '809') {
                    await this.setMenuDynamically(addon.productCode);
                } else if (this.authenticationService.getSkewCode() === '906') {
                    await this.setMenuDynamically(addon.productCode);
                } else {
                    this.menuService.removeParticularMenu('2');
                    this.router.navigate(['/Integration/' + this.menuService.getDefaultPage(this.authenticationService.getSkewCode())]);
                }
            } else {
                this.router.navigate(['/Integration/ecommerce/under-construct']);
            }
        } else {
            (Swal as any).fire({
                text: 'I want to experience ' + addon.displayName,
                type: 'question',
                showConfirmButton: true,
                showCloseButton: true,
                confirmButtonText: 'Know More'
            }).then(
                (result) => {
                    if (result.value) {
                        window.open('https://www.gofrugal.com/integration-pos', '_blank');
                    }
                });
            this.uiCommonService.hideLoader();
        }
    }

    async setMenuDynamically(skewCode) {
        const formObj = {
            'userId': this.authenticationService.getServiceUserId(),
            'skewCode': skewCode,
            'roleId': this.authenticationService.getRoleId()
        };
        this.loginService.getDynamicMenuFromHomePage(formObj).subscribe(
            (config) => {
                if (config) {
                    config.forEach(res => { res['link'] = res['link'] === 'false' ? false : res.link; });
                    this.menuListArray = config;
                    if (this.menuListArray.length > 0) {
                        const formMenu = [];
                        this.menuListArray.forEach(res => {
                            if (res.mappingStatus) {
                                formMenu.push(res.id);
                            }
                            if (res.sub && res.sub.length > 0) {
                                res.sub.forEach(res1 => {
                                    if (res1.mappingStatus) {
                                        formMenu.push(res1.id);
                                    }
                                });
                            }
                        });
                        this.menuService.setDynamicMenu(config);
                        console.log('setting menu');
                        this.menuService.setDynamicMenuFromJSON(formMenu);

                        for (let index = 0; index < formMenu.length; index++) {
                            const element = formMenu[index];
                            if (this.menuService.getMenuLinkPassingId(element) != false) {
                                this.router.navigate(['/Integration/' + this.menuService.getMenuLinkPassingId(element)]);
                                break;
                            }
                        }
                    } else {
                        this.uiCommonService.hideLoader();
                        this.notifyService.showWarn('Warning', 'Menu is not mapped . Please contact admin to map menu');
                    }
                }
            },
            (err) => {
            }
        );
    }

    onTabChange(type) {
        this.addOnListView = type;
    }

    onCopyContent() {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = 'ordereasy-support@gofrugal.com';
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.copyOption = 'copied';
        setTimeout(function () {
            this.copyOption = 'copy';
        }.bind(this), 1000);
    }

    onMatTooltipDisable(itemName) {
        if (itemName.length < 16) {
            return true;
        } else {
            return false;
        }
    }

    getColorBySkewCode(addonCode) {
        switch (addonCode) {
            case '742':
                return '#13153A';
            case '708':
                return '#30C2C4';
            case '538':
                return '#F84C5D';
            case '517':
                return '#3CA3C2';
            case '519':
                return '#81C242';
            case '522':
                return '#95BE46';
            case '532':
                return '#000000';
            case '533':
                return '#000000';
            case '535':
                return '#FE3F47';
            case '554':
                return '#7EB53D';
            case '604':
                return '#3098F7';
            case '708':
                return '#F84C5D';
            case '719':
                return '#CB1723';
            case '720':
                return '#042E6E';
            case '721':
                return '#0FD0CF';
            case '724':
                return '#EE140A';
            case '729':
                return '#235BA8';
            case '730':
                return '#61AB18';
            case '731':
                return '#E95A2E';
            case '732':
                return '#AE282F';
            case '737':
                return '#FCAE16';
            case '741':
                return '#F58630';
            case '744':
                return '#F02425';
            case '745':
                return '#9C5E91';
            case '751':
                return '#FF9F0A';
            case '764':
                return '#FE9801';
            case '773':
                return '#0CC1B0';
            case '774':
                return '#052556';
            case '776':
                return '#4686EC';
            case '777':
                return '#378FCE';
            case '809':
                return '#68C2E7';

            default:
                return '#000000';
        }
    }

    openLink(url: string) {
        window.open(url, '_blank');
    }

    onProfileCompleteRedirect(menuId?) {
        if (menuId) {
            const urlDetails = this.profileUrlList.filter(res => res.id == menuId)[0];
            if (urlDetails) {
                this.router.navigate([urlDetails.routerLink]);
            }
        } else {
            const inCompleteProfile = this.inCompleteProfile.filter(element => element.status == false)[0];
            if (inCompleteProfile) {
                const urlDetails = this.profileUrlList.filter(res => res.id == inCompleteProfile.rowNum)[0];
                if (urlDetails) {
                    this.router.navigate([urlDetails.routerLink]);
                }
            }
        }
    }

    onUploadImage(files: FileList) {
        const fileType = files[0].type;
        if (fileType.match(/image\/*/) === null) {
            this.notifyService.showWarn('Warning', 'Only jpg, jpeg and png files are allowed.');
            return;
        }
        if (fileType == 'image/gif') {
            this.notifyService.showWarn('Warning', 'Only jpg, jpeg and png files are allowed.');
            return;
        }
        const fr = new FileReader();
        fr.onload = () => {
            const img = new Image();
            img.onload = async () => {
                const width = img.width;
                const height = img.height;
                if (width < 100 || height < 100) {
                    this.notifyService.showWarn('Warning', 'Image dimension should not be less than 100x100');
                } else {
                    const reader = new FileReader();
                    reader.readAsDataURL(files.item(0));
                    reader.onload = () => {
                        this.profilePicUrl = reader.result;
                    };
                    this.profilePicImage = await this.imageCompressService.compressImage(files[0]);
                    this.onProfileImageUpload();
                }
            };
            img.src = fr.result as string;
        };
        fr.readAsDataURL(files.item(0));
    }

    async onProfileImageUpload() {
        const postJson = {
            'userId': '' + this.authenticationService.getServiceUserId(),
            'vendor': this.authenticationService.getSkewName(),
            'emailId': this.authenticationService.getEmailId(),
            'profileImage': await this.imageCompressService.toBase64(this.profilePicImage)
        };
        this.loginService.profilePicUpload(postJson).subscribe(
            (data) => {
                if (data && data.status) {
                    this.notifyService.showSuccess('Success', data.message);
                    window.location.reload();
                    // this.onGetMyProfileDetails();
                } else {
                    this.notifyService.showWarn('Warning', data.message);
                }
            },
            (err) => {
                this.notifyService.showError('Error', err);
            });
    }

    logout() {
        this.router.navigate(['/logout']);
    }

    getTimeZoneList() {
        this.loginService.getTimeZoneList().subscribe((res) => {
            if (res) {
                console.log(res);
                this.zoneList = res.availableZones;
                this.selectedZone = res.timeZoneValue;
            }
        },
        error => {
            this.notifyService.showWarn('Warn', error);
        });
    }

    setTimeZone() {
        const body = {
            'userId': this.authenticationService.getServiceUserId(),
            'timeZoneValue': this.selectedZone,
        };
        this.loginService.updateTimeZone(body).subscribe((res) => {
            if (res && res.status) {
                this.notifyService.showSuccess('Success', res.message);
                this.getTimeZoneList();
            } else {
                this.notifyService.showWarn('Warn', res.message);
            }
        },
        error => {
            this.notifyService.showWarn('Warn', error);
        });
    }
}
