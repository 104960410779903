export class User {
    serviceUserId: number;
    authKey: string;
    roleId: number;
    email: string;
    userName: string;
    outletId: string;
    skewCode: string;
    skewName: string;
    verticalId: string;
    baseProduct: string;
    custId: string;
    domainPassword: string;
    domainUrl: string;
    isConfigured: number;
    userId: number;
    domainPass: string;
    authEmpId: number;
    authId: number;
    customerId: any;
    vertical: string;
    productCode: string;
}

export interface Login {
    status: string;
    message: string;
    authKey: string;
    userId: string;
    roleId: number;
    userName: string;
    outletId: string;
    verticalId: string;
    baseProduct: string;
    domainPass: string;
    domainUrl: string;
}

export interface LoginResponse {
    status: string;
    message: string;
    skipOtp: string;
}

export class AutoLogin {
    identity: string;
    eidentity: string;
    userId: number;
    userName: string;
    password: string;
    custId: string;
}

export class Register {
    identity: string;
    eidentity: string;
    userId: number;
    emailId: string;
    contact: string;
    password: string;
    customerId: string;
    userName: string;
    mobileNo: string;
    credentials: string;
    otp: string;
}

export class EmailIds {
    mail: string;
}

export interface AddonMapping {
    skewCode: string;
    addonName: string;
    status: boolean;
}

export interface RoleList {
    id: number;
    name: string;
    description: string;
    status: boolean;
    addonMappings: AddonMapping[];
}

export interface ActiveAddOnList {
    displayName: string;
    subSkew: any[];
    addOnName: string;
    productCode: string;
    isNew: boolean;
    purchased: boolean;
    addonType: string;
    isTrending: boolean;
    menuMapping?: MenuMaster[];
}

export interface SubMenuMaster {
    id: string;
    parentId: string;
    name: string;
    link: string;
    icon: string;
    open: boolean;
    hidden: boolean;
    status?: any;
    mappingStatus: boolean;
}

export interface MenuMaster {
    id: string;
    name: string;
    icon: string;
    open: boolean;
    hidden: boolean;
    link: any;
    status?: any;
    mappingStatus: boolean;
    enableScroll?: boolean;
    sub?: SubMenuMaster[];
}

export interface EmployeeDetails {
    userName: string;
    outletType?: any;
    userType?: any;
    doorNo: string;
    streetName: string;
    city: string;
    state: string;
    pincode: string;
    area: string;
    landmark: string;
    country: string;
    password: string;
    roleId: string;
    createdAt: string;
    mobileNumber?: any;
    emailId: string;
    baseProductId: string;
    reportingTo: string;
    reportingType: string;
    integrationAccountId?: any;
    status: string;
    outletIds?: any;
    outletCustomerId: string;
    profileUrl?: any;
    mode: string;
}

export interface EmployeeList {
    totalRecords: number;
    perPage: number;
    data: EmployeeDetails[];
    count: number;
    totalPages: number;
    currentPage: number;
}

export class MasterImport {
        skuCode: any;
        aliasCode: any;
        itemName: any;
        itemQuantity: any;
        itemMrp: any;
        itemSalePrice: any;
        itemTaxPercentage: any;
        isExempted: any;
        taxInclusiveFlag: any;
        categoryType1: any;
        categoryType2: any;
        categoryType3: any;
        status: any;
        isReturnable: any;
        isCancellable: any;
        returnPeriod: any;
        weightGrams: any;
        uom: any;
        manufacturerName: any;
}


export interface SelectedEmployeeDetails {
    userName: string;
    mobileNumber: string | number;
    emailId: string;
    status: boolean | string;
    roleId: string | number;
}
