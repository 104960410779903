import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {StoreListing} from '../../../../../core/models';
import * as Handsontable from 'handsontable';
import {EcommerceService} from '../../ecommerce.service';
import {NotificationService} from '../../../../../core/services/notification.service';
import {MasterCreationService} from '../master-creation/master-creation.service';
import {SharedService} from '../../../../../shared/service/shared.service';
import {AuthenticationService} from '../../../../../core/services/authentication.service';
import {UiCommonService} from '../../../../../shared/service/uiCommon.service';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';

declare var require: any;
const _ = require('lodash');

@Component({
    selector: 'app-category-master-bulk-import',
    templateUrl: './category-master-bulk-import.component.html',
    styleUrls: ['./category-master-bulk-import.component.scss']
})
export class CategoryMasterBulkImportComponent implements OnInit {

    display = 'none';
    public itemSearchText = '';
    dataset: any[] = [];
    storeListings: StoreListing[];
    selectedOutlet: StoreListing = null;
    selectedMaster = 'Category';
    public ELEMENT_DATA = [];
    configurationDetails: any;
    private hotInstance: Handsontable;
    item1File: File = null;
    totalRecords = 0;
    records = [];
    pageAction = 'Upload';
    editRecord: any = [];
    pageSize = 5;
    pageIndex = 1;
    sortValue = 'catValueId asc';
    splitedData = [];
    displayItems = false;
    displayCategory = false;
    loadExistingData = false;
    editedRecoreds: any = [];
    allowBulkUploadEdit = false;
    filteredItems: any;
    unFilteredItems: any;
    isOndcAddonActive = false;
    columns = [
        {
            data: 'catValueId',
            title: 'Category ID',
            type: 'text',
            readOnly: true
        },
        {
            data: 'catValueName',
            title: 'Category Name',
            type: 'text'
        },
        {
            data: 'categoryName',
            title: 'Category Type',
            type: 'text',
            readOnly: true
        },
        {
            data: 'ondcId',
            title: 'ONDC Category ID',
            type: 'text',
        },
        {
            data: 'status',
            title: 'STATUS(ACTIVE/INACTIVE)',
            type: 'dropdown',
            source: ['Active', 'Inactive'],
            allowInvalid: false
        },
        {
            data: 'appliesOnline',
            title: 'APPLIES ONLINE(YES/NO)',
            type: 'dropdown',
            source: ['Yes', 'No'],
            allowInvalid: false
        },
        {
            data: 'category1Name',
            title: 'Category 1',
            type: 'text'
        },
        {
            data: 'cat1ONDCId',
            title: 'Category 1 ID',
            type: 'text'
        },
        {
            data: 'category2Name',
            title: 'Category 2',
            type: 'text'
        },
        {
            data: 'cat2ONDCId',
            title: 'Category 2 ID',
            type: 'text'
        },
        {
            data: 'category3Name',
            title: 'Category 3',
            type: 'text'
        },
        {
            data: 'cat3ONDCId',
            title: 'Category 3 ID',
            type: 'text'
        },
        {
            data: 'category4Name',
            title: 'Category 4',
            type: 'text'
        },
        {
            data: 'category5Name',
            title: 'Category 5',
            type: 'text'
        },
        {
            data: 'category6Name',
            title: 'Category 6',
            type: 'text'
        },
        {
            data: 'category7Name',
            title: 'Category 7',
            type: 'text'
        },
        {
            data: 'category8Name',
            title: 'Category 8',
            type: 'text'
        },
        {
            data: 'category9Name',
            title: 'Category 9',
            type: 'text'
        },
        {
            data: 'category10Name',
            title: 'Category 10',
            type: 'text'
        },
        {
            data: 'validity',
            title: 'VALIDITY',
            type: 'text',
            renderer: this.customRenderer,
        }
    ];

    fieldNameList = {
        'Category ID': 'catValueId',
        'Category Name': 'catValueName',
        'Category Type': 'categoryName',
        'STATUS(ACTIVE/INACTIVE)': 'status',
        'APPLIES ONLINE(YES/NO)': 'appliesOnline',
        'Category 1': 'category1Name',
        'Category 1 ID': 'cat1ONDCId',
        'Category 2': 'category2Name',
        'Category 2 ID': 'cat2ONDCId',
        'Category 3': 'category3Name',
        'Category 3 ID': 'cat3ONDCId',
        'Category 4': 'category4Name',
        'Category 5': 'category5Name',
        'Category 6': 'category6Name',
        'Category 7': 'category7Name',
        'Category 8': 'category8Name',
        'Category 9': 'category9Name',
        'Category 10': 'category10Name',
        'ONDC Category ID': 'ondcId',
        'VALIDITY': 'validity',
    };
    isONDC = false;
    color = 'primary';
    mode = 'determinate';
    value = 0;
    dataValidation = false;
    isOndcAdmin = false;
    isStandAloneOndc = false;
    private readonly subscription: Subscription;
    selectedOutletID = this.authService.getSelectedOutletId();
    uploadArray = ['Category ID', 'Category Name', 'Category Type', 'STATUS(ACTIVE/INACTIVE)', 'APPLIES ONLINE(YES/NO)', 'Category 1 ID', 'Category 2 ID', 'Category 3 ID', 'ONDC Category ID'];
    uploadOndcArray = ['Category ID', 'Category Name', 'Category Type', 'STATUS(ACTIVE/INACTIVE)', 'APPLIES ONLINE(YES/NO)', 'ONDC Category ID', 'Category 4', 'Category 5', 'Category 6', 'Category 7', 'Category 8', 'Category 9', 'Category 10'];
    uploadMarketplaceArray = ['Category ID', 'Category Name', 'Category Type', 'STATUS(ACTIVE/INACTIVE)', 'APPLIES ONLINE(YES/NO)', 'ONDC Category ID', 'Category 1 ID', 'Category 2 ID', 'Category 3 ID', 'Category 4', 'Category 5', 'Category 6', 'Category 7', 'Category 8', 'Category 9', 'Category 10'];
    updateArray = ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5', 'Category 6', 'Category 7', 'Category 8', 'Category 9', 'Category 10', 'Category 1 ID', 'Category 2 ID', 'Category 3 ID', 'ONDC Category ID'];
    updateONDCArray = ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5', 'Category 6', 'Category 7', 'Category 8', 'Category 9', 'Category 10', 'Category 1 ID', 'Category 2 ID', 'Category 3 ID'];
    updateMarketplaceArray = ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5', 'Category 6', 'Category 7', 'Category 8', 'Category 9', 'Category 10', 'Category 1 ID', 'Category 2 ID', 'Category 3 ID', 'ONDC Category ID'];
    @ViewChild('fileUploader', {static: false}) fileUploader: ElementRef;
    @ViewChild('hot', {static: false}) hot: Handsontable;
    hideColumns: any[];

    constructor(private readonly ecomService: EcommerceService,
                private readonly notifyService: NotificationService,
                private readonly masterService: MasterCreationService,
                private readonly sharedService: SharedService,
                private readonly authService: AuthenticationService,
                private readonly changeDetectorRef: ChangeDetectorRef,
                private readonly uiCommonService: UiCommonService,
                private readonly formatter: NgbDateParserFormatter,
                private readonly authenticationService: AuthenticationService,
                private readonly route: ActivatedRoute,
                private readonly router: Router,
    ) {
        this.subscription = this.sharedService.getOutletId().subscribe(
            event => {
                this.selectedOutletID = this.authService.getSelectedOutletId();
            });
    }

    ngOnInit() {
        this.isOndcAdmin = this.authService.getSkewCode() === '910' && this.authService.getMultiAddonDetails('923');
        this.isStandAloneOndc = (this.authService.getSkewCode() === '917' && this.authService.getMultiAddonDetails('923')) || (this.authService.getSkewCode() === '917');
        this.isOndcAddonActive = this.authService.getSkewCode() === '923' || this.isOndcAdmin;
        this.pageAction = this.route.snapshot.queryParams.action;
        this.hideColumnsFn();
        this.categoryMater();
    }

    categoryMater() {
        if (this.pageAction !== 'Upload') {
            this.splitedData = [];
            this.dataValidation = true;
            this.value = 0;
            this.getCategoryDetailsList('', '', 1);
        }
        this.dataset = [];
        this.itemSearchText = '';
        this.fileUploader.nativeElement.value = null;
    }

    hideColumnsFn() {
        this.hideColumns = [...this.columns];
        this.hideColumns.forEach(col => {
            if (this.pageAction === 'Upload') {
                const arr = this.isOndcAdmin ? this.uploadOndcArray : this.isStandAloneOndc ? this.uploadArray : this.uploadMarketplaceArray;
                if (arr.includes(col.title)) {
                    const index = this.columns.findIndex((el) => el.title === col.title);
                    if (index > -1) {
                        this.columns.splice(index, 1);
                    }
                }
            } else {
                const arr = this.isOndcAdmin ? this.updateONDCArray : this.isStandAloneOndc ? this.updateArray : this.updateMarketplaceArray;
                if (arr.includes(col.title)) {
                    const index = this.columns.findIndex((el) => el.title === col.title);
                    if (index > -1) {
                        this.columns.splice(index, 1);
                    }
                }
            }
        });
    }

    downloadTemplate() {
        if (this.isOndcAdmin) {
            return 'https://gf-oe-ordereasy-qa-c0nn3c7.s3.amazonaws.com/images/OrderEasy/18826/1685584041408_f6178fcb267e444b8d69b0170d9cbdca.csv';
        } else {
            return 'https://gf-oe-ordereasy-qa-c0nn3c7.s3.amazonaws.com/images/OrderEasy/18826/1683612225432_6c350b9158c347c6a98415c5d009e13e.csv';
        }
    }

    updateColumns() {
        this.columns  = [
            {
                data: 'catValueId',
                title: 'Category ID',
                type: 'text',
                readOnly: true
            },
            {
                data: 'catValueName',
                title: 'Category Name',
                type: 'text'
            },
            {
                data: 'categoryName',
                title: 'Category Type',
                type: 'text',
                readOnly: true
            },
            {
                data: 'ondcId',
                title: 'ONDC Category ID',
                type: 'text',
            },
            {
                data: 'status',
                title: 'STATUS(ACTIVE/INACTIVE)',
                type: 'dropdown',
                source: ['Active', 'Inactive'],
                allowInvalid: false
            },
            {
                data: 'appliesOnline',
                title: 'APPLIES ONLINE(YES/NO)',
                type: 'dropdown',
                source: ['Yes', 'No'],
                allowInvalid: false
            },
            {
                data: 'category1Name',
                title: 'Category 1',
                type: 'text'
            },
            {
                data: 'cat1ONDCId',
                title: 'Category 1 ID',
                type: 'text'
            },
            {
                data: 'category2Name',
                title: 'Category 2',
                type: 'text'
            },
            {
                data: 'cat2ONDCId',
                title: 'Category 2 ID',
                type: 'text'
            },
            {
                data: 'category3Name',
                title: 'Category 3',
                type: 'text'
            },
            {
                data: 'cat3ONDCId',
                title: 'Category 3 ID',
                type: 'text'
            },
            {
                data: 'category4Name',
                title: 'Category 4',
                type: 'text'
            },
            {
                data: 'category5Name',
                title: 'Category 5',
                type: 'text'
            },
            {
                data: 'category6Name',
                title: 'Category 6',
                type: 'text'
            },
            {
                data: 'category7Name',
                title: 'Category 7',
                type: 'text'
            },
            {
                data: 'category8Name',
                title: 'Category 8',
                type: 'text'
            },
            {
                data: 'category9Name',
                title: 'Category 9',
                type: 'text'
            },
            {
                data: 'category10Name',
                title: 'Category 10',
                type: 'text'
            },
            {
                data: 'validity',
                title: 'VALIDITY',
                type: 'text',
                renderer: this.customRenderer,
            }
        ];
    }

    getCategoryDetailsList(filter, orFilter, pageIndex) {
        this.splitedData = [];
        this.ecomService.getCategoryMaster(this.selectedOutletID, pageIndex, '1000', filter, orFilter, this.sortValue).subscribe(
            response => {
                if (response && response.category) {
                    const len = Math.ceil(response.totalRecords / 1000);
                    this.splitedData = [...this.splitedData, ...response.category];
                    this.setDataValidationValue(this.splitedData.length, response.totalRecords);
                    if (pageIndex < len) {
                        this.getCategoryDetailsList(filter, orFilter, pageIndex + 1);
                    } else {
                        this.dataset = this.splitedData;
                        this.records = JSON.parse(JSON.stringify(this.dataset));
                        this.editRecord = JSON.parse(JSON.stringify(this.records));
                        this.changeStatus('fromService', this.records);
                        this.changeStatus('fromService', this.editRecord);
                        this.totalRecords = this.editRecord.length;
                        this.dataValidate();
                        this.dataValidation = false;
                    }
                }
            },
            (error) => {
                this.notifyService.showWarn('Error', error);
            },
            () => {
            }
        );
    }

    changeStatus(event, itemsObj) {
        this.removeUnusedColumns();
        itemsObj.map(items => {
            if (Object.keys(items).length) {
                if (event === 'fromService') {
                    if (items.status) {
                        items.status = items.status === 'Y' ? 'Active' : 'InActive';
                    }
                    if (items.appliesOnline || items.appliesOnline === 0) {
                        items.appliesOnline = items.appliesOnline ? 'Yes' : 'No';
                    }
                }
            }
            if (items.validity) {
                delete items.validity;
            }
        });
    }

    public onAfterInit = (hotInstance) => {
        this.hotInstance = hotInstance;
    }

    updateData = (hotInstance, changes, source) => {
        if (changes !== null) {
            if (this.editedRecoreds.length > 0) {
                let contain = 0;
                this.editedRecoreds.map(item => {
                    changes.forEach((newArr) => {
                        if (newArr[2] !== newArr[3]) {
                            if (item.catValueId === this.editRecord[newArr[0]].catValueId) {
                                item[newArr[1]] = newArr[3];
                                contain += 1;
                            }
                        }
                    });
                });
                if (contain === 0) {
                    this.editValueToObjs(changes);
                }

            } else {
                this.editValueToObjs(changes);
            }
            this.dataValidate();
            this.updateColumns();
            this.hideColumnsFn();
        }
    }

    updateCell = (hotInstance, changes, source) => {
        if (changes !== null) {
            this.dataValidate();
            this.updateColumns();
            this.hideColumnsFn();
        }
    }

    setDataValidationValue(len, totalLength) {
        this.value = Math.round((len / totalLength) * 100);
    }

    isDuplicateCategory(data, checkItem) {
        const valueArr = data.map((item) => JSON.stringify(this.removeEmptyField({...item, validity: ''})));
        const valueItem = JSON.stringify(this.removeEmptyField({...checkItem, validity: ''}));
        const counts = {};
        for (const num of valueArr) {
            counts[num] = counts[num] ? counts[num] + 1 : 1;
        }
        return counts[valueItem] > 1;
    }

    removeEmptyField(data) {
        for (const val in data) {
            if (data[val] === '' || data[val] === null) {
                delete data[val];
            }
        }
        return data;
    }

    removeNullValues(obj) {
        obj.forEach((item, index) => {
            const checkItem = {...item};
            if (Object.values(checkItem).every(x => x === null || x === '')) {
                obj.splice(index, 1);
            }
            if (Object.keys(checkItem).length === 0) {
                obj.splice(index, 1);
            }
        });
        return obj;
    }

    dataValidate() {
        this.removeUnusedColumns();
        this.setTotalRecords();
        const data = this.pageAction === 'Upload' ? 'dataset' : 'editRecord';
        this[data].forEach((item, index) => {
            if (Object.keys(item).length) {
                const isEmpty = Object.values(item).every((x: any) => {
                    return x === null || x === '' || !x.length;
                });
                if (!isEmpty) {
                    if (this.pageAction === 'Upload') {
                        if (this.isOndcAddonActive) {
                            if (!item.category1Name) {
                                item['validity'] = 'Enter Category Name 1';
                            } else if (!item.cat1ONDCId) {
                                item['validity'] = 'Enter Category 1 ID';
                            } else if (item.category3Name && !item.category2Name) {
                                item['validity'] = 'Enter Category Name 2';
                            } else if (!item.category2Name) {
                                item['validity'] = 'Enter Category Name 2';
                            } else if (!item.cat2ONDCId) {
                                item['validity'] = 'Enter Category 2 ID';
                            } else if (!item.category3Name) {
                                item['validity'] = 'Enter Category Name 3';
                            } else if (!item.cat3ONDCId) {
                                item['validity'] = 'Enter Category 3 ID';
                            } else {
                                item['validity'] = 'Valid';
                            }
                        } else {
                            if (!item.category1Name) {
                                item['validity'] = 'Enter Category Name 1';
                            } else if (item.category3Name && !item.category2Name) {
                                item['validity'] = 'Enter Category Name 2';
                            } else if (item.category4Name && !item.category3Name) {
                                item['validity'] = 'Enter Category Name 3';
                            } else if (item.category5Name && !item.category4Name) {
                                item['validity'] = 'Enter Category Name 4';
                            } else if (item.category6Name && !item.category5Name) {
                                item['validity'] = 'Enter Category Name 5';
                            } else if (item.category7Name && !item.category6Name) {
                                item['validity'] = 'Enter Category Name 6';
                            } else if (item.category8Name && !item.category7Name) {
                                item['validity'] = 'Enter Category Name 7';
                            } else if (item.category9Name && !item.category8Name) {
                                item['validity'] = 'Enter Category Name 8';
                            } else if (item.category10Name && !item.category9Name) {
                                item['validity'] = 'Enter Category Name 9';
                            } else if (this.isDuplicateCategory(this[data], item)) {
                                item['validity'] = 'This Category Combination already exists!!';
                            } else {
                                item['validity'] = 'Valid';
                            }
                        }
                    } else {
                        if (!item.catValueName) {
                            item['validity'] = 'Enter Category Name';
                        } else if (!item.appliesOnline) {
                            item['validity'] = 'Enter applies online';
                        } else if (!item.status) {
                            item['validity'] = 'Enter status';
                        } else if ((item.appliesOnline.toLowerCase() !== 'yes') && (item.appliesOnline.toLowerCase() !== 'no')) {
                            item['validity'] = 'Kindly Check Applies Online Value';
                        } else if ((item.status.toLowerCase() === 'inactive') && (item.appliesOnline.toLowerCase() === 'yes')) {
                            item['validity'] = 'Status is inactive, so kindly change applies online value - No';
                        } else {
                            item['validity'] = 'Valid';
                        }
                    }
                } else {
                    item['validity'] = null;
                }
            }
            // if (this[data].length === index + 1) {
            //     this.dataValidation = false;
            // }
        });
        this[data] = this.removeNullValues(this[data]);
    }

    onUpload() {
        const formData = new FormData();
        formData.append('image', this.item1File);
        this.showDataInTable(this.item1File);
        this.onCloseHandled();
        // this.postCSV(formData, type);
        this.fileUploader.nativeElement.value = null;
    }

    showDataInTable(data) {
        const reader = new FileReader();
        reader.readAsText(data);

        reader.onload = () => {
            const csvData = reader.result;
            const csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
            this.getHeaderArray(csvRecordsArray);
            const headers = (<string>csvRecordsArray[0]).split(',');
            const headerArray = [];

            for (let j = 0; j < headers.length; j++) {
                headerArray.push(headers[j]);
            }
            let isInvalid = false;
            const addedArr = this.columns.map((item) => {
                return item.title;
            });
            addedArr.splice(addedArr.length - 1, 1);
            if (headerArray.length !== addedArr.length) {
                this.notifyService.showWarn('Warn', 'Invalid Format, Columns are missing');
                return;
            } else {
                for (const col of addedArr) {
                    const index = headerArray.findIndex((e) => {
                        return e === col;
                    });
                    if (index < 0) {
                        isInvalid = true;
                        this.notifyService.showWarn('Warn', `Check ${col} column name`);
                        return;
                    }
                }
            }
            if (isInvalid) {
                return;
            }
            const arr = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headerArray);
            this.records = this.convertArrtoObj(arr, headerArray);
            this.dataset = this.records;
            this.dataValidate();
            reader.onerror = function () {
            };
        };
    }

    getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerArray) {
        try {
            const csvArr = [];
            for (let i = 1; i < csvRecordsArray.length; i++) {
                let masterImport = [];
                let updatedArr = [];
                masterImport = (<string>csvRecordsArray[i]).split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
                if (masterImport.length === headerArray.length) {
                    updatedArr = masterImport.map((item) => {
                        return item.split('"').join('');
                    });
                    csvArr.push(updatedArr);
                }
            }
            return csvArr;
        } catch (e) {
            this.notifyService.showWarn('Error', 'Invalid format');
        }

    }

    convertArrtoObj(curruntRecord, headerArray) {
        const newHeaderArray = headerArray.map(arr => this.fieldNameList[arr]);
        const resultArray = curruntRecord.map(curRec => {
            const output = this.createObjects(newHeaderArray, curRec);
            return output;
        });
        return resultArray;
    }

    createObjects(keysArray, valuesArray) {
        return (<any>Object).fromEntries(keysArray.map((value, index) => [value, valuesArray[index].trim()]));
    }

    getHeaderArray(csvRecordsArr: any) {
        const headers = (<string>csvRecordsArr[0]).split(',');
        const headerArray = [];
        for (let j = 0; j < headers.length; j++) {
            headerArray.push(headers[j]);
        }
        return headerArray;
    }

    customRenderer(instance, td, row, col) {
        const cellValue = instance.getDataAtCell(row, col);
        if (cellValue === 'Valid') {
            td.style.backgroundColor = '#91fa5c';
        } else if (cellValue !== null) {
            td.style.backgroundColor = '#f68c86';
        }
        Handsontable.renderers.TextRenderer.apply(this, arguments);
    }

    onCloseHandled() {
        this.display = 'none';
    }

    setTotalRecords() {
        const data = this.pageAction === 'Upload' ? 'dataset' : 'editRecord';
        const totalData = [...this[data]];
        const invalidIndex = [];
        for (let i = 0; i < totalData.length; i++) {
            if (!Object.keys(totalData[i]).length || Object.values(totalData[i]).every(x => !x)) {
                invalidIndex.push(i);
            }
        }
        this.totalRecords = totalData.length - invalidIndex.length;
    }

    loadConfigurations() {
        this.dataset = [];
        if (this.selectedMaster != null) {
            if (this.selectedMaster === 'Items') {
                this.displayItems = true;
                this.displayCategory = false;
            } else {
                this.displayCategory = true;
                this.displayItems = false;
            }
        } else {
            this.displayItems = false;
            this.displayCategory = false;
        }
        this.loadExistingData = false;
    }

    postData(event, itemsObj) {
        itemsObj.map(items => {
            if (Object.keys(items).length) {
                if (event === 'toService') {
                    if (items.status) {
                        items.status = items.status.toLowerCase() === 'active' ? 'Y' : 'N';
                    }
                    if (items.appliesOnline) {
                        items.appliesOnline = items.appliesOnline.toLowerCase() === 'yes' ? 1 : 0;
                    }
                }
            }
        });
    }

    csvInputChange(files: FileList) {
        this.item1File = files.item(0);
    }

    onSaveHandled() {
        const hot = this.hotInstance;
        this.loadExistingData = false;
        this.removeUnusedColumns();
        if (hot.isEmptyRow(0)) {
            this.notifyService.showWarn('Error', 'No Data to Save');
        } else {
            this.uploadItem();
        }
    }

    onClearHandled() {
        this.loadExistingData = false;
        this.removeUnusedColumns();
        this.dataset = [];
        this.records = [];
        this.totalRecords = 0;
        this.item1File = null;
    }

    afterChange(e: any, hot) {
        // some commands
        hot.getHandsontableInstance().render();
    }

    removeUnusedColumns() {
        const data = this.pageAction === 'Upload' ? 'dataset' : 'editRecord';
        const validFieldArrays = this.columns.map(col => col.data);
        const validityIndex = validFieldArrays.indexOf('validity');
        if (validityIndex > 1) {
            validFieldArrays.splice(validityIndex, 1);
        }
        this[data].forEach((item, index) => {
            const validArray = [];
            for (const x in item) {
                validArray.push(!!((validFieldArrays.includes(x)) && (item[x] !== null && item[x] !== '')));
            }
            if (validArray.every(x => x === false)) {
                this[data].splice(index, 1);
            }
        });
        this[data] = this.removeNullValues(this[data]);
        return this[data];
    }

    // Edited value push to new array
    editValueToObjs(editedArr) {
        editedArr.forEach((newArr) => {
            this.editRecord[newArr[0]][newArr[1]] = newArr[3];
            this.editedRecoreds.push(this.editRecord[newArr[0]]);
        });
    }

    filterBySearchText(itemSearchText: any) {
        this.filteredItems = [];
        this.itemSearchText = itemSearchText.trim();

        if (this.itemSearchText !== '') {
            let getVar = this.pageAction === 'Upload' ? this.dataset : this.records;
            // getting filtered item list based on the itemName
            getVar = getVar.filter(item => !(_.isEmpty(item)));
            this.filteredItems = getVar.filter((element, index, array) => {
                if (element.catValueName) {
                    return (element.catValueName.toUpperCase().includes(this.itemSearchText.toUpperCase()));
                }
            });
            this.setDataSourceForHandsOnTable();
        } else {
            this.unFilteredItems = this.records;
            this.filteredItems = this.unFilteredItems;
            this.setDataSourceForHandsOnTable();
        }
    }

    setDataSourceForHandsOnTable() {
        const data = this.pageAction === 'Upload' ? 'dataset' : 'editRecord';
        if (this.itemSearchText === '') {
            this[data] = this.unFilteredItems;
            // this.count = this.unFilteredItems.length;
        } else {
            this[data] = this.filteredItems;
            // this.count = this.filteredItems.length;
        }
        this[data] = this.removeEmptyField(this[data]);
        this.hotInstance.loadData(this[data]);
        this.hotInstance.updateSettings({minRows: this.filteredItems.length}, true);
        this.dataValidate();
    }

    itemValidation(data) {
        let errorMsg = '';
        for (const item of data) {
            if (Object.keys(item).length) {
                const isEmpty = Object.values(item).every((x: any) => {
                    return x === null || x === '' || !x.length;
                });
                if (!isEmpty) {
                    if (this.pageAction === 'Upload') {
                        if (this.isOndcAddonActive) {
                            if (!item.category1Name) {
                                errorMsg = 'Enter Category Name 1';
                                return errorMsg;
                            } else if (!item.cat1ONDCId) {
                                errorMsg = 'Enter Category 1 ID';
                                return errorMsg;
                            } else if (item.category3Name && !item.category2Name) {
                                errorMsg = 'Enter Category Name 2';
                                return errorMsg;
                            } else if (!item.category2Name) {
                                errorMsg = 'Enter Category Name 2';
                                return errorMsg;
                            } else if (!item.cat2ONDCId) {
                                errorMsg = 'Enter Category 2 ID';
                                return errorMsg;
                            } else if (!item.category3Name) {
                                errorMsg = 'Enter Category Name 3';
                                return errorMsg;
                            } else if (!item.cat3ONDCId) {
                                errorMsg = 'Enter Category 3 ID';
                                return errorMsg;
                            }
                        } else {
                            if (!item.category1Name) {
                                errorMsg = 'Enter Category Name 1';
                                return errorMsg;
                            } else if (item.category3Name && !item.category2Name) {
                                errorMsg = 'Enter Category Name 2';
                                return errorMsg;
                            } else if (item.category4Name && !item.category3Name) {
                                errorMsg = 'Enter Category Name 3';
                                return errorMsg;
                            } else if (item.category5Name && !item.category4Name) {
                                errorMsg = 'Enter Category Name 4';
                                return errorMsg;
                            } else if (item.category6Name && !item.category5Name) {
                                errorMsg = 'Enter Category Name 5';
                                return errorMsg;
                            } else if (item.category7Name && !item.category6Name) {
                                errorMsg = 'Enter Category Name 6';
                                return errorMsg;
                            } else if (item.category8Name && !item.category7Name) {
                                errorMsg = 'Enter Category Name 7';
                                return errorMsg;
                            } else if (item.category9Name && !item.category8Name) {
                                errorMsg = 'Enter Category Name 8';
                                return errorMsg;
                            } else if (item.category10Name && !item.category9Name) {
                                errorMsg = 'Enter Category Name 9';
                                return errorMsg;
                            } else if (this.isDuplicateCategory(data, item)) {
                                errorMsg = 'Category Combination already exists, Kindly Check';
                                return errorMsg;
                            }
                        }
                    } else {
                        if (!item.catValueName) {
                            errorMsg = 'Enter Category Name';
                            return errorMsg;
                        } else if (!item.appliesOnline) {
                            errorMsg = 'Enter applies online';
                            return errorMsg;
                        } else if (!item.status) {
                            errorMsg = 'Enter status';
                            return errorMsg;
                        } else if ((item.appliesOnline.toLowerCase() !== 'yes') && (item.appliesOnline.toLowerCase() !== 'no')) {
                            errorMsg = 'Kindly Check Applies Online Value';
                            return errorMsg;
                        } else if ((item.status.toLowerCase() === 'inactive') && (item.appliesOnline.toLowerCase() === 'yes')) {
                            errorMsg = 'Status is inactive, so kindly change applies online value - No';
                            return errorMsg;
                        }
                    }
                }
            }
        }
        return errorMsg;
    }

    splitReqBody() {
        let itemToSplit = [];
        itemToSplit = this.pageAction === 'Upload' ? this.dataset : this.editedRecoreds;
        const isValid = this.itemValidation(itemToSplit);
        if (isValid) {
            this.notifyService.showWarn('Warning', isValid);
            return;
        }
        if (this.pageAction === 'Upload') {
            this.postData('toService', this.dataset);
        } else if (this.pageAction === 'Edit') {
            if (!this.editedRecoreds.length) {
                this.notifyService.showWarn('Warning', 'No changes to Save');
                return;
            }
            this.postData('toService', this.editedRecoreds);
        }
        let itemReq = [];
        itemReq = this.sliceIntoChunks(itemToSplit, 300);
        let count = itemReq.length;
        let i = 0;
        let len = 0;
        this.dataValidation = true;
        const totalLength = itemToSplit.length;
        const interval = setInterval(() => {
            if (i >= itemReq.length) {
                this.dataValidation = false;
                clearInterval(interval);
            } else {
                len = len + itemReq[i].length;
                this.setDataValidationValue(len, totalLength);
                this.value = Math.round((len / totalLength) * 100);
                let itemBulkUpload = {
                    'userId': this.authenticationService.getServiceUserId(),
                    'vendor': this.authenticationService.getSkewName(),
                    'outletId': this.selectedOutletID,
                };
                const fieldName = this.pageAction === 'Upload' ? 'categoryCreations' : 'categoryUpdates';
                itemBulkUpload[fieldName] = itemReq[i];
                count -= 1;
                this.postDataToService(itemBulkUpload, count);
                i++;
            }
        }, 3000);
    }

    sliceIntoChunks(arr, chunkSize) {
        arr = arr.filter(item => !(_.isEmpty(item)));
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }
        return res;
    }

    // API Calls
    uploadItem() {
        this.removeUnusedColumns();
        this.splitReqBody();
    }

    postDataToService(obj, count) {
        if (this.pageAction === 'Upload') {
            this.ecomService.bulkCategoryUpload(obj).subscribe(
                (response) => {
                    if (response && response.status && count === 0) {
                        this.onClearHandled();
                        this.notifyService.showSuccess('Success', 'Category uploaded successfully');
                        this.router.navigate(['/Integration/ecommerce/ordereasy/category-master']);
                        // this.getItemMaster();
                    } else if (!response.status) {
                        this.notifyService.showWarn('Error', response.message);
                        this.changeStatus('fromService', obj.item);
                    }
                },
                error => {
                });
        } else if (this.pageAction === 'Edit') {
            this.ecomService.bulkCategoryUpdate(obj).subscribe(
                (response) => {
                    if (response && response.status && count === 0) {
                        this.onClearHandled();
                        this.notifyService.showSuccess('Success', 'Category updated successfully');
                        this.router.navigate(['/Integration/ecommerce/ordereasy/category-master']);
                        // this.getItemMaster();
                    } else if (!response.status) {
                        this.notifyService.showWarn('Error', response.message);
                        this.changeStatus('fromService', obj.item);
                    }
                });
        }
    }

    onClickImport() {
        this.display = 'block';
    }
}



