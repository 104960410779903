import {Injectable} from '@angular/core';
import {EcommerceAPIService} from '../../../../../core/http/ecommerce/ecommerce-api.service';
import {AlertService} from '../../../../../core/http/alert/alert.service';
import {AuthenticationService} from '../../../../../core/services/authentication.service';
import {Observable} from 'rxjs';
import {ItemToggle} from '../../../../../core/models/integration/ecommerce';
import {HttpParams} from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class MasterCreationService {

    constructor(
        private ecommerceService: EcommerceAPIService,
        private alertService: AlertService,
        private authenticationService: AuthenticationService
    ) {
    }

    public getItemReport(outletSelected: string): Observable<ItemToggle[]> {
        const param = new HttpParams()
            .set('userId', '' + this.authenticationService.getServiceUserId())
            .set('vendor', this.authenticationService.getSkewName())
            .set('outletId', outletSelected);
        return this.ecommerceService.get('get/item-master/report', param, null, true);
    }

    public getCategoryDetails(outletSelected: string): Observable<any> {

        const param = new HttpParams()
            .set('userId', '' + this.authenticationService.getServiceUserId())
            .set('vendor', this.authenticationService.getSkewName())
            .set('outletId', outletSelected);
        return this.ecommerceService.get('get/category-master', param);
    }

    public uploadMaster(outletSelected: any, formData: FormData, type: any): Observable<any> {
        const param = new HttpParams()
            .set('userId', '' + this.authenticationService.getServiceUserId())
            .set('vendor', this.authenticationService.getSkewName())
            .set('outletId', outletSelected)
            .set('type', type);
        return this.ecommerceService.postFormData('upload-image', param, null, formData, true);
    }

}
