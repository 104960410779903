import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import {AuthenticationService} from "./authentication.service";
import {SurveyApiService} from "../http/survey/survey-api.service";


@Injectable({
  providedIn: 'root'
})

export class SurveyService {

  constructor(
    private readonly surveyApiService: SurveyApiService,
    private readonly authService: AuthenticationService
  ) { }

  public postOutletDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
    .set('X-Auth-Token', this.authService.getAuthToken());
    const param = new HttpParams();
    return this.surveyApiService.post('v1/save-outlet', param, header, body, true);
  }

  public getParticularOutletDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
    .set('X-Auth-Token', this.authService.getAuthToken());
    const param = new HttpParams();
    return this.surveyApiService.post('v1/get-outlets', param, header, body, true);
  }

  public getOutletDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
    .set('X-Auth-Token', this.authService.getAuthToken());
    const param = new HttpParams();
    return this.surveyApiService.post('v1/get/configured-stores', param, header, body, true);
  }

  public openInvoice(hash: string): Observable<any> {
    const header = new HttpHeaders();
    const param = new HttpParams()
    .set('shareLink', hash);
    return this.surveyApiService.get('v1/get/survey', param,header,true);
  }

  public uploadImg(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
        .set('X-Auth-Token', this.authService.getAuthToken());
    const param = new HttpParams();
    return this.surveyApiService.post('v1/upload-image', param, header, body, true);
  }
}
