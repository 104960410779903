import {Component, OnInit, Input, ElementRef, HostListener} from '@angular/core';
import { SharedService } from '../../service/shared.service';
import { Subscription } from 'rxjs';
import { StompService } from '../../../core/web-socket/StompService';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { Router } from '@angular/router';
import { LoginService } from '../../../modules/login/login.service';
import { NotificationService } from '../../../core/services/notification.service';
import {MenuService} from "../../../core/services/menu.service";
import {environment} from '../../../../environments/environment';
import {CookieService} from 'ngx-cookie-service';

@Component({
    selector: 'cdk-toolbar-notification',
    templateUrl: './toolbar-notification.component.html',
    styleUrls: ['./toolbar-notification.component.scss']
})
export class ToolbarNotificationComponent implements OnInit {

    cssPrefix = 'toolbar-notification';
    isOpen = false;
    @Input() notifications = [];
    private subscription: Subscription;
    pendingCount: number;
    private audio = new Audio();

    notificationList = [];
    unreadNotificationCount = 0;
    disableStompService = ['737', '519', '753', '721', '741', '532', '906', '809', '729'];
    pageIndex = 1;

    constructor(
        private sharedService: SharedService,
        private menuService: MenuService,
        private stompService: StompService,
        private authServie: AuthenticationService,
        private loginService: LoginService,
        private router: Router,
        private cookieService: CookieService,
    ) { }

    playAudio() {
        this.audio.src = '../../../assets/audio/notification.wav';
        this.audio.loop = true;
        this.audio.load();
        this.audio.play();
    }

    stopAudio() {
        this.audio.pause();
    }

    ngOnInit() {
        if(!this.disableStompService.includes(this.authServie.getSkewCode())){
            if (!this.stompService.getConnected() && this.authServie.getSkewCode() != '538') {
                this.stompService.connect(this.authServie.getServiceUserId());
            }
        }
        if (this.authServie.getSkewCode() === '535' || this.authServie.getSkewCode() === '762' || this.authServie.getSkewCode() === '765' || this.authServie.getSkewCode() === '762765'|| this.authServie.getSkewCode() === '811') {
            if (!this.stompService.getConnected()) {
                this.stompService.connect(this.authServie.getServiceUserId());
            }
            this.subscription = this.sharedService.getPendingOrderCount().subscribe(
                event => {
                    this.pendingCount = event;
                    if (event > 0) {
                        this.playAudio();
                    } else {
                        this.stopAudio();
                    }
                });
        }
        this.subscription = this.sharedService.getToolBarNotificationClose().subscribe(
            event => {
                if (event === false) {
                    this.isOpen = false;
                }
            }
        );

        this.subscription = this.sharedService.getPendingNotificationCount().subscribe(
            event => {
                if (!(event === 0)) {
                    this.pageIndex = 1;
                    this.notificationList = [];
                    this.getNotificationList();
                }
            });
        this.getNotificationList();
    }

    bottomReached(): boolean {
        const pos = document.getElementById('notification-scroll').scrollTop + document.documentElement.offsetHeight;
        const max = document.getElementById('notification-scroll').scrollHeight;
        console.log(pos, max);
        if (pos >= max) {
            return true;
        }
        return false;
    }

    @HostListener('scroll', [])
    onScroll(event): void {
        console.log(this.notificationList.length, this.unreadNotificationCount);
        if (this.notificationList.length < this.unreadNotificationCount) {
            if (this.bottomReached()) {
                // clearTimeout(this.timer);
                // this.timer = setTimeout(() => {
                this.pageIndex++;
                this.getNotificationList();
                // }, 500);
            }
        }
    }

    getNotificationList() {
        if (this.cookieService.get('currentUser')) {
            let body = {
                'userId': this.authServie.getServiceUserId(),
                'vendor': this.authServie.getSkewName(),
                'pageSize': 100,
                'pageIndex': this.pageIndex
            };
            this.loginService.getNotification(body).subscribe(
                (data) => {
                    if (data) {
                        console.log(data.notification);
                        this.notificationList = this.notificationList.concat(Object.assign([], data.notification));
                        if (this.notificationList && this.notificationList.length > 0) {
                            this.notificationList = this.notificationList.filter((arr, index, self) => {
                                return index === self.findIndex((t) => (t.id === arr.id));
                            });
                        }
                        console.log(this.notificationList);
                        this.unreadNotificationCount = data.unreadNotificationCount;
                    }
                },
                (err) => {
                    console.error(err);
                });
        }
    }

    onReadNotification(selectedNotification, readAll) {
        let body = {
            "userId": this.authServie.getServiceUserId(),
            "vendor": this.authServie.getSkewName(),
            "notifications": [],
            "readAll": readAll
        }
        if (!readAll) {
            body['notifications'].push(selectedNotification);
        }
        this.loginService.updateNotification(body).subscribe((data) => {
            if (data) {
                this.pageIndex = 1;
                this.notificationList = [];
                this.getNotificationList();
            }
        },
            (err) => {
                console.error(err);
            });
    }

    onViewNotification(item, needRouting) {
        const parentSkew  = '40';
        const subSkew = '4001';
        const salesorder = this.menuService.getMenuIsAvail(parentSkew, subSkew, 'submenu');
        if (!item.read) {
            this.onReadNotification(item, false);
        }
        if (needRouting) {
            this.sharedService.setNotificationOrderNo(item.orderNo);
            if (!window.location.pathname.includes(`${salesorder}`)) {
                this.router.navigate([`/Integration/${salesorder}`], { queryParams: { orderNo: item.orderNo } });
            }
        }
    }

    onNotificationIconClick() {
        if (!this.isOpen) {
            this.pageIndex = 1;
            this.notificationList = [];
            this.getNotificationList();
        }
        this.isOpen = !this.isOpen;
    }
}
